import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { IProposedInvestment } from '../../../services/proposedInvestmentsService'

import InputText from '../../commons/Inputs/InputText'

interface IProposedPlaceProps {
  proposedPlace: IProposedInvestment
}

export const ProposedPlace: FunctionComponent<IProposedPlaceProps> = (
  props: IProposedPlaceProps
) => {
  const { t } = useTranslation()
  const { proposedPlace } = props

  return (
    <>
      <InputText
        id='proposedPlaceInvestmentName'
        disabled
        text={t('commons:labels.investment-code')}
        value={proposedPlace?.investment?.name}
      />
      <InputText
        id='proposedPlaceInvestmentName'
        disabled
        text={t('commons:labels.stage')}
        value={proposedPlace?.stage?.name}
      />
      {proposedPlace?.place?.placeCode ? (
        <InputText
          id='proposedPlacePlaceCode'
          disabled
          text={t('place:labels.place-no')}
          value={proposedPlace?.place?.placeCode}
        />
      ) : null}
    </>
  )
}

export default ProposedPlace
