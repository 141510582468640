import * as React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch, RootState } from '../../ducks'
import {
  downloadXlsxReport,
  fetchDPPPlacesTallyReport,
} from '../../ducks/reports'
import { RaportWithDatesState } from './RaportWithDates/actions'
import { Investments } from './RaportWithDates/Investments'
import { Stages } from './RaportWithDates/Stages'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DPPPlacesTallyReport: React.FC = () => {
  useAuthRedirect(ACCESS_PAGES.DPP_REPORTS)
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(
    (state: RootState) =>
      state.combinedReports.DARArrangementListReport.isLoading
  )
  const generateReport = React.useCallback(
    async (state: RaportWithDatesState) => {
      const investmentsIDs = state.chosenInvestments.map((inv) => inv.id)
      const stagesIDs = state.chosenStages.map((stage) => stage.id)
      const response = await dispatch(
        fetchDPPPlacesTallyReport({ investmentsIDs, stagesIDs })
      )
      const content = response.payload?.DPPPlacesTallyReport
      if (!content) {
        return
      }
      dispatch(
        downloadXlsxReport({
          content,
          filename: 'Raport - zestawienie lokali.xlsx',
        })
      ).then(() => {
        history.push('/')
      })
    },
    [dispatch, history]
  )
  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <Investments />
      <Stages />
    </RaportWithDates>
  )
}

export default DPPPlacesTallyReport
