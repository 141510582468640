import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  createSelector as cs,
} from '@reduxjs/toolkit'
import ReminderService, {
  IAddNote,
  IAddNoteForOtherUser,
  IContractAddNote,
  IPaginatedRemindersParameters,
  IUpdateNote,
  IUserAlert,
  IUserNote,
} from '../services/reminderService'
import FileService from '../services/fileService'
import {
  IContactInCustomersReminder,
  ICustomersReminder,
} from '../services/reminderService'
import moment from 'moment'
import { DATE_FORMAT_YYYYMMDD } from '../constants/dates'
import { RootState } from '.'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

export interface INotesByContractIDParameters {
  contractID: number
  isDARRole: boolean
}

interface IPdfFile {
  content: string
}

interface IReminderState {
  customersReminder: ICustomersReminder[]
  totalCustomerReminders: number
  allNotes: IUserNote[]
  todayNotes: IUserNote[]
  notesByCustomerID: IUserNote[]
  areNotesDownloaded: boolean
  reminderFile: IPdfFile | null
  error: string | null
  notesByContractID: IContractAddNote[]
  alerts: IUserAlert[]
  areAlertsDownloaded: boolean
}

const initialState: IReminderState = {
  customersReminder: [],
  totalCustomerReminders: 0,
  allNotes: [],
  todayNotes: [],
  notesByCustomerID: [],
  areNotesDownloaded: false,
  reminderFile: null,
  error: null,
  notesByContractID: [],
  alerts: [],
  areAlertsDownloaded: false,
}

export const fetchPaginatedCustomersReminder = createAsyncThunk(
  'reminder/fetchPaginatedCustomersReminder',
  async (parameters: IPaginatedRemindersParameters) => {
    return ReminderService.getCustomersRemindersPaginated(parameters)
  }
)

export const fetchCustomersByDailyReminders = createAsyncThunk(
  'reminder/fetchCustomersByDailyReminders',
  async () => {
    return ReminderService.getCustomersByDailyCustomerReminder()
  }
)

export const fetchNotesByCustomerID = createAsyncThunk(
  'reminder/fetchNotesByCustomerID',
  async (customerID: number) => {
    return ReminderService.getNotesByCustomerID(customerID)
  }
)

export const fetchNotesByContractID = createAsyncThunk(
  'reminder/fetchNotesByContractID',
  async (parameters: INotesByContractIDParameters) => {
    return ReminderService.fetchNotesByContractID(parameters)
  }
)

export const fetchDARNotesByUserID = createAsyncThunk(
  'reminder/fetchDARNotes',
  async (userID: string) => {
    return ReminderService.getDARNotesByUserID(userID)
  }
)

export const fetchNotesByUserID = createAsyncThunk(
  'reminder/fetchNotes',
  async (userID: string) => {
    return ReminderService.getNotesByUserID(userID)
  }
)

export const fetchReminderPDFFile = createAsyncThunk(
  'reminder/fetchReminderFile',
  async (userToken: string, thunkAPI) => {
    const response = await ReminderService.getReminderPDFFile(userToken)
    if (response?.reminder) {
      const today = moment().format(DATE_FORMAT_YYYYMMDD)
      const filename = `${today}-DziennePrzypomnienie.pdf`
      FileService.downloadFile({ filename, content: response.reminder.content })
    }
    await thunkAPI.dispatch(setReminderFile(response))
    return response
  }
)
export const fetchAndPrintReminderPDFFile = createAsyncThunk(
  'reminder/fetchAndPrintReminderFile',
  async (userToken: string, thunkAPI) => {
    const response = await ReminderService.getReminderPDFFile(userToken)
    if (response?.reminder) {
      FileService.printBase64PdfFile({ content: response.reminder.content })
    }
    await thunkAPI.dispatch(setReminderFile(response))
    return response
  }
)
export const addUserNote = createAsyncThunk(
  'reminder/addUserNote',
  async (note: IAddNote) => {
    return ReminderService.addNote(note)
  }
)

export const addNoteForOtherUser = createAsyncThunk(
  'reminder/addNoteForOtherUser',
  async (note: IAddNoteForOtherUser) => {
    return ReminderService.addNoteForOtherUser(note)
  }
)

export const updateUserNote = createAsyncThunk(
  'reminder/updateUserNote',
  async (parameters: IUpdateNote) => {
    return ReminderService.updateNote(parameters)
  }
)

export const deleteUserNote = createAsyncThunk(
  'reminder/deleteUserNote',
  async (noteID: number) => {
    return ReminderService.deleteNote(noteID)
  }
)

export const fetchUserAlertsByDate = createAsyncThunk(
  'reminder/fetchUserAlertsByDate',
  async (date: string) => {
    return ReminderService.getUserAlertsByDate(date)
  }
)

export const getMessageAlerts = createAsyncThunk(
  'reminder/getMessageAlertsAndDelete',
  async () => {
    return ReminderService.getMessageAlertsAndDelete()
  }
)

const reminderSlice = createSlice({
  name: 'reminder',
  initialState,
  reducers: {
    setReminderFile(state, action: PayloadAction<IPdfFile>): void {
      state.reminderFile = action.payload
    },
    clearNotes(state): void {
      state.notesByCustomerID = []
      state.notesByContractID = []
    },
  },
  extraReducers: {
    [fetchPaginatedCustomersReminder.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.totalCustomerReminders =
        action.payload.paginateCustomersForReminder?.total ?? 0
      state.customersReminder =
        action.payload.paginateCustomersForReminder?.customers ?? []
    },
    [fetchCustomersByDailyReminders.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.customersReminder = action.payload.customersByDailyCustomerReminder
    },
    [fetchNotesByCustomerID.fulfilled.toString()]: (state, action): void => {
      state.notesByCustomerID = action.payload.notesByCustomerID
    },
    [fetchNotesByContractID.fulfilled.toString()]: (state, action): void => {
      state.notesByContractID = action.payload.notesByContractID
    },
    [fetchNotesByUserID.fulfilled.toString()]: (state, action): void => {
      state.allNotes = action.payload?.notesByUserID
      const today = moment().format(DATE_FORMAT_YYYYMMDD)
      const todayNotes = action.payload.notesByUserID?.filter(
        (note: IUserNote) => note?.reminderDate === today
      )
      state.todayNotes = todayNotes
      state.areNotesDownloaded = true
    },
    [fetchDARNotesByUserID.fulfilled.toString()]: (state, action): void => {
      state.allNotes = action.payload?.DARNotesByUserID
      const today = moment().format(DATE_FORMAT_YYYYMMDD)
      const todayNotes = action.payload.DARNotesByUserID?.filter(
        (note: IUserNote) => note?.reminderDate === today
      )
      state.todayNotes = todayNotes
      state.areNotesDownloaded = true
    },
    [addUserNote.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:addNote')))
    },
    [addUserNote.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:addNoteError')))
    },
    [deleteUserNote.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:deleteNote')))
    },
    [deleteUserNote.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:deleteNoteError')))
    },
    [updateUserNote.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editNote')))
    },
    [updateUserNote.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editNoteError')))
    },
    [fetchUserAlertsByDate.fulfilled.toString()]: (state, action): void => {
      state.alerts = action.payload.alertsByDateAndUser
      state.areAlertsDownloaded = true
    },
    [fetchUserAlertsByDate.rejected.toString()]: (state): void => {
      state.areAlertsDownloaded = false
    },
  },
})

const stateSelector = (state: RootState): RootState => state
export const getCustomersReminder = cs(
  stateSelector,
  (_stateSelector) => _stateSelector.reminder.customersReminder
)

export const { clearNotes, setReminderFile } = reminderSlice.actions

export default reminderSlice.reducer
