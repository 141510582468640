import * as React from 'react'
import { useTableOptions } from './useTableOptions'
import Pagination from './Pagination'
import TableBody from './TableBody'
import { TableContext } from './TableContext'
import TableFooter from './TableFooter'
import RedirectButton from './RedirectButton'
import TableHeader from './Header'
import SensitiveInfoButton from './SensitiveInfoButton'
import Filters from './Filters'
import TableSearch from './TableSearch'
import TableTitle from './TableTitle'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../ducks'
import PaginationWithFetch from './PaginationWithFetch'
import TableSearchWithPagination from './TableSearchWithPagination'
import { setCheckedValueOnSingleElement } from '../../../ducks/persistSettings'
import _ from 'lodash'
import { useTableRowSelect } from './useTableRowSelect'

type TableProps<Data, Columns> = {
  data: Data[]
  columns: Columns[]
  customTableWidth?: string
  defaultNumberOfRows?: number
  isMainTable?: boolean
  isSelectAllCheckbox?: boolean
  reSelectAllOnChange?: boolean
  customLoading?: boolean
  children: React.ReactNode
  totalRows?: number
  manualSortBy?: boolean
  hidenCols?: string[]
  withoutPaddingX?: boolean
}

function Table<Data, Columns>({
  children,
  data,
  columns,
  customTableWidth,
  defaultNumberOfRows = 10,
  isMainTable = false,
  isSelectAllCheckbox,
  reSelectAllOnChange = false,
  customLoading = false,
  manualSortBy = false,
  totalRows = 0,
  hidenCols = [],
  withoutPaddingX = false,
}: TableProps<Data, Columns>): JSX.Element {
  const { mainTableHiddenColumns } = useSelector(
    (state: RootState) => state.persistSettings
  )
  const dispatch = useDispatch()

  const hidenColumnsFromStore = React.useMemo(() => {
    let hiddenColumnsFromPersistStore: string[] = []
    for (const [key, value] of Object.entries(mainTableHiddenColumns)) {
      if (!value) {
        hiddenColumnsFromPersistStore = [...hiddenColumnsFromPersistStore, key]
      }
    }
    return hiddenColumnsFromPersistStore
  }, [mainTableHiddenColumns])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialHiddenColumns = React.useMemo(
    () => _.uniq([...hidenCols, ...hidenColumnsFromStore]),
    [hidenCols, hidenColumnsFromStore]
  )

  const tableOptions = useTableOptions({
    data,
    columns,
    defaultNumberOfRows,
    manualSortBy,
    initialHiddenData: initialHiddenColumns,
    isSelectAllCheckbox,
  })

  const isPrevArrayEqual = React.useMemo(
    () => _.isEqual(hidenColumnsFromStore.sort(), initialHiddenColumns?.sort()),
    [hidenColumnsFromStore, initialHiddenColumns]
  )
  React.useEffect(() => {
    if (isMainTable && !isPrevArrayEqual) {
      let hiddenColumnsFromProps = {}
      hidenCols.forEach((title) => {
        hiddenColumnsFromProps = {
          ...hiddenColumnsFromProps,
          [title]: false,
        }
      })
      dispatch(
        setCheckedValueOnSingleElement({
          ...mainTableHiddenColumns,
          ...hiddenColumnsFromProps,
        })
      )
      tableOptions.setHiddenColumns(hidenCols)
      return
    }
    tableOptions.setHiddenColumns(initialHiddenColumns)
    // TODO INVK-1662 - this line is causing an useEffect error:
    // [isMainTable, isPrevArrayEqual, roles, initialHiddenColumns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMainTable, isPrevArrayEqual])

  React.useEffect(() => {
    reSelectAllOnChange && tableOptions.toggleAllRowsSelected(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reSelectAllOnChange])

  const value = React.useMemo(() => tableOptions, [tableOptions])
  useTableRowSelect({
    isMainTable,
    selectedRowIds: tableOptions.state.selectedRowIds,
    toggleRowSelected: tableOptions.toggleRowSelected,
    dataLength: data.length,
  })
  return (
    <TableContext.Provider
      value={{ table: { ...value, customLoading, isMainTable, totalRows } }}
    >
      <div
        className='table-container'
        style={{
          width: customTableWidth ? `${customTableWidth}` : undefined,
          padding: withoutPaddingX ? '1rem 0' : '1rem',
        }}
      >
        {children}
      </div>
    </TableContext.Provider>
  )
}

Table.Pagination = Pagination
Table.PaginationWithFetch = PaginationWithFetch
Table.TableBody = TableBody
Table.Footer = TableFooter
Table.RedirectButton = RedirectButton
Table.Header = TableHeader
Table.SensitiveInfoButton = SensitiveInfoButton
Table.Filters = Filters
Table.TableSearch = TableSearch
Table.TableSearchWithPagination = TableSearchWithPagination
Table.Title = TableTitle

export default Table
