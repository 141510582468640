import React, { FC } from 'react'

import './Reports.scss'

import { ReportWithList } from './ReportWithList'

import { fetchDARArrangementReport } from '../../ducks/reports'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DARArrangementReport: FC = () => {
  useAuthRedirect(ACCESS_PAGES.DAR_REPORTS)
  return (
    <ReportWithList
      reportAPIName='DARArrangementReport'
      reportName='Raport-aranzacje.xlsx'
      reduxAction={fetchDARArrangementReport}
      isLoading={false}
    />
  )
}

export default DARArrangementReport
