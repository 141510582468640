import { useEffect } from 'react'
import { useUserPagesAbility } from '../hooks/ability'
import { useSelector } from 'react-redux'
import { RootState } from '../ducks'
import { useHistory } from 'react-router-dom'

export default (accessPage: string | string[]): void => {
  const { canReadPage } = useUserPagesAbility()
  const hasAccess = Array.isArray(accessPage)
    ? accessPage.find((a) => canReadPage(a))
    : canReadPage(accessPage)
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const history = useHistory()

  useEffect(() => {
    if (!hasAccess) {
      history.push('/')
    }
  }, [roles])
}
