import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IChanges,
  ICorrespondenceFileInput,
  ICorrespondenceInput,
  ICorrespondenceQuery,
} from '../services/correspondenceService'
import CorrespondenceService from '../services/correspondenceService'
import { RootState } from '.'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'
import FileService from '../services/fileService'

interface ICorrenspondencesState {
  correspondence: ICorrespondenceQuery
  history: IChanges[]
  isUploading: boolean
}

const initialState: ICorrenspondencesState = {
  correspondence: { getContract: { correspondences: [] } },
  isUploading: false,
  history: [],
}

export const getAllCorrespondencesById = createAsyncThunk(
  'correspondence/getAllCorrespondencesById',
  async (id: number) => {
    return CorrespondenceService.getAllCorrespondencesById(id)
  }
)

export const saveCorrespondence = createAsyncThunk(
  'correspondence/saveConrespondence',
  async (correspondence: ICorrespondenceInput) => {
    return CorrespondenceService.saveConrespondence(correspondence)
  }
)

export const saveCorrespondenceFile = createAsyncThunk(
  'correspondence/saveCorrespondenceFile',
  async (correspondenceFile: ICorrespondenceFileInput) => {
    return CorrespondenceService.saveCorrespondenceFile(correspondenceFile)
  }
)

export const updateCorrespondence = createAsyncThunk(
  'correspondence/updateCorrespondence',
  async ({
    correspondence,
    correspondenceID,
  }: {
    correspondence: ICorrespondenceInput
    correspondenceID: number
  }) => {
    return CorrespondenceService.updateCorrespondence({
      correspondence,
      correspondenceID,
    })
  }
)

export const deleteCorrespondence = createAsyncThunk(
  'correspondence/deleteCorrespondence',
  async (correspondenceID: number) => {
    return CorrespondenceService.deleteCorrespondence(correspondenceID)
  }
)

export const updateCorrespondenceFile = createAsyncThunk(
  'correspondence/updateCorrespondenceFile',
  async ({
    correspondenceFile,
    correspondenceFileID,
  }: {
    correspondenceFile: ICorrespondenceFileInput
    correspondenceFileID: number
  }) => {
    return CorrespondenceService.updateCorrespondenceFile({
      correspondenceFile,
      correspondenceFileID,
    })
  }
)

export const fetchCorrespondenceChanges = createAsyncThunk(
  'events/fetchCorrespondenceChanges',
  async (arg, thunkAPI) => {
    const contractID = (thunkAPI.getState() as RootState).globalInvestment
      .chosenPlacesContract?.activeContracts[0]?.id as number
    return CorrespondenceService.fetchCorrespondenceChanges(contractID)
  }
)

export const getCorrespondenceFile = createAsyncThunk(
  'correspondence/getCorrespondenceFile',
  async (variables: { fileID: number; fileName: string }) => {
    const result = await CorrespondenceService.getCorrespondenceFile(
      variables.fileID
    )

    if (result && result.getCorrespondenceFile.content) {
      FileService.downloadFileSavedByUser({
        filename: variables.fileName,
        content: result.getCorrespondenceFile.content,
      })
    }
  }
)

export const getEventFile = createAsyncThunk(
  'events/getEventFile',
  async (variables: { fileID: number; fileName: string }) => {
    const result = await CorrespondenceService.getEventFile(variables.fileID)

    if (result && result.getEventFile.content) {
      FileService.downloadFileSavedByUser({
        filename: variables.fileName,
        content: result.getEventFile.content,
      })
    }
  }
)

const correspondencesSlice = createSlice({
  name: 'correspondences',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllCorrespondencesById.fulfilled.toString()]: (state, action): void => {
      state.correspondence = action.payload
    },
    [saveCorrespondence.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:saveCorrespondence')))
    },
    [saveCorrespondence.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:saveCorrespondenceError')))
    },
    [updateCorrespondence.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editCorrespondence')))
    },
    [updateCorrespondence.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editCorrespondenceError')))
    },
    [deleteCorrespondence.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:deleteCorrespondence')))
    },
    [deleteCorrespondence.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:deleteCorrespondenceError')))
    },
    [fetchCorrespondenceChanges.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.history = action.payload.getCorrespondencesChangesByContract
    },
    [saveCorrespondenceFile.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addFile')))
      state.isUploading = false
    },
    [saveCorrespondenceFile.pending.toString()]: (state): void => {
      state.isUploading = true
    },
    [saveCorrespondenceFile.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addFileError')))
      state.isUploading = false
    },
    [updateCorrespondenceFile.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:updateFile')))
      state.isUploading = false
    },
    [updateCorrespondenceFile.pending.toString()]: (state): void => {
      state.isUploading = true
    },
    [updateCorrespondenceFile.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:updateFileError')))
      state.isUploading = false
    },
  },
})

export default correspondencesSlice.reducer
