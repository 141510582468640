import _ from 'lodash'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { IInvestment, IStage } from '../../../graphql/investments'

import MainColForReportCheckbox from '../../commons/Containers/MainColForReportCheckbox'
import MainRow from '../../commons/Containers/MainRow'
import SimpleInputCheckbox3 from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'

import { updateStages } from './actions'
import { useCheckIfSmallResolution } from '../../../hooks/useCheckIfSmallResolution'
import { useRaportsWithDatesContext } from './raportWithDatesContext'

export const Stages = (): JSX.Element => {
  const { t } = useTranslation()
  const { state, dispatch } = useRaportsWithDatesContext()

  const chosenInvestmentsWithStages = state.chosenInvestments.filter(
    (inv) => inv.stages?.length > 0
  )

  const selectAllStages = React.useCallback(
    (investment: IInvestment): void => {
      const allStageIDs = investment?.stages?.filter(
        (stage: IStage) => stage?.name !== 'Wszystkie'
      )
      const duplicates = _.filter(
        [...allStageIDs, ...state.chosenStages],
        (value, index, iteratee) => _.includes(iteratee, value, index + 1)
      )
      if (duplicates.length) {
        const investmentsIDsCleared = state.chosenStages.filter(
          (stage) => !allStageIDs.includes(stage)
        )
        return dispatch(updateStages(investmentsIDsCleared))
      }
      dispatch(updateStages([...state.chosenStages, ...allStageIDs]))
    },
    [dispatch, state.chosenStages]
  )

  const handleStageClick = React.useCallback(
    (clickedStage: IStage) => {
      const index = state.chosenStages.findIndex(
        (stage: IStage) => stage.id === clickedStage.id
      )
      if (index === -1) {
        dispatch(updateStages([...state.chosenStages, clickedStage]))
      } else {
        const newStages = state.chosenStages.filter(
          (stage) => stage.id !== clickedStage.id
        )
        dispatch(updateStages(newStages))
      }
    },
    [dispatch, state.chosenStages]
  )

  const isOneColInRow = useCheckIfSmallResolution()

  return (
    <>
      {chosenInvestmentsWithStages.map((investment, index) => (
        <React.Fragment key={investment.id + '.' + index}>
          <MainRow isRowContentCentered>
            {`${t('commons:labels.choose-stage-for-investment')}: ${
              investment.name
            }`}
          </MainRow>
          <MainRow isRowContentCentered>
            <Button onClick={(): void => selectAllStages(investment)}>
              {t('commons:actions.select-deselect-all')}
            </Button>
          </MainRow>
          <MainRow isRowContentCentered>
            {investment?.stages?.map((stage: IStage, index: number) => {
              const isDNKStage = stage?.name.includes('komercja')
              const numberOfCols = investment?.stages?.length ?? 0
              return (
                <MainColForReportCheckbox
                  numberOfCols={numberOfCols}
                  isDNKStage
                  isOneColInRow={isOneColInRow}
                  key={stage.id + '.' + index}
                >
                  <SimpleInputCheckbox3
                    additionalOnChange={(): void => handleStageClick(stage)}
                    customLabelWidth={isDNKStage ? 10 : 7}
                    label={stage.name}
                    name={`${stage.id}${stage.name}-stage`}
                    value={!!state.chosenStages.includes(stage)}
                  />
                </MainColForReportCheckbox>
              )
            })}
          </MainRow>
        </React.Fragment>
      ))}
    </>
  )
}
