import React, { FunctionComponent } from 'react'
import './Reports.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'

import { fetchDNIActsReport } from '../../ducks/reports'
import { ReportWithList } from './ReportWithList'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DNIActsReport: FunctionComponent = () => {
  useAuthRedirect(ACCESS_PAGES.DNI_REPORTS)
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DNIActsReport
  )

  return (
    <ReportWithList
      isLoading={isLoading}
      reportAPIName='DNIActScheduleReport'
      reportName='RaportDNIActy.xlsx'
      reduxAction={fetchDNIActsReport}
    />
  )
}

export default DNIActsReport
