import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ReminderSectionHeader } from './Reminder'
import { IUserAlert } from '../../services/reminderService'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'

export interface IMyAlertsReminderBodyProps {
  alerts: IUserAlert[]
}

const MyAlertsReminderBody: FunctionComponent<IMyAlertsReminderBodyProps> = (
  props
) => {
  const { alerts } = props
  const { t } = useTranslation()
  const { numberOfNotTakenUnassignedCustomers } = useSelector(
    (state: RootState) => state.unassignedCustomers
  )
  const alertsPastLead = alerts.filter(
    (alert) => alert.alertType === 'notTakenLead'
  )
  const alertsCustomerWithoutSecondContact = alerts.filter(
    (alert) => alert.alertType === 'customerWithoutSecondContact'
  )
  const alertsMyPastLead = alerts.filter(
    (alert) => alert.alertType === 'leadWithoutContact'
  )

  const getContent = (unassignedCustomer: any) =>
    `- ${unassignedCustomer.firstName} ${unassignedCustomer.lastName}${
      unassignedCustomer.email ? ', ' + unassignedCustomer.email : ''
    }${unassignedCustomer?.phone ? ', tel: ' + unassignedCustomer?.phone : ''}${
      unassignedCustomer?.creationDate
        ? ', ' + unassignedCustomer.creationDate
        : ''
    }`

  const getCustomerContent = (interestedCustomer: any) =>
    `- ${interestedCustomer.firstName} ${interestedCustomer.lastName}  ${
      interestedCustomer.email ? ', ' + interestedCustomer.email : ''
    }${
      interestedCustomer?.phone1 ? ', tel: ' + interestedCustomer.phone1 : ''
    }${
      interestedCustomer?.lastContactDate
        ? ', ' + interestedCustomer.lastContactDate
        : ''
    }
        `

  const alertArray = [
    { name: 'pastLead', alerts: alertsPastLead },
    { name: 'myPastLead', alerts: alertsMyPastLead },
    {
      name: 'customerWithoutSecondContact',
      alerts: alertsCustomerWithoutSecondContact,
    },
  ]

  return (
    <Col className='w-100'>
      <ReminderSectionHeader
        text={
          t('commons:labels.notTakenLead') +
          ` (${numberOfNotTakenUnassignedCustomers})`
        }
      />
      {alertArray.map((alertObject, index) => {
        const items = alertObject.alerts
        const itemName = alertObject.name
        const showCustomer = itemName === 'customerWithoutSecondContact'

        return (
          <div key={'alertObject' + index}>
            <ReminderSectionHeader
              text={t(`commons:labels.${itemName}`) + ` (${items.length})`}
            />
            {itemName !== 'pastLead' &&
              items.map((alert, index) => {
                const customer = showCustomer
                  ? alert.customer
                  : alert.unassignedCustomer

                if (customer) {
                  return (
                    <Row key={'alertsWithoutCustomer' + index}>
                      <Col className='mb-3' lg='auto' md='auto' sm={12}>
                        <span>
                          {showCustomer
                            ? getCustomerContent(customer)
                            : getContent(customer)}
                        </span>
                      </Col>
                    </Row>
                  )
                }
              })}
          </div>
        )
      })}
    </Col>
  )
}
export default MyAlertsReminderBody
