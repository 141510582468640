import React, { FunctionComponent } from 'react'
import './Reports.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDNIPickupScheduleReport } from '../../ducks/reports'
import { ReportWithList } from './ReportWithList'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DNIPickupScheduleReport: FunctionComponent = () => {
  useAuthRedirect(ACCESS_PAGES.DNI_REPORTS)
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DNIPickupScheduleReport
  )

  return (
    <ReportWithList
      reportAPIName='DNIPickupScheduleReport'
      reportName='Raport-odbiorow.xlsx'
      reduxAction={fetchDNIPickupScheduleReport}
      isLoading={isLoading}
    />
  )
}

export default DNIPickupScheduleReport
