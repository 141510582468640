import React, { FC } from 'react'

const TableHeaderWrapper: FC<{
  top?: string
  children: any
  zIndex?: number
}> = ({ children, top, zIndex }) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        position: 'sticky',
        top: top || '150px',
        zIndex: zIndex || 10,
      }}
    >
      {children ? children : <></>}
    </div>
  )
}

export default TableHeaderWrapper
