import React, { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SimpleInputCheckbox from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox'
import { updateEmailInvoice, updateValorization } from './actions'

import { useRaportsWithDatesContext } from './raportWithDatesContext'

export const EmailInvoiceAndValorization = (): JSX.Element => {
  const { dispatch } = useRaportsWithDatesContext()
  const { t } = useTranslation()

  const [emailInvoice, setEmailInvoice] = useState(false)
  const [valorization, setValorization] = useState(false)

  const updateInvoiceValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmailInvoice(e.target.checked)
      dispatch(updateEmailInvoice(e.target.checked))
    },
    [dispatch]
  )

  const updateValorizationValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValorization(e.target.checked)
      dispatch(updateValorization(e.target.checked))
    },
    [dispatch]
  )

  return (
    <div className='d-flex mt-2'>
      <SimpleInputCheckbox
        customLabelWidth='auto'
        additionalOnChange={updateInvoiceValue}
        defaultChecked={emailInvoice}
        label={String(t('commons:labels.email-invoice'))}
      />
      <SimpleInputCheckbox
        customLabelWidth='auto'
        additionalOnChange={updateValorizationValue}
        defaultChecked={valorization}
        label={String(t('commons:labels.valorization'))}
      />
    </div>
  )
}
