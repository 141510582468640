import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../ducks'
import { getGaragesByInvestmentNames } from '../../../ducks/garages'
import { fetchGroundPlanContent } from '../../../ducks/globalInvestment'

import ButtonWithTooltip from '../Inputs/ButtonTooltip'
import { ButtonWithOverlayTooltip } from '../Inputs/ButtonWithOverlayTooltip'
import MainButton from '../Inputs/MainButton'
import ReduxModal from '../Modals/ReduxModal'

interface IGarageForMainView {
  id: number
  name?: string
  groundPlans?: {
    id?: number
    garageID?: number
  }[]
  building?: {
    stageID?: number
  }
}

const GroundPlanButton: FC = () => {
  const { garagesByInvestmentsNames } = useSelector(
    (state: RootState) => state.garages
  )
  const { chosenPlacesContract, chosenInvestment } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const [
    isSelectGroundPlanModalVisible,
    setIsSelectGroundPlanModalVisible,
  ] = useState(false)
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const toggleSelectGroundPlanModal = useCallback(() => {
    setIsSelectGroundPlanModalVisible(!isSelectGroundPlanModalVisible)
  }, [setIsSelectGroundPlanModalVisible, isSelectGroundPlanModalVisible])

  const downloadGroundPlan = useCallback(
    (passedName?: string, passedID?: number): void => {
      const fileName = passedName
        ? passedName
        : chosenPlacesContract?.groundPlan
        ? chosenPlacesContract?.groundPlan?.name
        : ''
      const fileID = passedID
        ? passedID
        : chosenPlacesContract?.groundPlan
        ? chosenPlacesContract?.groundPlan.id
        : 0

      dispatch(fetchGroundPlanContent(fileID)).then((response: any) => {
        const downloadLink = document.createElement('a')
        document.body.appendChild(downloadLink)
        downloadLink.href = response.payload.file.content
        downloadLink.target = '_self'
        downloadLink.download = fileName
        downloadLink.click()
      })
    },
    [chosenPlacesContract, dispatch]
  )

  useEffect(() => {
    chosenInvestment?.name &&
      dispatch(getGaragesByInvestmentNames([chosenInvestment.name]))
  }, [dispatch, chosenInvestment])

  const allGaragesInStage: IGarageForMainView[] = garagesByInvestmentsNames.filter(
    (garage) => garage.building.stageID === chosenPlacesContract?.stage?.id
  )
  const garagesOfPlacesBuilding = chosenPlacesContract?.building?.garages ?? []
  const placeHasGaragesWithGroundPlans = Boolean(
    garagesOfPlacesBuilding?.length &&
      garagesOfPlacesBuilding.find((garage) => garage.groundPlans?.length)
  )

  const [
    garagesTakenFromPlacesBuildingOrWholeStage,
    setGaragesTakenFromPlacesBuildingOrWholeStage,
  ] = useState(
    placeHasGaragesWithGroundPlans
      ? garagesOfPlacesBuilding
      : chosenPlacesContract?.isAfterMigration
      ? []
      : allGaragesInStage
  )

  useEffect(() => {
    setGaragesTakenFromPlacesBuildingOrWholeStage(
      placeHasGaragesWithGroundPlans
        ? garagesOfPlacesBuilding
        : chosenPlacesContract?.isAfterMigration
        ? []
        : allGaragesInStage
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chosenPlacesContract,
    placeHasGaragesWithGroundPlans,
    garagesByInvestmentsNames,
  ])

  const garagesOfUtilityRoomsOnContract =
    chosenPlacesContract?.activeContracts?.[0]?.utilityRooms?.map(
      (ur) => ur?.garage
    ) ?? []
  const garagesOfUtilityRoomsOnPlace =
    chosenPlacesContract?.utilityRooms?.map((ur) => ur?.garage) ?? []
  const garagesOfParkingPlacesOnContract =
    chosenPlacesContract?.activeContracts?.[0]?.parkingPlaces?.map(
      (pp) => pp?.garage
    ) ?? []
  const garagesOfParkingPlacesOnPlace =
    chosenPlacesContract?.parkingPlaces?.map((pp) => pp?.garage) ?? []
  const allGarages = useMemo(
    () => [
      ...garagesTakenFromPlacesBuildingOrWholeStage,
      ...garagesOfUtilityRoomsOnContract,
      ...garagesOfUtilityRoomsOnPlace,
      ...garagesOfParkingPlacesOnContract,
      ...garagesOfParkingPlacesOnPlace,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      garagesOfUtilityRoomsOnContract,
      garagesOfParkingPlacesOnContract,
      garagesOfUtilityRoomsOnPlace,
      garagesOfParkingPlacesOnPlace,
      garagesTakenFromPlacesBuildingOrWholeStage,
      chosenPlacesContract,
    ]
  )

  const allGroundPlans: any[] = []
  const setGroundPlans = (): any =>
    allGarages?.length &&
    allGarages.map(
      (garage) =>
        garage?.groundPlans && allGroundPlans.push(...garage?.groundPlans)
    )
  setGroundPlans()

  const mappedGarage: any = {}
  for (const garage of allGarages) {
    if (garage) {
      mappedGarage[garage.id] = garage
    }
  }
  const uniqueGarages = Object.values(mappedGarage)

  const uniqueGroundPlansIDs = Array.from(
    new Set(allGroundPlans.map((gp) => gp.id))
  )
  const sumGaragesPlan = uniqueGroundPlansIDs.length ?? 0

  const selectGroundPlanModalBody = useMemo(
    () => (
      <>
        {uniqueGarages?.map((garage: any, index: number) =>
          garage.groundPlans?.length ? (
            <div key={garage?.id + '.' + index}>
              <p>Nazwa hali: {garage?.name}</p>
              {garage.groundPlans.map((groundPlan: any) => (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className='d-flex justify-content-md-center'
                  key={`${groundPlan?.id} - ${groundPlan?.name}.` + index}
                >
                  <ButtonWithTooltip
                    shouldTooltipBeVisible={groundPlan.migrationComment}
                    message={groundPlan?.migrationComment || ''}
                    isVisibleAsDefault
                  >
                    <MainButton
                      key={groundPlan?.id}
                      onClick={(): void =>
                        downloadGroundPlan(groundPlan?.name, groundPlan?.id)
                      }
                      disabled={Boolean(groundPlan?.migrationComment)}
                    >
                      {groundPlan?.name ??
                        t('commons:actions.download-ground-plan')}
                    </MainButton>
                  </ButtonWithTooltip>
                </Col>
              ))}
            </div>
          ) : null
        )}
      </>
    ),
    [downloadGroundPlan, uniqueGarages, t]
  )
  const groundPlanMigrationComment = useMemo(
    () => chosenPlacesContract?.groundPlan?.migrationComment,
    [chosenPlacesContract]
  )

  return (
    <>
      <ButtonWithOverlayTooltip
        show={!!groundPlanMigrationComment}
        message={groundPlanMigrationComment || ''}
      >
        <Button
          className='ik2-main-button-color '
          onClick={(): void => downloadGroundPlan()}
          disabled={
            !chosenPlacesContract?.groundPlan || !!groundPlanMigrationComment
          }
        >
          {t('commons:menu.plan')}
        </Button>
      </ButtonWithOverlayTooltip>
      <Button
        className='ik2-main-button-color'
        onClick={toggleSelectGroundPlanModal}
        disabled={sumGaragesPlan <= 0}
      >
        {t('commons:menu.halls-plans')} ({sumGaragesPlan})
      </Button>
      <ReduxModal
        body={selectGroundPlanModalBody}
        cancelAction={toggleSelectGroundPlanModal}
        onHide={toggleSelectGroundPlanModal}
        show={isSelectGroundPlanModalVisible}
        title={t('commons:actions.download-ground-plan')}
      />
    </>
  )
}

export default GroundPlanButton
