import _ from 'lodash'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../ducks'
import { IInvestment, IStage } from '../../../graphql/investments'

import MainColForReportCheckbox from '../../commons/Containers/MainColForReportCheckbox'
import MainRow from '../../commons/Containers/MainRow'
import SimpleInputCheckbox3 from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'

import { updateInvestments, updateStages } from './actions'
import { useRaportsWithDatesContext } from './raportWithDatesContext'

import { useCheckIfSmallResolution } from '../../../hooks/useCheckIfSmallResolution'

export const Investments = (): JSX.Element => {
  const { t } = useTranslation()
  const { investmentsList } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const { state, dispatch } = useRaportsWithDatesContext()

  const selectAllInvestments = React.useCallback(() => {
    const allInvestmentIDs = investmentsList.filter(
      (inv: IInvestment) =>
        inv?.name !== 'Umowy anulowane' && inv?.name !== 'Wszystkie'
    )
    const allStagesIDs = investmentsList
      .map((inv: IInvestment) =>
        inv?.stages.filter((stage: IStage) => stage?.name !== 'Wszystkie')
      )
      .flat(1)
    const duplicates = _.filter(
      [...allInvestmentIDs, ...state.chosenInvestments],
      (value, index, iteratee) => _.includes(iteratee, value, index + 1)
    )
    if (duplicates.length) {
      const investmentsIDsCleared = state.chosenInvestments.filter(
        (inv) => !allInvestmentIDs.includes(inv)
      )
      const stagesIDsCleared = state.chosenStages?.filter(
        (stage) => !allStagesIDs.includes(stage)
      )
      dispatch(updateStages(stagesIDsCleared))
      return dispatch(updateInvestments(investmentsIDsCleared))
    }
    dispatch(
      updateInvestments([...state.chosenInvestments, ...allInvestmentIDs])
    )
    dispatch(updateStages([...state.chosenStages, ...allStagesIDs]))
  }, [dispatch, investmentsList, state.chosenInvestments, state.chosenStages])

  const handleInvestmentClick = React.useCallback(
    (clickedInvestment: IInvestment) => {
      const index = state.chosenInvestments.findIndex(
        (investment: IInvestment) => investment?.id === clickedInvestment.id
      )
      if (index === -1) {
        const stagesOfClickedInvestment = clickedInvestment?.stages?.filter(
          (stage: IStage) => stage?.id && stage?.id > 0
        )
        dispatch(
          updateInvestments([...state.chosenInvestments, clickedInvestment])
        )
        stagesOfClickedInvestment &&
          dispatch(
            updateStages([...state.chosenStages, ...stagesOfClickedInvestment])
          )
      } else {
        const newInvestments = state.chosenInvestments.filter(
          (inv) => inv.id !== clickedInvestment.id
        )
        const newStages = state.chosenStages.filter(
          (stage) => stage.investmentID !== clickedInvestment.id
        )
        dispatch(updateInvestments(newInvestments))
        dispatch(updateStages(newStages))
      }
    },
    [dispatch, state.chosenInvestments, state.chosenStages]
  )

  const isOneColInRow = useCheckIfSmallResolution()

  return (
    <>
      <MainRow isRowContentCentered>
        <p className='icr-header mt-4'>
          {t('commons:labels.choose-investment')}
        </p>
      </MainRow>
      <MainRow isRowContentCentered>
        <Button onClick={selectAllInvestments}>
          {t('commons:actions.select-deselect-all')}
        </Button>
      </MainRow>
      <MainRow isRowContentCentered>
        {investmentsList.map((investment: IInvestment, index: number) => {
          const numberOfCols = investment?.stages?.length ?? 0
          return investment.name !== 'Umowy anulowane' &&
            investment.name !== 'Wszystkie' ? (
            <MainColForReportCheckbox
              numberOfCols={numberOfCols}
              isOneColInRow={isOneColInRow}
              key={investment.id + '.' + index}
            >
              <SimpleInputCheckbox3
                additionalOnChange={(): void =>
                  handleInvestmentClick(investment)
                }
                customLabelWidth={7}
                name={investment.name}
                label={investment.name}
                value={Boolean(state.chosenInvestments.includes(investment))}
              />
            </MainColForReportCheckbox>
          ) : null
        })}
      </MainRow>
    </>
  )
}
