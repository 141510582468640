import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../ducks'
import {
  clearReportState,
  downloadXlsxReport,
  fetchStagesWithPlacesForReport,
} from '../../ducks/reports'
import SimpleInputCheckbox3 from '../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'
import * as _ from 'lodash'
import useGetRole from '../../hooks/useGetRole'

interface ReportWithListProps {
  reduxAction: (arg: number[]) => void
  isLoading: boolean
  selectByActiveContractsIDs?: boolean
  reportAPIName: string
  reportName: string
}

export const ReportWithList: React.FC<ReportWithListProps> = ({
  reduxAction,
  isLoading,
  selectByActiveContractsIDs,
  reportAPIName,
  reportName,
}) => {
  const { isDNKCustomer, isDSPCustomer } = useGetRole()
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()

  const { chosenInvestment, chosenStage } = useSelector(
    (state: RootState) => state.globalInvestment
  )

  const { placesForReport } = useSelector((state: RootState) => state.reports)

  const [places, setPlaces] = React.useState<any[]>([])

  const buildingsByChosenStage = React.useMemo(
    () =>
      placesForReport.filter(
        (stage) => stage?.id === chosenStage?.id || chosenStage.id === -1
      ),
    [chosenStage, placesForReport]
  )

  const placesByServiceType = React.useMemo(
    () =>
      buildingsByChosenStage.filter((stage) => {
        const buildingsForDNK = stage.name.includes('_komercja')
        const buildingsForDSP = !stage.name.includes('_komercja')
        return isDNKCustomer
          ? buildingsForDNK
          : isDSPCustomer
          ? buildingsForDSP
          : stage
      }),
    [buildingsByChosenStage, isDNKCustomer, isDSPCustomer]
  )

  const updateSelectedPlaces = React.useCallback(
    (place: any) => {
      const investmentIsSelected = places.includes(place)
      if (investmentIsSelected) {
        return setPlaces((prevState: number[]) =>
          prevState.filter((p) => p !== place)
        )
      }
      setPlaces((prevState: any[]) => [...prevState, place])
    },
    [places]
  )

  const activeContractsIDs =
    places
      ?.map((place) => place?.activeContracts[0]?.id)
      .filter((ac) => ac !== undefined) ?? []
  const placesIDs = places?.map((place) => place?.id) ?? []

  const generateReport = React.useCallback(async () => {
    const response = await dispatch(
      reduxAction(selectByActiveContractsIDs ? activeContractsIDs : placesIDs)
    )
    const responseData = response?.payload?.[reportAPIName]
    ;(activeContractsIDs.length || placesIDs.length) &&
      dispatch(
        downloadXlsxReport({ content: responseData, filename: reportName })
      ).then(() => dispatch(clearReportState()))
  }, [
    activeContractsIDs,
    dispatch,
    placesIDs,
    reduxAction,
    reportAPIName,
    reportName,
    selectByActiveContractsIDs,
  ])

  const selectAllPlacesInBuildings = React.useCallback(
    (building: any) => {
      const allPlaces = building.places.map((place: any) => place)
      const duplicates = _.filter(
        [...allPlaces, ...places],
        (value, index, iteratee) => _.includes(iteratee, value, index + 1)
      )
      if (duplicates.length) {
        const placesCleared = places.filter(
          (place) => !allPlaces.includes(place)
        )
        return setPlaces(placesCleared)
      }
      setPlaces((prev) => [...prev, ...allPlaces])
    },
    [places]
  )

  React.useEffect(() => {
    dispatch(fetchStagesWithPlacesForReport(chosenInvestment?.id))
  }, [chosenInvestment, dispatch])

  return (
    <div className='reportContainer'>
      <Button disabled={!places.length || isLoading} onClick={generateReport}>
        {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
      </Button>
      <div className='stageWrapper'>
        {placesByServiceType.map((stage) => (
          <React.Fragment key={stage.id}>
            {stage.buildings.map((building) => (
              <React.Fragment key={building.id}>
                <div className='desc' key={building.id + 'div'}>
                  <div key={building.id + 'divdiv'}>
                    <h4 key={building.id + 'h4'}>Etap: {stage.name}</h4>
                    <h5 key={building.id + 'h5'}>Budynek: {building.name}</h5>
                  </div>
                  <Button
                    key={building.id + 'btn'}
                    onClick={(): void => selectAllPlacesInBuildings(building)}
                  >
                    Zaznacz/odznacz wszystkie
                  </Button>
                </div>
                <div
                  className='checkbox-wrapper'
                  key={building.id + 'divdivdiv'}
                >
                  {building.places.map((place) => (
                    <React.Fragment key={place.id}>
                      {!selectByActiveContractsIDs ? (
                        <SimpleInputCheckbox3
                          name={`${place.id + 1}. ${place.placeCode}`}
                          label={place.placeCode}
                          key={`${place.id + 1}. ${place.placeCode}`}
                          additionalOnChange={(): void =>
                            updateSelectedPlaces(place)
                          }
                          customLabelWidth={10}
                          value={Boolean(places.includes(place))}
                        />
                      ) : null}
                      {place.activeContracts.length &&
                      selectByActiveContractsIDs
                        ? place.activeContracts.map((ac: any) => (
                            <SimpleInputCheckbox3
                              name={`${ac.id + 1}. ${ac.contractNo}`}
                              label={ac.contractNo}
                              key={`${ac.id + 1}. ${ac.contractNo}`}
                              additionalOnChange={(): void =>
                                updateSelectedPlaces(place)
                              }
                              customLabelWidth={12}
                              value={Boolean(places.includes(place))}
                            />
                          ))
                        : null}
                    </React.Fragment>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
