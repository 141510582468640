export const formatAreas = (
  value: string,
  oneDigit: boolean = false
): string => {
  const hasTwoDigitsAfterComma = value.split(',')?.[1]?.length > 1
  const hasOneDigitAfterComma = value.split(',')?.[1]?.length === 1
  const hasNoComma = value.split(',').length === 1

  const twoDigitAfterComma = hasTwoDigitsAfterComma
    ? value
    : hasNoComma
    ? value + ',00'
    : value + 0
  const oneDigitAfterComma = hasOneDigitAfterComma
    ? value
    : hasNoComma
    ? value + ',0'
    : value
  return oneDigit ? oneDigitAfterComma : twoDigitAfterComma
}
