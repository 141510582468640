import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setCurrentInterestedCustomer,
  setCurrentInterestedCustomerID,
} from '../../ducks/interestedCustomers'
import { useTableContext } from '../commons/Table2/TableContext'

export const InterestedCustomersTableWrapper: FC = () => {
  const { data } = useTableContext()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentInterestedCustomer(null))
    dispatch(setCurrentInterestedCustomerID(undefined))
  }, [data, dispatch])

  return null
}
