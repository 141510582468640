import React, { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SimpleInputList from '../../commons/Inputs/SimpleInputs/SimpleInputList'

import { updateCustomerType } from './actions'

import { useRaportsWithDatesContext } from './raportWithDatesContext'

export const CustomerType = (): JSX.Element => {
  const { dispatch } = useRaportsWithDatesContext()
  const { t } = useTranslation()

  const [customerType, setCustomerType] = useState('')
  const customerTypes = [
    '',
    'Osoba fizyczna',
    'Działalność gospodarcza',
    'Podmiot gospodarczy',
  ]

  const updateCustomerTypeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCustomerType(e.target.value)
      dispatch(updateCustomerType(e.target.value))
    },
    [dispatch]
  )

  return (
    <div className='d-flex mt-2'>
      <SimpleInputList
        options={customerTypes}
        additionalOnChange={updateCustomerTypeValue}
        defaultValue={customerType}
        label={String(t('commons:labels.customer-type'))}
      />
    </div>
  )
}
