import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BrokerService from '../services/brokersService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

export type IBroker = {
  id: number
  name: string
}
interface IBrokersState {
  brokers: IBroker[]
}

const initialState: IBrokersState = {
  brokers: [],
}
export const getBrokers = createAsyncThunk('brokers/getBrokers', async () => {
  return await BrokerService.fetchBrokers()
})
export const addBroker = createAsyncThunk(
  'brokers/addBroker',
  async (brokerName: string) => {
    return BrokerService.saveBroker({ name: brokerName })
  }
)

export const editBroker = createAsyncThunk(
  'brokers/editBroker',
  async (broker: { name: string; id: number }) => {
    return BrokerService.editBroker(broker)
  }
)

export const deleteBroker = createAsyncThunk(
  'brokers/deleteBroker',
  async (brokerId: number) => {
    return BrokerService.deleteBroker(brokerId)
  }
)
const brokersSlice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {},
  extraReducers: {
    [getBrokers.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:get-broker-error')))
    },
    [getBrokers.fulfilled.toString()]: (state, action): void => {
      state.brokers = action.payload.paginatedBrokers.brokers
    },
    [addBroker.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:add-broker-success')))
    },
    [addBroker.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:add-broker-error')))
    },
    [editBroker.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:edit-broker-success')))
    },
    [editBroker.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:edit-broker-error')))
    },
    [deleteBroker.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:delete-broker-success')))
    },
    [deleteBroker.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:delete-broker-error')))
    },
  },
})

export default brokersSlice.reducer
