import { useSelector } from 'react-redux'
import { RootState } from '../ducks'

type RolesType = {
  isDNKCustomer: boolean
  isDARCustomer: boolean
  isDPICustomer: boolean
  isSEKCustomer: boolean
  isDKOCustomer: boolean
  isDNICustomer: boolean
  isDPPCustomer: boolean
  isSKACustomer: boolean
  isDSPCustomer: boolean
}
export default (): RolesType => {
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const haveRole = (role: string) => Boolean(roles.find((r) => r === role))
  return {
    isDNKCustomer: haveRole('DNK'),
    isDARCustomer: haveRole('DAR'),
    isDPICustomer: haveRole('DPI'),
    isSEKCustomer: haveRole('SEK'),
    isDKOCustomer: haveRole('DKO'),
    isDNICustomer: haveRole('DNI'),
    isDPPCustomer: haveRole('DPP'),
    isSKACustomer: haveRole('SKA'),
    isDSPCustomer: haveRole('DSP') || haveRole('DSP-ADM'),
  }
}
