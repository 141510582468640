import React, { FunctionComponent } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'

import IKInputGroup from './IKInputGroup'

interface IInputTextProps {
  behindText?: string | number
  customFieldWidth?: number
  customWidth?: number
  defaultValue?: string | number
  disabled?: boolean
  importedValue?: string
  isValid?: boolean
  id: string
  min?: number
  max?: number
  maxlength?: number
  name?: string
  onChange?: any
  onClick?: any
  pattern?: string
  required?: boolean
  value?: string | number
  text?: string | null
  type?: 'text' | 'number'
  reference?: any
  placeholder?: string
  readonly?: boolean
}

const InputText: FunctionComponent<IInputTextProps> = (
  props: IInputTextProps
) => {
  const {
    behindText,
    customFieldWidth,
    customWidth,
    defaultValue,
    disabled,
    id,
    importedValue,
    min = 0,
    max,
    maxlength,
    name,
    onChange,
    onClick,
    pattern,
    reference,
    required = true,
    value,
    type = 'string',
    text,
    placeholder = '',
    readonly,
  } = props

  return (
    <>
      <IKInputGroup id={id} reference={reference} name={name}>
        <>
          {text && (
            <InputGroup.Text
              style={{ width: customWidth ? `${customWidth}px` : '150px' }}
            >
              {text}
            </InputGroup.Text>
          )}
          <FormControl
            data-toggle='validator'
            defaultValue={defaultValue}
            disabled={disabled}
            placeholder={placeholder ?? importedValue}
            min={min}
            max={max}
            maxLength={maxlength}
            name={name}
            onChange={onChange}
            onClick={onClick}
            pattern={pattern}
            required={required}
            role='form'
            type={type}
            value={value}
            style={{
              width: customFieldWidth ? `${customFieldWidth}px` : undefined,
            }}
            readOnly={readonly}
          />
          {behindText && <span>{behindText}</span>}
        </>
      </IKInputGroup>
    </>
  )
}

export default InputText
