import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { mainTableColumnsFilterTitles } from '../components/App/mainTableColumns'

interface IPersistSettingsStore {
  mainTableHiddenColumns: {
    [key: string]: boolean
  }
  mainTableSelectedRowIndex: number | null
  mainTableSelectedRowPage: number | null
}

export const defineInitialsHiddenColumns = (): { [key: string]: boolean } => {
  let initialHiddenColumnsValue = {}
  mainTableColumnsFilterTitles.forEach((title) => {
    initialHiddenColumnsValue = {
      ...initialHiddenColumnsValue,
      [title]: true,
    }
  })
  return initialHiddenColumnsValue
}

const initialState: IPersistSettingsStore = {
  mainTableHiddenColumns: defineInitialsHiddenColumns(),
  mainTableSelectedRowIndex: null,
  mainTableSelectedRowPage: null,
}

const persistSettingsSlice = createSlice({
  name: 'persistSettings',
  initialState,
  reducers: {
    setCheckedValueOnSingleElement(state, action: PayloadAction<any>): void {
      state.mainTableHiddenColumns = action.payload
    },
    setInitialCheckedValuesForAllColumns(state): void {
      state.mainTableHiddenColumns = defineInitialsHiddenColumns()
    },
    setMainTableRowIndex(state, action: PayloadAction<number | null>): void {
      state.mainTableSelectedRowIndex = action.payload
    },
    setMainTableRowPage(state, action: PayloadAction<number | null>): void {
      state.mainTableSelectedRowPage = action.payload
    },
  },
  extraReducers: {},
})

export const {
  setCheckedValueOnSingleElement,
  setInitialCheckedValuesForAllColumns,
  setMainTableRowIndex,
  setMainTableRowPage,
} = persistSettingsSlice.actions

export default persistSettingsSlice.reducer
