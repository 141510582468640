import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import './HeaderSearch.scss'
import { useDispatch } from 'react-redux'
import { useAsyncDebounce } from 'react-table'
import { setSearchQuery } from '../../ducks/searchSlice'

const HeaderSearch: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const [phrase, setPhrase] = useState('')
  const isSearchView = location.pathname.indexOf('search') !== -1

  const onChange = useAsyncDebounce((value) => {
    !isSearchView && dispatch(setSearchQuery(value))
  }, 1000)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setPhrase(e.target.value)
      onChange(e.target.value)
    },
    [onChange]
  )

  const onEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && phrase && phrase !== '') {
        history.push('/search')
        dispatch(setSearchQuery(phrase))
      }
    },
    [dispatch, history, phrase]
  )

  const onButtonClick = useCallback(() => {
    if (phrase) {
      history.push('/search')
      dispatch(setSearchQuery(phrase))
    }
  }, [dispatch, history, phrase])

  const handleClearQuery = React.useCallback(() => {
    dispatch(setSearchQuery(''))
    setPhrase('')
    history.push('/')
  }, [dispatch, history])

  return (
    <InputGroup>
      <Form.Control
        className='searchPadding'
        onKeyDown={onEnter}
        placeholder={`${t('commons:labels.type-searched-keyword')}...`}
        value={phrase}
        onChange={handleChange}
      />
      <div>
        <Button className='searchButton' type='submit' onClick={onButtonClick}>
          <FontAwesomeIcon icon={faSearch} className='searchIcon' />
        </Button>
        <Button
          className='searchButton'
          type='button'
          style={{ marginLeft: '5px' }}
          onClick={handleClearQuery}
        >
          <span className='searchIcon'>X</span>
        </Button>
      </div>
    </InputGroup>
  )
}

export default HeaderSearch
