import React, { useEffect } from 'react'
import AppLoader from '../commons/Loader/Loader'
import './Reports.scss'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../ducks'
import { useHistory } from 'react-router-dom'
import { clearReportsState, downloadXlsxReport } from '../../ducks/reports'

interface IReportProps {
  reportData: string | undefined
  reportName: string
  reduxAction(): void
}

const Report: React.FC<IReportProps> = ({
  reportData,
  reportName,
  reduxAction,
}) => {
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(reduxAction())
    return (): void => {
      dispatch(clearReportsState())
    }
  }, [dispatch, reduxAction])

  useEffect(() => {
    if (reportData) {
      dispatch(
        downloadXlsxReport({ content: reportData, filename: reportName })
      ).then(() => {
        history.goBack()
      })
    }
  }, [reportData, dispatch, history, reportName])

  return (
    <>
      {reportData ? (
        <div className='reports-container'>
          <div className='reports-header'>
            Wczytano raport, plik zaraz zostanie pobrany
          </div>
        </div>
      ) : (
        <div className='reports-container'>
          <div className='reports-header'>Trwa pobieranie raportu</div>
          <AppLoader />
        </div>
      )}
      ;
    </>
  )
}

export default Report
