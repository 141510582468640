import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Row } from 'react-bootstrap'
import { IFoundedCustomer } from '../../../hooks/useNewCustomerVerification'
import cutomersValidationColumns from '../../../mockups/CustomersVerificationColumns'
import Table from '../Table2/Table'

export interface Props {
  foundedCustomers: IFoundedCustomer[]
  hideModal: () => void
}

const CustomersVerificationsModal: FC<Props> = ({
  foundedCustomers,
  hideModal,
}) => {
  const { t } = useTranslation()
  const tableColumns = useMemo(() => cutomersValidationColumns, [])

  return (
    <>
      <Row className='d-flex justify-content-center'>
        <Table
          customTableWidth='100%'
          columns={tableColumns}
          data={foundedCustomers}
        >
          <Table.TableBody />
        </Table>
      </Row>
      <Row className='d-flex justify-content-center mt-1'>
        <Button onClick={hideModal}>{t('commons:actions.close')}</Button>
      </Row>
    </>
  )
}

export default CustomersVerificationsModal
