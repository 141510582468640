import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { hasRoles } from '../services/idp'

import { AppDispatch, RootState } from '../ducks'
import {
  DMAAndDMAADMRoles,
  DNKRoles,
  getUserInitialsList,
  getUserInitialsListWithIDs,
} from '../ducks/globalUser'

export const useUserInitialsListForRoles = (
  roleInitials?: string[],
  withID?: boolean
): any => {
  const dispatch = useDispatch<AppDispatch>()

  const { roles, userInitialsList, userInitialsListWithIDs } = useSelector(
    (state: RootState) => state.globalUser
  )
  const { profile } = useSelector((state: RootState) => state.globalUser)

  const currentUserInitials = profile?.attributes?.initials?.[0] ?? ''
  const isDNKCustomer = hasRoles(DNKRoles, roles)
  const isDMARole = hasRoles(DMAAndDMAADMRoles, roles)
  const getUserList = withID ? getUserInitialsListWithIDs : getUserInitialsList

  useEffect(() => {
    const traderInitials = isDMARole
      ? ['DNK', 'DSP', 'DSP-ADM']
      : isDNKCustomer
      ? ['DNK']
      : ['DSP', 'DSP-ADM']
    dispatch(getUserList(roleInitials ?? traderInitials))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles])

  return withID
    ? isDMARole
      ? userInitialsListWithIDs.filter(
          (initialsWithID) => initialsWithID.initials !== currentUserInitials
        )
      : userInitialsListWithIDs
    : isDMARole
    ? userInitialsList.filter((initials) => initials !== currentUserInitials)
    : userInitialsList
}

export const useUserInitialsWithIDsListForRoles = (
  roleInitials?: string[]
): any => useUserInitialsListForRoles(roleInitials, true)
