import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../ducks'
import InterestedEventsTable from '../../InterestedCustomers/InterestedCustomersContacts/InterestedEventsTable'
import Table from '../../commons/Table2/Table'
import { getInterestedCustomerByID } from '../../../ducks/interestedCustomers'
import columnsData from './../messagesOfUnassignedColumns'
import { ITableMessagesOfUnassignedCustomers } from './../interfaces'
import { TableBodyWrapper } from './../TableBodyWrapper'
import TableMessages from './TableMessages'

const TableMessagesOfUnassignedCustomers: FC<ITableMessagesOfUnassignedCustomers> = (
  props
) => {
  const dispatch: AppDispatch = useDispatch()
  const { selectedInterestedCustomer, lastFetchedCustomerID } = useSelector(
    (state: RootState) => state.interestedCustomers
  )
  const {
    messagesByUnassignedCustomerID,
    totalMessagesOfUnassignedCustomer,
  } = useSelector((state: RootState) => state.unassignedCustomers)
  const [refreshContact, setRefreshContact] = useState(false)
  const columns = React.useMemo(() => columnsData, [])
  const interestedCustomerID = props.selectedUnassignedCustomer?.customerID

  useEffect(() => {
    if (
      interestedCustomerID && (
        interestedCustomerID !== selectedInterestedCustomer?.id ||
        interestedCustomerID !== lastFetchedCustomerID ||
        refreshContact)
    ) {
      dispatch(getInterestedCustomerByID(interestedCustomerID))
      setRefreshContact(false)
    }
  }, [
    dispatch,
    interestedCustomerID,
    lastFetchedCustomerID,
    selectedInterestedCustomer,
    refreshContact,
  ])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Table
        customLoading
        columns={columns}
        data={messagesByUnassignedCustomerID ?? []}
        defaultNumberOfRows={5}
        totalRows={totalMessagesOfUnassignedCustomer}
        manualSortBy
        withoutPaddingX
      >
        <TableBodyWrapper>
          {(pageSize: string, selectedRow: string) => (
            <TableMessages
              {...{ selectedRow, pageSize, setRefreshContact, ...props }}
            />
          )}
        </TableBodyWrapper>
      </Table>
      {selectedInterestedCustomer &&
      selectedInterestedCustomer?.id === interestedCustomerID ? (
        <InterestedEventsTable
          customer={selectedInterestedCustomer}
          isFullWidth
          isUnassignedCustomerView
        />
      ) : null}
    </div>
  )
}

export default TableMessagesOfUnassignedCustomers
