import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, RootState } from '../../ducks'
import { editEmployee, getAllUsers } from '../../ducks/adminIT'

import MainButton from '../commons/Inputs/MainButton'
import SimpleInputText from '../commons/Inputs/SimpleInputs/SimpleInputText'

import './style.scss'

const UserPanel: FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { employeeList } = useSelector((state: RootState) => state.adminIT)
  const { profile } = useSelector((state: RootState) => state.globalUser)
  const [newPassword, setNewPassword] = useState('')
  //const [oldPassword, setOldPassword] = useState('')
  const [repeatedNewPassword, setRepeatedNewPassword] = useState('')
  const { t } = useTranslation()

  const specialCharacters = ['!', '@', '#', '$', '%', '^', '&']
  const [arePasswordsHighlighted, setPasswordsHighlighted] = useState(false)
  const isPasswordValid =
    newPassword &&
    newPassword.length > 7 &&
    newPassword.length < 33 &&
    newPassword.toLowerCase() !== newPassword &&
    newPassword.toUpperCase() !== newPassword &&
    specialCharacters.some((sC) => newPassword.includes(sC))

  const isSaveBtnDisabled = !newPassword || !repeatedNewPassword
  const currentUser = useMemo(
    () =>
      employeeList?.find(
        (employee) => employee?.username === profile?.username
      ),
    [employeeList, profile]
  )

  const onSaveClick = useCallback(() => {
    const areNewPasswordsEqual = newPassword === repeatedNewPassword
    const isNewPasswordCorrect = areNewPasswordsEqual && isPasswordValid
    //TO DO - if possible in keycloack the old-password check should be implemented
    const isOldPasswordCorrect = true

    if (!isPasswordValid) {
      alert(
        'Hasło powinno zawierać od 8 do 32 znaków, co najmniej jedną wielką literę, co najmniej jedną małą literę, co najmniej jedną cyfrę, co najmniej jeden znak specjalny z: !@#$%^&'
      )
      setPasswordsHighlighted(true)
    }

    isNewPasswordCorrect &&
      isOldPasswordCorrect &&
      currentUser?.id &&
      dispatch(
        editEmployee({
          userID: currentUser.id,
          user: {
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            email: currentUser.email,
            phone: currentUser.phone,
            roles: currentUser.roles,
            username: currentUser.username,
            initials: currentUser.initials,
            password: newPassword,
          },
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList, newPassword, repeatedNewPassword])

  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch])

  useEffect(() => {
    isPasswordValid && setPasswordsHighlighted(false)
  }, [isPasswordValid])

  return (
    <Container fluid>
      <div className='user-panel'>
        <h2 className='user-panel-header'>Zmiana hasła</h2>
        {/* <SimpleInputText
          defaultValue={oldPassword}
          label='Stare hasło'
          onChange={(e : any) : void => setOldPassword(e.target.value)}
        /> */}
        <SimpleInputText
          defaultValue={newPassword}
          label='Nowe hasło'
          highlighted={arePasswordsHighlighted}
          onChange={(e: any): void => setNewPassword(e.target.value)}
        />
        <SimpleInputText
          defaultValue={repeatedNewPassword}
          label='Powtórz nowe hasło'
          highlighted={arePasswordsHighlighted}
          onChange={(e: any): void => setRepeatedNewPassword(e.target.value)}
        />
        <MainButton disabled={isSaveBtnDisabled} onClick={onSaveClick}>
          {t('commons:actions.save')}
        </MainButton>
      </div>
    </Container>
  )
}

export default UserPanel
