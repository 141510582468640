import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'

export interface IUserInitialsObject {
  initials: string
}

const fetchTraderInitialsOfCustomer = (): any => {
  const FETCH_TRADER_INITIALS_LIST_OF_CUSTOMERS = gql`
    query {
      traderInitialsOfCustomer
    }
  `

  return GraphqlService.send(FETCH_TRADER_INITIALS_LIST_OF_CUSTOMERS, {})
}

const fetchUserInitialsList = (roles?: string[]): any => {
  const FETCH_USER_INITIALS_LIST = gql`
    query($roles: [String!]) {
      users(roles: $roles) {
        id
        initials
      }
    }
  `

  return GraphqlService.send(FETCH_USER_INITIALS_LIST, { roles })
}

const mutations = {
  fetchTraderInitialsOfCustomer,
  fetchUserInitialsList,
}

export default mutations
