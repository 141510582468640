import React, { FC } from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReduxModal from '../commons/Modals/ReduxModal'

interface Props {
  title: string
  show: boolean
  handleClose: () => void
  onSubmit: () => void
}

const AssignCustomerModal: FC<Props> = ({
  title,
  show,
  handleClose,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const handleSubmit = (e: any) => {
    e.preventDefault()
    onSubmit()
  }

  const archiveModalBody = (
    <Form className='w-200' onSubmit={handleSubmit}>
      <Row className='d-flex justify-content-center mb-3 p-3'>
        <span> {t('customers:actions.assign-to-taken-lead')}</span>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Button onClick={handleClose}>{t('commons:actions.cancel')}</Button>
        <Button type='submit'>{t('commons:actions.assign')}</Button>
      </Row>
    </Form>
  )

  return (
    <ReduxModal
      body={archiveModalBody}
      cancelAction={handleClose}
      onHide={handleClose}
      show={show}
      title={title}
    />
  )
}

export default AssignCustomerModal
