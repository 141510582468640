import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GaragesService, {
  IGarage,
  IGarageGroundPlanInput,
  IGarageInput,
} from '../services/garagesService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface IGaragesState {
  allGarages: IGarage[]
  isGarageGroundPlanUploading: boolean
  isLoadingGarages: boolean
  garagesByInvestmentsNames: IGarage[]
}

const initialState: IGaragesState = {
  allGarages: [],
  isGarageGroundPlanUploading: false,
  isLoadingGarages: false,
  garagesByInvestmentsNames: [],
}

export const getGaragesByInvestmentNames = createAsyncThunk(
  'garages/getGaragesByInvestmentNames',
  async (investmentsNames: string[]) => {
    return GaragesService.getGaragesByInvestmentNames(
      investmentsNames || ['Wszystkie']
    )
  }
)

export const addNewGarage = createAsyncThunk(
  'garages/addNewGarage',
  async (garage: IGarageInput) => {
    return GaragesService.addGarage(garage)
  }
)

export const deleteGarage = createAsyncThunk(
  'garages/deleteGarage',
  async (garageID: number) => {
    return GaragesService.deleteGarage(garageID)
  }
)

export const updateGarage = createAsyncThunk(
  'garages/updateGarage',
  async (variables: { garageID: number; garage: IGarageInput }) => {
    return GaragesService.updateGarage(variables)
  }
)

export const addGroundPlanGarage = createAsyncThunk(
  'garages/addGroundPlanGarage',
  async (file: IGarageGroundPlanInput) => {
    return GaragesService.saveGroundPlanIntoGarage(file)
  }
)

const garagesSlice = createSlice({
  name: 'garages',
  initialState,
  reducers: {},
  extraReducers: {
    [getGaragesByInvestmentNames.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.garagesByInvestmentsNames = action.payload.garagesByInvestmentsNames
      state.isLoadingGarages = false
    },
    [getGaragesByInvestmentNames.pending.toString()]: (state): void => {
      state.isLoadingGarages = true
    },
    [getGaragesByInvestmentNames.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:getGaragesByInvestmentNamesError')))
      state.isLoadingGarages = false
    },
    [addNewGarage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addGarage')))
      state.isLoadingGarages = false
    },
    [addNewGarage.pending.toString()]: (state): void => {
      state.isLoadingGarages = true
    },
    [addNewGarage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addGarageError')))
      state.isLoadingGarages = false
    },
    [deleteGarage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:deleteGarage')))
      state.isLoadingGarages = false
    },
    [deleteGarage.pending.toString()]: (state): void => {
      state.isLoadingGarages = true
    },
    [deleteGarage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:deleteGarageError')))
      state.isLoadingGarages = false
    },
    [updateGarage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:editGarage')))
      state.isLoadingGarages = false
    },
    [updateGarage.pending.toString()]: (state): void => {
      state.isLoadingGarages = true
    },
    [updateGarage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:editGarageError')))
      state.isLoadingGarages = false
    },
    [addGroundPlanGarage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addGroundPlan')))
      state.isGarageGroundPlanUploading = false
      state.isLoadingGarages = false
    },
    [addGroundPlanGarage.pending.toString()]: (state): void => {
      state.isGarageGroundPlanUploading = true
      state.isLoadingGarages = true
    },
    [addGroundPlanGarage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addGroundPlanError')))
      state.isGarageGroundPlanUploading = false
      state.isLoadingGarages = false
    },
  },
})

export default garagesSlice.reducer
