import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ReduxModal from '../../commons/Modals/ReduxModal'
import { Button, Form, Row } from 'react-bootstrap'
import { AppDispatch } from '../../../ducks'
import {
  deleteInterestedCustomerContact,
  fetchInterestedCustomersByContractID,
  setCurrentInterestedCustomer,
  setCurrentInterestedCustomerID,
} from '../../../ducks/interestedCustomers'

import { refetchPagination } from '../../commons/Table2/paginationSlice'

interface Props {
  contactID: number
  contractID?: number
  hideDeleteModal: () => void
  showModal: boolean
  customerID?: number
}

const DeleteContact: React.FC<Props> = ({
  contactID,
  contractID,
  hideDeleteModal,
  showModal,
  customerID,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()

  const deleteContact = useCallback(() => {
    dispatch(deleteInterestedCustomerContact(contactID ?? 0)).then(() => {
      dispatch(setCurrentInterestedCustomer(null))
      dispatch(setCurrentInterestedCustomerID(undefined))
      contractID && dispatch(fetchInterestedCustomersByContractID([contractID]))
      dispatch(refetchPagination(true))
      hideDeleteModal()
    })
  }, [contactID, contractID, dispatch, hideDeleteModal])

  const deleteModalBody = (
    <Form className='w-100'>
      <Row className='d-flex justify-content-center'>
        <span>{t('administration:labels.warning-delete')}</span>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Button onClick={deleteContact}>{t('commons:actions.delete')}</Button>
        <Button onClick={hideDeleteModal}>{t('commons:actions.cancel')}</Button>
      </Row>
    </Form>
  )

  return (
    <>
      <ReduxModal
        body={deleteModalBody}
        cancelAction={hideDeleteModal}
        onHide={hideDeleteModal}
        show={showModal}
        title={t('customers:labels.delete-contact')}
      />
    </>
  )
}

export default DeleteContact
