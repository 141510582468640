import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../ducks'
import { performNewCustomerVerification } from '../ducks/interestedCustomers'
import { hasRoles } from '../services/idp'
import { DNKRoles } from '../ducks/globalUser'

export interface IFoundedCustomer {
  id: number
  firstName: string
  lastName: string
  phone1: string
  phone2: string
  email: string
}

export interface INewCustomerVerificationParameters {
  phone1: string | null
  phone2: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
}

interface INewCustomerVerification {
  verifyNewCustomer: (
    parameters: INewCustomerVerificationParameters
  ) => Promise<IFoundedCustomer[] | undefined>
}

export const useNewCustomerVerification = (): INewCustomerVerification => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const { roles } = useSelector((state: RootState) => state.globalUser)
  const isDNKCustomer = hasRoles(DNKRoles, roles)

  const verifyNewCustomer = async (
    parameters: INewCustomerVerificationParameters
  ): Promise<IFoundedCustomer[] | undefined> => {
    const { phone1, phone2, email, firstName, lastName } = parameters
    const isFullNameProvided = Boolean(firstName) && Boolean(lastName)

    const allParameters = isFullNameProvided
      ? [phone1, phone2, email]
      : [phone1, phone2, email, firstName, lastName]
    const filteredParameters = allParameters
      .filter((parameter) => parameter)
      .map((parameter) => (parameter as string).replace(/\s/g, ''))

    if (isFullNameProvided) {
      filteredParameters.push(`${firstName} ${lastName}`)
    }

    if (Boolean(filteredParameters.length)) {
      const requests = filteredParameters.map((parameter) =>
        dispatch(
          performNewCustomerVerification({
            keyWord: parameter,
            isDNKCustomer,
          })
        )
      )

      return Promise.all(requests).then((response) => {
        let foundedCustomers: IFoundedCustomer[] = []

        response.forEach((keyWordResponse) => {
          const {
            total,
            customers,
          } = keyWordResponse.payload.paginateCustomersByKeyWords

          if (total === 0 || customers.length === 0) {
            return
          }

          customers.forEach((customer: IFoundedCustomer) => {
            if (
              !foundedCustomers.some(
                (foundedCustomer) => foundedCustomer.id === customer.id
              )
            ) {
              foundedCustomers = [...foundedCustomers, customer]
            }
          })
        })

        return foundedCustomers
      })
    } else {
      alert(t('customers:labels.warning-no-parameters-verification'))
    }
  }

  return {
    verifyNewCustomer,
  }
}
