export const getRowStyle = (row: any, nextRow: any) => {
  const rowInvestName = row?.original?.investment?.name
  const nextRowInvestName = nextRow?.original?.investment?.name

  const rowStageId = row?.original?.stage?.id
  const nextRowStageId = nextRow?.original?.stage?.id

  return rowInvestName && rowInvestName !== nextRowInvestName
    ? {
        borderBottom: '6px solid #e5e5e5',
      }
    : rowStageId && rowStageId !== nextRowStageId
    ? {
        borderBottom: '4px solid #e5e5e5',
      }
    : {}
}
