import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDailyMiniReport } from '../../ducks/reports'
import Report from './Report'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DailyMiniReport: FC = () => {
  useAuthRedirect([ACCESS_PAGES.DSP_AND_DSPADM_REPORTS])
  const { DailyMiniReport } = useSelector((state: RootState) => state.reports)

  return (
    <Report
      reportData={DailyMiniReport}
      reportName={'Dzienny mini.xlsx'}
      reduxAction={fetchDailyMiniReport}
    />
  )
}

export default DailyMiniReport
