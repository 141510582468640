import React, { FunctionComponent, useCallback, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReduxModal from '../../commons/Modals/ReduxModal'

import { IContact } from '../../../graphql/contacts'
import { IUpdateContactInput } from '../../../services/interestedCustomerService'

import { AppDispatch } from '../../../ducks'
import {
  fetchInterestedCustomersByContractID,
  setCurrentInterestedCustomer,
  setCurrentInterestedCustomerID,
  updateInterestedCustomerContact,
} from '../../../ducks/interestedCustomers'
import '../../../styles/commons.scss'
import ContactContent from '../../../mockups/ContactContent'
import { useForm } from '../../../hooks/useForm'
import SimpleInputDate3 from '../../commons/Inputs/SimpleInputs/SimpleInputDate3'
import SimpleInputCheckbox3 from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'
import SimpleInput3 from '../../commons/Inputs/SimpleInputs/SimpleInput3'
import SimpleInputList2 from '../../commons/Inputs/SimpleInputs/SimpleInputList2'
import { refetchPagination } from '../../commons/Table2/paginationSlice'
import { resignationReasonsIDName } from '../../../mockups/resignationReasonsIDName'

import { useUserInitialsListForRoles } from '../../../hooks/useUserInitialsListForRoles'

interface IEditContactProps {
  contact: IContact
  contractID?: number
  hasCustomerContracts: boolean
  hideEditSection: () => void
  show: boolean
}
const typeOfContact = [
  ' ',
  'Korespondencja',
  'Od pośrednika',
  'Spotkanie',
  'Telefon',
]

export const EditContact: FunctionComponent<IEditContactProps> = ({
  contact,
  contractID,
  hideEditSection,
  show,
  hasCustomerContracts,
}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()

  const userInitialsList = useUserInitialsListForRoles()

  const { state, updateFromInput } = useForm(contact)

  const resignationReasons = resignationReasonsIDName
    .map((rr) => rr.name)
    .sort()
  const [isEditModalVisible, setIsEditModalVisible] = useState(show)
  const hideEditModal = (): void => setIsEditModalVisible(false)

  const updateContact = useCallback(
    (e: any): void => {
      e.preventDefault()
      const { id: contactID, ...contastWithoutID } = state
      const parameters: IUpdateContactInput = {
        contactID: contactID,
        contact: {
          ...contastWithoutID,
          nextContactName: state.isResignation
            ? ''
            : state?.nextContactName ?? '',
          nextContactComment: state.isResignation
            ? ''
            : state?.nextContactComment ?? '',
          nextContactDate: state.isResignation
            ? null
            : state?.nextContactDate || null,
        },
      }

      dispatch(updateInterestedCustomerContact(parameters)).then(() => {
        dispatch(setCurrentInterestedCustomer(null))
        dispatch(setCurrentInterestedCustomerID(undefined))
        contractID &&
          dispatch(fetchInterestedCustomersByContractID([contractID]))
        dispatch(refetchPagination(true))
        hideEditSection()
      })
    },
    [contractID, dispatch, hideEditSection, state]
  )

  const editModalBody = (
    <Container fluid>
      <Form className='w-100' onSubmit={updateContact}>
        <Container fluid>
          <div className='row w-100'>
            <SimpleInputList2
              defaultValue={state.name}
              options={ContactContent}
              name='name'
              additionalOnChange={updateFromInput}
              label={String(t('customers:labels.contact-content'))}
            />
            <SimpleInputDate3
              label={String(t('commons:labels.date'))}
              additionalOnChange={updateFromInput}
              name='date'
              defaultValue={state.date}
            />
            <SimpleInputList2
              defaultValue={state.traderInitials}
              options={userInitialsList}
              name='traderInitials'
              additionalOnChange={updateFromInput}
              label={String(t('commons:labels.trader-initials'))}
            />
            <SimpleInput3
              value={state.comment}
              onChange={updateFromInput}
              name='comment'
              label={String(t('customers:labels.comment'))}
            />
            <SimpleInputList2
              defaultValue={state.type}
              options={typeOfContact}
              name='type'
              additionalOnChange={updateFromInput}
              label={String(t('commons:labels.type'))}
            />
            <div
              className='row'
              style={{ marginLeft: '0em', marginRight: '0em' }}
            >
              <SimpleInputCheckbox3
                disabled
                label={String(t('customers:labels.signed'))}
                value={hasCustomerContracts}
                name='isSigned'
                onChange={updateFromInput}
              />
              <SimpleInputCheckbox3
                value={state.isResignation}
                onChange={updateFromInput}
                label={String(t('customers:labels.resignation'))}
                name='isResignation'
              />
            </div>
          </div>
          {state.isResignation ? (
            <div className='row w-100' style={{ marginBottom: '1.5em' }}>
              <SimpleInputList2
                name='resignationReason'
                label={String(t('commons:labels.resignation-reason'))}
                options={resignationReasons}
                additionalOnChange={updateFromInput}
                defaultValue={state.resignationReason}
              />
            </div>
          ) : (
            <div
              className='row w-100'
              style={{ marginBottom: '1.5em', marginTop: '1.5em' }}
            >
              <SimpleInputList2
                defaultValue={state.nextContactName}
                options={ContactContent}
                name='nextContactName'
                additionalOnChange={updateFromInput}
                label={String(t('customers:labels.next-contact-content'))}
              />
              <SimpleInput3
                value={state.nextContactComment}
                onChange={updateFromInput}
                name='nextContactComment'
                label={String(t('commons:labels.comment'))}
              />
              <SimpleInputDate3
                label={String(t('customers:labels.next-contact-date'))}
                additionalOnChange={updateFromInput}
                name='nextContactDate'
                defaultValue={state.nextContactDate}
              />
            </div>
          )}
          <Row className='d-flex justify-content-center'>
            <Col
              lg={8}
              md={12}
              sm={12}
              className='d-flex justify-content-center'
            >
              <Button type='submit'>{t('commons:actions.save')}</Button>
              <Button onClick={hideEditSection}>
                {t('commons:actions.cancel')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </Container>
  )

  return (
    <ReduxModal
      body={editModalBody}
      cancelAction={hideEditModal}
      onHide={hideEditModal}
      show={isEditModalVisible}
      title={t('customers:labels.edit-contact')}
    />
  )
}

export default EditContact
