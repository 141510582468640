import { useCallback, useEffect, useMemo, useState } from 'react'

export const useCheckIfSmallResolution = (): boolean => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const isSmallResolution = useMemo(() => {
    return innerWidth < 1163
  }, [innerWidth])

  const resizeFunction = useCallback((event: any): void => {
    setInnerWidth(event?.target?.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizeFunction)
    return (): void => window.removeEventListener('resize', resizeFunction)
  }, [resizeFunction])

  return isSmallResolution
}
