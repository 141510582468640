import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../commons/Logo/Logo'
import { useUserAbility } from '../../hooks/ability'

const HeaderLogo: FunctionComponent = () => {
  const ability = useUserAbility()
  const mainView = ability.can('see', 'Path', 'main-table-view')
    ? '/main-table-view'
    : ''

  return (
    <Link to={mainView}>
      <Logo />
    </Link>
  )
}

export default HeaderLogo
