import {
  IContractCustomerCompany,
  IContractCustomerCompanyInput,
  ISpouseFile,
} from '../graphql/contractCustomerCompany'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ContractCustomerCompanyService from '../services/contractCustomerCompanyService'
import { IMarketingConsentInput } from '../graphql/marketingConsents'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'
import { RootState } from '.'
import { defaultContractCustomerCompany } from './registerSales'

interface IContractCustomerCompaniesState {
  attorneyFiles: ISpouseFile[]
  contractCustomerCompanies: IContractCustomerCompany[]
}

const initialState: IContractCustomerCompaniesState = {
  attorneyFiles: [],
  contractCustomerCompanies: [],
}

export const anonymizeContractCustomerCompany = createAsyncThunk(
  'contractCustomerCompanies/anonymizeContractCustomerCompany',
  async (contractCustomerCompanyID?: number) => {
    if (contractCustomerCompanyID) {
      return ContractCustomerCompanyService.anonymizeContractCustomerCompany(
        contractCustomerCompanyID
      )
    }
  }
)

export const fetchAllContractCustomerCompanies = createAsyncThunk(
  'contractCustomerCompanies/fetchAllContractCustomerCompanies',
  async () => {
    return ContractCustomerCompanyService.fetchAllContractCustomerCompanies()
  }
)

export const addNewContractCustomerCompany = createAsyncThunk(
  'contractCustomerCompanies/addNewContractCustomerCompany',
  async (variables: { contractCustomerCompany: IContractCustomerCompany }) => {
    return ContractCustomerCompanyService.addNewContractCustomerCompany(
      variables
    )
  }
)

export const updateContractCompanyCustomerWithMarketingConsents = createAsyncThunk(
  'contractCustomerCompanies/updateContractCompanyCustomerWithMarketingConsents',
  async ({
    contractCustomerCompany,
    contractCustomerCompanyID,
    marketingConsent,
    marketingConsentID,
  }: {
    contractCustomerCompany: Partial<IContractCustomerCompanyInput>
    contractCustomerCompanyID: number
    marketingConsent: Partial<IMarketingConsentInput>
    marketingConsentID: number
  }) => {
    return ContractCustomerCompanyService.updateContractCompanyCustomerWithMarketingConsents(
      {
        contractCustomerCompany,
        contractCustomerCompanyID,
        marketingConsent,
        marketingConsentID,
      }
    )
  }
)

export const updateContractCompanyCustomerWithMarketingConsentsWithoutID = createAsyncThunk(
  'contractCustomerCompanies/updateContractCompanyCustomerWithMarketingConsents',
  async ({
    contractCustomerCompany,
    contractCustomerCompanyID,
    marketingConsent,
  }: {
    contractCustomerCompany: Partial<IContractCustomerCompanyInput>
    contractCustomerCompanyID: number
    marketingConsent: Partial<IMarketingConsentInput>
  }) => {
    return ContractCustomerCompanyService.updateContractCompanyCustomerWithMarketingConsentsWithoutID(
      {
        contractCustomerCompany,
        contractCustomerCompanyID,
        marketingConsent,
      }
    )
  }
)

export const updateContractCustomerCompany = createAsyncThunk(
  'contractCustomerCompanies/updateContractCustomerCompany',
  async ({
    contractCustomerCompany,
    contractCustomerCompanyID,
    marketingConsent,
  }: {
    contractCustomerCompany: Partial<IContractCustomerCompanyInput>
    contractCustomerCompanyID: number
    marketingConsent: IMarketingConsentInput | undefined
  }) => {
    return ContractCustomerCompanyService.updateContractCustomerCompany({
      contractCustomerCompany,
      contractCustomerCompanyID,
      marketingConsent,
    })
  }
)

export const saveContractCustomerCompany = createAsyncThunk(
  'contractCustomerCompanies/saveContractCustomerCompany',
  async (contractCustomerCompany: Partial<IContractCustomerCompanyInput>) => {
    return ContractCustomerCompanyService.saveContractCustomerCompany(
      contractCustomerCompany
    )
  }
)

export const deleteContractCustomerCompany = createAsyncThunk(
  'contractCustomerCompanies/deleteContractCustomerCompany',
  async (contractCustomerCompanyID?: number) => {
    if (contractCustomerCompanyID) {
      return ContractCustomerCompanyService.deleteContractCustomerCompany(
        contractCustomerCompanyID
      )
    }
  }
)

export const deleteContractCustomerCompanyWithRelative = createAsyncThunk(
  'contractCustomers/deleteContractCustomerCompanyWithRelative',
  async (customer: IContractCustomerCompanyInput, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { chosenPlacesContract } = state.globalInvestment
    const contractCustomerCompanies =
      chosenPlacesContract?.activeContracts?.[0]?.contractCustomerCompanies

    if (contractCustomerCompanies) {
      // Find and remove all relative customers
      contractCustomerCompanies.forEach((c: IContractCustomerCompanyInput) => {
        const isOwner = !c.isAgentOfMainCustomer && !c.isRepresentativePerson
        if (isOwner && c.id === customer.id) {
          const relatives = contractCustomerCompanies
            .filter(
              (r: IContractCustomerCompanyInput) =>
                r.isAgentOfMainCustomer || r.isRepresentativePerson
            )
            .filter(
              (r: IContractCustomerCompanyInput) => r.uuid === customer.uuid
            )

          relatives.forEach((r: IContractCustomerCompanyInput) =>
            ContractCustomerCompanyService.deleteContractCustomerCompany(r.id)
          )
        }
      })
      // Remove owner
      return ContractCustomerCompanyService.deleteContractCustomerCompany(
        customer.id
      )
    }
  }
)

export const getFileByID = createAsyncThunk(
  'file/getFile',
  async (fileID: number) => {
    return ContractCustomerCompanyService.getFileByID(fileID)
  }
)

export const saveNewRelativeContractCustomerCompany = createAsyncThunk(
  'contractCustomerCompanies/saveNewRelativeContractCustomerCompany',
  async (
    variables: {
      isAgentOfMainCustomer: boolean
      isRepresentativePerson: boolean
      contractID?: number
      uuid?: string
      companyParentID?: number
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState

    const {
      uuid,
      isAgentOfMainCustomer,
      isRepresentativePerson,
      contractID,
      companyParentID,
    } = variables

    const relativeCustomerCompanies =
      state.registerSales.newRelativeCustomerCompanies
    const {
      id,
      parentUuid,
      ...defaultContractCustomerCompanyWithoutID
    } = defaultContractCustomerCompany

    const oldAgent = relativeCustomerCompanies.find(
      (c) => uuid === c.uuid && c.isAgentOfMainCustomer
    )

    if (oldAgent && isAgentOfMainCustomer) {
      return
    }
    const customer: any = {
      ...defaultContractCustomerCompanyWithoutID,
      uuid,
      isAgentOfMainCustomer,
      isRepresentativePerson,
      contractID,
    }
    if (companyParentID !== undefined) {
      customer['companyParentID'] = companyParentID
    }
    return ContractCustomerCompanyService.saveContractCustomerCompany(customer)
  }
)

export const saveNewContractCustomerCompany = createAsyncThunk(
  'contractCustomer/saveNewContractCustomerCompany',
  async (
    variables: { customer: IContractCustomerCompanyInput; contractID: number },
    thunkAPI
  ) => {
    const { id, parentUuid, ...contractCustomersToSave } = variables.customer
    const customer = {
      ...contractCustomersToSave,
      contractID: variables.contractID,
    }
    return ContractCustomerCompanyService.saveContractCustomerCompany(customer)
  }
)

const contractCustomerCompaniesSlice = createSlice({
  name: 'contractCustomerCompanies',
  initialState,
  reducers: {
    setAttorneyFiles(state, action): void {
      const attorneyFiles = action.payload || []
      state.attorneyFiles = attorneyFiles
    },
  },
  extraReducers: {
    [fetchAllContractCustomerCompanies.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.contractCustomerCompanies = action.payload.contractCustomerCompanies
    },
    [fetchAllContractCustomerCompanies.rejected.toString()]: (state): void => {
      errorToastNotify(
        String(i18n.t('toast:fetchAllContractCustomerCompaniesError'))
      )
      state.contractCustomerCompanies = []
    },
    [anonymizeContractCustomerCompany.fulfilled.toString()]: (): void => {
      successToastNotify(
        String(i18n.t('toast:anonymizeContractCustomerCompany'))
      )
    },
    [anonymizeContractCustomerCompany.rejected.toString()]: (): void => {
      errorToastNotify(
        String(i18n.t('toast:anonymizeContractCustomerCompanyError'))
      )
    },
    [deleteContractCustomerCompany.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:removeContractCustomerCompany')))
    },
    [deleteContractCustomerCompany.rejected.toString()]: (): void => {
      errorToastNotify(
        String(i18n.t('toast:removeContractCustomerCompanyError'))
      )
    },
    [updateContractCustomerCompany.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editPerson')))
    },
    [updateContractCustomerCompany.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editPersonError')))
    },
    [saveContractCustomerCompany.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editPerson')))
    },
    [saveContractCustomerCompany.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editPersonError')))
    },
  },
})

export const { setAttorneyFiles } = contractCustomerCompaniesSlice.actions

export default contractCustomerCompaniesSlice.reducer
