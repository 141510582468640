import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import proposedInvestmentsService, {
  IProposedInvestment,
  IProposedInvestmentInput,
} from '../services/proposedInvestmentsService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface IProposedInvestmentState {
  allProposedInvestments: IProposedInvestment[]
}

const initialState: IProposedInvestmentState = {
  allProposedInvestments: [],
}

export const addNewProposedInvestment = createAsyncThunk(
  'proposedPlaces/addNewProposedInvestment',
  async (proposedInvestment: IProposedInvestmentInput) => {
    return proposedInvestmentsService.saveProposedInvestment(proposedInvestment)
  }
)

export const removeProposedInvestment = createAsyncThunk(
  'proposedPlaces/removeProposedInvestment',
  async (proposedPlaceID: number) => {
    return proposedInvestmentsService.deleteProposedInvestment(proposedPlaceID)
  }
)

const proposedInvestmentsSlice = createSlice({
  name: 'proposedInvestments',
  initialState,
  reducers: {},
  extraReducers: {
    [addNewProposedInvestment.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:addProposedPlace')))
    },
    [addNewProposedInvestment.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:addProposedPlaceError')))
    },
    [removeProposedInvestment.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:removeProposedPlace')))
    },
    [removeProposedInvestment.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:removeProposedPlaceError')))
    },
  },
})

export default proposedInvestmentsSlice.reducer
