import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import FileService from '../services/fileService'
import InfoCards, {
  IFetchCompanyDataReportProps,
  IFetchCustomerDataReportProps,
  IFetchInformationCompanyReportProps,
  IFetchInformationCustomerReportProps,
  PdfReport,
} from '../services/infoCardsService'

interface IInfoCardsState {
  informationCustomerReport?: PdfReport
  informationCompanyReport?: PdfReport
  companyDataReport?: PdfReport
  customerDataReport?: PdfReport
}

const initialState: IInfoCardsState = {
  companyDataReport: undefined,
  customerDataReport: undefined,
  informationCompanyReport: undefined,
  informationCustomerReport: undefined,
}

const infoCardsSlice = createSlice({
  name: 'infoCards',
  initialState,
  reducers: {
    setCompanyDataReport(state, action: PayloadAction<PdfReport>): void {
      state.companyDataReport = action.payload
    },
    setCustomerDataReport(state, action: PayloadAction<PdfReport>): void {
      state.customerDataReport = action.payload
    },
    setInformationCompanyReport(state, action: PayloadAction<PdfReport>): void {
      state.informationCompanyReport = action.payload
    },
    setInformationCustomerReport(
      state,
      action: PayloadAction<PdfReport>
    ): void {
      state.informationCustomerReport = action.payload
    },
  },
})

export const getCompanyDataReport = createAsyncThunk(
  'infoCards/getCompanyDataReport',
  async (variables: IFetchCompanyDataReportProps, thunkAPI) => {
    const response = await InfoCards.fetchCompanyDataReport(variables)
    if (response && response.companyDataReport) {
      FileService.downloadFile({
        filename: 'RaportFirmy.pdf',
        content: response.companyDataReport.content,
      })
    }
    await thunkAPI.dispatch(setCompanyDataReport(response))
    return response
  }
)

export const getCustomerDataReport = createAsyncThunk(
  'infoCards/getCustomerDataReport',
  async (variables: IFetchCustomerDataReportProps, thunkAPI) => {
    const response = await InfoCards.fetchCustomerDataReport(variables)
    if (response && response.customerDataReport) {
      FileService.openBase64PdfFile({
        content: response.customerDataReport.content,
      })
    }
    await thunkAPI.dispatch(setCustomerDataReport(response))
    return response
  }
)

export const getInformationCompanyReport = createAsyncThunk(
  'infoCards/getInformationCompanyReport',
  async (variables: IFetchInformationCompanyReportProps, thunkAPI) => {
    const response = await InfoCards.fetchInformationCompanyReport(variables)
    if (response && response.informationCardCompanyReport) {
      FileService.downloadFile({
        filename: 'FirmaKartaInformacyjna.pdf',
        content: response.informationCardCompanyReport.content,
      })
    }
    await thunkAPI.dispatch(setInformationCompanyReport(response))
    return response
  }
)

export const getInformationCustomerReport = createAsyncThunk(
  'infoCards/getInformationCustomerReport',
  async (variables: IFetchInformationCustomerReportProps, thunkAPI) => {
    const response = await InfoCards.fetchInformationCustomerReport(variables)
    if (response && response.informationCardCustomerReport) {
      FileService.downloadFile({
        filename: 'KartaInformacyjnaKlienta.pdf',
        content: response.informationCardCustomerReport.content,
      })
    }
    await thunkAPI.dispatch(setInformationCustomerReport(response))
    return response
  }
)

export const {
  setCompanyDataReport,
  setCustomerDataReport,
  setInformationCompanyReport,
  setInformationCustomerReport,
} = infoCardsSlice.actions

export default infoCardsSlice.reducer
