import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDKOSerialCorrespondenceReport } from '../../ducks/reports'

import './Reports.scss'

import { ReportWithList } from './ReportWithList'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DKOSerialCorrespondenceReport: FC = () => {
  useAuthRedirect(ACCESS_PAGES.DKO_SERIAL_CORRESPONDENCE_REPORT)
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DKOSerialCorrespondenceReport
  )

  return (
    <ReportWithList
      isLoading={isLoading}
      reportAPIName='DKOSerialCorrespondenceReport'
      reportName='Korespondencja-seryjna.xlsx'
      reduxAction={fetchDKOSerialCorrespondenceReport}
    />
  )
}

export default DKOSerialCorrespondenceReport
