import React, { FunctionComponent, useCallback } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReduxModal from '../../commons/Modals/ReduxModal'
import { AppDispatch } from '../../../ducks'
import { addNewProposedInvestment } from '../../../ducks/proposedInvestments'
import { getInterestedCustomerByID } from '../../../ducks/interestedCustomers'
import SimpleInputList2 from '../../commons/Inputs/SimpleInputs/SimpleInputList2'
import { useProposedInvestment } from '../useProposedInvestment'
import { IProposedInvestment } from '../../../services/proposedInvestmentsService'
import { useDataForProposedInputs } from '../useDataForProposedInputs'

interface IAddProposedPlaceProps {
  customerID: number
  handleClose: () => void
  show: boolean
  proposedPlaces: IProposedInvestment[]
  modalMode?: boolean
}

const AddProposedPlace: FunctionComponent<IAddProposedPlaceProps> = ({
  customerID,
  handleClose,
  show,
  proposedPlaces,
  modalMode = true,
}) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const { state: proposedState, setters } = useProposedInvestment()
  const {
    stagesByInvestment,
    placesByChosenStage,
    investmentsWithFirstPositionEmpty,
  } = useDataForProposedInputs({ proposedState })

  const isAddProposedDisabled =
    proposedState.investment?.id === -1 || proposedState.stage?.id === -1

  const isAlreadyProposed = React.useCallback(
    (name: string) => {
      const matchedProposedItems = proposedPlaces.filter(
        (proposed) =>
          `${proposed?.investment?.name || ''} - ${
            proposed?.stage?.name || ''
          }${proposed?.place ? ' - ' + proposed.place.placeCode : ''}` === name
      )
      return !!matchedProposedItems.length
    },
    [proposedPlaces]
  )

  const onSubmitForm = useCallback(
    async (e: any) => {
      e.preventDefault()
      const name = `${proposedState.investment?.name || ''} - ${
        proposedState.stage?.name || ''
      }${
        proposedState.place.id !== -1 ? ' - ' + proposedState.place?.name : ''
      }`
      const proposedInvestment = {
        investmentID: proposedState.investment?.id,
        stageID: proposedState.stage?.id,
        placeID:
          proposedState.place?.id !== -1 ? proposedState.place?.id : null,
        customerID,
      }
      if (isAlreadyProposed(name)) {
        return alert(t('place:labels.proposed-place-duplicate'))
      }
      await dispatch(addNewProposedInvestment(proposedInvestment))
      await dispatch(getInterestedCustomerByID(customerID))
      handleClose()
    },
    [
      customerID,
      dispatch,
      handleClose,
      isAlreadyProposed,
      proposedState.investment,
      proposedState.place,
      proposedState.stage,
      t,
    ]
  )

  const addProposedPlaceModalData = (
    <Form onSubmit={onSubmitForm}>
      <Col lg={12} md={12} sm={12}>
        {!modalMode && (
          <h6 className='px-1 mt-4'>
            {t('customers:labels.add-proposed-place')}
          </h6>
        )}
        <SimpleInputList2
          defaultValue={proposedState.investment}
          onChangeValue={setters.onChangeInvestmentValue}
          label={t('commons:labels.investment').toString()}
          options={investmentsWithFirstPositionEmpty}
        />
        <SimpleInputList2
          defaultValue={proposedState.stage}
          onChangeValue={setters.onChangeStageValue}
          label={String(t('commons:labels.stage'))}
          options={stagesByInvestment}
        />
        {Boolean(placesByChosenStage.length) && (
          <SimpleInputList2
            defaultValue={proposedState.place}
            disabled={placesByChosenStage.length <= 1}
            onChangeValue={setters.onChangePlacesValue}
            label={String(t('place:labels.place-no'))}
            options={placesByChosenStage}
          />
        )}
      </Col>
      <Col className='d-flex justify-content-end' lg={12} md={12} sm={12}>
        <Button type={'submit'} disabled={isAddProposedDisabled}>
          {modalMode ? t('commons:actions.add') : 'Dodaj propozycje'}
        </Button>
      </Col>
    </Form>
  )

  return (
    <>
      {modalMode ? (
        <ReduxModal
          body={addProposedPlaceModalData}
          cancelAction={handleClose}
          onHide={handleClose}
          show={show}
          title={t('customers:labels.add-proposed-place')}
        />
      ) : (
        addProposedPlaceModalData
      )}
    </>
  )
}

export default AddProposedPlace
