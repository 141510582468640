import React from 'react'
import TooltipWrapper from '../commons/TooltipWrapper'

const columnsData = [
  {
    id: 'customerID',
    Header: 'Zainteresowany',
    accessor: (a: {
      unassignedCustomer: { customerID?: number | null }
    }): string => (a.unassignedCustomer?.customerID ? '☑' : '☐'),
    width: '150',
    disableSortBy: true,
  },
  {
    Header: 'Data wpływu',
    accessor: 'date',
    width: '200',
  },
  {
    id: 'attachmentsLength',
    Header: 'Załączniki',
    accessor: (a: { attachments?: {}[] }): string =>
      a.attachments?.length ? '☑' : '☐',
    width: '150',
  },
  {
    Header: 'Temat',
    accessor: 'subject',
    width: '250',
  },
  {
    id: 'content',
    Header: 'Treść',
    accessor: (a: { content?: string; htmlContent?: string }): JSX.Element => {
      const string = a?.content || ''
      const shorterString =
        string?.length > 499 ? string?.substr(0, 500).concat('...') : string
      const linesArray =
        shorterString.split('\n').filter((s: string) => s) ?? []
      const lessLinesArray =
        linesArray.length > 5 ? linesArray.slice(0, 4) : linesArray
      const linesHTML = lessLinesArray.map((line, index) => (
        <span key={index + 'span'}>{' ' + line}</span>
      ))

      const component = (
        <TooltipWrapper
          message={
            a?.htmlContent ? (
              <iframe
                style={{
                  width: 900,
                  border: 'none',
                  height: 730,
                }}
                id='idIframe'
                srcDoc={a?.htmlContent}
              />
            ) : (
              ''
            )
          }
        >
          <div style={{ width: '100%' }}>{linesHTML}</div>
        </TooltipWrapper>
      )

      return component
    },
    width: '1220',
  },
]

export default columnsData
