import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BuildingService, {
  IBuilding,
  IBuildingInput,
} from '../services/buildingService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface IBuildingState {
  allBuildings: IBuilding[]
  buildingsByInvestmentsNames: IBuilding[]
  isLoadingBuildings: boolean
}

const initialState: IBuildingState = {
  allBuildings: [],
  buildingsByInvestmentsNames: [],
  isLoadingBuildings: false,
}

export const getAllBuildings = createAsyncThunk(
  'buildings/getAllBuildings',
  async () => {
    return BuildingService.getAllBuilidings()
  }
)

export const getBuildingsByInvestmentNames = createAsyncThunk(
  'garages/getBuildingsByInvestmentNames',
  async ({
    investmentsNames,
    isTypeService,
  }: {
    investmentsNames: string[]
    isTypeService: boolean
  }) => {
    return BuildingService.getBuildingsByInvestmentsNames(
      investmentsNames,
      isTypeService
    )
  }
)

export const addNewBuilding = createAsyncThunk(
  'buildings/addNewBuilding',
  async (building: IBuildingInput) => {
    return BuildingService.addBuilding(building)
  }
)

export const deleteBuilding = createAsyncThunk(
  'buildings/deleteBuilding',
  async (buildingID: number) => {
    return BuildingService.deleteBuilding(buildingID)
  }
)

export const updateBuilding = createAsyncThunk(
  'buildings/updateBuilding',
  async (variables: { buildingID: number; building: IBuildingInput }) => {
    return BuildingService.updateBuilding(variables)
  }
)

const buildingsSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllBuildings.fulfilled.toString()]: (state, action): void => {
      state.allBuildings = action.payload.buildings
      state.isLoadingBuildings = false
    },
    [getAllBuildings.pending.toString()]: (state): void => {
      state.isLoadingBuildings = true
    },
    [getAllBuildings.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:getAllBuildingsError')))
      state.isLoadingBuildings = false
    },
    [getBuildingsByInvestmentNames.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.buildingsByInvestmentsNames =
        action.payload.buildingsByInvestmentsNames
      state.isLoadingBuildings = false
    },
    [getBuildingsByInvestmentNames.pending.toString()]: (state): void => {
      state.isLoadingBuildings = true
    },
    [getBuildingsByInvestmentNames.rejected.toString()]: (state): void => {
      errorToastNotify(
        String(i18n.t('toast:getBuildingsByInvestmentNamesError'))
      )
      state.isLoadingBuildings = false
    },
    [addNewBuilding.fulfilled.toString()]: (state, action): void => {
      successToastNotify(String(i18n.t('toast:addBuilding')))
      const newBuilding = action.payload.saveBuilding
      state.buildingsByInvestmentsNames = [
        ...state.buildingsByInvestmentsNames,
        newBuilding,
      ]
      state.isLoadingBuildings = false
    },
    [addNewBuilding.pending.toString()]: (state): void => {
      state.isLoadingBuildings = true
    },
    [addNewBuilding.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addBuildingError')))
      state.isLoadingBuildings = false
    },
    [deleteBuilding.fulfilled.toString()]: (state, action): void => {
      successToastNotify(String(i18n.t('toast:deleteBuilding')))
      const deletedBuildingID = action.meta.arg
      const filteredBuildings = state.buildingsByInvestmentsNames.filter(
        (building) => building.id !== deletedBuildingID
      )
      state.buildingsByInvestmentsNames = filteredBuildings
      state.isLoadingBuildings = false
    },
    [deleteBuilding.pending.toString()]: (state): void => {
      state.isLoadingBuildings = true
    },
    [deleteBuilding.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:deleteBuildingError')))
      state.isLoadingBuildings = false
    },
    [updateBuilding.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:editBuilding')))
      state.isLoadingBuildings = false
    },
    [updateBuilding.pending.toString()]: (state): void => {
      state.isLoadingBuildings = true
    },
    [updateBuilding.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:editBuildingError')))
      state.isLoadingBuildings = false
    },
  },
})

export default buildingsSlice.reducer
