import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDSPDailyInterestedPartiesReport } from '../../ducks/reports'
import Report from './Report'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'
import useGetRole from '../../hooks/useGetRole'

const DSPDailyInterestedPartiesReport: FC = () => {
  useAuthRedirect([ACCESS_PAGES.DSP_AND_DSPADM_REPORTS])
  const { isDNKCustomer } = useGetRole()
  const { DSPDailyInterestedPartiesReport } = useSelector(
    (state: RootState) => state.reports
  )

  return (
    <Report
      reportData={DSPDailyInterestedPartiesReport}
      reportName={
        isDNKCustomer
          ? 'Dzienny Zainteresowani DNK.xlsx'
          : 'Dzienny Zainteresowani DSP.xlsx'
      }
      reduxAction={fetchDSPDailyInterestedPartiesReport}
    />
  )
}

export default DSPDailyInterestedPartiesReport
