import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AdminITService, {
  FetchClientsInInvestmentsProps,
  IEmployee,
  IEmployeeInput,
  IEmployeeLogs,
  IEmployeeLogsFetch,
} from '../services/adminITService'
import { IContractCustomer } from '../graphql/contractCustomers'
import { IContractCustomerCompany } from '../graphql/contractCustomerCompany'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface AdminITState {
  companiesInInvestments: IContractCustomerCompany[]
  companiesInInvestmentsTotal: number
  clientsInInvestments: IContractCustomer[]
  clientsInInvestmentsTotal: number
  employeeLogs: IEmployeeLogs[]
  employeeLogsTotal: number
  employeeList: IEmployee[]
  tradersInitials: { initials: string }[]
  isLoadingCompaniesInInvestments: boolean
  isLoadingClientsInInvestments: boolean
}

const initialState = {
  clientsInInvestments: [],
  clientsInInvestmentsTotal: 0,
  companiesInInvestments: [],
  companiesInInvestmentsTotal: 0,
  employeeLogs: [],
  employeeList: [],
  tradersInitials: [],
  employeeLogsTotal: 0,
  isLoadingClientsInInvestments: false,
  isLoadingCompaniesInInvestments: false,
} as AdminITState

export const fetchClientsInInvestments = createAsyncThunk(
  'adminIT/fetchClientsInInvestments',
  async (variables: FetchClientsInInvestmentsProps) => {
    return AdminITService.getClientsInInvestments(variables)
  }
)

export const fetchClientsInInvestmentsForSearch = createAsyncThunk(
  'adminIT/fetchClientsInInvestmentsForSearch',
  async (variables: FetchClientsInInvestmentsProps) => {
    return AdminITService.getClientsInInvestmentsForSearch(variables)
  }
)

export const fetchCompaniesInInvestmentsForSearch = createAsyncThunk(
  'adminIT/fetchCompaniesInInvestmentsForSearch',
  async (variables: FetchClientsInInvestmentsProps) => {
    return AdminITService.getCompaniesInInvestmentsForSearch(variables)
  }
)

export const generateClientDataInInvestment = createAsyncThunk(
  'adminIT/generateClientDataInInvestment',
  async () => {
    return AdminITService.fetchClientDataReport()
  }
)

export const fetchEmployeeLogs = createAsyncThunk(
  'adminIT/fetchEmployeeLogs',
  async (variables: IEmployeeLogsFetch) => {
    return AdminITService.fetchEmployeeLogs(variables)
  }
)

export const getAllUsers = createAsyncThunk(
  'employee/getAllUsers',
  async () => {
    return AdminITService.getAllUsers()
  }
)

export const addEmployee = createAsyncThunk(
  'employee/addUser',
  async (user: IEmployeeInput) => {
    return AdminITService.addUser(user)
  }
)

export const editEmployee = createAsyncThunk(
  'employee/editUser',
  async ({ user, userID }: { user: IEmployeeInput; userID: string }) => {
    return AdminITService.editUser(user, userID)
  }
)

export const deleteEmployee = createAsyncThunk(
  'employee/deleteUser',
  async (userID: string) => {
    return AdminITService.deleteUser(userID)
  }
)

export const fetchTraderInitials = createAsyncThunk(
  'employee/fetchTraderInitials',
  async (roles?: string[]) => {
    return AdminITService.fetchTraderInitials(roles)
  }
)

const adminITSlice = createSlice({
  name: 'adminIT',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchClientsInInvestments.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ): void => {
      state.clientsInInvestmentsTotal =
        action.payload.paginatedContractCustomersByKeyWordsForAdmin?.total ?? 0
      state.clientsInInvestments =
        action.payload.paginatedContractCustomersByKeyWordsForAdmin?.contractCustomers
      state.isLoadingClientsInInvestments = false
    },
    [fetchClientsInInvestments.pending.toString()]: (state): void => {
      state.isLoadingClientsInInvestments = true
    },
    [fetchClientsInInvestments.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:fetchClientsInInvestmentsError')))
      state.isLoadingClientsInInvestments = false
    },
    [fetchClientsInInvestmentsForSearch.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ): void => {
      state.clientsInInvestmentsTotal =
        action.payload.paginatedContractCustomersByKeyWordsForAdmin?.total ?? 0
      state.clientsInInvestments =
        action.payload.paginatedContractCustomersByKeyWordsForAdmin?.contractCustomers
      state.isLoadingClientsInInvestments = false
    },
    [fetchClientsInInvestmentsForSearch.pending.toString()]: (state): void => {
      state.isLoadingClientsInInvestments = true
    },
    [fetchClientsInInvestmentsForSearch.rejected.toString()]: (state): void => {
      errorToastNotify(
        String(i18n.t('toast:fetchClientsInInvestmentsForSearch'))
      )
      state.isLoadingClientsInInvestments = false
    },
    [fetchCompaniesInInvestmentsForSearch.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ): void => {
      state.companiesInInvestmentsTotal =
        action.payload.paginatedContractCustomerCompaniesByKeyWordsForAdmin
          ?.total ?? 0
      state.companiesInInvestments =
        action.payload.paginatedContractCustomerCompaniesByKeyWordsForAdmin?.contractCustomerCompanies
      state.isLoadingCompaniesInInvestments = false
    },
    [fetchCompaniesInInvestmentsForSearch.pending.toString()]: (
      state
    ): void => {
      state.isLoadingCompaniesInInvestments = true
    },
    [fetchCompaniesInInvestmentsForSearch.rejected.toString()]: (
      state
    ): void => {
      errorToastNotify(
        String(i18n.t('toast:fetchCompaniesInInvestmentsForSearch'))
      )
      state.isLoadingCompaniesInInvestments = false
    },
    [fetchEmployeeLogs.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ): void => {
      state.employeeLogs = action.payload.paginatedChanges.changes
      state.employeeLogsTotal = action.payload.paginatedChanges.total
    },
    [fetchEmployeeLogs.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:fetchEmployeeLogs')))
    },
    [getAllUsers.fulfilled.toString()]: (state, action): void => {
      state.employeeList = action.payload.users
    },
    [getAllUsers.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:getAllUsersError')))
    },
    [addEmployee.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:addUser')))
    },
    [addEmployee.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:addUserError')))
    },
    [editEmployee.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editUser')))
    },
    [editEmployee.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editUserError')))
    },
    [deleteEmployee.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:removeUser')))
    },
    [deleteEmployee.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:removeUserError')))
    },
    [fetchTraderInitials.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ): void => {
      state.tradersInitials = action.payload.users
    },
    [fetchTraderInitials.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:fetchTraderInitialsError')))
    },
  },
})

export default adminITSlice.reducer
