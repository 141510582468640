import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import UtilityRoomService from '../services/utilityRoomsService'
import { RootState } from './index'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

import { IInvestmentNames } from '../hooks/useGetInvestmentNames'

export interface IUtilityRoom {
  id?: number
  number: string
  designedInnerUtilRoomArea: number
  asBuildUsableUtilRoomArea: number
  asBuildInnerUtilRoomArea: number
  designedUsableUtilRoomArea: number
  comment: string
  buildingID?: number | null
  garageID?: number | null
  placeID?: number | null
  contractID?: number | null
  isTypeService: boolean
  isAfterMigration: boolean
  building?: {
    id: number
    name: string
  }
  garage?: {
    id: number
    name: string
  }
  place?: {
    placeCode: string
    id: number
    activeContracts?: {
      id?: number
    }[]
    building?: {
      name: string
      id: number
    }
    utilityRooms?: {
      id?: number
      parkingPlace?: {
        name?: string
        id?: number
        isExternal?: boolean
        type?: string
        garage?: {
          name?: string
        }
      }
    }[]
  }
  parkingPlaceID: number | null
  parkingPlace?: {
    name?: string
    id?: number
    isExternal?: boolean
    type?: string
    garage?: {
      name?: string
    }
  }
}

interface IUtilityRoomsStore {
  areUtilityRoomsOnContractLoading: boolean
  areUtilityRoomsToPlaceLoading: boolean
  rooms: IUtilityRoom[]
  allRooms: IUtilityRoom[]
  roomsLinkedToPlace: IUtilityRoom[]
  roomsLinkedToContract: IUtilityRoom[]
  unassignedUtilityRooms: IUtilityRoom[]
}

const initialState: IUtilityRoomsStore = {
  areUtilityRoomsOnContractLoading: false,
  areUtilityRoomsToPlaceLoading: false,
  rooms: [],
  allRooms: [],
  roomsLinkedToPlace: [],
  roomsLinkedToContract: [],
  unassignedUtilityRooms: [],
}

export const getUnassignedUtilityRoomsMinusAdded = createSelector(
  (state: RootState) => state.utilityRooms,
  (utilityRooms) => {
    return utilityRooms.unassignedUtilityRooms.filter(
      (unassigned) => !unassigned.contractID
    )
  }
)

export const getAllRooms = createAsyncThunk(
  'utilityRooms/getAllRooms',
  async () => {
    return UtilityRoomService.getAllUtilityRooms()
  }
)

export const getRoomsByInvestmentNames = createAsyncThunk(
  'utilityRooms/getRoomsByInvestmentNames',
  async ({ investmentNames, stagesIDs }: IInvestmentNames) => {
    return UtilityRoomService.getRoomsByInvestmentNames({
      investmentNames,
      stagesIDs,
    })
  }
)

export const getRoomsByInvestmentStageId = createAsyncThunk(
  'utilityRooms/getRoomsByInvestmentStageId',
  async (stageID: number) => {
    return UtilityRoomService.getRoomsByInvestmentStageId(stageID)
  }
)

export const addNewUtilityRoom = createAsyncThunk(
  'utilityRooms/addNewUtilityRoom',
  async (variables: { utilityRoom: IUtilityRoom }) => {
    return UtilityRoomService.addNewRoom(variables)
  }
)

export const updateUtilityRoom = createAsyncThunk(
  'utilityRooms/updateUtilityRoom',
  async (variables: { utilityRoom: IUtilityRoom; utilityRoomID: number }) => {
    return UtilityRoomService.updateRoom(variables)
  }
)

export const removeUtilityRoom = createAsyncThunk(
  'utilityRooms/removeUtilityRoom',
  async (utilityRoomID: number) => {
    return UtilityRoomService.removeRoom(utilityRoomID)
  }
)

export const getUtilityRoomsLinkedToContract = createAsyncThunk(
  'utilityRooms/getUtilityRoomsLinkedToContract',
  async (variables: { contractID: number }) => {
    return {
      rooms: await UtilityRoomService.getUtilityRoomsByContract({
        contractID: variables.contractID,
      }),
    }
  }
)

export const getUtilityRoomsLinkedToPlace = createAsyncThunk(
  'utilityRooms/getUtilityRoomsLinkedToPlace',
  async (variables: { placeID: number }) => {
    return {
      rooms: await UtilityRoomService.getUtilityRoomsByPlace(variables.placeID),
    }
  }
)

export const getUnassignedUtilityRooms = createAsyncThunk(
  'utilityRooms/getUnassignedUtilityRooms',
  async (variables: IInvestmentNames) => {
    return {
      rooms: (await UtilityRoomService.getRoomsByInvestmentNames({
        investmentNames: variables.investmentNames,
        stagesIDs: variables.stagesIDs,
      })) as IUtilityRoom[],
    }
  }
)

export const getContractConnectedToUtilityRoom = createAsyncThunk(
  'utilityRooms/getContractConnectedToUtilityRoom',
  async (contractID: number) => {
    return UtilityRoomService.getContractConnectedToUtilityRoom(contractID)
  }
)

const utilityRoomsSlice = createSlice({
  name: 'utilityRooms',
  initialState,
  reducers: {
    addUtilityRoomToContract(state, action: PayloadAction<IUtilityRoom>): void {
      state.roomsLinkedToContract.push(action.payload)
    },
    removeUtilityRoomFromContract(state, action: PayloadAction<number>): void {
      const id = action.payload
      const foundIndex = state.roomsLinkedToContract.findIndex(
        (room) => room.id === id
      )
      if (foundIndex !== -1) {
        state.roomsLinkedToContract.splice(foundIndex, 1)
      }
    },
    clearLinkedUtilityRooms(state): void {
      state.roomsLinkedToContract = []
      state.roomsLinkedToPlace = []
    },
  },
  extraReducers: {
    [getAllRooms.fulfilled.toString()]: (state, action): void => {
      state.allRooms = action.payload.utilityRooms
      state.areUtilityRoomsToPlaceLoading = false
      state.areUtilityRoomsOnContractLoading = false
    },
    [getAllRooms.pending.toString()]: (state): void => {
      state.areUtilityRoomsToPlaceLoading = true
      state.areUtilityRoomsOnContractLoading = true
    },
    [getAllRooms.rejected.toString()]: (state): void => {
      state.areUtilityRoomsToPlaceLoading = false
      state.areUtilityRoomsOnContractLoading = false
    },
    [getRoomsByInvestmentNames.fulfilled.toString()]: (state, action): void => {
      state.rooms = action.payload.utilityRoomsByInvestmentsNamesThroughGarage
      state.areUtilityRoomsToPlaceLoading = false
      state.areUtilityRoomsOnContractLoading = false
    },
    [getRoomsByInvestmentNames.pending.toString()]: (state): void => {
      state.rooms = []
      state.areUtilityRoomsToPlaceLoading = true
      state.areUtilityRoomsOnContractLoading = true
    },
    [getRoomsByInvestmentNames.rejected.toString()]: (state): void => {
      state.rooms = []
      state.areUtilityRoomsToPlaceLoading = false
      state.areUtilityRoomsOnContractLoading = false
    },
    [getUtilityRoomsLinkedToContract.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.roomsLinkedToContract =
        action.payload.rooms.utilityRoomsByContract ?? []
      state.areUtilityRoomsOnContractLoading = false
    },
    [getUtilityRoomsLinkedToContract.pending.toString()]: (state, _): void => {
      state.areUtilityRoomsOnContractLoading = true
    },
    [getUtilityRoomsLinkedToContract.rejected.toString()]: (state, _): void => {
      state.roomsLinkedToContract = []
      errorToastNotify(
        String(i18n.t('toast:getUtilityRoomsLinkedToContractError'))
      )
      state.areUtilityRoomsOnContractLoading = false
    },
    [getUtilityRoomsLinkedToPlace.fulfilled.toString()]: (
      state,
      action
    ): void => {
      const pp = action?.payload?.rooms?.utilityRoomsByPlace ?? []
      state.roomsLinkedToPlace = pp
      state.areUtilityRoomsToPlaceLoading = false
      //if roomsLinkedToPlace is not empty there should not be any rooms linked to Contract! only 'either - or'
      if (pp?.length > 0) {
        state.roomsLinkedToContract = []
      }
    },
    [getUtilityRoomsLinkedToPlace.pending.toString()]: (state, _): void => {
      state.areUtilityRoomsToPlaceLoading = true
    },
    [getUtilityRoomsLinkedToPlace.rejected.toString()]: (state, _): void => {
      state.roomsLinkedToPlace = []
      errorToastNotify(
        String(i18n.t('toast:getUtilityRoomsLinkedToPlaceError'))
      )
      state.areUtilityRoomsToPlaceLoading = false
    },
    [getUnassignedUtilityRooms.fulfilled.toString()]: (state, action): void => {
      const possibleUtilityRooms =
        action.payload.rooms.utilityRoomsByInvestmentsNamesThroughGarage ?? []
      const rawRooms =
        (possibleUtilityRooms.filter(
          (room: IUtilityRoom) => room.contractID == null
        ) as IUtilityRoom[]) ?? []

      const filteredRooms = rawRooms.filter(
        (room: IUtilityRoom) => room.placeID == null
      ) // Business logic - unassigned places dont' have a place
      state.unassignedUtilityRooms = filteredRooms
      state.areUtilityRoomsToPlaceLoading = false
      state.areUtilityRoomsOnContractLoading = false
    },
    [getUnassignedUtilityRooms.pending.toString()]: (state): void => {
      state.areUtilityRoomsToPlaceLoading = true
      state.areUtilityRoomsOnContractLoading = true
    },
    [getUnassignedUtilityRooms.rejected.toString()]: (state): void => {
      state.areUtilityRoomsToPlaceLoading = false
      state.areUtilityRoomsOnContractLoading = false
    },
    [getRoomsByInvestmentStageId.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.rooms = action.payload.utilityRoomsByStages
      state.areUtilityRoomsToPlaceLoading = false
      state.areUtilityRoomsOnContractLoading = false
    },
    [getRoomsByInvestmentStageId.pending.toString()]: (state, action): void => {
      state.areUtilityRoomsToPlaceLoading = true
      state.areUtilityRoomsOnContractLoading = true
    },
    [getRoomsByInvestmentStageId.rejected.toString()]: (state): void => {
      state.rooms = []
      state.areUtilityRoomsToPlaceLoading = false
      state.areUtilityRoomsOnContractLoading = false
    },
    [addNewUtilityRoom.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:addUtilityRoom')))
    },
    [addNewUtilityRoom.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:addUtilityRoomError')))
    },
    [removeUtilityRoom.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:deleteUtilityRoom')))
    },
    [removeUtilityRoom.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:deleteUtilityRoomError')))
    },
    [updateUtilityRoom.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editUtilityRoom')))
    },
    [updateUtilityRoom.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editUtilityRoomError')))
    },
  },
})

export const {
  addUtilityRoomToContract,
  removeUtilityRoomFromContract,
  clearLinkedUtilityRooms,
} = utilityRoomsSlice.actions

export default utilityRoomsSlice.reducer
