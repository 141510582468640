import * as React from 'react'
import { RaportWithDatesState } from './actions'
import { RaportWithDatesContextProvider } from './raportWithDatesContext'

interface RaportWithDatesProps {
  isLoading: boolean
  generateReport(state: RaportWithDatesState): Promise<void>
}

export const RaportWithDates: React.FC<RaportWithDatesProps> = ({
  children,
  ...rest
}) => (
  <RaportWithDatesContextProvider {...rest}>
    <div className='d-flex flex-column justify-content-center align-items-center my-0 py-0'>
      {children}
    </div>
  </RaportWithDatesContextProvider>
)
