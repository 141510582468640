import React, { FunctionComponent } from 'react'
import './Reports.scss'
import { useSelector } from 'react-redux'

import { RootState } from '../../ducks'
import { fetchDKOAreasReport } from '../../ducks/reports'

import { ReportWithList } from './ReportWithList'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DKOAreasReport: FunctionComponent = () => {
  useAuthRedirect(ACCESS_PAGES.DKO_REPORTS)
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DKOAreasReport
  )

  return (
    <ReportWithList
      reportName='Raport-powierzchni.xlsx'
      reportAPIName='DKOAreasReport'
      reduxAction={fetchDKOAreasReport}
      isLoading={isLoading}
    />
  )
}

export default DKOAreasReport
