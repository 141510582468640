import {
  IContractCustomerCompany,
  IContractCustomerCompanyInput,
} from '../graphql/contractCustomerCompany'
import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'
import { IMarketingConsentInput } from '../graphql/marketingConsents'

export const fetchAllContractCustomerCompanies = (): Promise<any> => {
  const FETCH_ALL_CONTRACT_CUSTOMER_COMPANIES_QUERY = gql`
    query {
      contractCompanyCustomers {
        id
        businessName
        krsString
        taxNumber
        phone1
        phone2
        representativePhone
        businessActivityRegister
        regon
        email
        representativeEmail
        businessType
        placeShares
        lastName
        firstName
        pesel
        residenceName
        residenceSurname
        residenceBusinessName
        residenceCountry
        residenceCity
        residenceStreet
        residenceNumber
        residencePostalCode
        residenceVoivodeship
        residenceIsForeign
        correspondenceName
        correspondenceSurname
        correspondenceBusinessName
        correspondenceCountry
        correspondenceCity
        correspondenceStreet
        correspondenceNumber
        correspondencePostalCode
        correspondenceVoivodeship
        correspondenceIsForeign
        businessAddressName
        businessAddressSurname
        businessAddressBusinessName
        businessAddressCountry
        businessAddressCity
        businessAddressStreet
        businessAddressNumber
        businessAddressPostalCode
        businessAddressVoivodeship
        businessAddressIsForeign
        isInfoDutyFulfilled
        infoDutyForm
        isAgreementPhone
        agreementPhoneDate
        isAgreementPhoneCancelled
        agreementPhoneCancellationDate
        isAgreementEmail
        agreementEmailDate
        isAgreementEmailCancelled
        agreementEmailCancellationDate
        contractID
        customerInvestmentCode
        isCommissionDivision
        commissionDivision
        isAfterMigration
        sourceInfo
        isBroker
        brokerName
        purchasePurpose
        traderInitials
        isBusinessActivity
        customerID
        marketingConsents {
          id
          isInfoDutyFulfilled
          infoDutyForm
          isAgreementPhone
          agreementPhoneDate
          isAgreementPhoneCancelled
          agreementPhoneCancellationDate
          isAgreementEmail
          agreementEmailDate
          isAgreementEmailCancelled
          agreementEmailCancellationDate
          customerID
          contractCustomerCompanyID
          infoDutyFormDate
          isMSWiAAgreement
        }
        companyForm
        customerCode
        comments
        isMSWiAAgreement
        isIKCustomer
        isAgentOfMainCustomer
        isRepresentativePerson
        uuid
        companyParentID
        spouseFiles {
          created
          id
          name
        }
        contract {
          id
        }
        citizenship
        passportNumberAndSeries
        IDNumberAndSeries
      }
    }
  `
  return GraphqlService.send(FETCH_ALL_CONTRACT_CUSTOMER_COMPANIES_QUERY, {})
}

export const addNewContractCustomerCompany = ({
  contractCustomerCompany,
}: {
  contractCustomerCompany: IContractCustomerCompany
}): any => {
  const ADD_CONTRACT_CUSTOMER_COMPANY_QUERY = gql`
    mutation aur($contractCustomerCompany: ContractCustomerCompanyInput!) {
      saveContractCustomerCompany(
        contractCustomerCompany: $contractCustomerCompany
      ) {
        id
      }
    }
  `
  return GraphqlService.send(ADD_CONTRACT_CUSTOMER_COMPANY_QUERY, {
    contractCustomerCompany,
  })
}

export const anonymizeContractCustomerCompany = (
  contractCustomerCompanyID: number
): any => {
  const ANONYMIZE_CONTRACT_CUSTOMER_COMPANY_QUERY = gql`
    mutation anonymizeContractCustomer($contractCustomerCompanyID: Float!) {
      anonymizeContractCustomer(
        contractCustomerCompanyID: $contractCustomerCompanyID
      )
    }
  `
  return GraphqlService.send(ANONYMIZE_CONTRACT_CUSTOMER_COMPANY_QUERY, {
    contractCustomerCompanyID,
  })
}

export const updateContractCompanyCustomerWithMarketingConsents = ({
  contractCustomerCompany,
  contractCustomerCompanyID,
  marketingConsent,
  marketingConsentID,
}: {
  contractCustomerCompany: Partial<IContractCustomerCompanyInput>
  contractCustomerCompanyID: number
  marketingConsent: Partial<IMarketingConsentInput>
  marketingConsentID: number
}): any => {
  const UPDATE_CONTRACT_CUSTOMER_COMPANY_QUERY = gql`
    mutation sp(
      $contractCustomerCompany: ContractCustomerCompanyInput!
      $contractCustomerCompanyID: Float!
      $marketingConsent: MarketingConsentInput!
      $marketingConsentID: Float!
    ) {
      updateContractCompanyCustomerWithMarketingConsent(
        contractCustomerCompany: $contractCustomerCompany
        contractCustomerCompanyID: $contractCustomerCompanyID
        marketingConsent: $marketingConsent
        marketingConsentID: $marketingConsentID
      ) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_CONTRACT_CUSTOMER_COMPANY_QUERY, {
    contractCustomerCompany,
    contractCustomerCompanyID,
    marketingConsent,
    marketingConsentID,
  })
}

export const updateContractCompanyCustomerWithMarketingConsentsWithoutID = ({
  contractCustomerCompany,
  contractCustomerCompanyID,
  marketingConsent,
}: {
  contractCustomerCompany: Partial<IContractCustomerCompanyInput>
  contractCustomerCompanyID: number
  marketingConsent: Partial<IMarketingConsentInput>
}): any => {
  const UPDATE_CONTRACT_CUSTOMER_COMPANY_QUERY = gql`
    mutation sp(
      $contractCustomerCompany: ContractCustomerCompanyInput!
      $contractCustomerCompanyID: Float!
      $marketingConsent: MarketingConsentInput!
    ) {
      updateContractCompanyCustomerWithMarketingConsent(
        contractCustomerCompany: $contractCustomerCompany
        contractCustomerCompanyID: $contractCustomerCompanyID
        marketingConsent: $marketingConsent
      ) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_CONTRACT_CUSTOMER_COMPANY_QUERY, {
    contractCustomerCompany,
    contractCustomerCompanyID,
    marketingConsent,
  })
}

export const updateContractCustomerCompany = ({
  contractCustomerCompany,
  contractCustomerCompanyID,
  marketingConsent,
}: {
  contractCustomerCompany: Partial<IContractCustomerCompanyInput>
  contractCustomerCompanyID: number
  marketingConsent: IMarketingConsentInput | undefined
}): any => {
  const UPDATE_CONTRACT_CUSTOMER_COMPANY_QUERY = gql`
    mutation sp(
      $marketingConsent: MarketingConsentInput
      $contractCustomerCompany: ContractCustomerCompanyInput!
      $contractCustomerCompanyID: Float!
    ) {
      updateContractCompanyCustomerWithMarketingConsent(
        contractCustomerCompany: $contractCustomerCompany
        contractCustomerCompanyID: $contractCustomerCompanyID
        marketingConsent: $marketingConsent
      ) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_CONTRACT_CUSTOMER_COMPANY_QUERY, {
    contractCustomerCompany,
    contractCustomerCompanyID,
  })
}

export const saveContractCustomerCompany = (
  contractCustomerCompany: Partial<IContractCustomerCompanyInput>
): any => {
  const SAVE_CONTRACT_CUSTOMER_COMPANY_QUERY = gql`
    mutation($contractCustomerCompany: ContractCustomerCompanyInput!) {
      saveContractCompanyCustomer(
        contractCustomerCompany: $contractCustomerCompany
      ) {
        id
      }
    }
  `
  return GraphqlService.send(SAVE_CONTRACT_CUSTOMER_COMPANY_QUERY, {
    contractCustomerCompany,
  })
}

export const deleteContractCustomerCompany = (
  contractCustomerCompanyID: number
): Promise<any> => {
  const DELETE_CONTRACT_CUSTOMER_COMPANY_QUERY = gql`
    mutation deleteContractCompanyCustomer($contractCustomerCompanyID: Float!) {
      deleteContractCompanyCustomer(
        contractCustomerCompanyID: $contractCustomerCompanyID
      )
    }
  `
  return GraphqlService.send(DELETE_CONTRACT_CUSTOMER_COMPANY_QUERY, {
    contractCustomerCompanyID,
  })
}

export const getFileByID = (fileID: number): Promise<any> => {
  const FETCH_FILE_BY_ID = gql`
    query($fileID: Float!) {
      file(fileID: $fileID) {
        content
        name
      }
    }
  `
  return GraphqlService.send(FETCH_FILE_BY_ID, { fileID })
}

const mutations = {
  addNewContractCustomerCompany,
  anonymizeContractCustomerCompany,
  deleteContractCustomerCompany,
  fetchAllContractCustomerCompanies,
  updateContractCustomerCompany,
  updateContractCompanyCustomerWithMarketingConsents,
  updateContractCompanyCustomerWithMarketingConsentsWithoutID,
  getFileByID,
  saveContractCustomerCompany,
}

export default mutations
