import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  downloadXlsxReport,
  fetchDSPContractCustomerSearchReport,
  fetchDSPCustomerSearchReport,
} from './reports'
import { RootState } from './index'
import { hasRoles } from '../services/idp'
import { DNKRoles } from './globalUser'

export const generateData = createAsyncThunk(
  'search/generateData',
  async (
    variables: { interestedChecked: boolean; regularChecked: boolean },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState
    const isDNKCustomer = hasRoles(DNKRoles, state.globalUser.roles)
    const { query } = state.search

    if (variables.interestedChecked) {
      thunkAPI
        .dispatch(
          fetchDSPCustomerSearchReport({
            keyword: query,
            isDNKCustomer,
          })
        )
        .then((response: any) => {
          if (response.payload?.DSPCustomerSearchReport) {
            return thunkAPI.dispatch(
              downloadXlsxReport({
                content: response.payload.DSPCustomerSearchReport,
                filename: 'Zainteresowani-Wyszukiwarka.xlsx',
              })
            )
          }
          alert('Błąd podczas pobierania raportu')
        })
    }
    if (variables.regularChecked) {
      thunkAPI
        .dispatch(
          fetchDSPContractCustomerSearchReport({
            keyword: query,
          })
        )
        .then((response: any) => {
          if (response.payload?.DSPContractCustomerSearchReport) {
            return thunkAPI.dispatch(
              downloadXlsxReport({
                content: response.payload.DSPContractCustomerSearchReport,
                filename: 'KlienciNaUmowie-Wyszukiwarka.xlsx',
              })
            )
          }
          alert('Błąd podczas pobierania raportu')
        })
    }
  }
)

export interface SearchSliceState {
  query: string
  interestedChecked: boolean
  regularChecked: boolean
}

const initialState = {
  query: '',
  interestedChecked: true,
  regularChecked: true,
} as SearchSliceState

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch(state): void {
      state.query = ''
    },
    setSearchQuery(state, action: PayloadAction<string>): void {
      state.query = action.payload
    },
    toggleInterestedChecked(state): void {
      state.interestedChecked = !state.interestedChecked
    },
    toggleRegularChecked(state): void {
      state.regularChecked = !state.regularChecked
    },
  },
})

export const {
  clearSearch,
  setSearchQuery,
  toggleInterestedChecked,
  toggleRegularChecked,
} = searchSlice.actions
export default searchSlice.reducer
