import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../ducks'
import { DNKRoles, DSPRoles } from '../ducks/globalUser'

import { hasRoles } from '../services/idp'

import { IStage } from '../graphql/investments'

export const useStagesForDSPandDNK = (): IStage[] => {
  const { chosenInvestment } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const { roles } = useSelector((state: RootState) => state.globalUser)

  const hasDNKRole = useMemo(() => hasRoles(DNKRoles, roles), [roles])
  const isDSPCustomer = useMemo(() => hasRoles(DSPRoles, roles || []), [roles])

  const DNKStages = useMemo(
    () =>
      hasDNKRole
        ? chosenInvestment?.stages?.filter((stage) =>
            stage?.name.includes('_komercja')
          )
        : [],
    [chosenInvestment, hasDNKRole]
  )
  const DSPStages = useMemo(
    () =>
      isDSPCustomer
        ? chosenInvestment?.stages?.filter(
            (stage) => !stage?.name.includes('_komercja')
          )
        : [],
    [chosenInvestment, isDSPCustomer]
  )
  return hasDNKRole
    ? DNKStages
    : isDSPCustomer
    ? DSPStages
    : chosenInvestment?.stages
}

export default useStagesForDSPandDNK
