import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import SimpleInputDate from '../commons/Inputs/SimpleInputs/SimpleInputDate'
import SimpleInputText from '../commons/Inputs/SimpleInputs/SimpleInputText'

import { IInterestedCustomer } from '../../graphql/customers'
import { IContract } from '../../graphql/contracts/contracts'

interface IInterestedCustomerContractsProps {
  customer: IInterestedCustomer
}

export const InterestedCustomerContracts: FunctionComponent<IInterestedCustomerContractsProps> = ({
  customer,
}) => {
  const { t } = useTranslation()

  return (
    <Col lg={12} style={{ paddingLeft: '4%', paddingRight: '4%' }}>
      <Row className='d-flex justify-content-center mt-3'>
        {customer.contracts.map((contract: IContract, index: number) => (
          <Col
            className='my-4'
            lg='auto'
            md='auto'
            sm='auto'
            key={contract.id + '.' + index}
          >
            <SimpleInputText
              disabled
              label={`${t('place:labels.contract-no')} ${index + 1}.`}
              valueDependingOnExternalValues={contract.contractNo}
            />
            <SimpleInputDate
              disabled
              defaultValue={contract.contractDealDate}
              label={`${t('place:labels.date-of-contract-conclusion')} ${
                index + 1
              }.`}
            />
          </Col>
        ))}
      </Row>
    </Col>
  )
}

export default InterestedCustomerContracts
