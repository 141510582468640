import {
  faLock,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useKeycloak } from '@react-keycloak/web'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetGlobalUser } from '../../ducks/globalUser'

interface IUserProfile {
  firstName: string
  lastName: string
  username: string
  attributes: {
    initials: string
  }
}

const HeaderUser: FunctionComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { keycloak } = useKeycloak()
  const { t } = useTranslation()
  const [userProfile, setUserProfile] = useState({} as IUserProfile)

  const isUserAuthenticated = keycloak?.authenticated

  useEffect(() => {
    if (keycloak?.authenticated) {
      keycloak
        .loadUserProfile()
        .then(() => setUserProfile(keycloak.profile as IUserProfile))
    }
  }, [isUserAuthenticated, keycloak])

  const logout = (): void => {
    dispatch(resetGlobalUser())
    keycloak &&
      keycloak.logout().then(() => localStorage.setItem('idp:token', ''))
  }

  const openHelp = (): void => {
    window.open('/documents/Instrukcja_IK2.pdf', '_newtab')
  }

  const openUserPanel = (): void => {
    history.push('/panel')
  }

  return (
    <Col>
      <div className='user-panel' onClick={openUserPanel}>
        <FontAwesomeIcon icon={faLock} className='fontIcon' />
      </div>
      <div className='user-panel2'>
        <p>
          {userProfile.firstName} {userProfile.lastName}
        </p>
      </div>
      <div className='user-panel3 clickableGroup' onClick={openHelp}>
        <FontAwesomeIcon icon={faQuestionCircle} className='fontIcon' />
        <p className='small '>{t('commons:actions.help')}</p>
      </div>
      <div className='user-panel3 clickableGroup' onClick={logout}>
        <FontAwesomeIcon icon={faTimesCircle} className='fontIcon' />
        <p className='small '>{t('commons:actions.logout')}</p>
      </div>
    </Col>
  )
}

export default HeaderUser
