import moment from 'moment'
import {
  IMessage,
  IUnassignedCustomer,
} from '../../../ducks/unassignedCustomers'

export const downloadEml = (message: string) => {
  const date = moment(new Date()).format('HH.mm.ss DD-MM-YY')
  const downloadLink = document.createElement('a')
  document.body.appendChild(downloadLink)
  downloadLink.href = `data:application;base64,${message}`
  downloadLink.target = '_self'
  downloadLink.download = `Wiadomosc ${date}.eml`
  downloadLink.click()
}

export const replToAllMessages = (
  messagesByUnassignedCustomerID: IMessage[],
  selectedUnassignedCustomer: IUnassignedCustomer
) => {
  const messages = Array.from(messagesByUnassignedCustomerID || []).sort(
    (m1, m2) => moment(m2?.date).diff(moment(m1?.date))
  )
  const body = messages
    ?.map((message: IMessage, index) => {
      const header = `<html><body><div style="padding-bottom: 10px">Wiadomość napisana dnia
${moment(messages[index]?.date).format(
  ' DD.MM.YY[, o godz. ]HH:mm:'
)}</div></body></html>`

      const formatMessage = message.htmlContent
        ? message.htmlContent.includes('html')
          ? message.htmlContent.replace('height:100%', 'max-height:10000px')
          : `<html><body>${message.htmlContent.replace(
              'height:100%',
              'max-height:10000px'
            )}</body></html>`
        : ''

      return message?.htmlContent
        ? `${index > 0 ? header : ''}` + formatMessage
        : ''
    })
    .join('<html><body><hr style="margin:30px 0"></body></html>')

  const subject = messages[0]?.subject || ''
  const emlTex = `From: ${selectedUnassignedCustomer?.email}
Subject: RE:${subject}
Date: ${
    messages[0]?.date
      ? new Date(moment(messages[0]?.date).format())
      : new Date()
  }
Content-Type: text/html; charset=utf-8

${body}
    `
  const date = moment(new Date()).format('HH.mm.ss DD-MM-YY')
  const downloadLink = document.createElement('a')
  document.body.appendChild(downloadLink)
  downloadLink.href = `data:application;base64,${window.btoa(
    unescape(encodeURIComponent(emlTex))
  )}`
  downloadLink.target = '_self'
  downloadLink.download = `Wszystkie wiadomosc ${date}.eml`
  downloadLink.click()
}

export const getCellStyle = (
  row: any,
  cell: any,
  timeToServeTaken: number | null,
  timeToServeNotTaken: number | null
) => {
  const takenDate = row?.original?.takenDate
  const leadDate = row?.values?.date
  const diffStatusDate = moment(new Date()).diff(moment(takenDate), 'days')
  const diffLeadDate = moment(new Date()).diff(moment(leadDate), 'days')
  const cleanTimeToServeTaken = timeToServeTaken || 3
  const cleanTimeToServeNotTaken = timeToServeNotTaken || 3

  if (cell?.column?.id === 'status') {
    if (cell?.value === 'Podjęty' && diffStatusDate > cleanTimeToServeTaken) {
      return { color: 'red' }
    } else if (cell?.value === 'Niepodjęty') {
      if (diffLeadDate > cleanTimeToServeNotTaken) {
        return { fontWeight: 'bold', color: 'red' }
      } else {
        return { fontWeight: 'bold' }
      }
    } else if (
      cell?.value === 'Spam' ||
      cell?.value === 'Klient' ||
      cell?.value === 'Zakończony'
    ) {
      return { color: 'grey' }
    } else {
      return {}
    }
  } else if (cell?.column?.id === 'broker') {
    return { color: 'red' }
  }
  return {}
}

export const getMessagesCellStyle = (cell: any) => {
  const setGrey =
    cell.original?.isSpam ||
    cell.original?.isArchive ||
    cell.original?.fromCustomer
  return setGrey ? { color: 'grey' } : {}
}
