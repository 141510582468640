import { IInterestedCustomer } from '../graphql/customers'
import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'
import { ICustomer } from '../graphql/customers/customers'
import { IMarketingConsent } from '../graphql/marketingConsents'
import { IProposedInvestmentInput } from './proposedInvestmentsService'
import { SortingRule } from 'react-table'

export interface IDSPCustomerSourceInfoReportProps {
  fromDate: string
  toDate: string
  sourceInfo?: string[]
  stagesIDs: number[]
}

export interface ICustomerInput {
  customerType: string
  lastName: string
  firstName: string
  maidenName: string
  parentsNames: string
  pesel: string
  taxNumber: string
  sex: string
  phone1: string
  phone2: string
  email: string
  billNumber: string
  passportNumberAndSeries: string
  IDNumberAndSeries: string
  citizenship: string
  regon: string
  purchasePurpose: string
  businessType: string
  placeShares?: string
  businessAddressName: string
  businessAddressSurname: string
  businessAddressBusinessName: string
  businessAddressCountry: string
  businessAddressCity: string
  businessAddressStreet: string
  businessAddressNumber: string
  businessAddressPostalCode: string
  businessAddressVoivodeship: string
  businessAddressIsForeign: boolean
  residenceName: string
  residenceSurname: string
  residenceBusinessName: string
  residenceCountry: string
  residenceCity: string
  residenceStreet: string
  residenceNumber: string
  residencePostalCode: string
  residenceVoivodeship: string
  residenceIsForeign: boolean
  correspondenceName: string
  correspondenceSurname: string
  correspondenceBusinessName: string
  correspondenceCountry: string
  correspondenceCity: string
  correspondenceStreet: string
  correspondenceNumber: string
  correspondencePostalCode: string
  correspondenceVoivodeship: string
  correspondenceIsForeign: boolean
  maritalStatus: string
  sourceInfo: string
  isBroker: boolean
  brokerName: string
  isIKCustomer: boolean
  isToughCustomer: boolean
  isVIP: boolean
  isBlueList: boolean
  traderInitials: string
  isBusiness: boolean
  businessName: string
  isCommissionDivision: boolean
  commissionDivision: string
  isAfterMigration: boolean
  MarketingConsents?: IMarketingConsent[]
}

export const getToughCustomerUpdateVariables = (
  customerID: number,
  customer: ICustomer | null | undefined,
  form: any,
  isTough: boolean
): { customerID: number; customer: any } => {
  return {
    customerID: customerID,
    customer: {
      isToughCustomer: isTough,
      customerType: customer?.customerType ? customer.customerType : '',
      lastName: customer?.lastName ? customer?.lastName : '',
      firstName: customer?.firstName ? customer?.firstName : '',
      IDNumberAndSeries: customer?.IDNumberAndSeries
        ? customer.IDNumberAndSeries
        : '',
      citizenship: customer?.citizenship ? customer.citizenship : '',
      purchasePurpose: customer?.purchasePurpose
        ? customer.purchasePurpose
        : '',
      maidenName: customer?.maidenName ? customer.maidenName : '',
      parentsNames: customer?.parentsNames ? customer.parentsNames : '',
      sex: customer?.sex ? customer.sex : '',
      pesel: customer?.pesel ? customer.pesel : '',
      maritalStatus: customer?.maritalStatus ? customer.maritalStatus : '',
      marketingConsents: customer?.marketingConsents ?? undefined,

      // CustomerInput fields
      taxNumber: '',
      residenceName: '',
      isCommissionDivision: false,
      residenceSurname: '',
      residenceCountry: '',
      residencePostalCode: '',
      residenceVoivodeship: '',
      correspondenceSurname: '',
      correspondenceCountry: '',
      correspondenceStreet: '',
      correspondenceNumber: '',
      correspondencePostalCode: '',
      correspondenceVoivodeship: '',
      correspondenceIsForeign: false,
      sourceInfo: '',
      isBroker: false,
      isIKCustomer: false,
      isVIP: false,
      isBlueList: false,
      traderInitials: '',
      isBusiness: false,
      businessName: '',
      residenceCity: '',
      residenceStreet: '',
      residenceNumber: '',
      residenceIsForeign: false,
      correspondenceName: '',
      correspondenceCity: '',
    } as Partial<ICustomerInput>,
  }
}

export const anonymizeInterestedCustomer = (
  customerID: number
): Promise<any> => {
  const ANONYMIZE_INTERESTED_CUSTOMER_QUERY = gql`
    mutation anonymizeCustomer($customerID: Float!) {
      anonymizeCustomer(customerID: $customerID)
    }
  `
  return GraphqlService.send(ANONYMIZE_INTERESTED_CUSTOMER_QUERY, {
    customerID,
  })
}

const fetchInterestedCustomersByContractID = (
  contractID: number[]
): Promise<any> => {
  const FETCH_INTERESTED_CUSTOMERS = gql`
    query ($contractID: [Float!]!) {
      customersByContractID(contractID: $contractID) {
        id
        lastName
        firstName
        secondName
        lastContactDate
        pesel
        phone1
        phone2
        email
        email2
        state
        passportNumberAndSeries
        IDNumberAndSeries
        citizenship
        regon
        isDNKCustomer
        purchasePurpose
        isIKCustomer
        isToughCustomer
        isVIP
        isBlueList
        sourceInfo
        isBroker
        brokerName
        isCommissionDivision
        commissionDivision
        isAfterMigration
        traderInitials
        marketingConsents {
          id
          isInfoDutyFulfilled
          infoDutyForm
          infoDutyFormDate
          isAgreementPhone
          agreementPhoneDate
          isAgreementPhoneCancelled
          agreementPhoneCancellationDate
          isAgreementEmail
          agreementEmailDate
          isAgreementEmailCancelled
          agreementEmailCancellationDate
          isMSWiAAgreement
        }
        contacts {
          id
          date
          name
          type
          comment
          resignationReason
          traderInitials
          nextContactName
          nextContactComment
          nextContactDate
          nextContactType
          isResignation
          isSigned
          customerID
        }
        proposedInvestments {
          id
          investmentID
          placeID
          investment {
            name
            stages {
              name
            }
            id
          }
          place {
            id
            placeCode
            investment {
              id
              name
            }
          }
          stage {
            id
            name
          }
        }
        proposedPlaces {
          id
          placeID
          customerID
          place {
            id
            placeCode
            investment {
              id
              name
            }
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_INTERESTED_CUSTOMERS, { contractID })
}

export const addNewInterestedCustomer = ({
  customer,
}: {
  customer: IInterestedCustomer
}): Promise<any> => {
  const ADD_INTERESTED_CUSTOMER_QUERY = gql`
    mutation saveCustomerWithMarketingConsentsProposedPlacesAndContacts(
      $customer: CustomerInput!
    ) {
      saveCustomerWithMarketingConsentsProposedPlacesAndContacts(
        customer: $customer
      ) {
        id
      }
    }
  `
  return GraphqlService.send(ADD_INTERESTED_CUSTOMER_QUERY, { customer })
}

export const updateInterestedCustomer = ({
  customer,
  customerID,
}: {
  customer: Partial<ICustomerInput>
  customerID: number
}): Promise<any> => {
  const UPDATE_INTERESTED_CUSTOMER_QUERY = gql`
    mutation updateCustomer($customer: CustomerInput!, $customerID: Float!) {
      updateCustomer(customer: $customer, customerID: $customerID) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_INTERESTED_CUSTOMER_QUERY, {
    customer,
    customerID,
  })
}

export const deleteInterestedCustomer = (customerID: number): any => {
  const DELETE_INTERESTED_CUSTOMER_QUERY = gql`
    mutation deleteCustomer($customerID: Float!) {
      deleteCustomer(customerID: $customerID)
    }
  `
  return GraphqlService.send(DELETE_INTERESTED_CUSTOMER_QUERY, { customerID })
}

const fetchDSPCustomerSourceInfoReport = ({
  fromDate,
  toDate,
  sourceInfo,
  stagesIDs,
}: IDSPCustomerSourceInfoReportProps): Promise<any> => {
  const FETCH_DSP_CUSTOMER_SOURCE_INFO_REPORT = gql`
    query dspcsir(
      $fromDate: String!
      $toDate: String!
      $sourceInfo: [String!]
      $stagesIDs: [Float!]
    ) {
      DSPCustomerSourceInfoReport(
        fromDate: $fromDate
        toDate: $toDate
        sourceInfo: $sourceInfo
        stagesIDs: $stagesIDs
      )
    }
  `
  return GraphqlService.send(FETCH_DSP_CUSTOMER_SOURCE_INFO_REPORT, {
    fromDate,
    toDate,
    sourceInfo,
    stagesIDs,
  })
}

export interface IContactInput {
  date: string | null
  name: string
  type: string
  comment: string
  traderInitials: string
  resignationReason?: string
  nextContactName?: string
  nextContactComment?: string
  nextContactDate: string | null
  nextContactType?: string
  isResignation: boolean
  isSigned: boolean
  customerID: number
}

export interface IUpdateContactInput {
  contactID: number
  contact: IContactInput
}

const updateInterestedCustomerContact = (
  parameters: IUpdateContactInput
): Promise<any> => {
  const UPDATE_CONTACT = gql`
    mutation updateContact($contact: ContactInput!, $contactID: Float!) {
      updateContact(contact: $contact, contactID: $contactID) {
        id
      }
    }
  `

  return GraphqlService.send(UPDATE_CONTACT, parameters)
}

const deleteInterestedCustomerContact = (contactID: number): Promise<any> => {
  const DELETE_CONTACT = gql`
    mutation deleteContact($contactID: Float!) {
      deleteContact(contactID: $contactID)
    }
  `

  return GraphqlService.send(DELETE_CONTACT, { contactID })
}

const addInterestedCustomerContact = (contact: IContactInput): Promise<any> => {
  const ADD_CONTACT = gql`
    mutation saveContact($contact: ContactInput!) {
      saveContact(contact: $contact) {
        id
      }
    }
  `

  return GraphqlService.send(ADD_CONTACT, { contact })
}

export interface IAddCustomerWithMarketingConsentsProposedInvestmentsAndContacts {
  contacts: IContactInput[]
  proposedInvestments: IProposedInvestmentInput[]
  marketingConsents: any[] // TODO Fix incompatible types;
  customer: ICustomerInput
}

const addCustomerWithMarketingConsentsProposedInvestmentsAndContacts = (
  props: IAddCustomerWithMarketingConsentsProposedInvestmentsAndContacts
): Promise<any> => {
  const ADD_INTERESTED_CUSTOMER_MARKETING_PROPOSED_INVESTMENTS_CONTACT_QUERY = gql`
    mutation saveCustomerWithMarketingConsentsProposedInvestmentsAndContacts(
      $contacts: [ContactInput2!]
      $proposedInvestments: [ProposedInvestmentInput2!]
      $marketingConsents: [MarketingConsentInput2!]!
      $customer: CustomerInput!
    ) {
      saveCustomerWithMarketingConsentsProposedInvestmentsAndContacts(
        contacts: $contacts
        proposedInvestments: $proposedInvestments
        marketingConsents: $marketingConsents
        customer: $customer
      ) {
        id
      }
    }
  `

  return GraphqlService.send(
    ADD_INTERESTED_CUSTOMER_MARKETING_PROPOSED_INVESTMENTS_CONTACT_QUERY,
    props
  )
}

const getInterestedCustomerByID = (customerID: number): Promise<any> => {
  const GET_CUSTOMER = gql`
    query ($customerID: Float!) {
      customer(customerID: $customerID) {
        id
        customerType
        lastName
        firstName
        isDNKCustomer
        phone1
        phone2
        email
        email2
        isIKCustomer
        isToughCustomer
        isVIP
        isBlueList
        sourceInfo
        isBroker
        brokerName
        isCommissionDivision
        commissionDivision
        isAfterMigration
        traderInitials
        marketingConsents {
          id
          isInfoDutyFulfilled
          infoDutyForm
          infoDutyFormDate
          isAgreementPhone
          agreementPhoneDate
          isAgreementPhoneCancelled
          agreementPhoneCancellationDate
          isAgreementEmail
          agreementEmailDate
          isAgreementEmailCancelled
          agreementEmailCancellationDate
          isMSWiAAgreement
          isAfterMigration
        }
        latestMarketingConsent {
          id
          isInfoDutyFulfilled
          infoDutyForm
          infoDutyFormDate
          isAgreementPhone
          agreementPhoneDate
          isAgreementPhoneCancelled
          agreementPhoneCancellationDate
          isAgreementEmail
          agreementEmailDate
          isAgreementEmailCancelled
          agreementEmailCancellationDate
          isMSWiAAgreement
          isAfterMigration
        }
        contacts {
          id
          date
          name
          type
          comment
          resignationReason
          traderInitials
          nextContactName
          nextContactComment
          nextContactDate
          nextContactType
          isResignation
          isSigned
          isAfterMigration
          customerID
        }
        proposedInvestments {
          id
          investmentID
          placeID
          investment {
            name
            stages {
              name
            }
            id
          }
          place {
            id
            placeCode
            investment {
              id
              name
            }
          }
          stage {
            id
            name
          }
        }

        contracts {
          id
          contractNo
          contractDealDate
          customerCode
        }
      }
    }
  `
  return GraphqlService.send(GET_CUSTOMER, { customerID })
}

export interface GenericPaginationProps {
  perPage: number
  page: number
  isDNKCustomer?: boolean
  isTypeService?: boolean
  sortBy?: SortingRule<object>
  showArchived: boolean
  sortOrder?: boolean
  sortField?: string
  phone?: string
  email?: string
  firstName?: string
  lastName?: string
}

export interface CustomerPagination {
  total: number
  customers: IInterestedCustomer[]
}

export interface CustomerPaginationResponse {
  paginateCustomers: CustomerPagination
}

export interface CustomerPaginationByKeyWordsResponse {
  paginateCustomersByKeyWords: CustomerPagination
}

export interface CustomerPaginationByUnassignedCustomerEmail {
  paginateCustomersByUnassignedCustomerEmail: CustomerPagination
}
export interface InterestedCustomerPaginationProps {
  isBlueList?: boolean
  keyWords?: string
  keyword?: string
  traderInitials?: string
  customerStatus?: string
  filterForUnassignedCustomerID?: number | undefined
  perPage?: number
  page?: number
  isDNKCustomer?: boolean
  isTypeService?: boolean
  sortBy?: SortingRule<object>
  showArchived?: boolean
  sortOrder?: boolean
  sortField?: string
  phone?: string
  email?: string
  firstName?: string
  lastName?: string
}

export const fetchInterestedCustomersPagination = (
  props: InterestedCustomerPaginationProps
): Promise<CustomerPaginationResponse> => {
  const FETCH_ALL_INTERESTED_CUSTOMERS_QUERY = gql`
    query (
      $perPage: Float
      $page: Float
      $keyWords: String!
      $isDNKCustomer: Boolean
      $isBlueList: Boolean
      $sortField: String
      $sortOrder: Boolean
      $traderInitials: String
      $customerStatus: String
    ) {
      paginateCustomersByKeyWords(
        perPage: $perPage
        page: $page
        keyWords: $keyWords
        isDNKCustomer: $isDNKCustomer
        isBlueList: $isBlueList
        sortField: $sortField
        sortOrder: $sortOrder
        traderInitials: $traderInitials
        customerStatus: $customerStatus
      ) {
        total
        customers {
          id
          customerStatus
          customerType
          lastName
          firstName
          phone1
          phone2
          email
          email2
          isDNKCustomer
          maritalStatus
          isIKCustomer
          isToughCustomer
          isVIP
          isBlueList
          sourceInfo
          isBroker
          brokerName
          isCommissionDivision
          commissionDivision
          isAfterMigration
          traderInitials
          marketingConsents {
            id
            isInfoDutyFulfilled
            infoDutyForm
            infoDutyFormDate
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            isMSWiAAgreement
            isAfterMigration
          }
          contacts {
            id
            date
            name
            type
            comment
            resignationReason
            traderInitials
            nextContactName
            nextContactComment
            nextContactDate
            nextContactType
            isResignation
            isSigned
            customerID
          }
          proposedInvestments {
            id
            investmentID
            placeID
            investment {
              name
              id
            }
            place {
              id
              placeCode
              investment {
                id
                name
              }
            }
            stage {
              name
              id
            }
          }

          contracts {
            id
            contractNo
            customerCode
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_ALL_INTERESTED_CUSTOMERS_QUERY, {
    perPage: props.perPage,
    page: props.page,
    keyWords: props.keyWords || props.keyword || '',
    isDNKCustomer: props.isDNKCustomer,
    isBlueList: props.isBlueList,
    sortField: props.sortBy?.id ?? undefined,
    sortOrder: props.sortBy?.desc ?? false,
    customerStatus: props?.customerStatus,
  })
}

export const fetchInterestedCustomersByKeywordPagination = (
  props: InterestedCustomerPaginationProps
): Promise<CustomerPaginationResponse> => {
  const FETCH_ALL_INTERESTED_CUSTOMERS_QUERY = gql`
    query (
      $perPage: Float
      $page: Float
      $keyWords: String!
      $isDNKCustomer: Boolean
      $sortField: String
      $sortOrder: Boolean
      $isBlueList: Boolean
      $traderInitials: String
      $customerStatus: String
      $filterForUnassignedCustomerID: Float
    ) {
      paginateCustomersByKeyWords(
        perPage: $perPage
        page: $page
        keyWords: $keyWords
        isDNKCustomer: $isDNKCustomer
        sortField: $sortField
        sortOrder: $sortOrder
        isBlueList: $isBlueList
        traderInitials: $traderInitials
        customerStatus: $customerStatus
        filterForUnassignedCustomerID: $filterForUnassignedCustomerID
      ) {
        total
        customers {
          id
          customerStatus
          customerType
          lastName
          firstName
          phone1
          phone2
          email
          email2
          lastContactDate
          isDNKCustomer
          maritalStatus
          isIKCustomer
          isToughCustomer
          isVIP
          state
          isBlueList
          sourceInfo
          isBroker
          brokerName
          isCommissionDivision
          commissionDivision
          isAfterMigration
          traderInitials
          unassignedCustomers {
            id
          }
          marketingConsents {
            id
            isInfoDutyFulfilled
            infoDutyForm
            infoDutyFormDate
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            isMSWiAAgreement
            isAfterMigration
          }
          contacts {
            id
            date
            name
            type
            comment
            resignationReason
            traderInitials
            nextContactName
            nextContactComment
            nextContactDate
            nextContactType
            isResignation
            isSigned
            customerID
          }
          proposedInvestments {
            id
            investmentID
            placeID
            investment {
              name
              stages {
                name
              }
              id
            }
            place {
              id
              placeCode
              investment {
                id
                name
              }
            }
            stage {
              id
              name
            }
          }
          contracts {
            id
            contractNo
            customerCode
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_ALL_INTERESTED_CUSTOMERS_QUERY, {
    perPage: props.perPage,
    page: props.page,
    keyWords: props.keyWords || props.keyword || '',
    isDNKCustomer: props.isDNKCustomer,
    isBlueList: props.isBlueList,
    sortField: props.sortBy?.id ?? undefined,
    sortOrder: props.sortBy?.desc ?? false,
    traderInitials: props.traderInitials ?? undefined,
    customerStatus: props.customerStatus ?? undefined,
    filterForUnassignedCustomerID:
      props.filterForUnassignedCustomerID ?? undefined,
  })
}

export const fetchInterestedCustomersByUnassignedCustomerEmail = (
  props: InterestedCustomerPaginationProps
): Promise<CustomerPaginationResponse> => {
  const FETCH_ALL_INTERESTED_CUSTOMERS_QUERY = gql`
    query (
      $perPage: Float
      $page: Float
      $keyWords: String!
      $sortField: String
      $sortOrder: Boolean
      $traderInitials: String
      $customerStatus: String
      $unassignedCustomerID: Float!
    ) {
      paginateCustomersByUnassignedCustomerEmail(
        perPage: $perPage
        page: $page
        keyWords: $keyWords
        sortField: $sortField
        sortOrder: $sortOrder
        traderInitials: $traderInitials
        customerStatus: $customerStatus
        unassignedCustomerID: $unassignedCustomerID
      ) {
        total
        customers {
          id
          customerStatus
          customerType
          lastName
          firstName
          phone1
          phone2
          email
          email2
          lastContactDate
          isDNKCustomer
          maritalStatus
          isIKCustomer
          isToughCustomer
          isVIP
          state
          isBlueList
          sourceInfo
          isBroker
          brokerName
          isCommissionDivision
          commissionDivision
          isAfterMigration
          traderInitials
          unassignedCustomers {
            id
          }
          marketingConsents {
            id
            isInfoDutyFulfilled
            infoDutyForm
            infoDutyFormDate
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            isMSWiAAgreement
            isAfterMigration
          }
          contacts {
            id
            date
            name
            type
            comment
            resignationReason
            traderInitials
            nextContactName
            nextContactComment
            nextContactDate
            nextContactType
            isResignation
            isSigned
            customerID
          }
          proposedInvestments {
            id
            investmentID
            placeID
            investment {
              name
              stages {
                name
              }
              id
            }
            place {
              id
              placeCode
              investment {
                id
                name
              }
            }
            stage {
              id
              name
            }
          }
          contracts {
            id
            contractNo
            customerCode
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_ALL_INTERESTED_CUSTOMERS_QUERY, {
    perPage: props.perPage,
    page: props.page,
    keyWords: props.keyWords ?? '',
    sortField: props.sortBy?.id ?? undefined,
    sortOrder: props.sortBy?.desc ?? false,
    traderInitials: props.traderInitials ?? undefined,
    customerStatus: props.customerStatus ?? undefined,
    unassignedCustomerID: props.filterForUnassignedCustomerID ?? undefined,
  })
}

export const fetchTotalCustomerForUnassignedCustomer = (
  props: InterestedCustomerPaginationProps
): Promise<CustomerPaginationResponse> => {
  const FETCH_ALL_INTERESTED_CUSTOMERS_QUERY = gql`
    query ($keyWords: String!, $unassignedCustomerID: Float!) {
      paginateCustomersByUnassignedCustomerEmail(
        keyWords: $keyWords
        unassignedCustomerID: $unassignedCustomerID
      ) {
        total
      }
    }
  `
  return GraphqlService.send(FETCH_ALL_INTERESTED_CUSTOMERS_QUERY, {
    keyWords: props.keyWords ?? '',
    unassignedCustomerID: props.filterForUnassignedCustomerID ?? undefined,
  })
}

export interface INewCustomerVerificationQueryParameters {
  keyWord: string
  isDNKCustomer: boolean
}

export const newCustomerVerification = (
  params: INewCustomerVerificationQueryParameters
): Promise<any> => {
  const CUSTOMERS = gql`
    query ($perPage: Float, $page: Float, $keyWords: String!) {
      paginateCustomersByKeyWords(
        perPage: $perPage
        page: $page
        keyWords: $keyWords
      ) {
        total
        customers {
          id
          firstName
          lastName
          phone1
          phone2
          email
          isBlueList
          isIKCustomer
          isBroker
          brokerName
        }
      }
    }
  `

  return GraphqlService.send(CUSTOMERS, {
    perPage: 10,
    page: 1,
    keyWords: params.keyWord,
    isDNKCustomer: params.isDNKCustomer,
  })
}

export const getTraderInitialsOfCustomer = (): Promise<any> => {
  const GET_TRADER_INITIALS_OF_INTERESTED_CUSTOMERS = gql`
    query {
      traderInitialsOfCustomer
    }
  `

  return GraphqlService.send(GET_TRADER_INITIALS_OF_INTERESTED_CUSTOMERS, {})
}

const mutations = {
  getInterestedCustomerByID,
  addNewInterestedCustomer,
  anonymizeInterestedCustomer,
  deleteInterestedCustomer,
  updateInterestedCustomer,
  fetchDSPCustomerSourceInfoReport,
  updateInterestedCustomerContact,
  deleteInterestedCustomerContact,
  addInterestedCustomerContact,
  fetchInterestedCustomersByContractID,
  fetchInterestedCustomersPagination,
  fetchInterestedCustomersByKeywordPagination,
  addCustomerWithMarketingConsentsProposedInvestmentsAndContacts,
  newCustomerVerification,
  getTraderInitialsOfCustomer,
  fetchTotalCustomerForUnassignedCustomer,
  fetchInterestedCustomersByUnassignedCustomerEmail,
}

export default mutations
