import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'

export interface IArrangementFile {
  id: number
  name: string
  content?: string
  arrangementID: number
  creationDate: string
  trader: string
}

export interface IArrangementFileInput {
  name: string
  content?: string
  arrangementID: number
  creationDate: string
  trader: string
}

export interface IArrangement {
  id?: number
  placeID?: number
  arrangementFiles?: IArrangementFile[]
  conductingPerson: string
  wallsAnnexSignatureDate: string | null
  wallsIsDeliveredToConstruction: boolean
  wallsDeliveredToConstructionDate: string | null
  sanitaryInstallationsAnnexSignatureDate: string | null
  sanitaryInstallationsIsDeliveredToConstruction: boolean
  sanitaryInstallationsDeliveredToConstructionDate: string | null
  electricInstallationsAnnexSignatureDate: string | null
  electricInstallationsIsDeliveredToConstruction: boolean
  electricInstallationsDeliveredToConstructionDate: string | null
  finishingWorksAnnexSignatureDate: string | null
  finishingWorksIsDeliveredToConstruction: boolean
  finishingWorksDeliveredToConstructionDate: string | null
}

export interface IAddArrangementStateProps {
  form: {
    addArrangement: {
      values: IArrangement
    }
  }
}

export interface IAddArrangementFileStateProps {
  form: {
    addArrangementFile: {
      values: IArrangementFile
    }
  }
}

export interface IUpdateArrangementStateProps {
  form: {
    updateArrangement: {
      values: IArrangement
    }
  }
}

export const saveArrangement = ({
  arrangement,
}: {
  arrangement: IArrangement
}): Promise<any> => {
  const SAVE_ARRANGEMENT_QUERY = gql`
    mutation saveArrangementFile($arrangement: ArrangementInput!) {
      saveArrangement(arrangement: $arrangement) {
        id
      }
    }
  `

  return GraphqlService.send(SAVE_ARRANGEMENT_QUERY, { arrangement })
}

export const saveArrangementFile = ({
  arrangementFile,
}: {
  arrangementFile: IArrangementFileInput
}): Promise<any> => {
  const SAVE_ARRANGEMENT_FILE_QUERY = gql`
    mutation saveArrangementFile($arrangementFile: ArrangementFileInput!) {
      saveArrangementFile(arrangementFile: $arrangementFile) {
        id
        name
        content
        arrangementID
        creationDate
        trader
      }
    }
  `

  return GraphqlService.send(SAVE_ARRANGEMENT_FILE_QUERY, { arrangementFile })
}

export const deleteArrangementFileFromArrangement = (
  arrangementFileID: number
): Promise<any> => {
  const DELETE_ARRANGEMENT_FILE_QUERY = gql`
    mutation deleteArrangementFile($arrangementFileID: Float!) {
      deleteArrangementFile(arrangementFileID: $arrangementFileID)
    }
  `

  return GraphqlService.send(DELETE_ARRANGEMENT_FILE_QUERY, {
    arrangementFileID,
  })
}

export const updateArrangement = ({
  arrangement,
  arrangementID,
}: {
  arrangement: IArrangement
  arrangementID: number
}): Promise<any> => {
  const UPDATE_ARRANGEMENT_QUERY = gql`
    mutation updateArrangement(
      $arrangement: ArrangementInput!
      $arrangementID: Float!
    ) {
      updateArrangement(
        arrangement: $arrangement
        arrangementID: $arrangementID
      ) {
        id
      }
    }
  `

  return GraphqlService.send(UPDATE_ARRANGEMENT_QUERY, {
    arrangement,
    arrangementID,
  })
}

export const getArrangementFilesByArrangementID = (
  arrangementID: number
): Promise<any> => {
  const FETCH_FILES_BY_ARRANGEMENT_ID = gql`
    query($arrangementID: Float!) {
      arrangement(arrangementID: $arrangementID) {
        arrangementFiles {
          id
          name
          content
          arrangementID
          creationDate
          trader
        }
      }
    }
  `

  return GraphqlService.send(FETCH_FILES_BY_ARRANGEMENT_ID, { arrangementID })
}

const mutations = {
  deleteArrangementFileFromArrangement,
  saveArrangement,
  saveArrangementFile,
  updateArrangement,
  getArrangementFilesByArrangementID,
}

export default mutations
