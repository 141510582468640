import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch, RootState } from '../../ducks'
import { downloadXlsxReport, fetchRodoReport } from '../../ducks/reports'
import { RaportWithDatesState } from './RaportWithDates/actions'
import { Dates } from './RaportWithDates/Dates'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const RodoReport: FC = () => {
  useAuthRedirect([ACCESS_PAGES.DSP_AND_DSPADM_REPORTS])
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(
    (state: RootState) => state.combinedReports.RodoReport.isLoading
  )

  const generateReport = React.useCallback(
    async (state: RaportWithDatesState) => {
      const response = await dispatch(
        fetchRodoReport({
          fromDate: state.fromDate,
          toDate: state.toDate,
        })
      )
      const content = response.payload.DSPRodoReport
      dispatch(
        downloadXlsxReport({
          content,
          filename: 'Rodo raport.xlsx',
        })
      ).then(() => {
        history.push('/')
      })
    },
    [dispatch, history]
  )
  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <Dates />
    </RaportWithDates>
  )
}

export default RodoReport
