import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IContract } from '../graphql/contracts/contracts'
import CancelledContractSerive, {
  ICancelContractProps,
  IDeleteContractProps,
} from '../services/cancelledContractService'

export interface ICancelledContractsQuery {
  cancelledContracts: IContract[]
}

interface CancelledContractsInitialState {
  cancelledContracts: ICancelledContractsQuery[]
}

const initialState: CancelledContractsInitialState = {
  cancelledContracts: [],
}

export const fetchCancelledContracts = createAsyncThunk(
  'cancelledContracts/fetchCancelledContracts',
  async () => {
    return CancelledContractSerive.fetchCancelledContracts()
  }
)

export const cancelContract = createAsyncThunk(
  'cancelledContracts/cancelContract',
  async (variables: ICancelContractProps) => {
    return CancelledContractSerive.cancelContract(variables)
  }
)

export const deleteContract = createAsyncThunk(
  'cancelledContracts/deleteContract',
  async (variables: IDeleteContractProps) => {
    return CancelledContractSerive.deleteContract(variables)
  }
)

const cancelledContractsSlice = createSlice({
  name: 'cancelledContracts',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCancelledContracts.fulfilled.toString()]: (state, action): void => {
      state.cancelledContracts = action.payload.cancelledContracts
    },
  },
})

export default cancelledContractsSlice.reducer
