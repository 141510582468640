import * as React from 'react'
import { Col, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ColumnInstance } from 'react-table'
import ToggleContainer from '../Containers/ToggleContainer'
import { useTableContext } from './TableContext'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../ducks'
import { setCheckedValueOnSingleElement } from '../../../ducks/persistSettings'
import { useMainTableHiddenColumns } from '../../../hooks/useMainTableHiddenColumns'
import { useUserAbility } from '../../../hooks/ability'

const defaultExcludedFields = [
  'selection',
  'orderNo',
  'investmentCode',
  'propertyNo',
]

const Filters: React.FC = () => {
  const ability = useUserAbility()
  const { allColumns } = useTableContext()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { mainTableHiddenColumns } = useSelector(
    (state: RootState) => state.persistSettings
  )
  const hiddenColumns = useMainTableHiddenColumns()

  const excludedFields = React.useMemo(
    () => [...defaultExcludedFields, ...hiddenColumns],
    [hiddenColumns]
  )

  const handleChange = React.useCallback(
    (e, reactTableOnChange) => {
      reactTableOnChange(e)
      const oldValue = mainTableHiddenColumns[e.target.name]
      const newHiddenColumnsState = {
        ...mainTableHiddenColumns,
        [e.target.name]: !oldValue,
      }
      dispatch(setCheckedValueOnSingleElement(newHiddenColumnsState))
    },
    [dispatch, mainTableHiddenColumns]
  )

  const filterColumns = React.useMemo(() => {
    const roleFilteredColumns = allColumns.filter((column: ColumnInstance) => {
      const col = column.id
      if (
        col === 'firstName1' ||
        col === 'firstName2' ||
        col === 'lastName1' ||
        col === 'lastName2'
      ) {
        return (
          ability.can('read', 'CustomerFirstAndLastName') &&
          (mainTableHiddenColumns?.firstName1 ||
            mainTableHiddenColumns?.firstName2 ||
            mainTableHiddenColumns?.lastName1 ||
            mainTableHiddenColumns?.lastName2)
        )
      } else {
        return true
      }
    })
    const removedExcludedColumns = roleFilteredColumns.filter(
      (column: ColumnInstance) => !excludedFields.includes(column.id)
    )
    return removedExcludedColumns
  }, [allColumns, excludedFields, mainTableHiddenColumns, ability])

  return (
    <Col
      className='d-flex align-items-top justify-content-start my-2'
      lg={8}
      md={12}
      sm={12}
    >
      <ToggleContainer
        arrowColor='#000000'
        barColor='main-bg-color'
        customWidth={97.7}
        title={t('commons:labels.column-menu')}
      >
        <InputGroup>
          {filterColumns.map((column: ColumnInstance, index: number) => (
            <Col lg={3} key={`col${column.id}.${index}`}>
              <label key={`label${column.id}${index}`}>
                <input
                  type='checkbox'
                  {...column.getToggleHiddenProps()}
                  name={column.id ?? ''}
                  checked={column.isVisible}
                  onChange={(e): void =>
                    handleChange(e, column.getToggleHiddenProps().onChange)
                  }
                  key={`input${column.id}${index}`}
                />{' '}
                {column.Header}
              </label>
            </Col>
          ))}
        </InputGroup>
      </ToggleContainer>
    </Col>
  )
}
export default Filters
