import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useCheckIfSmallResolution } from '../../../hooks/useCheckIfSmallResolution'
import { useUserInitialsListForRoles } from '../../../hooks/useUserInitialsListForRoles'

import MainColForReportCheckbox from '../../commons/Containers/MainColForReportCheckbox'
import MainRow from '../../commons/Containers/MainRow'
import SimpleInputCheckbox3 from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'

import { updateTraders } from './actions'
import { useRaportsWithDatesContext } from './raportWithDatesContext'

export const Traders = (): JSX.Element => {
  const { t } = useTranslation()
  const { state, dispatch } = useRaportsWithDatesContext()

  const usernamesList = useUserInitialsListForRoles()
  const selectAllTraders = React.useCallback(() => {
    if (state.chosenTraderInitials.length) {
      return dispatch(updateTraders([]))
    }
    dispatch(updateTraders(usernamesList))
  }, [dispatch, state.chosenTraderInitials.length, usernamesList])

  const updateTraderList = React.useCallback(
    (traderInitial: string): void => {
      const isTraderSelected = state.chosenTraderInitials.includes(
        traderInitial
      )
      if (isTraderSelected) {
        return dispatch(
          updateTraders(
            state.chosenTraderInitials.filter(
              (trader) => trader !== traderInitial
            )
          )
        )
      }
      dispatch(updateTraders([...state.chosenTraderInitials, traderInitial]))
    },
    [dispatch, state.chosenTraderInitials]
  )

  const isOneColInRow = useCheckIfSmallResolution()

  return (
    <>
      <MainRow isRowContentCentered>
        <p className='icr-header my-4'>
          {t('commons:labels.choose-trader-initials')}
        </p>
      </MainRow>
      <MainRow isRowContentCentered>
        <Button onClick={selectAllTraders}>
          {t('commons:actions.select-deselect-all-traders')}
        </Button>
      </MainRow>
      <MainRow isRowContentCentered>
        {usernamesList?.map((user: string, index: number) => {
          const numberOfCols = usernamesList.length
          return (
            <MainColForReportCheckbox
              numberOfCols={numberOfCols}
              isOneColInRow={isOneColInRow}
              key={user + '.' + index}
            >
              <SimpleInputCheckbox3
                additionalOnChange={(): void => updateTraderList(user)}
                customLabelWidth={7}
                name={user}
                label={user}
                key={user}
                value={!!state.chosenTraderInitials.includes(user)}
              />
            </MainColForReportCheckbox>
          )
        })}
      </MainRow>
    </>
  )
}
