import { gql } from 'graphql-request'
import { IDMAReports } from '../ducks/reports'
import GraphqlService from './graphqlService'

export interface IDKOSerialCorrespondenceReport {
  DKOSerialCorrespondenceReport: string
}

export interface IDPPPlacesTallyReport {
  stagesIDs?: number[]
  investmentsIDs?: number[]
}

export interface IDNIForNotarialOfficeReport {
  DNIForNotarialOfficeReport: string
}

export interface IDownloadFileProps {
  filename: string
  content: string
}

export interface IDSPCustomerResignationReport {
  investmentIDs: number[]
  toDate: string
  fromDate: string
  tradersInitials: string[]
  resignationReason: string[]
  stagesIDs?: number[]
}

export interface IDSPCustomersByBrokersContractsReport {
  investmentIDs: number[]
  toDate: string
  fromDate: string
  brokerNames: string[]
  stagesIDs: number[]
}

export interface IDSPPlacesListReport {
  investmentsIDs: number[]
  stagesIDs: number[]
  isTypeService?: boolean
}

export interface IDSPSummaryReport {
  investmentsIDs: number[]
  stagesIDs: number[]
  startDate: string
  endDate: string
}

export interface IDSPDailyReport {
  endDate: string
  startDate: string
  stagesIDs?: number[]
  investmentIDs?: number[]
}

export interface IDSPCustomerConcludedContractsReport {
  investmentIDs: number[]
  fromDate: string
  toDate: string
  tradersInitials: string[] | undefined
  stagesIDs: number[]
}
export interface IRodoReport {
  fromDate: string
  toDate: string
}
export interface IDKSBusinessPlacesReport {
  valorization: boolean
  emailInvoice: boolean
  customerType: string
  stagesIDs?: number[]
}

export interface DSPCustomerSearchReportProps {
  isDNKCustomer: boolean
  keyword: string
}

export interface DSPContractCustomerSearchReportProps {
  keyword: string
}

export interface LeadAndInterestedReportProps {
  year: number
}

const getStagesWithInvestmentsForReport = (
  investmentID: number
): Promise<any> => {
  const FETCH_INVESTMENT = gql`
    query ($investmentID: Float!) {
      investment(investmentID: $investmentID) {
        stages {
          id
          name
          buildings {
            id
            name
            isTypeService
            places {
              id
              placeCode
              isTypeService
              activeContracts {
                id
                contractNo
              }
            }
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_INVESTMENT, { investmentID })
}

const fetchDARArrangementReport = (placesIDs: number[]): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($placesIDs: [Float!]!) {
      DARArrangementReport(placesIDs: $placesIDs)
    }
  `

  return GraphqlService.send(FETCH_REPORT, { placesIDs })
}

const fetchDKOExecutionStatusReport = (placeIDs: number[]): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($placeIDs: [Float!]!) {
      DKOExecutionStatusReport(placeIDs: $placeIDs)
    }
  `

  return GraphqlService.send(FETCH_REPORT, { placeIDs })
}

const fetchDKOSerialCorrespondenceReport = (
  placeIDs: number[]
): Promise<any> => {
  const FETCH_DKO_SERIAL_CORRESPONDENCE_REPORT = gql`
    query dkoscr($placeIDs: [Float!]!) {
      DKOSerialCorrespondenceReport(placeIDs: $placeIDs)
    }
  `

  return GraphqlService.send(FETCH_DKO_SERIAL_CORRESPONDENCE_REPORT, {
    placeIDs,
  })
}

const fetchDNIForNotarialOfficeReport = (placeIDs: number[]): Promise<any> => {
  const FETCH_DNI_FOR_NOTARIAL_OFFICE_REPORT = gql`
    query dnifnor($placeIDs: [Float!]!) {
      DNIForNotarialOfficeReport(placeIDs: $placeIDs)
    }
  `

  return GraphqlService.send(FETCH_DNI_FOR_NOTARIAL_OFFICE_REPORT, { placeIDs })
}

const fetchDNIAdministrationReport = (placeIDs: number[]): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($placeIDs: [Float!]!) {
      DNIForAdministrationReport(placeIDs: $placeIDs)
    }
  `

  return GraphqlService.send(FETCH_REPORT, { placeIDs })
}

const fetchDSPCancelledContractsReport = (): Promise<any> => {
  const FETCH_REPORT = gql`
    query {
      DSPCancelledContractsReport
    }
  `
  return GraphqlService.send(FETCH_REPORT, {})
}

const fetchDSPCustomerSourceContractReport = ({
  investmentIDs,
  toDate,
  fromDate,
  tradersInitials,
  sourceInfo,
  stagesIDs,
}: {
  investmentIDs: number[]
  toDate: string
  fromDate: string
  tradersInitials?: string[]
  sourceInfo: string[]
  stagesIDs?: number[]
}): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $investmentIDs: [Float!]!
      $toDate: String!
      $fromDate: String!
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $stagesIDs: [Float!]
    ) {
      DSPCustomerSourceContractReport(
        investmentIDs: $investmentIDs
        toDate: $toDate
        fromDate: $fromDate
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        stagesIDs: $stagesIDs
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, {
    investmentIDs,
    toDate,
    fromDate,
    tradersInitials,
    sourceInfo,
    stagesIDs,
  })
}

const fetchDSPCustomerByTradersReport = ({
  traderInitials,
  toDate,
  fromDate,
  stagesIDs,
  investmentIDs,
}: {
  traderInitials: string[]
  toDate: string
  fromDate: string
  stagesIDs: number[]
  investmentIDs: number[]
}): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $toDate: String!
      $fromDate: String!
      $traderInitials: [String!]!
      $stagesIDs: [Float!]
      $investmentIDs: [Float!]
    ) {
      DSPCustomerByTradersReport(
        toDate: $toDate
        fromDate: $fromDate
        tradersInitials: $traderInitials
        stagesIDs: $stagesIDs
        investmentIDs: $investmentIDs
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, {
    traderInitials,
    toDate,
    fromDate,
    investmentIDs,
    stagesIDs,
  })
}

const fetchDNIPickupScheduleReport = (placeIDs: number[]): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($placeIDs: [Float!]!) {
      DNIPickupScheduleReport(placeIDs: $placeIDs)
    }
  `
  return GraphqlService.send(FETCH_REPORT, { placeIDs })
}

const fetchDNIShareTableReport = (placeIDs: number[]): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($placeIDs: [Float!]!) {
      DNIShareTableReport(placeIDs: $placeIDs)
    }
  `
  return GraphqlService.send(FETCH_REPORT, { placeIDs })
}

const fetchDKOAreasReport = (placeIDs: number[]): Promise<any> => {
  const FETCH_DKO_AREAS_REPORT = gql`
    query ($placeIDs: [Float!]!) {
      DKOAreasReport(placeIDs: $placeIDs)
    }
  `
  return GraphqlService.send(FETCH_DKO_AREAS_REPORT, { placeIDs })
}

const fetchDNIActsReport = (placeIDs: number[]): Promise<any> => {
  const FETCH_DNI_ACTS_REPORT = gql`
    query ($placeIDs: [Float!]!) {
      DNIActScheduleReport(placeIDs: $placeIDs)
    }
  `
  return GraphqlService.send(FETCH_DNI_ACTS_REPORT, { placeIDs })
}

const fetchDSPCustomersByBrokersContractsReport = (
  parameters: IDSPCustomersByBrokersContractsReport
): Promise<any> => {
  const FETCH_DSP_CUSTOMERS_BY_BROKERS_CONTRACTS_REPORT = gql`
    query (
      $investmentIDs: [Float!]
      $toDate: String!
      $fromDate: String!
      $brokerNames: [String!]!
      $stagesIDs: [Float!]
    ) {
      DSPCustomersByBrokersContractsReport(
        investmentIDs: $investmentIDs
        toDate: $toDate
        fromDate: $fromDate
        brokerNames: $brokerNames
        stagesIDs: $stagesIDs
      )
    }
  `

  return GraphqlService.send(
    FETCH_DSP_CUSTOMERS_BY_BROKERS_CONTRACTS_REPORT,
    parameters
  )
}

const fetchDSPCustomersByBrokersReport = ({
  toDate,
  fromDate,
  brokerNames,
  investmentIDs,
  stagesIDs,
}: {
  toDate: string
  fromDate: string
  brokerNames: string[]
  investmentIDs: number[]
  stagesIDs: number[]
}): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $toDate: String!
      $fromDate: String!
      $brokerNames: [String!]!
      $investmentIDs: [Float!]!
      $stagesIDs: [Float!]
    ) {
      DSPCustomersByBrokersReport(
        toDate: $toDate
        fromDate: $fromDate
        brokerNames: $brokerNames
        investmentIDs: $investmentIDs
        stagesIDs: $stagesIDs
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, {
    toDate,
    fromDate,
    brokerNames,
    investmentIDs,
    stagesIDs,
  })
}

const fetchDSPBlueListReport = (): Promise<any> => {
  const FETCH_DSP_BLUE_LIST_REPORT = gql`
    query {
      DSPBlueListReport
    }
  `

  return GraphqlService.send(FETCH_DSP_BLUE_LIST_REPORT, {})
}

const fetchDSPPlacesListReport = (
  parameters: IDSPPlacesListReport
): Promise<any> => {
  const FETCH_DSP_PLACES_LIST_REPORT = gql`
    query (
      $stagesIDs: [Float!]
      $investmentsIDs: [Float!]!
      $isTypeService: Boolean
    ) {
      DSPPlacesListReport(
        stagesIDs: $stagesIDs
        investmentsIDs: $investmentsIDs
        isTypeService: $isTypeService
      )
    }
  `

  return GraphqlService.send(FETCH_DSP_PLACES_LIST_REPORT, parameters)
}

const fetchDSPDailyInterestedPartiesReport = (): Promise<any> => {
  const FETCH_DSP_DAILY_INTERESTED_PARTIES_REPORT = gql`
    query {
      DSPDailyInterestedPartiesReport
    }
  `

  return GraphqlService.send(FETCH_DSP_DAILY_INTERESTED_PARTIES_REPORT, {})
}

const fetchDailyMiniReport = (): Promise<any> => {
  const FETCH_DAILY_MINI_REPORT = gql`
    query {
      DSPDailyProposedInvestmentsCountReport
    }
  `
  return GraphqlService.send(FETCH_DAILY_MINI_REPORT, {})
}

const fetchDSPSummaryReport = (parameters: IDSPSummaryReport): Promise<any> => {
  const FETCH_DSP_SUMMARY_REPORT = gql`
    query (
      $stagesIDs: [Float!]
      $investmentsIDs: [Float!]!
      $endDate: String!
      $startDate: String!
    ) {
      DSPSummaryReport(
        stagesIDs: $stagesIDs
        investmentsIDs: $investmentsIDs
        endDate: $endDate
        startDate: $startDate
      )
    }
  `

  return GraphqlService.send(FETCH_DSP_SUMMARY_REPORT, parameters)
}

const fetchDSPInterestedCustomersDailyReport = (
  parameters: IDSPDailyReport
): Promise<any> => {
  const FETCH_DSP_SUMMARY_REPORT = gql`
    query (
      $endDate: String!
      $startDate: String!
      $stagesIDs: [Float!]
      $investmentIDs: [Float!]
    ) {
      DSPInterestedPartiesReport(
        endDate: $endDate
        startDate: $startDate
        stagesIDs: $stagesIDs
        investmentIDs: $investmentIDs
      )
    }
  `

  return GraphqlService.send(FETCH_DSP_SUMMARY_REPORT, parameters)
}

const fetchDSPCustomerResignationReport = (
  parameters: IDSPCustomerResignationReport
): Promise<any> => {
  const FETCH_DSP_CUSTOMER_RESIGNATION_REPORT = gql`
    query (
      $tradersInitials: [String!]
      $resignationReason: [String!]
      $investmentIDs: [Float!]!
      $toDate: String!
      $fromDate: String!
      $stagesIDs: [Float!]
    ) {
      DSPCustomerResignationReport(
        tradersInitials: $tradersInitials
        resignationReason: $resignationReason
        investmentIDs: $investmentIDs
        toDate: $toDate
        fromDate: $fromDate
        stagesIDs: $stagesIDs
      )
    }
  `

  return GraphqlService.send(FETCH_DSP_CUSTOMER_RESIGNATION_REPORT, parameters)
}

const fetchDSPCustomerConcludedContractsReport = (
  parameters: IDSPCustomerConcludedContractsReport
): Promise<any> => {
  const FETCH_DSP_CUSTOMER_CONCLUDED_CONTRACTS_REPORT = gql`
    query (
      $tradersInitials: [String!]
      $toDate: String!
      $fromDate: String!
      $stagesIDs: [Float!]
      $investmentIDs: [Float!]!
    ) {
      DSPCustomerConcludedContractsReport(
        tradersInitials: $tradersInitials
        toDate: $toDate
        fromDate: $fromDate
        stagesIDs: $stagesIDs
        investmentIDs: $investmentIDs
      )
    }
  `

  return GraphqlService.send(
    FETCH_DSP_CUSTOMER_CONCLUDED_CONTRACTS_REPORT,
    parameters
  )
}

const fetchRodoReport = (parameters: IRodoReport): Promise<any> => {
  const FETCH_RODO_REPORT = gql`
    query ($toDate: String!, $fromDate: String!) {
      DSPRodoReport(toDate: $toDate, fromDate: $fromDate)
    }
  `
  return GraphqlService.send(FETCH_RODO_REPORT, parameters)
}

const fetchDMAMarketingCustomerReport = (
  variables: IDMAReports
): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
      $consentType: String!
    ) {
      DMAMarketingCustomerReport(
        consentType: $consentType
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, variables)
}

const fetchDMAStatisticCustomerReport = (
  variables: IDMAReports
): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
      $consentType: String
    ) {
      DMAStatisticCustomerReport(
        consentType: $consentType
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, variables)
}

const fetchDMAMarketingContractCustomerReport = (
  variables: IDMAReports
): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
      $consentType: String!
    ) {
      DMAMarketingContractCustomerReport(
        consentType: $consentType
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, variables)
}

const fetchDMAStatisticContractCustomerReport = (
  variables: IDMAReports
): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
    ) {
      DMAStatisticContractCustomerReport(
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, variables)
}

const fetchDMAMarketingContractCustomerCompaniesReport = (
  variables: IDMAReports
): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
      $consentType: String!
    ) {
      DMAMarketingContractCustomerCompaniesReport(
        consentType: $consentType
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, variables)
}

const fetchDPPPlacesTallyReport = (
  variables: IDPPPlacesTallyReport
): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($stagesIDs: [Float!], $investmentsIDs: [Float!]) {
      DPPPlacesTallyReport(
        stagesIDs: $stagesIDs
        investmentsIDs: $investmentsIDs
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, variables)
}

const fetchDMAStatisticContractCustomerCompaniesReport = (
  variables: IDMAReports
): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
    ) {
      DMAStatisticContractCustomerCompaniesReport(
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, variables)
}

const fetchDKSBusinessPlacesReport = (
  variables: IDKSBusinessPlacesReport
): Promise<any> => {
  const FETCH_REPORT = gql`
    query (
      $valorization: Boolean!
      $emailInvoice: Boolean!
      $customerType: String!
      $stagesIDs: [Float!]
    ) {
      DKSBusinessPlacesReport(
        valorization: $valorization
        emailInvoice: $emailInvoice
        customerType: $customerType
        stagesIDs: $stagesIDs
      )
    }
  `
  return GraphqlService.send(FETCH_REPORT, variables)
}

const fetchDARDateAnnexesReport = (contractsIDs: number[]): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($contractsIDs: [Float!]!) {
      DARDateAnnexesReport(contractsIDs: $contractsIDs)
    }
  `
  return GraphqlService.send(FETCH_REPORT, { contractsIDs })
}

const fetchDARArrangementListReport = (stagesIDs: number[]): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($stagesIDs: [Float!]!) {
      DARArrangementListReport(stagesIDs: $stagesIDs)
    }
  `
  return GraphqlService.send(FETCH_REPORT, { stagesIDs })
}

const fetchDSPCustomerSearchReport = (
  props: DSPCustomerSearchReportProps
): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($isDNKCustomer: Boolean, $keyword: String!) {
      DSPCustomerSearchReport(isDNKCustomer: $isDNKCustomer, keyWord: $keyword)
    }
  `
  return GraphqlService.send(FETCH_REPORT, props)
}

const fetchDSPContractCustomerSearchReport = (
  props: DSPContractCustomerSearchReportProps
): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($keyword: String!) {
      DSPContractCustomerSearchReport(keyWord: $keyword)
    }
  `
  return GraphqlService.send(FETCH_REPORT, props)
}
const fetchLeadAndInterestedReport = (
  props: LeadAndInterestedReportProps
): Promise<any> => {
  const FETCH_REPORT = gql`
    query ($year: Float!) {
      MiniCRMUnassignedCustomersAndCustomersReport(year: $year)
    }
  `
  return GraphqlService.send(FETCH_REPORT, props)
}

const mutations = {
  getStagesWithInvestmentsForReport,
  fetchDARArrangementReport,
  fetchDPPPlacesTallyReport,
  fetchDKOAreasReport,
  fetchDKOExecutionStatusReport,
  fetchDKOSerialCorrespondenceReport,
  fetchDNIActsReport,
  fetchDNIForNotarialOfficeReport,
  fetchDNIPickupScheduleReport,
  fetchDNIShareTableReport,
  fetchDNIAdministrationReport,
  fetchDSPCancelledContractsReport,
  fetchDSPCustomerSourceContractReport,
  fetchDSPCustomerByTradersReport,
  fetchDSPCustomersByBrokersReport,
  fetchDSPCustomersByBrokersContractsReport,
  fetchDSPBlueListReport,
  fetchDSPPlacesListReport,
  fetchDSPSummaryReport,
  fetchDSPInterestedCustomersDailyReport,
  fetchDSPCustomerResignationReport,
  fetchDSPCustomerConcludedContractsReport,
  fetchDSPDailyInterestedPartiesReport,
  fetchDMAMarketingCustomerReport,
  fetchDMAStatisticCustomerReport,
  fetchDMAMarketingContractCustomerReport,
  fetchDMAStatisticContractCustomerReport,
  fetchDMAMarketingContractCustomerCompaniesReport,
  fetchDMAStatisticContractCustomerCompaniesReport,
  fetchDKSBusinessPlacesReport,
  fetchDARDateAnnexesReport,
  fetchDARArrangementListReport,
  fetchDSPCustomerSearchReport,
  fetchDSPContractCustomerSearchReport,
  fetchLeadAndInterestedReport,
  fetchDailyMiniReport,
  fetchRodoReport,
}

export default mutations
