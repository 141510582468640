import { Col, Row } from 'react-bootstrap'
import InputCheckbox from '../commons/Inputs/InputCheckbox'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import {
  toggleInterestedChecked,
  toggleRegularChecked,
} from '../../ducks/searchSlice'

export const SearchFilters: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { interestedChecked, regularChecked } = useSelector(
    (state: RootState) => state.search
  )

  const onRegularChecked = useCallback(() => {
    dispatch(toggleRegularChecked())
  }, [dispatch])

  const onInterestedChecked = useCallback(() => {
    dispatch(toggleInterestedChecked())
  }, [dispatch])

  return (
    <Row className='d-flex align-items-center justify-content-center search-narrow-results'>
      <Col lg='auto' md='auto' sm='auto'>
        <span>{t('table:labels.narrow-search-results-to')}:</span>
      </Col>
      <Col lg='auto' md='auto' sm='auto'>
        <InputCheckbox
          defaultChecked={regularChecked}
          id='regular-customers-searchbar-checkbox'
          label={t('table:labels.regular-customers')}
          onClick={onRegularChecked}
        />
      </Col>
      <Col lg='auto' md='auto' sm='auto'>
        <InputCheckbox
          customWidth={200}
          defaultChecked={interestedChecked}
          id='interested-customers-searchbar-checkbox'
          label={t('table:labels.interested-customers')}
          onClick={onInterestedChecked}
        />
      </Col>
    </Row>
  )
}
