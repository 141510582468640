import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '.'
import { IChanges } from '../services/correspondenceService'
import EventServices, {
  IEventFileInput,
  IEventInput,
  IEventQuery,
} from '../services/eventsService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface IEventsState {
  events: IEventQuery
  history: IChanges[]
  isUploading: boolean
}

const initialState: IEventsState = {
  events: { getContract: { events: [] } },
  history: [],
  isUploading: false,
}

export const getEventByContractID = createAsyncThunk(
  'events/getEventByContractID',
  async (contractID: number) => {
    return EventServices.getEventByContractID(contractID)
  }
)

export const saveEvent = createAsyncThunk(
  'events/saveEvent',
  async (event: IEventInput) => {
    return EventServices.saveEvent(event)
  }
)

export const saveEventFile = createAsyncThunk(
  'events/saveEventFile',
  async (eventFile: IEventFileInput) => {
    return EventServices.saveEventFile(eventFile)
  }
)

export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (eventID: number) => {
    return EventServices.deleteEvent(eventID)
  }
)

export const fetchEventsChanges = createAsyncThunk(
  'events/fetchEventsChanges',
  async (arg, thunkAPI) => {
    const contractID = (thunkAPI.getState() as RootState).globalInvestment
      .chosenPlacesContract?.activeContracts[0]?.id as number
    return EventServices.fetchEventsChanges(contractID)
  }
)

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: {
    [getEventByContractID.fulfilled.toString()]: (state, action): void => {
      state.events = action.payload
    },
    [saveEvent.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:saveEvent')))
    },
    [saveEvent.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:saveEventError')))
    },
    [saveEventFile.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:saveEvent')))
      state.isUploading = false
    },
    [saveEventFile.pending.toString()]: (state): void => {
      state.isUploading = true
    },
    [saveEventFile.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:saveEventError')))
    },
    [deleteEvent.fulfilled.toString()]: (state): void => {
      state.isUploading = false
      successToastNotify(String(i18n.t('toast:deleteEvent')))
    },
    [deleteEvent.rejected.toString()]: (state): void => {
      state.isUploading = false
      errorToastNotify(String(i18n.t('toast:deleteEventError')))
    },
    [fetchEventsChanges.fulfilled.toString()]: (state, action): void => {
      state.history = action.payload.getEventsChangesByContract
    },
  },
})

export default eventsSlice.reducer
