import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'

export interface IProposedInvestment {
  id: number
  placeID: number
  investmentID: number
  customerID: number
  place: {
    id: number
    placeCode: string
  }
  investment: {
    id: number
    name: string
  }
  stage: {
    id: number
    name: string
  }
}

export interface IProposedInvestmentInput {
  name?: string
  placeID?: number | null
  customerID?: number
  investmentID: number
  stageID?: number
}

export const saveProposedInvestment = (
  proposedInvestment: IProposedInvestmentInput
): any => {
  const SAVE_PROPOSED_PLACE = gql`
    mutation spi($proposedInvestment: ProposedInvestmentInput!) {
      saveProposedInvestment(proposedInvestment: $proposedInvestment) {
        id
      }
    }
  `

  return GraphqlService.send(SAVE_PROPOSED_PLACE, { proposedInvestment })
}

export const deleteProposedInvestment = (proposedPlaceID: number): any => {
  const DELETE_PROPOSED_INVESTMENT = gql`
    mutation dpi($proposedPlaceID: Float!) {
      deleteProposedInvestment(proposedPlaceID: $proposedPlaceID)
    }
  `

  return GraphqlService.send(DELETE_PROPOSED_INVESTMENT, { proposedPlaceID })
}

const mutations = {
  saveProposedInvestment,
  deleteProposedInvestment,
}

export default mutations
