import React, { FC, ReactText } from 'react'
import { ToastContainer, ToastPosition, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Toast: FC = () => <ToastContainer />

export default Toast

interface IAllToastProps {
  position: ToastPosition | undefined
  autoClose: number
  hideProgressBar: boolean
  closeOnClick: boolean
  pauseOnHover: boolean
  draggable: boolean
  progress?: number
}

const allToastProps: IAllToastProps = {
  position: 'top-right',
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const errorToastNotify = (title: string): ReactText =>
  toast.error(title, { ...allToastProps, autoClose: 30000 })
export const infoToastNotify = (title: string): ReactText =>
  toast.info(title, allToastProps)
export const successToastNotify = (title: string): ReactText =>
  toast.success(title, allToastProps)
