import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import SimpleInputList from '../commons/Inputs/SimpleInputs/SimpleInputList'
import HeaderBreadcrumb from './HeaderBreadcrumb'
import HeaderLogo from './HeaderLogo'
import HeaderSearch from './HeaderSearch'
import HeaderUser from './HeaderUser'

import { IInvestment, IStage } from '../../graphql/investments'

import './Header.scss'

import {
  setGlobalChosenInvestment,
  switchArchiveMode,
} from '../../ducks/globalInvestment'
import { RootState } from '../../ducks'
import { refetchPagination } from '../commons/Table2/paginationSlice'
import useStagesForDSPandDNK from '../../hooks/useStagesForDSPandDNK'
import { useUserAbility } from '../../hooks/ability'

interface IHeaderProps {
  onChangeChosenInvestment: (selectedInvestment: IInvestment) => void
  onChangeChosenStage: (selectedStage: IStage) => void
}

const Header: FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ability = useUserAbility()
  const { onChangeChosenInvestment, onChangeChosenStage } = props
  const {
    chosenInvestment,
    chosenStage,
    investmentsList,
    isArchiveMode,
    isLoading,
  } = useSelector((state: RootState) => state.globalInvestment)
  const onChangeInvestmentValue = (selectedInvestment: IInvestment): void => {
    const selectedInvestmentFromTheList =
      investmentsList.find(
        (investment) => investment?.id === selectedInvestment.id
      ) || selectedInvestment
    onChangeChosenInvestment(selectedInvestmentFromTheList)
  }

  const onChangeStageValue = (selectedStage: IStage): void => {
    onChangeChosenStage(selectedStage)
  }

  const stagesByRoles =
    useStagesForDSPandDNK()?.filter(
      (stage) => isArchiveMode || stage?.isArchive === false
    ) || []
  const initialStagesListOptions = useMemo(
    () => [{ id: -1, name: 'Wszystkie' }],
    []
  )
  const stagesListOptions = useMemo(() => {
    if (chosenInvestment?.stages?.length) {
      const sortedStagesInChosenInvestment = [...stagesByRoles]
        .sort((a, b) => ('' + a.name).localeCompare(b.name))
        .map((stage) =>
          stage.isArchive
            ? { ...stage, name: stage.name + ' (archiwum)' }
            : stage
        )
      return [...initialStagesListOptions, ...sortedStagesInChosenInvestment]
    } else {
      return initialStagesListOptions
    }
  }, [chosenInvestment, initialStagesListOptions, stagesByRoles])

  const location = useLocation()
  const { pathname } = location

  const isIvestmentAndStageInputListDisabled =
    pathname.indexOf('admin') === -1 &&
    pathname.length > 1 &&
    !pathname.includes('main-table-view')
  const isInvestmentDisabled =
    (isIvestmentAndStageInputListDisabled &&
      pathname.indexOf('cancelled-contracts') === -1) ||
    isLoading ||
    (ability.can('see', 'Path', 'main-table-view') &&
      pathname.indexOf('main-table-view') === -1)
  const isStageDisabled =
    isIvestmentAndStageInputListDisabled ||
    !chosenInvestment?.stages?.length ||
    isLoading ||
    (ability.can('see', 'Path', 'main-table-view') &&
      pathname.indexOf('main-table-view') === -1) ||
    chosenInvestment?.name === 'Wszystkie'

  const canAllInvestmentsOptionBeVisible =
    (location.pathname.indexOf('report') === -1 ||
      location.pathname.indexOf('places-list-report') !== -1) &&
    location.pathname.indexOf('reports') === -1 &&
    location.pathname.indexOf('admin') === -1
  const canViewCancelledContract = ability.can(
    'read',
    'HeaderCancelledContract'
  )

  const filteredInvestments = useMemo(
    () =>
      canAllInvestmentsOptionBeVisible
        ? canViewCancelledContract
          ? investmentsList
          : investmentsList.filter((inv) => inv.name !== 'Umowy anulowane')
        : investmentsList.filter(
            (inv) => inv.name !== 'Wszystkie' && inv.name !== 'Umowy anulowane'
          ),
    [
      canAllInvestmentsOptionBeVisible,
      canViewCancelledContract,
      investmentsList,
    ]
  )

  const notArchivedInvestments = useMemo((): IInvestment[] => {
    const invList = filteredInvestments.filter(
      (investment: IInvestment) => investment.isArchive === isArchiveMode
    )
    return invList
  }, [filteredInvestments, isArchiveMode])

  const allInvestments = useMemo((): IInvestment[] => {
    const invList = [...filteredInvestments].map((investment: IInvestment) => {
      if (investment.isArchive) {
        const { id, isArchive, name, stages } = investment
        return {
          id,
          isArchive,
          stages,
          name: `${name} (archiwum)`,
        }
      }
      return investment
    })
    return invList
  }, [filteredInvestments])

  const activeInvestment = useMemo(
    () =>
      investmentsList.find((i) => {
        return i?.id === chosenInvestment?.id
      }),
    [investmentsList, chosenInvestment]
  )

  const activeStage = useMemo(
    () =>
      stagesListOptions.find((s) => {
        return s?.id === chosenStage.id
      }),
    [stagesListOptions, chosenStage]
  )

  const handleArchiveSwitch = useCallback(() => {
    dispatch(switchArchiveMode())
    dispatch(refetchPagination(true))
  }, [dispatch])

  const firstInvestmentOnList = useMemo(() => {
    const invs = isArchiveMode ? allInvestments : notArchivedInvestments
    return invs.find((inv) => inv?.id > 0)
  }, [allInvestments, isArchiveMode, notArchivedInvestments])

  useEffect(() => {
    !canAllInvestmentsOptionBeVisible &&
      chosenInvestment?.name === 'Wszystkie' &&
      dispatch(setGlobalChosenInvestment(firstInvestmentOnList))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <nav
      id='nav'
      className='navbar'
      style={{ backgroundColor: '#707070', position: 'sticky' }}
    >
      <Col className='sticky-header'>
        <div
          className='d-flex justify-content-between row mt-2'
          style={{ backgroundColor: '#707070' }}
          id='header-first-row'
        >
          <Col xs={3} sm={3} md={3} lg={1}>
            <HeaderLogo />
          </Col>
          <Col xs={9} sm={9} md={9} lg={2}>
            <HeaderSearch />
          </Col>
          <Form
            id='header-archive-investment-stage-inputs'
            className='col-xs-12 col-sm-12 col-md-12 col-lg-6'
          >
            <div>
              {ability.can('read', 'HeaderArchive') && (
                <Form.Check
                  className='align-items-center text-center archives-checkbox'
                  type='checkbox'
                  label={t('commons:labels.Archives')}
                  defaultChecked={isArchiveMode}
                  onClick={handleArchiveSwitch}
                />
              )}
            </div>
            <div>
              <SimpleInputList
                defaultValue={activeInvestment}
                disabled={isInvestmentDisabled}
                onChangeValue={onChangeInvestmentValue}
                label={t('commons:labels.investment').toString()}
                customSelectWidth={6}
                options={
                  isArchiveMode ? allInvestments : notArchivedInvestments
                }
              />
            </div>
            <div>
              <SimpleInputList
                defaultValue={activeStage}
                disabled={isStageDisabled}
                onChangeValue={onChangeStageValue}
                label={t('commons:labels.stage').toString()}
                customSelectWidth={6}
                options={stagesListOptions}
              />
            </div>
          </Form>
          <div
            className='d-flex col-xs-12 col-sm-12 col-md-12 col-lg-3 mx-0 px-0 ik2-light-color-1'
            id='header-user-element'
          >
            <HeaderUser />
          </div>
        </div>
        <Row className='d-flex justify-content-start ik2-light-color-1'>
          <div className='mx-5'>
            <HeaderBreadcrumb />
          </div>
        </Row>
      </Col>
    </nav>
  )
}

export default Header
