import React, { FC } from 'react'
import { Button, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../ducks'
import {
  getMessageFileByMessageFileID,
  IAttachment,
  IMessage,
  markAsSpamMessage,
  markUnassignedCustomerFromMessage,
} from '../../../ducks/unassignedCustomers'
import ConfirmationModal from '../../commons/Modals/ConfirmationModal'
import Loader from '../../commons/Loader/Loader'
import ReduxModal from '../../commons/Modals/ReduxModal'
import Table from '../../commons/Table2/Table'
import {
  refetchPagination,
  setMessagesOfChosenUnassignedCustomerSearchQuery,
} from '../../commons/Table2/paginationSlice'
import { StorageKeys } from '../../../hooks/useBrowserStorage'
import { AppDispatch } from '../../../ducks'
import { getMessagesByUnassignedCustomerID } from '../../../ducks/unassignedCustomers'
import { ITableMessages } from './../interfaces'
import {
  downloadEml,
  getMessagesCellStyle,
  replToAllMessages,
} from './../functions'
import AddContact from '../../InterestedCustomers/InterestedCustomersContacts/AddContact'
import { reset } from 'redux-form'
import TableHeaderWrapper from './../TableHeaderWrapper'

const TableMessages: FC<ITableMessages> = ({
  pageSize,
  selectedRow,
  selectedUnassignedCustomer,
  showAllMessages,
  setShowAllMessages,
  isSpamModalVisible,
  setIsSpamModalVisible,
  isClientModalVisible,
  setIsClientModalVisible,
  isAddModalVisible,
  setIsAddModalVisible,
  isMessageContentModalVisible,
  setIsMessageContentModalVisible,
  setRefreshContact,
}) => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const { selectedInterestedCustomer } = useSelector(
    (state: RootState) => state.interestedCustomers
  )
  const { messagesOfChosenUnassignedCustomerSearchQuery } = useSelector(
    (state: RootState) => state.pagination
  )
  const {
    isLoadingCreateContact,
    isLoadingSelectedUnassignedCustomer,
    isLoadingUnassignedCustomers,
    isLoadingMessagesByUnassignedCustomerID,
    messagesByUnassignedCustomerID,
    unassignedCustomerIDForMessages,
  } = useSelector((state: RootState) => state.unassignedCustomers)
  const selectedMessageOfUnassignedCustomer:
    | IMessage
    | undefined = messagesByUnassignedCustomerID
    ? messagesByUnassignedCustomerID[Number(selectedRow)]
    : undefined
  const interestedCustomerID = selectedUnassignedCustomer?.customerID
  const selectedUnassignedTaken = selectedUnassignedCustomer?.status === 'taken'
  const showMessages =
    selectedUnassignedCustomer &&
    unassignedCustomerIDForMessages &&
    selectedUnassignedCustomer.id === unassignedCustomerIDForMessages &&
    !isLoadingSelectedUnassignedCustomer &&
    !isLoadingUnassignedCustomers &&
    !isLoadingMessagesByUnassignedCustomerID

  const handleClose = () => {
    setIsMessageContentModalVisible(false)
    setIsSpamModalVisible(false)
    setIsClientModalVisible(false)
  }

  const showAttachments = (): void => {
    selectedMessageOfUnassignedCustomer?.attachments?.map(
      (attachment: IAttachment) => {
        dispatch(
          getMessageFileByMessageFileID({ messageFileID: attachment.id })
        ).then((response: any) => {
          const downloadLink = document.createElement('a')
          document.body.appendChild(downloadLink)
          downloadLink.href = `data:application;base64,${response.payload.messageFile.getMessageFile.content}`
          downloadLink.target = '_self'
          downloadLink.download = attachment?.name ?? 'Załącznik'
          downloadLink.click()
        })
      }
    )
  }

  const handleMarkAsSpam = (): void => {
    sessionStorage.setItem(
      'emailOfUnassignedCustomer',
      selectedUnassignedCustomer?.email ?? undefined
    )
    selectedMessageOfUnassignedCustomer &&
      dispatch(
        markAsSpamMessage({ messageID: selectedMessageOfUnassignedCustomer.id })
      ).then(() => {
        setIsSpamModalVisible(false)
        dispatch(refetchPagination(true))
      })
  }
  const markAsClient = () => {
    selectedMessageOfUnassignedCustomer?.id &&
      dispatch(
        markUnassignedCustomerFromMessage({
          messageID: selectedMessageOfUnassignedCustomer.id,
        })
      ).then(() => {
        setIsClientModalVisible(false)
        dispatch(refetchPagination(true))
      })
  }
  const handleMarkAsSpamClick = (): void => {
    setIsSpamModalVisible(true)
  }

  const handleMarkAsClientClick = (): void => {
    setIsClientModalVisible(true)
  }

  const messageModalText =
    selectedMessageOfUnassignedCustomer?.htmlContent ||
    selectedMessageOfUnassignedCustomer?.subject
      ? `<div style='padding-bottom:14px'><b>${
          selectedMessageOfUnassignedCustomer?.subject ?? ''
        }</b></div>
      ${
        selectedMessageOfUnassignedCustomer?.htmlContent
          ? selectedMessageOfUnassignedCustomer.htmlContent
          : ''
      }`
      : `${t('customers:labels:no-message-content')}`

  const messagesFetch = (variables?: any): void => {
    selectedUnassignedCustomer?.id && dispatch(
      getMessagesByUnassignedCustomerID({
        unassignedCustomerID: selectedUnassignedCustomer?.id,
        page: variables?.page,
        perPage: variables?.perPage,
        sortOrder: variables?.sortBy?.desc,
        sortField: variables?.sortBy?.id,
        keywords: variables?.keywords,
        showArchive: showAllMessages,
      })
    )
  }

  const handleCreateContact = (): void => {
    setIsAddModalVisible(true)
  }

  const handleCloseAddContract = (): void => {
    setIsAddModalVisible(false)
    setRefreshContact(true)
    dispatch(refetchPagination(true))
    dispatch(reset('saveContact'))
    dispatch(reset('updateContact'))
  }
  const showMarkClientButton =
    !selectedMessageOfUnassignedCustomer?.isSpam &&
    !selectedMessageOfUnassignedCustomer?.isArchive &&
    !selectedMessageOfUnassignedCustomer?.fromCustomer

  return (
    <>
      <TableHeaderWrapper top={'200px'} zIndex={9}>
        <Table.Header align='end'>
          <Button
            disabled={!showMessages}
            className='primary'
            onClick={(): void => {
              setShowAllMessages(!showAllMessages)
              dispatch(refetchPagination(true))
            }}
          >
            {showAllMessages
              ? t('table:actions.hide-archive')
              : t('table:actions.show-all-messages')}
          </Button>
          {selectedMessageOfUnassignedCustomer &&
          selectedUnassignedCustomer?.email ? (
            <>
              <Col
                className='d-flex justify-content-end mx-1 px-0'
                lg='auto'
                md='auto'
                sm='auto'
              >
                {selectedMessageOfUnassignedCustomer?.attachments?.length ? (
                  <Button className='primary' onClick={showAttachments}>
                    {t('customers:actions.show-attachments')}
                  </Button>
                ) : null}
                {interestedCustomerID &&
                selectedUnassignedTaken &&
                selectedUnassignedCustomer?.email &&
                !Boolean(
                  selectedMessageOfUnassignedCustomer?.isLinkedWithContact
                ) ? (
                  <Button
                    className='primary'
                    disabled={isLoadingCreateContact}
                    onClick={handleCreateContact}
                  >
                    {isLoadingCreateContact
                      ? t('commons:actions.uploading-in-progress')
                      : t('customers:actions.create-contact')}
                  </Button>
                ) : null}
                <Button
                  className='primary'
                  onClick={(): void => {
                    setIsMessageContentModalVisible(true)
                  }}
                >
                  {t('customers:actions.show-message')}
                </Button>
                <Button className='primary' onClick={handleMarkAsSpamClick}>
                  {t('customers:actions.mark-as-spam')}
                </Button>
                {showMarkClientButton && (
                  <Button className='primary' onClick={handleMarkAsClientClick}>
                    {t('customers:actions.mark-as-client')}
                  </Button>
                )}
                {selectedMessageOfUnassignedCustomer?.eml && (
                  <Button
                    className='primary'
                    onClick={() =>
                      downloadEml(
                        selectedMessageOfUnassignedCustomer?.eml || ''
                      )
                    }
                  >
                    {t('customers:actions.open-in-mail-client')}
                  </Button>
                )}
              </Col>
            </>
          ) : (
            <div style={{ height: '3.4em' }}></div>
          )}
              <Button
                className='primary'
                disabled={!showMessages || !messagesByUnassignedCustomerID?.length}
                onClick={() =>
                  messagesByUnassignedCustomerID &&
                  replToAllMessages(
                    messagesByUnassignedCustomerID,
                    selectedUnassignedCustomer
                  )
                }
              >
                {t('customers:actions.reply-all')}
              </Button>
          <Table.TableSearchWithPagination
            isLoading={isLoadingUnassignedCustomers}
            query={messagesOfChosenUnassignedCustomerSearchQuery}
            setQuery={setMessagesOfChosenUnassignedCustomerSearchQuery}
            refreshPage={refetchPagination}
          />
        </Table.Header>
      </TableHeaderWrapper>
      {showMessages ? (
        <>
          {messagesByUnassignedCustomerID?.length ? (
            <div>
              <Table.TableBody
                isLoading={
                  isLoadingSelectedUnassignedCustomer ||
                  isLoadingUnassignedCustomers
                }
                getCellStyle={getMessagesCellStyle}
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                paddingTop: '2em',
              }}
            >
              {showAllMessages
                ? 'Brak wiadomości'
                : 'Brak aktywnych wiadomości'}
            </div>
          )}
        </>
      ) : (
        <Loader timeout={9999999} />
      )}
      <Table.PaginationWithFetch
        storageType='localStorage'
        tableKey={StorageKeys.MessagesOfUnassignedCustomersTable}
        genericFetch={messagesFetch}
        additionalParameters={{
          keywords: messagesOfChosenUnassignedCustomerSearchQuery,
        }}
        isGenericFetchDispatched
      />
      {isMessageContentModalVisible && selectedMessageOfUnassignedCustomer ? (
        <ReduxModal
          body={<p dangerouslySetInnerHTML={{ __html: messageModalText }}></p>}
          cancelAction={handleClose}
          onHide={handleClose}
          show={isMessageContentModalVisible}
          title={t('customers:labels:message-details')}
          customWidth={100}
        />
      ) : null}
      {isSpamModalVisible && selectedMessageOfUnassignedCustomer ? (
        <ConfirmationModal
          body={t('commons:labels:are-you-sure-mark-as-spam')}
          cancelAction={handleClose}
          onConfirmWithoutSubmit={handleMarkAsSpam}
          onHide={handleClose}
          show={isSpamModalVisible}
          submitText={t('commons:actions:confirm')}
          title={t('commons:labels:is-spam-confirmation')}
          submitWithoutReload
        />
      ) : null}
      {isClientModalVisible && selectedUnassignedCustomer ? (
        <ConfirmationModal
          body={t('commons:labels:are-you-sure-mark-as-client')}
          cancelAction={handleClose}
          submitAction={markAsClient}
          onHide={handleClose}
          show={isClientModalVisible}
          submitText={t('commons:actions:confirm')}
          title={t('commons:labels:is-client-confirmation')}
          submitWithoutReload
        />
      ) : null}
      {isAddModalVisible &&
        selectedInterestedCustomer?.id &&
        selectedMessageOfUnassignedCustomer && (
          <AddContact
            addContactFromMessageProps={{
              messageID: selectedMessageOfUnassignedCustomer.id,
              message: selectedMessageOfUnassignedCustomer?.content || '',
              unassignedCustomerEmail: selectedUnassignedCustomer.email,
            }}
            customerID={selectedInterestedCustomer.id}
            customerProposedInvestments={
              selectedInterestedCustomer?.proposedInvestments || []
            }
            hasCustomerContracts={Boolean(
              selectedInterestedCustomer?.contracts?.length
            )}
            handleClose={handleCloseAddContract}
            isBroker={selectedInterestedCustomer?.isBroker}
            show={isAddModalVisible}
            key='add-contact-messages'
            withProposedInvestment
          />
        )}
    </>
  )
}

export default TableMessages
