import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import adminIT from './translations/pl/adminIT.json'
import administration from './translations/pl/administration.json'
import annexhistory from './translations/pl/annexhistory.json'
import arrangements from './translations/pl/arrangements.json'
import customers from './translations/pl/customers.json'
import commons from './translations/pl/commons.json'
import contract from './translations/pl/contract.json'
import investment from './translations/pl/investment.json'
import table from './translations/pl/table.json'
import place from './translations/pl/place.json'
import toast from './translations/pl/toast.json'

i18n.use(initReactI18next).init({
  resources: {
    pl: {
      adminIT,
      administration,
      annexhistory,
      arrangements,
      customers,
      commons,
      contract,
      investment,
      table,
      place,
      toast,
    },
  },
  lng: 'pl',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
