import 'moment/locale/pl'
import React, { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'
import { DATE_FORMAT_YYYYMMDD } from '../../../../constants/dates'

import './SimpleInputs.scss'

interface ISimpleInputDateProps {
  additionalOnChange?: (e: any) => void
  contact2Date?: boolean
  customInputWidth?: number
  customLabelWidth?: number
  defaultValue?: string | null
  disabled?: boolean
  highlighted?: boolean
  label: string | number
  children?: JSX.Element
  required?: boolean
  name: string
}

export const SimpleInputDate3: FunctionComponent<ISimpleInputDateProps> = ({
  additionalOnChange,
  customInputWidth,
  customLabelWidth,
  defaultValue,
  disabled,
  highlighted,
  label,
  required,
  name,
}) => {
  const { t } = useTranslation()
  const value =
    String(defaultValue)?.length > 2 && defaultValue !== null
      ? defaultValue
      : t('commons:labels.no-date')

  const onDayChange = (day: any): void => {
    if (additionalOnChange) {
      return additionalOnChange({ day, name })
    }
  }

  const onClear = useCallback(() => {
    if (additionalOnChange) {
      additionalOnChange({ day: null, name })
    }
  }, [additionalOnChange, name])

  return (
    <div className='d-flex align-items-center'>
      <label
        className='simple-input-label'
        style={{ width: customLabelWidth ? `${customLabelWidth}px` : '150px' }}
      >
        <span
          className='input-group-text simple-input-label-span'
          style={{ width: customLabelWidth ? `${customLabelWidth}em` : '10em' }}
        >
          {label}
        </span>
      </label>
      {disabled ? (
        <span
          className='input-group-text simple-input-label-span'
          style={{ width: customInputWidth ? `${customInputWidth}em` : '7em' }}
        >
          {value}
        </span>
      ) : null}
      <div>
        {!disabled ? (
          <>
            <DayPickerInput
              onDayChange={onDayChange}
              inputProps={{
                required: required,
                readOnly: false,
                style: {
                  padding: '0.5rem',
                  height: 'calc(1.5em + 0.75rem + 2px)',
                  width: customInputWidth ? `${customInputWidth}em` : '7em',
                  backgroundColor: highlighted
                    ? '#f2a291'
                    : disabled
                    ? '#ebebeb'
                    : undefined,
                },
              }}
              clickUnselectsDay
              formatDate={MomentLocaleUtils.formatDate}
              format={DATE_FORMAT_YYYYMMDD}
              value={value}
              parseDate={MomentLocaleUtils.parseDate}
              dayPickerProps={{
                locale: 'pl',
                localeUtils: MomentLocaleUtils,
              }}
            />
            <span className='simple-input-date-clear' onClick={onClear}>
              x
            </span>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default SimpleInputDate3
