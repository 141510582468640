import * as React from 'react'
import { Row } from 'react-bootstrap'

const TableFooter: React.FC = ({ children }) => (
  <div>
    <Row className='d-flex justify-content-center my-2 w-100'>{children}</Row>
  </div>
)

export default TableFooter
