import React, { FunctionComponent, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReduxModal from '../../commons/Modals/ReduxModal'
import SimpleInputList from '../../commons/Inputs/SimpleInputs/SimpleInputList'
import ContactContent from '../../../mockups/ContactContent'
import TypeOfContact from '../../../mockups/TypeOfContact'
import moment from 'moment'
import { DATE_FORMAT_YYYYMMDD } from '../../../constants/dates'
import {
  addInterestedCustomerContact,
  fetchInterestedCustomersByContractID,
  setCurrentInterestedCustomer,
  setCurrentInterestedCustomerID,
} from '../../../ducks/interestedCustomers'
import { AppDispatch } from '../../../ducks'
import { IContactInput } from '../../../services/interestedCustomerService'
import { useForm } from '../../../hooks/useForm'
import SimpleInput3 from '../../commons/Inputs/SimpleInputs/SimpleInput3'
import SimpleInputDate3 from '../../commons/Inputs/SimpleInputs/SimpleInputDate3'
import SimpleInputCheckbox3 from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'
import { refetchPagination } from '../../commons/Table2/paginationSlice'
import { resignationReasonsIDName } from '../../../mockups/resignationReasonsIDName'
import { useUserInitialsListForRoles } from '../../../hooks/useUserInitialsListForRoles'
import { saveContactFromMailMessage } from '../../../ducks/unassignedCustomers'
import SimpleInputList2 from '../../../components/commons/Inputs/SimpleInputs/SimpleInputList2'
import { useProposedInvestment } from '../../../components/InterestedCustomers/useProposedInvestment'
import { useDataForProposedInputs } from '../../../components/InterestedCustomers/useDataForProposedInputs'

interface IAddContactProps {
  contractID?: number
  customerID: number
  handleClose: () => void
  hasCustomerContracts?: boolean
  isBroker?: boolean
  show: boolean
  addContactFromMessageProps?: {
    message: string
    messageID: number
    unassignedCustomerEmail: string
  }
  withProposedInvestment?: boolean
  customerProposedInvestments?: any
}

const AddContact: FunctionComponent<IAddContactProps> = ({
  contractID,
  customerID,
  handleClose,
  hasCustomerContracts,
  isBroker,
  show,
  addContactFromMessageProps,
  withProposedInvestment,
  customerProposedInvestments,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const { messageID, unassignedCustomerEmail } =
    addContactFromMessageProps || {}
  const addContactFromMessage = messageID && unassignedCustomerEmail
  const today = moment().format(DATE_FORMAT_YYYYMMDD)
  const userInitialsList = useUserInitialsListForRoles()
  const { state: proposedState, setters } = useProposedInvestment()
  const {
    stagesByInvestment,
    placesByChosenStage,
    investmentsWithFirstPositionEmpty,
  } = useDataForProposedInputs({ proposedState })
  const isAddProposedDisabled =
    proposedState.investment?.id === -1 || proposedState.stage?.id === -1

  const sevenDaysAfterTodayDate = moment()
    .add(7, 'days')
    .format(DATE_FORMAT_YYYYMMDD)

  const resignationReasons = resignationReasonsIDName
    .map((rr) => rr.name)
    .sort()

  const defaultContact = React.useMemo(() => {
    return {
      nextContactName: addContactFromMessage ? 'Przypomnienie' : '',
      nextContactComment: '',
      nextContactDate: addContactFromMessage ? sevenDaysAfterTodayDate : null,
      name: addContactFromMessage ? 'Prezentacja oferty' : '',
      comment: '',
      date: today,
      type: addContactFromMessage ? 'Korespondencja' : '',
      traderInitials: userInitialsList[0],
      isSigned: false,
      isResignation: false,
    }
  }, [today, addContactFromMessage])

  const { state, updateField, updateFromInput } = useForm(defaultContact)

  const onSubmit = (e: any) => {
    e.preventDefault()
    const proposedInvestments = proposedState.proposedInvestments.map(
      (item: any) => {
        return {
          investmentID: item.investmentID,
          stageID: item.stageID,
          placeID: item.placeID,
        }
      }
    )

    const parameters: IContactInput = {
      name: state?.name ?? '',
      comment: state?.comment ?? '',
      date: state?.date,
      type: state?.type ?? '',
      traderInitials: state?.traderInitials,
      nextContactName: state.isResignation ? '' : state?.nextContactName ?? '',
      nextContactComment: state.isResignation
        ? ''
        : state?.nextContactComment ?? '',
      nextContactDate: state?.nextContactDate ? state?.nextContactDate : null,
      isResignation: Boolean(state?.isResignation) ?? false,
      resignationReason: state?.resignationReason ?? '',
      isSigned: hasCustomerContracts ?? false,
      customerID: customerID as number,
    }
    if (messageID && unassignedCustomerEmail) {
      dispatch(
        saveContactFromMailMessage({
          messageID,
          unassignedCustomerEmail,
          contact: parameters,
          proposedInvestments,
        })
      ).then(() => handleClose())
    } else if (customerID) {
      dispatch(addInterestedCustomerContact(parameters)).then(() => {
        dispatch(setCurrentInterestedCustomer(null))
        dispatch(setCurrentInterestedCustomerID(undefined))
        contractID &&
          dispatch(fetchInterestedCustomersByContractID([contractID]))
        dispatch(refetchPagination(true))
        handleClose()
      })
    }
  }

  useEffect(() => {
    if (state.nextContactName?.length > 1) {
      updateField({ name: 'nextContactDate', value: sevenDaysAfterTodayDate })
    } else {
      updateField({ name: 'nextContactDate', value: null })
    }
  }, [sevenDaysAfterTodayDate, state.nextContactName, updateField])

  const renderAdditionalFields = (): JSX.Element | null => {
    if (state.isResignation) {
      return (
        <div
          className='row'
          style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
        >
          <SimpleInputList
            name='resignationReason'
            label={String(t('commons:labels.resignation-reason'))}
            options={resignationReasons}
            additionalOnChange={updateFromInput}
            defaultValue={state.resignationReason}
          />
        </div>
      )
    } else if (state.isSigned) {
      return null
    } else {
      return (
        <div
          className='row'
          style={{
            marginLeft: '0.5em',
            marginRight: '0.5em',
            marginTop: '1.5em',
          }}
        >
          <SimpleInputList
            name='nextContactName'
            label={String(t('customers:labels.next-contact-content'))}
            additionalOnChange={updateFromInput}
            defaultValue={state.nextContactName}
            options={ContactContent}
          />
          <SimpleInput3
            name='nextContactComment'
            type='text'
            label={String(t('commons:labels.comment'))}
            onChange={updateFromInput}
            value={state.nextContactComment}
          />
          <SimpleInputDate3
            additionalOnChange={updateFromInput}
            defaultValue={state.nextContactDate}
            label={String(t('customers:labels.next-contact-date'))}
            name='nextContactDate'
          />
        </div>
      )
    }
  }

  const addContactModalBody = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Form onSubmit={onSubmit}>
        <div
          className='row'
          style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
        >
          <SimpleInputList
            name='type'
            label={String(t('commons:labels.type'))}
            options={TypeOfContact}
            defaultValue={state.type}
            additionalOnChange={updateFromInput}
          />
          <SimpleInputList
            name='name'
            label={String(t('commons:labels.name-not-person'))}
            options={ContactContent}
            additionalOnChange={updateFromInput}
            defaultValue={state.name}
          />
          <SimpleInput3
            name='comment'
            onChange={updateFromInput}
            value={state.comment}
            type='text'
            label={String(t('commons:labels.comment'))}
          />
          <SimpleInputDate3
            name='date'
            label={String(t('commons:labels.date'))}
            defaultValue={state.date}
            additionalOnChange={updateFromInput}
          />
          <SimpleInputList
            name='traderInitials'
            label={String(t('commons:labels.trader-initials'))}
            defaultValue={state.traderInitials}
            options={userInitialsList}
            additionalOnChange={updateFromInput}
          />
        </div>
        {renderAdditionalFields()}
        <div
          className='row'
          style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
        >
          <SimpleInputCheckbox3
            name='isSigned'
            label={String(t('customers:labels.signed'))}
            onChange={updateFromInput}
            disabled
            value={state.isSigned}
          />
          <SimpleInputCheckbox3
            name='isResignation'
            label={String(t('customers:labels.resignation'))}
            onChange={updateFromInput}
            value={state.isResignation}
          />
        </div>
        <Col className='d-flex justify-content-end' lg={12} md={12} sm={12}>
          <Button type='submit'>{t('commons:actions.add')}</Button>
        </Col>
      </Form>
      {withProposedInvestment && (
        <Col lg={6} md={12} sm={12}>
          <h6 className='px-1 mt-4'>Propozycje inwestycji</h6>
          <Row className='row d-flex justify-content-center'>
            <div>
              <SimpleInputList2
                defaultValue={proposedState.investment}
                onChangeValue={setters.onChangeInvestmentValue}
                label={t('commons:labels.investment').toString()}
                options={investmentsWithFirstPositionEmpty}
              />
              <SimpleInputList2
                defaultValue={proposedState.stage}
                onChangeValue={setters.onChangeStageValue}
                label={String(t('commons:labels.stage'))}
                options={stagesByInvestment}
              />
              {placesByChosenStage.length ? (
                <SimpleInputList2
                  defaultValue={proposedState.place}
                  disabled={placesByChosenStage.length <= 1}
                  onChangeValue={setters.onChangePlacesValue}
                  label={String(t('place:labels.place-no'))}
                  options={placesByChosenStage}
                />
              ) : null}

              <div className='proposed-places-container'>
                {proposedState.proposedInvestments.length > 0 && (
                  <div className='proposed-places-title'>Dodane propozycje</div>
                )}
                {proposedState.proposedInvestments.map(
                  (item: { name: string }, index: number) => (
                    <li className='proposed-place' key={item.name}>
                      {item.name}
                      <span
                        className='proposed-places-remove-button'
                        onClick={(): void => setters.removeProposedItem(index)}
                      >
                        x
                      </span>
                    </li>
                  )
                )}
              </div>
              <Button
                className='proposed-places-button'
                onClick={setters.addProposedInvestment}
                disabled={isAddProposedDisabled}
              >
                Dodaj propozycje
              </Button>
            </div>
          </Row>
        </Col>
      )}
    </div>
  )

  return (
    <ReduxModal
      size={withProposedInvestment ? 'xl' : undefined}
      body={addContactModalBody}
      cancelAction={handleClose}
      onHide={handleClose}
      show={show}
      title={t('customers:labels.add-contact')}
    />
  )
}

export default AddContact
