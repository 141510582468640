import React, { FC } from 'react'
import { useTableContext } from '../commons/Table2/TableContext'

export const TableBodyWrapper: FC = ({ children }: any) => {
  const {
    state: { pageSize, selectedRowIds, sortBy },
  } = useTableContext()

  const selectedRow = Object.keys(selectedRowIds)?.[0]

  return <>{children && children(pageSize, selectedRow, sortBy[0])}</>
}
