import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_YYYYMMDD } from '../../../constants/dates'

import MainRow from '../../commons/Containers/MainRow'
import SimpleInputDate3 from '../../commons/Inputs/SimpleInputs/SimpleInputDate3'
import { updateFromDate, updateToDate } from './actions'
import { useRaportsWithDatesContext } from './raportWithDatesContext'

export const Dates = (): JSX.Element => {
  const { t } = useTranslation()
  const { state, dispatch } = useRaportsWithDatesContext()

  const updateFromDateValue = React.useCallback(
    (e: { day: string | null }) => {
      const date =
        e.day === null ? ' ' : moment(e.day).format(DATE_FORMAT_YYYYMMDD)
      dispatch(updateFromDate(date))
    },
    [dispatch]
  )

  const updateToDateValue = React.useCallback(
    (e: { day: string | null }) => {
      const date =
        e.day === null ? ' ' : moment(e.day).format(DATE_FORMAT_YYYYMMDD)
      dispatch(updateToDate(date))
    },
    [dispatch]
  )

  return (
    <>
      <MainRow isRowContentCentered>
        <p className='icr-header mt-4'>{t('commons:actions.select-dates')}</p>
      </MainRow>
      <MainRow isRowContentCentered>
        <SimpleInputDate3
          name='fromDate'
          additionalOnChange={updateFromDateValue}
          defaultValue={state.fromDate}
          label='Od daty'
        />
        <SimpleInputDate3
          name='toDate'
          additionalOnChange={updateToDateValue}
          defaultValue={state.toDate}
          label='Do daty'
        />
      </MainRow>
    </>
  )
}
