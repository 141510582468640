import React from 'react'
import { Col, Row } from 'react-bootstrap'
import InterestedEventsTable from './InterestedCustomersContacts/InterestedEventsTable'

import { IInterestedCustomer } from '../../graphql/customers'
import { NotesTableForInterestedCustomers } from '../commons/Table/NotesTableForInterestedCustomers'

interface IInterestedCustomerEventsAndNotesProps {
  customer: IInterestedCustomer
}

export const InterestedCustomerEventsAndNotes: React.FC<IInterestedCustomerEventsAndNotesProps> = ({
  customer,
}) => {
  return (
    <Col lg={12} md={12} sm={12}>
      <Row className='d-flex justify-content-between mt-3'>
        <Col lg={6} md={12} sm={12}>
          <InterestedEventsTable customer={customer} />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <NotesTableForInterestedCustomers customer={customer} />
        </Col>
      </Row>
    </Col>
  )
}

export default InterestedCustomerEventsAndNotes
