import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'

export interface IPlaceShares {
  placeAdministrativeCode: string
  placeIsTypeService: boolean
  customersNamesLastNames: string
  contractCustomerCode: string
  contractCustomerMaritalStatus: string
  contractContractNo: string
  contractDeveloperContractDate: string
  contractNotarialOffice: string
  parkingPlaceName: string
  otherLaws: string
  utilityRoomNumber: string
  placeAsBuildInnerPropertyArea: string
  placeAsBuildUsablePropertyArea: string
  utilityRoomAsBuildUsableUtilRoomArea: string
  sumOfAsBuildUsableArea: string
  grossPirce: string
  netPrice: string
  placeGrossPrice: string
  placeNetPrice: string
  vat: string
  fitnessGrossPrice: string
  fitnessNetPrice: string
  roadGrossPrice: string
  roadNetPrice: string
  placePlaceShares: string
  working: string
  placeFitnessShares: string
  fitnessSharesWorking: string
  placeRoadShares: string
  roadSharesWorking: string
  contractAcceptanceDate: string
  contractActDate: string
  contractDeluxeContract: string
  customerContractIsBusinessActivity: string
  contractOtherContract: string
  contractCustomerMarketingConsentsIsMSWiAAgreement: string
  contractCustomerCitizenship: string
  contractContractualActDate: string
  contractContractualAcceptanceDate: string
  contractDateOfLetterDispatch: string
  maxContractPrice: string
  actRepNumber: string
  actTime: string
}

export interface ImportDNIShares {
  content: string
  investmentID: number
}

export interface IGenerateDNIReport {
  edit: IPlaceShares[]
  investmentID: number
}

interface IGenerateDNIReportResponse {
  getReportFromImportedDNIData: string
}

interface IUpdateShares {
  toUpdateImportedDataList: IPlaceShares[]
  investmentID: number
}

const generateDNIReport = (
  variables: IGenerateDNIReport
): Promise<IGenerateDNIReportResponse> => {
  const GENERATE_RAPORT = gql`
    mutation gen($investmentID: Float!, $edit: [ImportedDataInput!]!) {
      getReportFromImportedDNIData(
        investmentID: $investmentID
        importedDataList: $edit
      )
    }
  `
  return GraphqlService.send(GENERATE_RAPORT, variables)
}

const saveDNIPlaceShares = (
  variables: IUpdateShares
): Promise<IPlaceShares> => {
  const SAVE_SHARES = gql`
    mutation gen(
      $investmentID: Float!
      $toUpdateImportedDataList: [ImportedDataInput!]!
    ) {
      updateImportedDNIData(
        investmentID: $investmentID
        toUpdateImportedDataList: $toUpdateImportedDataList
      ) {
        placeAdministrativeCode
        placeIsTypeService
        customersNamesLastNames
        contractCustomerCode
        contractCustomerMaritalStatus
        contractContractNo
        contractDeveloperContractDate
        contractNotarialOffice
        parkingPlaceName
        otherLaws
        utilityRoomNumber
        placeAsBuildInnerPropertyArea
        placeAsBuildUsablePropertyArea
        utilityRoomAsBuildUsableUtilRoomArea
        sumOfAsBuildUsableArea
        grossPirce
        netPrice
        placeGrossPrice
        placeNetPrice
        vat
        fitnessGrossPrice
        fitnessNetPrice
        roadGrossPrice
        roadNetPrice
        placePlaceShares
        working
        placeFitnessShares
        fitnessSharesWorking
        placeRoadShares
        roadSharesWorking
        contractAcceptanceDate
        contractActDate
        contractDeluxeContract
        customerContractIsBusinessActivity
        contractOtherContract
        contractOtherContract
        contractCustomerMarketingConsentsIsMSWiAAgreement
        contractCustomerCitizenship
        contractContractualActDate
        contractContractualAcceptanceDate
        contractDateOfLetterDispatch
        maxContractPrice
        actRepNumber
        actTime
      }
    }
  `
  return GraphqlService.send(SAVE_SHARES, variables)
}

const importPlaceShares = (
  variables: ImportDNIShares
): Promise<IPlaceShares> => {
  const IMPORT_SHARES = gql`
    mutation importDNI($content: String!, $investmentID: Float!) {
      importDNIData(content: $content, investmentID: $investmentID) {
        placeAdministrativeCode
        placeIsTypeService
        customersNamesLastNames
        contractCustomerCode
        contractCustomerMaritalStatus
        contractContractNo
        contractDeveloperContractDate
        contractNotarialOffice
        parkingPlaceName
        otherLaws
        utilityRoomNumber
        placeAsBuildInnerPropertyArea
        placeAsBuildUsablePropertyArea
        utilityRoomAsBuildUsableUtilRoomArea
        sumOfAsBuildUsableArea
        grossPirce
        netPrice
        placeGrossPrice
        placeNetPrice
        vat
        fitnessGrossPrice
        fitnessNetPrice
        roadGrossPrice
        roadNetPrice
        placePlaceShares
        working
        placeFitnessShares
        fitnessSharesWorking
        placeRoadShares
        roadSharesWorking
        contractAcceptanceDate
        contractActDate
        contractDeluxeContract
        customerContractIsBusinessActivity
        contractOtherContract
        contractOtherContract
        contractCustomerMarketingConsentsIsMSWiAAgreement
        contractCustomerCitizenship
        contractContractualActDate
        contractContractualAcceptanceDate
        contractDateOfLetterDispatch
        maxContractPrice
        actRepNumber
        actTime
      }
    }
  `
  return GraphqlService.send(IMPORT_SHARES, variables)
}

const mutations = {
  importPlaceShares,
  generateDNIReport,
  saveDNIPlaceShares,
}

export default mutations
