import React, { FC } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'

type ToolTipPlacement = 'top' | 'right' | 'bottom' | 'left'

interface Props {
  show?: boolean
  placement?: ToolTipPlacement
  message: string
}

export const ButtonWithOverlayTooltip: FC<Props> = ({
  show = false,
  placement = 'top',
  message,
  children,
}) => {
  const target = React.useRef(null)

  return (
    <>
      {show ? (
        <Overlay target={target.current} show={show} placement={placement}>
          {(props): JSX.Element => (
            <Tooltip id='overlay-example' {...props}>
              {message}
            </Tooltip>
          )}
        </Overlay>
      ) : null}
      <div ref={target}>{children}</div>
    </>
  )
}
