import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableContext } from './TableContext'
import { GenericPaginationProps } from '../../../services/interestedCustomerService'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../ducks'
import { refetchPagination, setMainTableCurrentPage } from './paginationSlice'
import _ from 'lodash'
import {
  StorageKeys,
  useBrowserStorage,
} from '../../../hooks/useBrowserStorage'
import useGetRole from '../../../hooks/useGetRole'

export type PaginationWithFetchProps = {
  defaultAmountOfRows?: number
  genericFetch: <T extends GenericPaginationProps>(props: T) => void
  additionalParameters?: any
  tableKey?: StorageKeys
  storageType?: 'localStorage'
  isGenericFetchDispatched?: boolean
  firstRenderexcludeDispatchFetch?: boolean
}

const PaginationWithFetch: React.FC<PaginationWithFetchProps> = ({
  defaultAmountOfRows = 10,
  genericFetch,
  additionalParameters = null,
  tableKey,
  storageType,
  isGenericFetchDispatched = false,
  firstRenderexcludeDispatchFetch,
}) => {
  const { t } = useTranslation()
  const { isDNKCustomer, isDSPCustomer } = useGetRole()
  const { isArchiveMode } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const [exludeDispatchFetch, setExludeDispatchFetch] = useState(
    firstRenderexcludeDispatchFetch
  )
  const dispatch = useDispatch()
  const {
    isMainTable,
    state: { pageSize, sortBy },
    setPageSize,
    totalRows,
  } = useTableContext()
  const { refetch, mainTableCurrentPage } = useSelector(
    (state: RootState) => state.pagination
  )
  const [currentPage, setCurrentPage] = useState<number>(() =>
    isMainTable ? mainTableCurrentPage : 1
  )
  const filterByRoles = isDNKCustomer ? true : isDSPCustomer ? false : undefined

  const [value, setValue] = useBrowserStorage(tableKey, storageType)
  const sizeOfPage = pageSize ?? totalRows
  const numberOfPages = useMemo(
    () => Math.ceil(totalRows / sizeOfPage),
    [sizeOfPage, totalRows]
  )

  const sortingParam = useMemo(() => {
    if (sortBy && sortBy.length > 0) {
      return sortBy[0]
    }
  }, [sortBy])

  const amountOfRows = useMemo((): number[] => {
    let standardOptions = [5, 10, 20, 50, 100, 150, 200, 300, 500]
    if (!standardOptions?.includes(defaultAmountOfRows)) {
      standardOptions = [defaultAmountOfRows, ...standardOptions]
    }
    return standardOptions
  }, [defaultAmountOfRows])

  const handleChangePageSizeClick = useCallback(
    (e): void => {
      setPageSize(Number(e.target.value))
      tableKey && setValue(Number(e.target.value))
    },
    [setPageSize, setValue, tableKey]
  )

  const handleGoToFirstPageClick = useCallback(() => {
    setCurrentPage(1)
    isMainTable && dispatch(setMainTableCurrentPage(1))
  }, [dispatch, isMainTable])

  const handleGoToPreviousPageClick = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
      isMainTable && dispatch(setMainTableCurrentPage(currentPage - 1))
    }
  }, [currentPage, dispatch, isMainTable])

  const handleLastPageClick = useCallback(() => {
    setCurrentPage(numberOfPages)
    isMainTable && dispatch(setMainTableCurrentPage(numberOfPages))
  }, [dispatch, isMainTable, numberOfPages])

  const handleNextPageClick = useCallback(() => {
    setCurrentPage(currentPage + 1)
    isMainTable && dispatch(setMainTableCurrentPage(currentPage + 1))
  }, [currentPage, dispatch, isMainTable])

  const cancelAllRefreshes = useCallback(() => {
    dispatch(refetchPagination(false))
  }, [dispatch])

  useEffect(() => {
    if (refetch) {
      isGenericFetchDispatched
        ? genericFetch({
            ...additionalParameters,
            sortBy: sortingParam,
            page: currentPage,
            perPage: value || pageSize,
            isDNKCustomer: filterByRoles,
            isTypeService: filterByRoles,
            showArchived: isArchiveMode,
          })
        : dispatch(
            genericFetch({
              ...additionalParameters,
              sortBy: sortingParam,
              page: currentPage,
              perPage: value || pageSize,
              isDNKCustomer: filterByRoles,
              isTypeService: filterByRoles,
              showArchived: isArchiveMode,
            })
          )
      cancelAllRefreshes()
    }
    // TODO : Figure out better solution for dependency additionalParameters?.investmentName
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    additionalParameters,
    cancelAllRefreshes,
    currentPage,
    dispatch,
    genericFetch,
    filterByRoles,
    numberOfPages,
    pageSize,
    refetch,
    sortingParam,
    isArchiveMode,
  ])

  const [savedQueryParameters, setSavedQueryParameters] = useState<any>(null)
  useEffect(() => {
    let page = currentPage
    if (page > numberOfPages) {
      setCurrentPage(1)
      page = 1
      isMainTable && dispatch(setMainTableCurrentPage(1))
    }

    const newQueryParameters = {
      ...additionalParameters,
      page,
      sortBy: sortingParam,
      perPage: value || pageSize,
      isDNKCustomer: filterByRoles,
      isTypeService: filterByRoles,
    }
    if (
      savedQueryParameters === null ||
      !_.isEqual(savedQueryParameters, newQueryParameters)
    ) {
      setSavedQueryParameters(newQueryParameters)

      if (isGenericFetchDispatched || exludeDispatchFetch) {
        genericFetch(newQueryParameters)
        setExludeDispatchFetch(false)
      } else {
        dispatch(genericFetch(newQueryParameters))
      }
    }
    // TODO : Figure out better solution for dependency additionalParameters?.investmentName
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    additionalParameters,
    currentPage,
    dispatch,
    genericFetch,
    filterByRoles,
    numberOfPages,
    pageSize,
    refetch,
    sortingParam,
  ])

  React.useEffect(() => {
    tableKey && value && setPageSize(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    isMainTable && setCurrentPage(mainTableCurrentPage)
  }, [isMainTable, mainTableCurrentPage])

  return (
    <div className='d-flex align-items-center justify-content-end pagination mx-4'>
      <div className='mx-1'>
        <button
          className='py-2'
          onClick={handleGoToFirstPageClick}
          disabled={currentPage === 1}
          style={{ backgroundColor: '#fff', border: '0' }}
        >
          {'«'}
        </button>
        <button
          className='py-2'
          onClick={handleGoToPreviousPageClick}
          disabled={currentPage === 1}
          style={{ backgroundColor: '#fff', border: '0' }}
        >
          {'◄'}
        </button>
      </div>
      <div className='mx-1'>
        <select
          className='py-2 px-1 mx-2'
          onChange={handleChangePageSizeClick}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #dedede',
            height: '40px',
          }}
          value={pageSize ?? 500}
        >
          {amountOfRows.map((pageSize, index) => (
            <option key={pageSize + '.' + index} value={pageSize}>
              {pageSize} {t('commons:labels.of-rows')}
            </option>
          ))}
        </select>
        <span className='mx-2'>
          {t('commons:labels.page')}
          <strong className='px-1' style={{ fontWeight: 'initial' }}>
            <span>{currentPage}</span> {t('commons:labels.from-2')}{' '}
            {numberOfPages}
          </strong>
        </span>
      </div>
      <div className='mx-1'>
        <button
          className='py-2'
          disabled={currentPage === numberOfPages}
          onClick={handleNextPageClick}
          style={{ backgroundColor: '#fff', border: '0' }}
        >
          {'►'}
        </button>
        <button
          className='py-2'
          disabled={currentPage === numberOfPages}
          onClick={handleLastPageClick}
          style={{ backgroundColor: '#fff', border: '0' }}
        >
          {'»'}
        </button>
      </div>
    </div>
  )
}

export default PaginationWithFetch
