import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import MainColForReportCheckbox from '../../commons/Containers/MainColForReportCheckbox'
import MainRow from '../../commons/Containers/MainRow'
import SimpleInputCheckbox3 from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'

import { useRaportsWithDatesContext } from './raportWithDatesContext'
import infoSourcesIDName from '../../../mockups/infoSourcesIDName'
import { updateSourceInfo } from './actions'
import { useCheckIfSmallResolution } from '../../../hooks/useCheckIfSmallResolution'

import { IListItem } from '../../../interfaces/commons'

export const SourceInfo = (): JSX.Element => {
  const { t } = useTranslation()
  const { state, dispatch } = useRaportsWithDatesContext()
  const infoSources = infoSourcesIDName
    .filter((is) => is.name !== ' ')
    .map((is) => is.name)

  const selectAllSources = React.useCallback(() => {
    if (state.chosenSourceInfo.length) {
      return dispatch(updateSourceInfo([]))
    }
    dispatch(updateSourceInfo(infoSources))
  }, [dispatch, infoSources, state.chosenSourceInfo.length])

  const handleUpdateSourceInfo = React.useCallback(
    (infoSource: string) => {
      const infoIsSelected = state.chosenSourceInfo.includes(infoSource)
      if (infoIsSelected) {
        return dispatch(
          updateSourceInfo(
            state.chosenSourceInfo.filter((info) => info !== infoSource)
          )
        )
      }
      dispatch(updateSourceInfo([...state.chosenSourceInfo, infoSource]))
    },
    [dispatch, state.chosenSourceInfo]
  )

  const isOneColInRow = useCheckIfSmallResolution()

  return (
    <>
      <MainRow isRowContentCentered>
        <p className='icr-header mt-4'>
          {t('commons:labels.choose-source-info')}
        </p>
      </MainRow>
      <MainRow isRowContentCentered>
        <Button onClick={selectAllSources}>
          {t('commons:actions.select-deselect-all')}
        </Button>
      </MainRow>
      <MainRow isRowContentCentered>
        {infoSourcesIDName?.map((info: IListItem, index: number) => {
          const numberOfCols = infoSourcesIDName.length - 1
          return info.name.length === 1 ? null : (
            <MainColForReportCheckbox
              forLargeCheckbox
              numberOfCols={numberOfCols}
              isOneColInRow={isOneColInRow}
              key={info.id + '.' + index}
            >
              <SimpleInputCheckbox3
                additionalOnChange={(): void =>
                  handleUpdateSourceInfo(info.name)
                }
                customLabelWidth={18}
                key={`${info.name}-${info.id}-checkbox`}
                name={info.name}
                label={info.name}
                value={!!state.chosenSourceInfo.includes(info.name)}
                wordWrap
              />
            </MainColForReportCheckbox>
          )
        })}
      </MainRow>
    </>
  )
}
