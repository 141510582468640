import { IProposedItem } from '../components/InterestedCustomers/useProposedInvestment'

const createProposedInvestmentObject = (
  investment: IProposedItem,
  stage: IProposedItem,
  place: IProposedItem
) => {
  const name = `${investment?.name || ''} - ${stage?.name || ''}${
    place ? ' - ' + place.name : ''
  }`
  return {
    investmentID: investment?.id,
    stageID: stage?.id,
    placeID: place?.id !== -1 ? place?.id : null,
    name,
  }
}
export default createProposedInvestmentObject
