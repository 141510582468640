import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SimpleInputList2 from '../commons/Inputs/SimpleInputs/SimpleInputList2'
import ValidationTooltip from '../commons/Inputs/ValidationTooltip'
import infoSourcesIDName from '../../mockups/infoSourcesIDName'
import { IInterestedCustomer } from '../../graphql/customers'
import { AppDispatch, RootState } from '../../ducks'
import {
  setCurrentInterestedCustomer,
  setCurrentInterestedCustomerID,
  updateInterestedCustomer,
} from '../../ducks/interestedCustomers'
import { useForm } from '../../hooks/useForm'
import SimpleInput3 from '../commons/Inputs/SimpleInputs/SimpleInput3'
import { MaskedInputText2 } from '../commons/Inputs/SimpleInputs/MaskedInputText2'
import SimpleInputCheckbox3 from '../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'
import { refetchPagination } from '../commons/Table2/paginationSlice'
import { useIdle } from '../../hooks/useIdle'
import { defaultIdleTime } from '../../constants/idleTime'
import {
  clearPersistRegisterSalesCopy,
  pasteInterestedCustomerIntoPersistStore,
} from '../../ducks/persistCopyOfForm'
import { useUserInitialsListForRoles } from '../../hooks/useUserInitialsListForRoles'
import { useCustomerSourceInfo } from '../../hooks/useCustomerSourceInfo'
import SimpleInputText from '../commons/Inputs/SimpleInputs/SimpleInputText'
import { useUserAbility } from '../../hooks/ability'
import { omit } from 'lodash-es'
import { getBrokers } from '../../ducks/brokers'
import { getFormBlocks } from '../../utils/index'

const defaultInterestedCustomer = {
  customerType: '',
  maidenName: '',
  parentsNames: '',
  secondName: '',
  pesel: '',
  sex: '',
  IDNumberAndSeries: '',
  citizenship: '',
  purchasePurpose: '',
  residenceName: '',
  residenceSurname: '',
  residenceCountry: '',
  residenceCity: '',
  residenceStreet: '',
  residenceNumber: '',
  residencePostalCode: '',
  residenceVoivodeship: '',
  residenceIsForeign: false,
  correspondenceName: '',
  correspondenceSurname: '',
  correspondenceCountry: '',
  correspondenceCity: '',
  correspondenceStreet: '',
  correspondenceNumber: '',
  correspondencePostalCode: '',
  correspondenceVoivodeship: '',
  correspondenceIsForeign: false,
  isBusiness: false,
  maritalStatus: '',
  isAfterMigration: true,
}

interface IEditInterestedProps {
  customer: IInterestedCustomer
}

export const EditInterested: FunctionComponent<IEditInterestedProps> = ({
  customer,
}) => {
  const phoneTarget = useRef(null)
  const phoneTarget2 = useRef(null)
  const [isRequiredRed, setRequiredAsRed] = useState(false)
  const ability = useUserAbility()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const { copyOfInterestedCustomer } = useSelector(
    (state: RootState) => state.persistCopyOfForm
  )

  const [isActiveEditMode, setIsActiveEditMode] = useState(false)
  const hasBrokerViewRole = ability.can('read', 'InterestedCustomersBroker')
  const emailTarget = useRef()
  const emailTarget2 = useRef()
  const infoSources = infoSourcesIDName.map((is) => is.name)

  const isIdle = useIdle(defaultIdleTime)
  const userInitialsList = useUserInitialsListForRoles()

  const { brokers } = useSelector((state: RootState) => state.brokers)
  const brokersName = brokers?.map((broker) => broker.name)

  useEffect(() => {
    if (isIdle) {
      const collectedDataAboutInterestedCustomer = {
        ...copyOfInterestedCustomer,
        customer: {
          ...state,
          sourceInfo,
        },
      }
      dispatch(
        pasteInterestedCustomerIntoPersistStore(
          collectedDataAboutInterestedCustomer
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle])

  React.useEffect(() => {
    dispatch(getBrokers())
  }, [dispatch])

  useEffect(() => {
    return (): void => {
      dispatch(clearPersistRegisterSalesCopy())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultValuesForForm = useMemo(() => {
    const { customer: copyOfCustomer } = copyOfInterestedCustomer
    return {
      ...customer,
      firstName: copyOfCustomer?.firstName ?? customer?.firstName ?? '',
      lastName: copyOfCustomer?.lastName ?? customer?.lastName ?? '',
      email: copyOfCustomer?.email ?? customer?.email ?? '',
      email2: copyOfCustomer?.email2 ?? customer?.email2 ?? '',
      phone1: copyOfCustomer?.phone1 ?? customer?.phone1 ?? '',
      phone2: copyOfCustomer?.phone2 ?? customer?.phone2 ?? '',
      sourceInfo: copyOfCustomer?.sourceInfo ?? customer?.sourceInfo ?? '',
      traderInitials:
        copyOfCustomer?.traderInitials ?? customer?.traderInitials ?? '',
      brokerName: copyOfCustomer?.brokerName ?? customer?.brokerName ?? '',
      commissionDivision:
        copyOfCustomer?.commissionDivision ??
        customer?.commissionDivision ??
        '',
      isVIP: copyOfCustomer?.isVIP ?? customer?.isVIP ?? false,
      isCommissionDivision:
        copyOfCustomer?.isCommissionDivision ??
        customer?.isCommissionDivision ??
        false,
      isBlueList: copyOfCustomer?.isBlueList ?? customer?.isBlueList ?? false,
      isBroker: copyOfCustomer?.isBroker ?? customer?.isBroker ?? false,
      isToughCustomer:
        copyOfCustomer?.isToughCustomer ?? customer?.isToughCustomer ?? false,
      isAfterMigration:
        copyOfCustomer?.isAfterMigration ?? customer?.isAfterMigration ?? true,
    }
  }, [copyOfInterestedCustomer, customer])

  const sourceInfoOptions = infoSources.filter(
    (source: string) => source !== ' '
  )
  const [sourceInfo, setSourceInfo, shouldDisplayAdditionalSourceInfoInput] =
    useCustomerSourceInfo(customer, sourceInfoOptions)
  const { state, updateFromInput } = useForm(defaultValuesForForm)

  const {
    showPhone1Tooltip,
    showPhone2Tooltip,
    showEmailTooltip,
    showEmail2Tooltip,
    tooltipBlock,
  } = getFormBlocks(state)

  const updateSourceInfo = React.useCallback(
    (e: any): void => {
      setSourceInfo(e.target.value)
    },
    [setSourceInfo]
  )
  const handleFormSubmit = useCallback(
    (e: any): void => {
      e.preventDefault()
      if (!tooltipBlock) {
        const stateWithoutLatestMarketingConsent = omit(state, [
          'latestMarketingConsent',
        ])
        const {
          id: customerID,
          marketingConsents,
          contacts,
          proposedPlaces,
          proposedInvestments,
          contracts,
          phone1,
          phone2,
          ...rest
        } = stateWithoutLatestMarketingConsent
        dispatch(
          updateInterestedCustomer({
            customerID: customer.id,
            customer: {
              ...rest,
              ...defaultInterestedCustomer,
              sourceInfo,
              phone1: phone1.replace(/\s/g, '') || '',
              phone2: phone2.replace(/\s/g, '') || '',
              brokerName: state.isBroker ? state.brokerName : '',
              commissionDivision: state.isCommissionDivision
                ? state.commissionDivision
                : '',
            },
          })
        ).then(() => {
          dispatch(setCurrentInterestedCustomer(null))
          dispatch(setCurrentInterestedCustomerID(undefined))
          dispatch(refetchPagination(true))
          setIsActiveEditMode(false)
        })
      } else {
        alert('Uzupełnij pola obowiązkowe!')
        setRequiredAsRed(true)
      }
    },
    [customer.id, dispatch, state, sourceInfo]
  )

  return (
    <div className='d-flex align-items-start justify-content-center row'>
      <Col lg={11} style={{ marginLeft: '4%', marginRight: '4%' }}>
        <Form onSubmit={handleFormSubmit} className='w-100'>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <Row>
                <SimpleInput3
                  name='lastName'
                  disabled={!isActiveEditMode}
                  label={String(t('commons:labels.surname'))}
                  value={state.lastName}
                  type='text'
                  required
                  onChange={updateFromInput}
                />
              </Row>
              <Row>
                <SimpleInput3
                  name='firstName'
                  disabled={!isActiveEditMode}
                  label={String(t('commons:labels.name'))}
                  value={state.firstName}
                  type='text'
                  required
                  onChange={updateFromInput}
                />
              </Row>
              <Row>
                <SimpleInput3
                  name='email'
                  disabled={!isActiveEditMode}
                  label={String(t('commons:labels.email'))}
                  value={state.email}
                  onChange={updateFromInput}
                  reference={emailTarget}
                  highlighted={isRequiredRed && showEmailTooltip}
                />
                <ValidationTooltip
                  isEmail
                  number={10}
                  show={isRequiredRed && showEmailTooltip}
                  target={emailTarget.current}
                />
              </Row>
              <Row>
                <SimpleInput3
                  name='email2'
                  disabled={!isActiveEditMode}
                  label={String(t('commons:labels.email') + ' 2')}
                  value={state.email2}
                  onChange={updateFromInput}
                  reference={emailTarget2}
                  highlighted={isRequiredRed && showEmail2Tooltip}
                />
                <ValidationTooltip
                  isEmail
                  number={10}
                  show={isRequiredRed && showEmail2Tooltip}
                  target={emailTarget2.current}
                />
              </Row>
              <Row>
                <MaskedInputText2
                  name='phone1'
                  disabled={!isActiveEditMode}
                  label={String(t('commons:labels.phone'))}
                  value={state.phone1}
                  onChange={updateFromInput}
                  mask='111 111 111'
                  reference={phoneTarget}
                  highlighted={isRequiredRed && showPhone1Tooltip}
                />
                <ValidationTooltip
                  isPhoneNumber
                  show={isRequiredRed && showPhone1Tooltip}
                  target={phoneTarget.current}
                />
              </Row>
              <Row>
                <SimpleInput3
                  name='phone2'
                  disabled={!isActiveEditMode}
                  label={String(t('commons:labels.phone'))}
                  value={state.phone2}
                  type='text'
                  onChange={updateFromInput}
                  maxLength={18}
                  isNumberInput
                  reference={phoneTarget2}
                  highlighted={isRequiredRed && showPhone2Tooltip}
                />
                <ValidationTooltip
                  isPhoneNumber2
                  show={isRequiredRed && showPhone2Tooltip}
                  target={phoneTarget2.current}
                />
              </Row>
            </Col>
            <Col lg={4} md={12} sm={12}>
              {state.isBroker && hasBrokerViewRole && (
                <Row>
                  <SimpleInputList2
                    name='brokerName'
                    additionalOnChange={updateFromInput}
                    defaultValue={state.brokerName}
                    disabled={
                      !isActiveEditMode ||
                      !ability.can('edit', 'InterestedCustomersBroker')
                    }
                    label={String(t('place:labels.broker-name'))}
                    options={brokersName}
                  />
                </Row>
              )}
              {ability.can('read', 'InterestedCustomersCommissionDivision') &&
                state.isCommissionDivision && (
                  <Row>
                    <SimpleInput3
                      name='commissionDivision'
                      disabled={!isActiveEditMode}
                      value={state.commissionDivision}
                      onChange={updateFromInput}
                      label={String(t('place:labels.commision-division'))}
                      type='text'
                    />
                  </Row>
                )}
              {ability.can('read', 'InterestedCustomersSourceInfo') && (
                <Row>
                  <SimpleInputList2
                    name='sourceInfo'
                    additionalOnChange={updateSourceInfo}
                    defaultValue={sourceInfo}
                    disabled={!isActiveEditMode}
                    label={String(t('place:labels.information-source'))}
                    options={sourceInfoOptions}
                  />
                </Row>
              )}
              {shouldDisplayAdditionalSourceInfoInput && (
                <Row>
                  <SimpleInputText
                    defaultValue={customer.sourceInfo}
                    valueDependingOnExternalValues={customer.sourceInfo}
                    disabled
                    label={String(t('place:labels.information-source'))}
                  />
                </Row>
              )}
              <Row>
                <SimpleInputList2
                  name='traderInitials'
                  additionalOnChange={updateFromInput}
                  defaultValue={state.traderInitials}
                  disabled={!isActiveEditMode}
                  options={userInitialsList}
                  label={String(t('commons:labels.trader-initials'))}
                />
              </Row>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <Row>
                <SimpleInputCheckbox3
                  name='isVIP'
                  disabled={!isActiveEditMode}
                  value={state.isVIP}
                  label={String(t('customers:labels.vip'))}
                  onChange={updateFromInput}
                />
                {ability.can(
                  'read',
                  'InterestedCustomersCommissionDivision'
                ) && (
                  <SimpleInputCheckbox3
                    name='isCommissionDivision'
                    disabled={!isActiveEditMode}
                    value={state.isCommissionDivision}
                    label={String(t('place:labels.commision-division'))}
                    onChange={updateFromInput}
                  />
                )}
                <SimpleInputCheckbox3
                  name='isBlueList'
                  disabled={!isActiveEditMode}
                  value={state.isBlueList}
                  label={String(t('customers:labels.blue-list'))}
                  onChange={updateFromInput}
                />
              </Row>
              <Row>
                {hasBrokerViewRole && (
                  <SimpleInputCheckbox3
                    name='isBroker'
                    disabled={
                      !isActiveEditMode ||
                      !ability.can('edit', 'InterestedCustomersBroker')
                    }
                    value={state.isBroker}
                    label={String(t('place:labels.broker'))}
                    onChange={updateFromInput}
                  />
                )}
                <SimpleInputCheckbox3
                  name='isToughCustomer'
                  disabled={!isActiveEditMode}
                  value={state.isToughCustomer}
                  label={String(t('customers:labels.tough-customer'))}
                  onChange={updateFromInput}
                />
              </Row>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            {!isActiveEditMode && (
              <Button onClick={(): void => setIsActiveEditMode(true)}>
                {t('commons:actions.edit')}
              </Button>
            )}
            {isActiveEditMode && (
              <Button disabled={isRequiredRed && tooltipBlock} type='submit'>
                {t('commons:actions.update-data')}
              </Button>
            )}
          </Row>
        </Form>
      </Col>
    </div>
  )
}

export default EditInterested
