// @ts-nocheck

import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { RootState } from '../ducks'
import ACCESS_PAGES from '../constants/accessPages'

export const manageAbilities = ({
  isADM,
  isDAR,
  isDKO,
  isDMA,
  isDMAADM,
  isDNI,
  isDNK,
  isDNKADM,
  isDPI,
  isDPP,
  isDSP,
  isDSPADM,
  isDKS,
  isDYR,
  isSEK,
  isSKA,
  UMOWYAN,
  UMOWYARCH,
}: {
  isADM: boolean
  isDAR: boolean
  isDKO: boolean
  isDMA: boolean
  isDMAADM: boolean
  isDNI: boolean
  isDNK: boolean
  isDNKADM: boolean
  isDPI: boolean
  isDPP: boolean
  isDSP: boolean
  isDSPADM: boolean
  isDKS: boolean
  isDYR: boolean
  isSEK: boolean
  isSKA: boolean
  UMOWYAN: boolean
  UMOWYARCH: boolean
}) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility)
  can('read', 'Pages', ACCESS_PAGES.MAIN_TABLE)
  if (isADM) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.ADMIN_IT_PANEL)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('see', 'Path', 'main-table-view')
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
    can('read', 'SalesRegisterRepresentativePerson')
    can('read', 'SalesRegisterMarketingConsents')
    can('read', 'SalesRegisterAgent')
    can('read', 'SalesRegisterInterestedCustomers')
    can('edit', 'SalesRegisterInterestedCustomers')
    can('add', 'SalesRegisterCustomers')
    can('delete', 'SalesRegisterContractAndCustomer')
    can('read', 'InterestedCustomersBroker')
    can('read', 'InterestedCustomersCommissionDivision')
    can('read', 'InterestedCustomersSourceInfo')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonPesel')
    can('read', 'SalesRegisterPersonCitizenship')
    can('read', 'SalesRegisterPersonPassportNumberAndSeries')
    can('read', 'SalesRegisterPersonIDNumberAndSeries')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonKRS')
    can('read', 'SalesRegisterPersonTaxNumber')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'SalesRegisterPersonRegon')
    can('delete', 'Note')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'CustomerParentsNames')
    can('read', 'CustomerMaidenName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can('read', 'PlaceNotarialOffice')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceLawAndDeluxeContract')
    can('read', 'PlaceValorization')
    can('read', 'PlacePledgeWarranty')
    can('read', 'PlaceEmailInvoice')
    can('read', 'PlaceCreditBasic')
    can('read', 'PlaceBank')
    can('read', 'PlaceCreditDetails')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractFile')
    can('edit', 'PlaceArrangementsReminder')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceContractAnnexes')
    can('read', 'PlaceContractProtocols')
    can('read', 'PlaceContractDisclaimer')
    can('read', 'PlaceBusinessPurchase')
    can('edit', 'PlaceAssignedRooms')
    can('edit', 'CustomerMarketingConsents')
  }
  if (isDAR) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.ARRANGEMENTS)
    can('read', 'Pages', ACCESS_PAGES.DAR_REPORTS)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('fetch', 'User', 'fetchUsersInitialsInAppRouter')
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
    can('read', 'SalesRegisterRepresentativePerson')
    can('read', 'SalesRegisterMarketingConsents')
    can('read', 'SalesRegisterAgent')
    can('read', 'InterestedCustomersSourceInfo')
    can('edit', 'SalesRegisterContractAndCustomer')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonPesel')
    can('read', 'SalesRegisterPersonCitizenship')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'CustomerMaidenName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can('read', 'PlaceNotarialOffice')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceLawAndDeluxeContract')
    can('read', 'PlacePledgeWarranty')
    can('read', 'PlaceEditButton')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractFile')
    can(['edit', 'read'], 'PlaceArrangementsReminder')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceContractAnnexes')
    can('read', 'PlaceContractProtocols')
    can('read', 'PlaceContractDisclaimer')
    can('read', 'PlaceBusinessPurchase')
  }
  if (isDKO) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DKO_REPORTS)
    can('read', 'Pages', ACCESS_PAGES.DKO_SERIAL_CORRESPONDENCE_REPORT)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
    can('read', 'SalesRegisterRepresentativePerson')
    can('edit', 'SalesRegisterContractAndCustomer')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterAgent')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonKRS')
    can('read', 'SalesRegisterPersonTaxNumber')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'SalesRegisterPersonRegon')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'CustomerParentsNames')
    can('read', 'CustomerMaidenName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlacePledgeWarranty')
    can(['read', 'edit', 'delete'], 'PlaceContractProtocols')
    can('read', 'PlaceEditButton')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractFile')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceContractAnnexes')
    can('read', 'PlaceBusinessPurchase')
  }
  if (isDKS) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DKS_REPORTS)
    can('read', 'Pages', ACCESS_PAGES.DKO_SERIAL_CORRESPONDENCE_REPORT)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
    can('read', 'SalesRegisterRepresentativePerson')
    can('read', 'SalesRegisterMarketingConsents')
    can('read', 'SalesRegisterAgent')
    can('read', 'InterestedCustomersBroker')
    can('read', 'InterestedCustomersCommissionDivision')
    can('edit', 'SalesRegisterContractAndCustomer')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonPesel')
    can('read', 'SalesRegisterPersonCitizenship')
    can('read', 'SalesRegisterPersonPassportNumberAndSeries')
    can('read', 'SalesRegisterPersonIDNumberAndSeries')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonKRS')
    can('read', 'SalesRegisterPersonTaxNumber')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'SalesRegisterPersonRegon')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'CustomerMaidenName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can(['read', 'edit'], 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can('read', 'PlaceNotarialOffice')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceLawAndDeluxeContract')
    can('read', 'PlaceValorization')
    can('read', 'PlacePledgeWarranty')
    can(['read', 'edit'], 'PlaceEmailInvoice')
    can('read', 'PlaceCreditBasic')
    can('read', 'PlaceBank')
    can('read', 'PlaceCreditDetails')
    can('read', 'PlaceEditButton')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractFile')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceContractAnnexes')
    can('read', 'PlaceContractProtocols')
    can('read', 'PlaceContractDisclaimer')
    can('read', 'PlaceBusinessPurchase')
  }
  if (isDMA) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DMA_MARKETING_REPORT)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('fetch', 'User', 'fetchUsersInitialsInAppRouter')
    can('see', 'Path', 'main-table-view')
    can('edit', 'MarketingConsents')
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
    can('read', 'SalesRegisterRepresentativePerson')
    can(['read', 'edit'], 'SalesRegisterMarketingConsents')
    can('read', 'SalesRegisterInterestedCustomers')
    can('read', 'InterestedCustomersSourceInfo')
    can('edit', 'SalesRegisterCustomers')
    can('edit', 'SalesRegisterContractAndCustomer')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonPesel')
    can('read', 'SalesRegisterPersonCitizenship')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceCreditBasic')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceBusinessPurchase')
    can('edit', 'CustomerMarketingConsents')
  }
  if (isDMAADM) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DMA_MARKETING_REPORT)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('fetch', 'User', 'fetchUsersInitialsInAppRouter')
    can('see', 'Path', 'main-table-view')
    can('edit', 'MarketingConsents')
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
    can('read', 'SalesRegisterRepresentativePerson')
    can(['read', 'edit'], 'SalesRegisterMarketingConsents')
    can('read', 'SalesRegisterInterestedCustomers')
    can('read', 'InterestedCustomersSourceInfo')
    can('edit', 'SalesRegisterCustomers')
    can('edit', 'SalesRegisterContractAndCustomer')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonPesel')
    can('read', 'SalesRegisterPersonCitizenship')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceCreditBasic')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceBusinessPurchase')
    can('edit', 'CustomerMarketingConsents')
  }
  if (isDNI) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DNI_REPORTS)
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterRepresentativePerson')
    can('edit', 'SalesRegisterContractAndCustomer')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterAgent')
    can('read', 'SalesRegisterPersonPesel')
    can('read', 'SalesRegisterPersonCitizenship')
    can('read', 'SalesRegisterPersonPassportNumberAndSeries')
    can('read', 'SalesRegisterPersonIDNumberAndSeries')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonKRS')
    can('read', 'SalesRegisterPersonTaxNumber')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'SalesRegisterPersonRegon')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'CustomerParentsNames')
    can('read', 'CustomerMaidenName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'PlaceAreas')
    can('read', 'PlaceNotarialOffice')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceLawAndDeluxeContract')
    can('read', 'PlaceValorization')
    can('read', 'PlacePledgeWarranty')
    can('read', 'PlaceCreditBasic')
    can('read', 'PlaceBank')
    can('read', 'PlaceEditButton')
    can('read', 'PlaceComment')
    can('edit', 'PlaceAcceptanceDate')
    can('read', 'PlaceContractFile')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceContractAnnexes')
    can(['read', 'edit'], 'PlaceContractProtocols')
    can('read', 'PlaceContractDisclaimer')
    can('edit', 'PlaceActDate')
    can('read', 'PlaceBusinessPurchase')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
  }
  if (isDNK) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DNK_REPORTS)
    can('read', 'Pages', ACCESS_PAGES.TABLE_UNASSIGNED_CUSTOMERS)
    can('read', 'Pages', ACCESS_PAGES.INTERESTED_CUSTOMERS)
    can('read', 'Pages', ACCESS_PAGES.SALES_REGISTRATION)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('fetch', 'User', 'fetchUsersInitialsInAppRouter')
    can(['read', 'edit'], 'SalesRegisterPersonAddress')
    can(['read', 'edit'], 'SalesRegisterPersonPhonesAndEmail')
    can(['read', 'edit'], 'SalesRegisterRepresentativePerson')
    can(['read', 'edit'], 'SalesRegisterMarketingConsents')
    can(['read', 'edit'], 'SalesRegisterAgent')
    can(['read', 'edit'], 'SalesRegisterInterestedCustomers')
    can(['add', 'edit'], 'SalesRegisterCustomers')
    can(['delete', 'edit'], 'SalesRegisterContractAndCustomer')
    can(['read', 'edit'], 'SalesRegisterIsBusiness')
    can(['read', 'edit'], 'InterestedCustomersBroker')
    can('read', 'InterestedCustomersCommissionDivision')
    can(['read', 'edit'], 'InterestedCustomersSourceInfo')
    can('copyAndPaste')
    can(['read', 'edit'], 'SalesRegisterPersonTraderInitials')
    can(['read', 'edit'], 'SalesRegisterPersonPesel')
    can(['read', 'edit'], 'SalesRegisterPersonCitizenship')
    can(['read', 'edit'], 'SalesRegisterPersonPassportNumberAndSeries')
    can(['read', 'edit'], 'SalesRegisterPersonIDNumberAndSeries')
    can(['read', 'edit'], 'SalesRegisterPersonCode')
    can(['read', 'edit'], 'SalesRegisterPersonCompanyName')
    can(['read', 'edit'], 'SalesRegisterPersonCompanyForm')
    can(['read', 'edit'], 'SalesRegisterPersonKRS')
    can(['read', 'edit'], 'SalesRegisterPersonTaxNumber')
    can(['read', 'edit'], 'SalesRegisterPersonBusinessTypeAndName')
    can(['read', 'edit'], 'SalesRegisterPersonPlaceShares')
    can(['read', 'edit'], 'SalesRegisterPersonPurchasePurpose')
    can(['read', 'edit'], 'SalesRegisterPersonRegon')
    can('delete', 'Note')
    can(['read', 'edit'], 'CustomerFirstAndLastName')
    can('edit', 'CustomerCommissionDivision')
    can(['read', 'edit'], 'CustomerMaritalStatus')
    can(['read', 'edit'], 'CustomerSex')
    can(['read', 'edit'], 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can(['read', 'edit'], 'PlaceNotarialOffice')
    can(['read', 'edit'], 'PlaceOtherContractResolutions')
    can(['read', 'edit'], 'PlaceValorization')
    can(['read', 'edit'], 'PlacePledgeWarranty')
    can(['read', 'edit'], 'PlaceEmailInvoice')
    can('read', 'PlaceCreditBasic')
    can(['read', 'delete'], 'PlaceContractAnnexes')
    can(['read', 'edit', 'delete'], 'PlaceContractProtocols')
    can('read', 'PlaceBank')
    can('read', 'PlaceCreditDetails')
    can(['read', 'edit'], 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceEditButton')
    can('edit', 'PlaceContractNo')
    can('edit', 'PlaceCustomerCodeForPlaceData')
    can('edit', 'PlaceRepNo')
    can(['read', 'edit'], 'PlaceComment')
    can('edit', 'PlaceContractDealDate')
    can('edit', 'PlaceContractualAcceptanceDate')
    can('edit', 'PlaceContractualActDate')
    can(['read', 'edit'], 'PlaceContractFile')
    can('cancel', 'PlaceContract')
    can(['edit', 'read'], 'PlaceBusinessPurchase')
    can('edit', 'PlaceCredit')
    can('edit', 'PlaceAssignedRooms')
    can('edit', 'CustomerMarketingConsents')
    can('read', 'HeaderCancelledContract')
    can('read', 'HeaderArchive')
  }
  if (isDNKADM) {
  }
  if (isDPI) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('read', 'InterestedCustomersSourceInfo')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceBusinessPurchase')
  }
  if (isDPP) {
    can('read', 'Pages', ACCESS_PAGES.DPP_REPORTS)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'PlaceAreas')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractFile')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceContractAnnexes')
  }

  if (isDSP) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DSP_AND_DSPADM_REPORTS)
    can('read', 'Pages', ACCESS_PAGES.TABLE_UNASSIGNED_CUSTOMERS)
    can('read', 'Pages', ACCESS_PAGES.INTERESTED_CUSTOMERS)
    can('read', 'Pages', ACCESS_PAGES.SALES_REGISTRATION)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('fetch', 'User', 'fetchUsersInitialsInAppRouter')
    can('delete', 'Contact')
    can(['read', 'edit'], 'SalesRegisterPersonAddress')
    can(['read', 'edit'], 'SalesRegisterPersonPhonesAndEmail')
    can(['read', 'edit'], 'SalesRegisterRepresentativePerson')
    can(['read', 'edit'], 'SalesRegisterMarketingConsents')
    can(['read', 'edit'], 'SalesRegisterAgent')
    can(['read', 'edit'], 'SalesRegisterInterestedCustomers')
    can(['add', 'edit'], 'SalesRegisterCustomers')
    can(['delete', 'edit'], 'SalesRegisterContractAndCustomer')
    can(['read', 'edit'], 'SalesRegisterIsBusiness')
    can(['read', 'edit'], 'InterestedCustomersBroker')
    can('read', 'InterestedCustomersCommissionDivision')
    can(['read', 'edit'], 'InterestedCustomersSourceInfo')
    can('copyAndPaste')
    can(['read', 'edit'], 'SalesRegisterPersonTraderInitials')
    can(['read', 'edit'], 'SalesRegisterPersonPesel')
    can(['read', 'edit'], 'SalesRegisterPersonCitizenship')
    can(['read', 'edit'], 'SalesRegisterPersonPassportNumberAndSeries')
    can(['read', 'edit'], 'SalesRegisterPersonIDNumberAndSeries')
    can(['read', 'edit'], 'SalesRegisterPersonCode')
    can(['read', 'edit'], 'SalesRegisterPersonCompanyName')
    can(['read', 'edit'], 'SalesRegisterPersonCompanyForm')
    can(['read', 'edit'], 'SalesRegisterPersonKRS')
    can(['read', 'edit'], 'SalesRegisterPersonTaxNumber')
    can(['read', 'edit'], 'SalesRegisterPersonBusinessTypeAndName')
    can(['read', 'edit'], 'SalesRegisterPersonPlaceShares')
    can(['read', 'edit'], 'SalesRegisterPersonRegon')
    can(['read', 'edit'], 'CustomerFirstAndLastName')
    can(['read', 'edit'], 'CustomerParentsNames')
    can(['read', 'edit'], 'CustomerMaidenName')
    can('edit', 'CustomerCommissionDivision')
    can(['read', 'edit'], 'CustomerMaritalStatus')
    can(['read', 'edit'], 'CustomerSex')
    can(['read', 'edit'], 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can(['read', 'edit'], 'PlaceNotarialOffice')
    can(['read', 'edit'], 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can(['read', 'edit'], 'PlaceTerraceBackyard')
    can(['read', 'edit'], 'PlaceLawAndDeluxeContract')
    can(['read', 'edit'], 'PlaceValorization')
    can(['read', 'edit'], 'PlacePledgeWarranty')
    can(['read', 'edit'], 'PlaceEmailInvoice')
    can('read', 'PlaceCreditBasic')
    can(['read', 'delete'], 'PlaceContractAnnexes')
    can(['read', 'delete'], 'PlaceContractDisclaimer')
    can('read', 'PlaceBank')
    can('read', 'PlaceCreditDetails')
    can(['read', 'edit'], 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceEditButton')
    can('edit', 'PlaceContractNo')
    can('edit', 'PlaceCustomerCodeForPlaceData')
    can('edit', 'PlaceRepNo')
    can(['read', 'edit'], 'PlaceComment')
    can('edit', 'PlaceContractDealDate')
    can('edit', 'PlaceContractualAcceptanceDate')
    can('edit', 'PlaceContractualActDate')
    can(['read', 'edit'], 'PlaceContractFile')
    can('read', 'PlaceContractProtocols')
    can('cancel', 'PlaceContract')
    can(['edit', 'read'], 'PlaceBusinessPurchase')
    can('edit', 'PlaceCredit')
    can('edit', 'CustomerMarketingConsents')
  }
  if (isDSPADM) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DSPADM_REPORTS)
    can('read', 'Pages', ACCESS_PAGES.DSP_AND_DSPADM_REPORTS)
    can('read', 'Pages', ACCESS_PAGES.TABLE_UNASSIGNED_CUSTOMERS)
    can('read', 'Pages', ACCESS_PAGES.INTERESTED_CUSTOMERS)
    can('read', 'Pages', ACCESS_PAGES.SALES_REGISTRATION)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('fetch', 'User', 'fetchUsersInitialsInAppRouter')
    can('delete', 'Contact')
    can(['read', 'edit'], 'SalesRegisterPersonAddress')
    can(['read', 'edit'], 'SalesRegisterPersonPhonesAndEmail')
    can(['read', 'edit'], 'SalesRegisterRepresentativePerson')
    can(['read', 'edit'], 'SalesRegisterMarketingConsents')
    can(['read', 'edit'], 'SalesRegisterAgent')
    can(['read', 'edit'], 'SalesRegisterInterestedCustomers')
    can(['add', 'edit'], 'SalesRegisterCustomers')
    can(['delete', 'edit'], 'SalesRegisterContractAndCustomer')
    can(['read', 'edit'], 'SalesRegisterIsBusiness')
    can(['read', 'edit'], 'InterestedCustomersBroker')
    can('read', 'InterestedCustomersCommissionDivision')
    can(['read', 'edit'], 'InterestedCustomersSourceInfo')
    can('copyAndPaste')
    can(['read', 'edit'], 'SalesRegisterPersonTraderInitials')
    can(['read', 'edit'], 'SalesRegisterPersonPesel')
    can(['read', 'edit'], 'SalesRegisterPersonCitizenship')
    can(['read', 'edit'], 'SalesRegisterPersonPassportNumberAndSeries')
    can(['read', 'edit'], 'SalesRegisterPersonIDNumberAndSeries')
    can(['read', 'edit'], 'SalesRegisterPersonCode')
    can(['read', 'edit'], 'SalesRegisterPersonCompanyName')
    can(['read', 'edit'], 'SalesRegisterPersonCompanyForm')
    can(['read', 'edit'], 'SalesRegisterPersonKRS')
    can(['read', 'edit'], 'SalesRegisterPersonTaxNumber')
    can(['read', 'edit'], 'SalesRegisterPersonBusinessTypeAndName')
    can(['read', 'edit'], 'SalesRegisterPersonPlaceShares')
    can(['read', 'edit'], 'SalesRegisterPersonRegon')
    can('delete', 'Note')
    can(['read', 'edit'], 'CustomerFirstAndLastName')
    can(['read', 'edit'], 'CustomerParentsNames')
    can(['read', 'edit'], 'CustomerMaidenName')
    can('edit', 'CustomerCommissionDivision')
    can(['read', 'edit'], 'CustomerMaritalStatus')
    can(['read', 'edit'], 'CustomerSex')
    can(['read', 'edit'], 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can(['read', 'edit'], 'PlaceNotarialOffice')
    can(['read', 'edit'], 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can(['read', 'edit'], 'PlaceTerraceBackyard')
    can(['read', 'edit'], 'PlaceLawAndDeluxeContract')
    can(['read', 'edit'], 'PlaceValorization')
    can(['read', 'edit'], 'PlacePledgeWarranty')
    can(['read', 'edit'], 'PlaceEmailInvoice')
    can('read', 'PlaceCreditBasic')
    can(['read', 'delete'], 'PlaceContractAnnexes')
    can(['read', 'delete'], 'PlaceContractDisclaimer')
    can('read', 'PlaceBank')
    can('read', 'PlaceCreditDetails')
    can(['read', 'edit'], 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceEditButton')
    can('edit', 'PlaceContractNo')
    can('edit', 'PlaceCustomerCodeForPlaceData')
    can('edit', 'PlaceRepNo')
    can(['read', 'edit'], 'PlaceComment')
    can('edit', 'PlaceContractDealDate')
    can('edit', 'PlaceContractualAcceptanceDate')
    can('edit', 'PlaceContractualActDate')
    can(['read', 'edit'], 'PlaceContractFile')
    can('read', 'PlaceContractProtocols')
    can('cancel', 'PlaceContract')
    can(['edit', 'read'], 'PlaceBusinessPurchase')
    can('edit', 'PlaceCredit')
    can('edit', 'PlaceAssignedRooms')
    can('edit', 'CustomerMarketingConsents')
    can('read', 'HeaderCancelledContract')
    can('read', 'HeaderArchive')
  }
  if (isDYR) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.CONTRACT_DETAILS)
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
    can('read', 'SalesRegisterRepresentativePerson')
    can('read', 'SalesRegisterMarketingConsents')
    can('read', 'SalesRegisterAgent')
    can('read', 'InterestedCustomersBroker')
    can('read', 'InterestedCustomersCommissionDivision')
    can('read', 'InterestedCustomersSourceInfo')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonPesel')
    can('read', 'SalesRegisterPersonCitizenship')
    can('read', 'SalesRegisterPersonPassportNumberAndSeries')
    can('read', 'SalesRegisterPersonIDNumberAndSeries')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonKRS')
    can('read', 'SalesRegisterPersonTaxNumber')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'SalesRegisterPersonRegon')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'CustomerParentsNames')
    can('read', 'CustomerMaidenName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'CorrespondenceAndEvents')
    can('read', 'Pages', ACCESS_PAGES.CONTRACT)
    can('read', 'PlaceAreas')
    can('read', 'PlaceNotarialOffice')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceLawAndDeluxeContract')
    can('read', 'PlaceValorization')
    can('read', 'PlacePledgeWarranty')
    can('read', 'PlaceEmailInvoice')
    can('read', 'PlaceCreditBasic')
    can('read', 'PlaceBank')
    can('read', 'PlaceCreditDetails')
    can('read', 'PlaceEditButton')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractFile')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceContractAnnexes')
    can('read', 'PlaceContractProtocols')
    can('read', 'PlaceContractDisclaimer')
    can('read', 'PlaceBusinessPurchase')
  }
  if (isSEK) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.DKO_SERIAL_CORRESPONDENCE_REPORT)
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterPersonPhonesAndEmail')
    can('read', 'SalesRegisterRepresentativePerson')
    can('read', 'SalesRegisterAgent')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'PlaceNotarialOffice')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceEmailInvoice')
    can('read', 'PlaceEditButton')
    can('read', 'PlaceContractFile')
    can('read', 'PlaceContractAnnexes')
    can('read', 'PlaceContractProtocols')
    can('read', 'PlaceContractDisclaimer')
  }
  if (isSKA) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'Pages', ACCESS_PAGES.SKA_REPORTS)
    can('read', 'SalesRegisterPersonAddress')
    can('read', 'SalesRegisterRepresentativePerson')
    can('read', 'SalesRegisterAgent')
    can('read', 'InterestedCustomersBroker')
    can('read', 'InterestedCustomersCommissionDivision')
    can('read', 'InterestedCustomersSourceInfo')
    can('read', 'SalesRegisterPersonTraderInitials')
    can('read', 'SalesRegisterPersonPesel')
    can('read', 'SalesRegisterPersonCitizenship')
    can('read', 'SalesRegisterPersonCode')
    can('read', 'SalesRegisterPersonCompanyName')
    can('read', 'SalesRegisterPersonCompanyForm')
    can('read', 'SalesRegisterPersonKRS')
    can('read', 'SalesRegisterPersonTaxNumber')
    can('read', 'SalesRegisterPersonBusinessTypeAndName')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('read', 'SalesRegisterPersonPurchasePurpose')
    can('read', 'SalesRegisterPersonRegon')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'SalesRegisterIsBusiness')
    can('read', 'CustomerMaritalStatus')
    can('read', 'CustomerSex')
    can('read', 'PlaceAreas')
    can('read', 'PlaceNotarialOffice')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceNoOfRooms')
    can('read', 'PlaceTerraceBackyard')
    can('read', 'PlaceLawAndDeluxeContract')
    can('read', 'PlaceValorization')
    can('read', 'PlacePledgeWarranty')
    can('read', 'PlaceCreditBasic')
    can('read', 'PlaceBank')
    can('read', 'PlaceCreditDetails')
    can('read', 'PlaceEditButton')
    can('read', 'PlaceComment')
    can('read', 'PlaceContractFile')
    can('read', 'PlaceContractUtilityRoomsAndParking')
    can('read', 'PlaceContractAnnexes')
    can('read', 'PlaceContractProtocols')
    can('read', 'PlaceBusinessPurchase')
  }
  if (UMOWYAN) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'SalesRegisterPersonKRS')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('delete', 'Note')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceBusinessPurchase')
    can('edit', 'PlaceAssignedRooms')
    can('edit', 'CustomerMarketingConsents')
    can('read', 'HeaderCancelledContract')
  }
  if (UMOWYARCH) {
    can('read', 'Pages', ACCESS_PAGES.CUSTOMER_DATA)
    can('read', 'Pages', ACCESS_PAGES.PLACE)
    can('read', 'SalesRegisterPersonKRS')
    can('read', 'SalesRegisterPersonPlaceShares')
    can('delete', 'Note')
    can('read', 'CustomerFirstAndLastName')
    can('read', 'PlaceOtherContractResolutions')
    can('read', 'PlaceBusinessPurchase')
    can('edit', 'PlaceAssignedRooms')
    can('edit', 'CustomerMarketingConsents')
    can('read', 'HeaderArchive')
  }

  return build()
}

export const useUserAbility = () => {
  const { roles } = useSelector((state: RootState) => state.globalUser)
  const haveRole = (role: string) => Boolean(roles.find((r) => r === role))
  const isADM = haveRole('ADM')
  const isDAR = haveRole('DAR')
  const isDKO = haveRole('DKO')
  const isDMA = haveRole('DMA')
  const isDMAADM = haveRole('DMA-ADM')
  const isDNI = haveRole('DNI')
  const isDNK = haveRole('DNK')
  const isDNKADM = haveRole('DNK-ADM')
  const isDPI = haveRole('DPI')
  const isDPP = haveRole('DPP')
  const isDSP = haveRole('DSP')
  const isDSPADM = haveRole('DSP-ADM')
  const isDKS = haveRole('DKS')
  const isDYR = haveRole('DYR')
  const isSEK = haveRole('SEK')
  const isSKA = haveRole('SKA')
  const UMOWYAN = haveRole('UMOWYAN')
  const UMOWYARCH = haveRole('UMOWYARCH')

  return useMemo(
    () =>
      manageAbilities({
        isADM,
        isDAR,
        isDKO,
        isDMA,
        isDMAADM,
        isDNI,
        isDNK,
        isDNKADM,
        isDPI,
        isDPP,
        isDSP,
        isDSPADM,
        isDKS,
        isDYR,
        isSEK,
        isSKA,
        UMOWYAN,
        UMOWYARCH,
      }),
    [roles]
  )
}

export const useUserPagesAbility = () => {
  const ability = useUserAbility()
  const canReadPage = (field: string) => ability.can('read', 'Pages', field)
  return { canReadPage }
}
