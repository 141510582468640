import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import './Reports.scss'

import { RootState } from '../../ducks'
import { fetchDNIShareTableReport } from '../../ducks/reports'

import { ReportWithList } from './ReportWithList'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DNIShareTableReport: FunctionComponent = () => {
  useAuthRedirect([ACCESS_PAGES.DNI_REPORTS, ACCESS_PAGES.SKA_REPORTS])
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DNIShareTableReport
  )

  return (
    <ReportWithList
      reportAPIName='DNIShareTableReport'
      reportName='Raport-udziałów.xlsx'
      reduxAction={fetchDNIShareTableReport}
      isLoading={isLoading}
    />
  )
}

export default DNIShareTableReport
