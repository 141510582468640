import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import FileService, {
  ContractFileLogs,
  GetFilesChangesByContractPayload,
  IFile,
  IFileInput,
  ISaveFileInput,
} from '../services/fileService'
import moment from 'moment'
import { DATE_FORMAT_YYYYMMDD } from '../constants/dates'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface IFilesState {
  files: IFile[]
  contractFiles: IFile[]
  downloadedFile: IFile
  contractFileChanges: ContractFileLogs[]
  isLoading: boolean
  isUploading: boolean
}

const initialState = {
  files: [],
  isLoading: false,
  isUploading: false,
  contractFiles: [],
  contractFileChanges: [],
  downloadedFile: {
    id: 0,
    category: '',
    content: '',
    contractID: 0,
    created: '',
    description: '',
    length: 0,
    name: '',
  },
} as IFilesState

export const saveUserFile = createAsyncThunk(
  'files/addFile',
  async (props: ISaveFileInput, thunkAPI) => {
    const { event, category, contractID, description } = props
    const today = moment().format(DATE_FORMAT_YYYYMMDD)
    const { name, size } = event.target.files[0]
    const reader = new FileReader()

    reader.onload = (): any => {
      const fileBas64 = (reader?.result as string) || ''
      const dto = {
        contractID: contractID,
        name,
        length: size,
        category,
        description,
        content: fileBas64,
        created: today,
      } as IFileInput

      return FileService.saveFile(dto)
    }
    reader.onerror = (error): void => {
      alert(`Błąd przy wysyłaniu pliku\n${error.toString()}`)
    }
    reader.readAsDataURL(event.target.files[0])
  }
)

export const getAllFiles = createAsyncThunk('files/getAllFiles', async () => {
  return FileService.fetchAllFiles()
})

export const getFilesChangesByContract = createAsyncThunk(
  'files/getFilesChangesByContract',
  async (contractID: number) => {
    return FileService.getFilesChangesByContract(contractID)
  }
)

export const saveFile = createAsyncThunk(
  'files/saveFile',
  async (props: any) => {
    return FileService.saveFile(props)
  }
)

export const getFileContentByIDSavedByUser = createAsyncThunk(
  'files/getFile',
  async (fileID: number) => {
    const result = await FileService.fetchFileContentByFileID(fileID)

    if (result && result.file) {
      FileService.downloadFileSavedByUser({
        content: result.file.content,
        filename: result.file.name,
      })
    }
  }
)

export const getArrangementFileContentByID = createAsyncThunk(
  'files/getArrangementFile',
  async (arrangementFileID: number) => {
    const result = await FileService.fetchArrangementFileContentByArrangementFileID(
      arrangementFileID
    )
    if (result && result.arrangementFile) {
      FileService.downloadFileSavedByUser({
        content: result.arrangementFile.content,
        filename: result.arrangementFile.name,
      })
    }
  }
)

export const removeFileByID = createAsyncThunk(
  'files/removeFileByID',
  async (fileID: number) => {
    return FileService.deleteFileByID(fileID)
  }
)

export const importPlaces = createAsyncThunk(
  'files/importPlaces',
  async ({
    stageID,
    content,
    isTypeService,
  }: {
    stageID: number
    content: string
    isTypeService: boolean
  }) => {
    return FileService.importPlaces(stageID, content, isTypeService)
  }
)

export const importBuildings = createAsyncThunk(
  'files/importBuildings',
  async ({
    investmentID,
    content,
    isTypeService,
  }: {
    investmentID: number
    content: string
    isTypeService: boolean
  }) => {
    return FileService.importBuildings(investmentID, content, isTypeService)
  }
)

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    clearDownloadFile(state, _: PayloadAction<void>): void {
      state.downloadedFile = initialState.downloadedFile
    },
    clearFilesChanges(state, _: PayloadAction<void>): void {
      state.contractFileChanges = []
    },
  },
  extraReducers: {
    [getFileContentByIDSavedByUser.pending.toString()]: (state): void => {
      state.isLoading = true
    },
    [getFileContentByIDSavedByUser.fulfilled.toString()]: (state): void => {
      state.isLoading = false
    },
    [getFileContentByIDSavedByUser.rejected.toString()]: (state): void => {
      state.isLoading = false
    },
    [getAllFiles.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ): void => {
      if (action.payload && action.payload.files) {
        state.files = action.payload.files
      }
    },
    [getFilesChangesByContract.fulfilled.toString()]: (
      state,
      action: PayloadAction<GetFilesChangesByContractPayload>
    ): void => {
      state.contractFileChanges = action.payload.getFilesChangesByContract
    },
    [removeFileByID.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:removeFile')))
    },
    [removeFileByID.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:removeFileError')))
    },
    [saveFile.fulfilled.toString()]: (state): void => {
      state.isUploading = false
      successToastNotify(String(i18n.t('toast:addFile')))
    },
    [saveFile.pending.toString()]: (state): void => {
      state.isUploading = true
    },
    [saveFile.rejected.toString()]: (state): void => {
      state.isUploading = false
      errorToastNotify(String(i18n.t('toast:addFileError')))
    },
  },
})

export const { clearDownloadFile, clearFilesChanges } = filesSlice.actions
export default filesSlice.reducer
