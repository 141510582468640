import React, { FunctionComponent, useState } from 'react'
import { AppDispatch } from '../../../ducks'
import { useDispatch } from 'react-redux'
import { updateIsYslabAvailableInPlace } from '../../../ducks/places'

interface ISimpleInputCheckboxProps {
  name: number
  value: any
}

export const YslabCheckbox: FunctionComponent<ISimpleInputCheckboxProps> = ({
  name,
  value = (): void => {},
}) => {
  const [checked, setChecked] = useState(value)
  const dispatch: AppDispatch = useDispatch()
  const handleChange = React.useCallback(
    async (e: any) => {
      const response = await dispatch(
        updateIsYslabAvailableInPlace({
          isYslabAvailable: e.target.checked,
          placeID: name,
        })
      )
      setChecked(response.meta.arg.isYslabAvailable || false)
    },
    [dispatch, name]
  )

  return (
    <div className='d-flex align-items-center justify-content-center'>
      <input
        name={`YsLab${name}`}
        checked={checked}
        onChange={handleChange}
        className='simple-input-checkbox'
        type='checkbox'
      />
    </div>
  )
}

export default YslabCheckbox
