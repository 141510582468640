import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'
import { b64toBlob } from '../utils/b64toBlob'
import printJS from 'print-js'

export interface IFile {
  id: number
  contractID: number
  name: string
  length: number
  category: string
  created: string
  description: string
  content: string
}

export interface IDownloadFileProps {
  filename: string
  content: string
}

export interface IOpenBase64PdfFileProps {
  content: string
}

export interface ISaveFileInput {
  event: any
  contractID: number
  category: string
  description: string
}

export interface IFileInput {
  contractID: number
  name: string
  length: number
  category: string
  created: string
  description: string
  content: string
}
export interface ContractFileLogs {
  id: number
  opID: string
  author: string
  date: string
}

export interface GetFilesChangesByContractPayload {
  getFilesChangesByContract: ContractFileLogs[]
}

const saveFile = (file: IFileInput): Promise<any> => {
  const SAVE_FILE_FOR_CONTRACT = gql`
    mutation saveFile($file: FileInput!) {
      saveFile(file: $file) {
        id
        name
        length
        category
        contractID
        created
        description
      }
    }
  `

  return GraphqlService.send(SAVE_FILE_FOR_CONTRACT, { file })
}

export const fetchAllFiles = (): Promise<any> => {
  const FETCH_ALL_FILES = gql`
    query {
      files {
        id
        name
        length
        category
        content
        created
        contractID
        description
      }
    }
  `

  return GraphqlService.send(FETCH_ALL_FILES, {})
}

const fetchArrangementFileContentByArrangementFileID = (
  arrangementFileID: number
): Promise<any> => {
  const FETCH_FILE_CONTENT_BY_ID = gql`
    query af($arrangementFileID: Float!) {
      arrangementFile(arrangementFileID: $arrangementFileID) {
        id
        name
        content
      }
    }
  `

  return GraphqlService.send(FETCH_FILE_CONTENT_BY_ID, { arrangementFileID })
}

const fetchFileContentByFileID = (fileID: number): Promise<any> => {
  const FETCH_FILE_CONTENT_BY_ID = gql`
    query ffcbfi($fileID: Float!) {
      file(fileID: $fileID) {
        id
        name
        length
        contractID
        category
        content
        created
        description
      }
    }
  `

  return GraphqlService.send(FETCH_FILE_CONTENT_BY_ID, { fileID })
}

export const deleteFileByID = (fileID: number): Promise<any> => {
  const DELETE_FILE_BY_ID = gql`
    mutation deleteFile($fileID: Float!) {
      deleteFile(fileID: $fileID)
    }
  `

  return GraphqlService.send(DELETE_FILE_BY_ID, { fileID })
}

const importPlaces = (
  stageID: number,
  content: string,
  isTypeService: boolean
): Promise<any> => {
  const IMPORT_PLACES = gql`
    mutation($stageID: Float!, $content: String!, $isTypeService: Boolean!) {
      importPlaces(
        stageID: $stageID
        content: $content
        isTypeService: $isTypeService
      ) {
        notExistingBuildings
        notImportedPlaces
        importSuccess
      }
    }
  `
  return GraphqlService.send(IMPORT_PLACES, { stageID, content, isTypeService })
}

const importBuildings = (
  investmentID: number,
  content: string,
  isTypeService: boolean
): Promise<any> => {
  const IMPORT_BUILDINGS = gql`
    mutation(
      $investmentID: Float!
      $content: String!
      $isTypeService: Boolean!
    ) {
      importBuildings(
        investmentID: $investmentID
        content: $content
        isTypeService: $isTypeService
      ) {
        notExistingStages
        notImportedBuildings
        importSuccess
      }
    }
  `
  return GraphqlService.send(IMPORT_BUILDINGS, {
    investmentID,
    content,
    isTypeService,
  })
}

const getFilesChangesByContract = (
  contractID: number
): Promise<GetFilesChangesByContractPayload> => {
  const FILES_CHANGES = gql`
    query($contractID: Float!) {
      getFilesChangesByContract(contractID: $contractID) {
        id
        opID
        author
        date
      }
    }
  `
  return GraphqlService.send(FILES_CHANGES, { contractID })
}

const downloadFile = ({ filename, content }: IDownloadFileProps): void => {
  const url = window.URL.createObjectURL(new Blob([content]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

const downloadFileSavedByUser = ({
  filename,
  content,
}: IDownloadFileProps): void => {
  const base64File = content.split('base64,')?.[1]
  const blob = b64toBlob(base64File, 'application/zip')
  const elem = document.createElement('a')
  document.body.appendChild(elem)
  elem.target = '_self'
  elem.href = URL.createObjectURL(blob)
  elem.download = filename
  elem.click()
  URL.revokeObjectURL(elem.href)
  document.body.removeChild(elem)
}

const downloadXlsxReport = (content: string, filename: string): void => {
  const byteCharacters = atob(content)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays)
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

const openPdfFileSavedByUser = ({ content }: IOpenBase64PdfFileProps): void => {
  const pdfContentString = content?.includes('data:application/pdf;base64,')
    ? content?.replace('data:application/pdf;base64,', '')
    : content
  const binaryString = window.atob(pdfContentString)
  const emptyBytesArray = new Uint8Array(binaryString.length)
  const bytes = emptyBytesArray.map((_, index) =>
    binaryString.charCodeAt(index)
  )

  const blob = new Blob([bytes], { type: 'application/pdf' })
  window.open(URL.createObjectURL(blob), '_blank')
}

const openBase64PdfFile = ({ content }: IOpenBase64PdfFileProps): void => {
  const url = window.URL.createObjectURL(
    new Blob([content], { type: 'application/pdf' })
  )
  window.open(url, '_blank')
}
const printBase64PdfFile = ({ content }: IOpenBase64PdfFileProps): void => {
  const url = window.URL.createObjectURL(
    new Blob([content], { type: 'application/pdf' })
  )
  printJS(url)
}

const actions = {
  getFilesChangesByContract,
  saveFile,
  fetchAllFiles,
  fetchArrangementFileContentByArrangementFileID,
  fetchFileContentByFileID,
  deleteFileByID,
  downloadFile,
  downloadFileSavedByUser,
  openBase64PdfFile,
  openPdfFileSavedByUser,
  downloadXlsxReport,
  importPlaces,
  importBuildings,
  printBase64PdfFile,
}

export default actions
