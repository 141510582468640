import React from 'react'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import './style.css'

const TooltipWrapper = ({ message, children }: any) => (
  <Tooltip
    placement='top'
    overlay={message}
    overlayStyle={{ maxWidth: '1000px' }}
  >
    {children ? children : <></>}
  </Tooltip>
)

export default TooltipWrapper
