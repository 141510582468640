import * as React from 'react'
import createProposedInvestmentObject from '../../utils/createProposedInvestmentObject'

export interface IProposedInvestment {
  name: string
  investmentID?: number | null
  placeID?: number | null
  stageID?: number | null
}

export interface IProposedItem {
  name: string
  id: number
}

const deafultOption = {
  id: -1,
  name: '',
}

export const useProposedInvestment = (): any => {
  const [proposedInvestments, setProposedInvestments] = React.useState<
    IProposedInvestment[]
  >([])
  const [investment, setInvestment] =
    React.useState<IProposedItem>(deafultOption)
  const [stage, setStage] = React.useState<IProposedItem>(deafultOption)
  const [place, setPlace] = React.useState<IProposedItem>(deafultOption)

  const onChangeInvestmentValue = React.useCallback(
    (selectedInvestment: IProposedItem): void => {
      setInvestment(selectedInvestment)
      setStage(deafultOption)
      setPlace(deafultOption)
    },
    []
  )
  const onChangeStageValue = React.useCallback(
    (selectedStage: IProposedItem): void => {
      setStage(selectedStage)
      setPlace(deafultOption)
    },
    []
  )
  const onChangePlacesValue = React.useCallback(
    (selectedPlace: IProposedItem): void => {
      setPlace(selectedPlace)
    },
    []
  )

  const isAlreadyProposed = React.useCallback(
    (name: string) => {
      const matchedProposedItems = proposedInvestments.filter(
        (proposed) => proposed.name === name
      )
      return !!matchedProposedItems.length
    },
    [proposedInvestments]
  )

  const addInitialProposedInvestments = (
    proposedInvestmentObjects: IProposedInvestment[]
  ) => {
    if (proposedInvestmentObjects) {
      setProposedInvestments((prev) => [...prev, ...proposedInvestmentObjects])
    }
  }

  const addProposedInvestment = React.useCallback(() => {
    const proposedInvestmentObject = createProposedInvestmentObject(
      investment,
      stage,
      place
    )
    if (isAlreadyProposed(proposedInvestmentObject.name)) {
      return
    }
    setProposedInvestments((prev) => [...prev, proposedInvestmentObject])
  }, [investment, isAlreadyProposed, place, stage])

  const removeProposedItem = React.useCallback(
    (index: number) => {
      const proposedCopy = [...proposedInvestments]
      proposedCopy.splice(index, 1)
      setProposedInvestments(proposedCopy)
    },
    [proposedInvestments]
  )

  return {
    state: {
      proposedInvestments,
      stage,
      investment,
      place,
    },
    setters: {
      removeProposedItem,
      onChangeStageValue,
      onChangePlacesValue,
      onChangeInvestmentValue,
      addProposedInvestment,
      addInitialProposedInvestments,
    },
  }
}
