import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'

export type GenericProps = {
  sortOrder?: boolean
  sortField?: string
  perPage?: number
  page?: number
}

const fetchTimeToServeTaken = (): Promise<void> => {
  const QUERY = gql`
    query {
      timeToServeTaken
    }
  `
  return GraphqlService.send(QUERY, {})
}
const fetchTimeToServeNotTaken = (): Promise<void> => {
  const QUERY = gql`
    query {
      timeToServeNotTaken
    }
  `
  return GraphqlService.send(QUERY, {})
}
const fetchTimeToSecondContactWithCustomer = (): Promise<void> => {
  const QUERY = gql`
    query {
      timeToSecondContactWithCustomer
    }
  `
  return GraphqlService.send(QUERY, {})
}

const setTimeToServeTaken = (value: number): Promise<void> => {
  const MUTATION = gql`
    mutation setTimeToServeTaken($value: Float!) {
      setTimeToServeTaken(value: $value)
    }
  `
  return GraphqlService.send(MUTATION, { value })
}

const setTimeToServeNotTaken = (value: number): Promise<void> => {
  const MUTATION = gql`
    mutation setTimeToServeNotTaken($value: Float!) {
      setTimeToServeNotTaken(value: $value)
    }
  `
  return GraphqlService.send(MUTATION, { value })
}

const setTimeToSecondContactWithCustomer = (value: number): Promise<void> => {
  const MUTATION = gql`
    mutation setTimeToSecondContactWithCustomer($value: Float!) {
      setTimeToSecondContactWithCustomer(value: $value)
    }
  `
  return GraphqlService.send(MUTATION, { value })
}

const fetchBannedEmails = (arg: GenericProps): Promise<void> => {
  const QUERY = gql`
    query bannedMails(
      $sortOrder: Boolean
      $sortField: String
      $perPage: Float
      $page: Float
    ) {
      bannedMails(
        sortOrder: $sortOrder
        sortField: $sortField
        perPage: $perPage
        page: $page
      ) {
        total
        bannedMails {
          id
          email
          isBanned
        }
      }
    }
  `
  return GraphqlService.send(QUERY, {
    sortOrder: arg?.sortOrder,
    sortField: arg?.sortField,
    perPage: arg?.perPage,
    page: arg?.page,
  })
}

const banEmail = (email: string): Promise<void> => {
  const MUTATION = gql`
    mutation saveBannedMail($bannedMail: BannedMailInput!) {
      saveBannedMail(bannedMail: $bannedMail) {
        email
      }
    }
  `
  return GraphqlService.send(MUTATION, { bannedMail: { email } })
}

const removeBan = (bannedMailID: number): Promise<void> => {
  const MUTATION = gql`
    mutation deleteBannedMail($bannedMailID: Float!) {
      deleteBannedMail(bannedMailID: $bannedMailID)
    }
  `
  return GraphqlService.send(MUTATION, { bannedMailID })
}

const mutations = {
  fetchTimeToServeTaken,
  fetchTimeToServeNotTaken,
  fetchTimeToSecondContactWithCustomer,
  setTimeToServeTaken,
  setTimeToServeNotTaken,
  setTimeToSecondContactWithCustomer,
  fetchBannedEmails,
  banEmail,
  removeBan,
}
export default mutations
