import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FC, useCallback } from 'react'

import {
  refetchPagination,
  setStraightAfterFilter,
} from '../../commons/Table2/paginationSlice'

import './Table.scss'

export interface TableSearchWithPaginationProps {
  defaultValue?: string
  isLoading: boolean
  query: string
  setQuery: (value: string) => void
  refreshPage: (value: boolean) => void
}

const TableSearchWithPagination: FC<TableSearchWithPaginationProps> = ({
  defaultValue,
  isLoading,
  query,
  setQuery,
  refreshPage,
}: TableSearchWithPaginationProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [phrase, setPhrase] = React.useState(defaultValue ?? query)

  const onButtonClick = React.useCallback(() => {
    if (phrase) {
      dispatch(setQuery(phrase))
      dispatch(refreshPage(true))
      dispatch(refetchPagination(true))
      dispatch(setStraightAfterFilter(true))
    }
  }, [dispatch, phrase, refreshPage, setQuery])

  const onEnter = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && phrase && phrase !== '') {
        dispatch(setQuery(phrase))
        dispatch(refreshPage(true))
        dispatch(refetchPagination(true))
        dispatch(setStraightAfterFilter(true))
      }
    },
    [dispatch, phrase, refreshPage, setQuery]
  )

  const clearQuery = useCallback(() => {
    dispatch(setQuery(''))
    setPhrase('')
    dispatch(refreshPage(true))
    dispatch(refetchPagination(true))
    dispatch(setStraightAfterFilter(true))
  }, [dispatch, refreshPage, setQuery])

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPhrase(e.target.value)
    },
    []
  )

  return (
    <Col
      className='d-flex justify-content-end align-items-center'
      lg='auto'
      md='auto'
      sm='auto'
    >
      <InputGroup>
        <Form.Control
          className='searchPadding'
          disabled={isLoading}
          onKeyDown={onEnter}
          placeholder={`${
            isLoading
              ? t('commons:actions.uploading-in-progress')
              : `${t('commons:labels.type-searched-keyword')}...`
          }`}
          value={phrase}
          onChange={onChangeHandler}
        />
        <div>
          <Button
            className='searchButton'
            type='submit'
            onClick={onButtonClick}
          >
            <FontAwesomeIcon icon={faSearch} className='searchIcon' />
          </Button>
          <Button
            className='searchButton'
            type='button'
            style={{ marginLeft: '5px' }}
            onClick={clearQuery}
          >
            <span className='searchIcon'>X</span>
          </Button>
        </div>
      </InputGroup>
    </Col>
  )
}

export default TableSearchWithPagination
