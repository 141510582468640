import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDSPBlueListReport } from '../../ducks/reports'
import Report from './Report'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const BlueListSalesReport: FunctionComponent = () => {
  useAuthRedirect([ACCESS_PAGES.DSP_AND_DSPADM_REPORTS])
  const { DSPBlueListReport } = useSelector((state: RootState) => state.reports)

  return (
    <Report
      reportData={DSPBlueListReport}
      reportName='Raport-Błękitna lista.xlsx'
      reduxAction={fetchDSPBlueListReport}
    />
  )
}

export default BlueListSalesReport
