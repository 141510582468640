const ContactContent = [
  ' ',
  'Prezentacja oferty',
  'Uszczegółowienie oferty',
  'Wizyta na terenie inwestycji',
  'Negocjacje warunków umowy',
  'Rezerwacja',
  'Przypomnienie',
  'Zapytanie',
]

export default ContactContent
