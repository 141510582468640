import { IListItem } from '../interfaces/commons'

const infoSourcesIDName: IListItem[] = [
  { id: 0, name: ' ' },
  { id: 1, name: 'Baner na portalu internetowym' },
  { id: 2, name: 'Biuro sprzedaży' },
  { id: 3, name: 'Dni Otwarte' },
  { id: 4, name: 'Film reklamowy na portalu YouTube' },
  { id: 5, name: 'Klient wcześniejszej inwestycji' },
  { id: 6, name: 'Polecenie od znajomych' },
  { id: 7, name: 'Pośrednicy' },
  {
    id: 8,
    name:
      'Reklama na Facebooku, Instagramie lub innych mediach społecznościowych',
  },
  {
    id: 9,
    name: 'Reklama na miejscu budowy (na ogrodzeniu, na żurawiu, flagach)',
  },
  { id: 10, name: 'Reklama outdoorowa (tablice, siatki wielkoformatowe)' },
  { id: 11, name: 'Reklama podczas eventu (sportowego, kulturalnego)' },
  { id: 12, name: 'Reklama w poczcie e-mail (e-Mailing)' },
  { id: 13, name: 'Reklama w prasie' },
  { id: 14, name: 'Reklama w radio' },
  { id: 15, name: 'Reklama w TV' },
  { id: 16, name: 'Spacer w okolicy gotowej inwestycji' },
  { id: 17, name: 'Strona www.investkomfort.pl (wejście bezpośrednie)' },
  { id: 18, name: 'Targi mieszkaniowe' },
  { id: 19, name: 'Wyszukiwarka Google' },
  { id: 20, name: 'Portale o nieruchomościach' },
  { id: 21, name: 'Inne' },
]

export default infoSourcesIDName
