import React, { FC, HTMLAttributes } from 'react'
import Button, { ButtonProps } from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'

interface IMainButtonProps
  extends ButtonProps,
    HTMLAttributes<HTMLButtonElement> {
  customWidth?: number
  isUploading?: boolean
}

const MainButton: FC<IMainButtonProps> = ({
  children,
  customWidth,
  disabled,
  isUploading,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Button
      disabled={disabled || isUploading}
      style={{
        minWidth: customWidth ? `${customWidth}rem` : '8rem',
      }}
      type='submit'
      {...rest}
    >
      {isUploading ? t('commons:actions.uploading-in-progress') : children}
    </Button>
  )
}

export default MainButton
