const TypeOfContact = [
  ' ',
  'Korespondencja',
  'Od pośrednika',
  'Spotkanie',
  'Telefon',
  'Email od klienta',
]

export default TypeOfContact
