import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchInvestmentsWithStagesOnly } from '../../ducks/globalInvestment'
import {
  clearAdminPlaces,
  getPlacesByInvestmentsNamesForSelectList,
} from '../../ducks/places'
import createProposedInvestmentObject from '../../utils/createProposedInvestmentObject'

interface IProposedItem {
  id: number
  name: string
}

interface IUseDataForProposedInputs {
  proposedState: {
    stage: IProposedItem
    investment: IProposedItem
    place: IProposedItem
  }
  initialProposedInvestments?: {
    investmentID: number
    stageID: number
    placeID: number
  }[]
}
export const useDataForProposedInputs = ({
  proposedState,
  initialProposedInvestments,
}: IUseDataForProposedInputs): any => {
  const { adminPlaces } = useSelector((state: RootState) => state.places)
  const { investmentsList } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const dispatch = useDispatch()

  const investments = !!investmentsList ? investmentsList : []
  const investmentsWithFirstPositionEmpty = React.useMemo(() => {
    const excludedInvestmentNames = ['Wszystkie', 'Umowy anulowane']
    const notArchivedInvestments = investments.filter(
      (investment) =>
        !investment.isArchive &&
        !excludedInvestmentNames.includes(investment.name)
    )
    return [{ id: -1, name: '', stages: [] }, ...notArchivedInvestments]
  }, [investments])

  const getPlacesByChosenStage = (stageId: number) => {
    const places = adminPlaces.filter((place) => place.stage.id === stageId)
    const placesForList =
      places.map((place) => {
        return {
          name: place.placeCode,
          id: place.id,
        }
      }) || []
    return [{ id: -1, name: '' }, ...placesForList]
  }
  const placesByChosenStage = React.useMemo(
    () => getPlacesByChosenStage(proposedState.stage?.id),
    [adminPlaces, proposedState.stage]
  )

  const getStagesByInvestment = (investmentId: number) => {
    const investment = investmentsWithFirstPositionEmpty.filter(
      (inv) => inv.id === investmentId
    )
    const stages = investment?.[0]?.stages || []
    return [{ id: -1, name: '' }, ...stages]
  }
  const stagesByInvestment = React.useMemo(
    () => getStagesByInvestment(proposedState.investment?.id),
    [investmentsWithFirstPositionEmpty, proposedState.investment]
  )

  const getProposedInvestmentObjectsFromInitial = () =>
    initialProposedInvestments
      ?.map((iPI) => {
        const investment = investments.find((i) => i.id === iPI.investmentID)
        const stages = getStagesByInvestment(iPI.investmentID)
        const stage = stages?.find((s) => s.id === iPI.stageID)
        const places = getPlacesByChosenStage(iPI.stageID)
        const place = places?.find((p) => p.id === iPI.placeID)

        return investment && stage && place
          ? createProposedInvestmentObject(investment, stage, place)
          : undefined
      })
      .filter((i) => i)

  React.useEffect(() => {
    dispatch(fetchInvestmentsWithStagesOnly())
  }, [dispatch])

  React.useEffect((): (() => void) => {
    const investmentNames =
      initialProposedInvestments
        ?.map(
          (iPI) =>
            investments?.find((i) => i.id === iPI.investmentID)?.name || ''
        )
        .filter((i) => i) || []
    dispatch(
      getPlacesByInvestmentsNamesForSelectList([
        proposedState.investment?.name || investments[0]?.name,
        ...investmentNames,
      ])
    )
    return () => dispatch(clearAdminPlaces())
  }, [
    dispatch,
    investments,
    proposedState.investment,
    initialProposedInvestments,
  ])

  return {
    stagesByInvestment,
    placesByChosenStage,
    investmentsWithFirstPositionEmpty,
    getProposedInvestmentObjectsFromInitial,
  }
}
