import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import PlacesServices, {
  IAdminPlace,
  IGroundPlanPlaceInput,
  IPlaceInput,
  IUpdateGroundPlan,
  PlacesByInvestmentsNamesForAdminPanel,
} from '../services/placesService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'
import { IPlaceContractsQuery } from '../graphql/currentPlaceContracts/currentPlaceContracts'
import { IInterestedCustomer } from '../graphql/customers'
import { RootState } from '.'
import ContractCustomerService from '../services/contractCustomerService'
import { IContractCustomerInput } from '../graphql/contractCustomers'
import { defaultMarketingConsents } from './marketingConsents'
import { IMarketingConsentInput } from '../graphql/marketingConsents'

export type PlaceArea = {
  designedUsablePropertyAreas: number
  designedInnerPropertyAreas: number
  asBuildUsablePropertyAreas: number
  asBuildInnerPropertyAreas: number
  designedUsableUtilRoomAreas: number
  designedInnerUtilRoomAreas: number
  asBuildUsableUtilRoomAreas: number
  asBuildInnerUtilRoomAreas: number
}

interface IPlacesState {
  adminPlaces: IAdminPlace[]
  isLoadingContractOnPlace: boolean
  isPlaceGroundPlanUploading: boolean
  isPlaceLoading: boolean
  selectedPlace: Partial<IPlaceContractsQuery> | undefined
  interestedCustomers: IInterestedCustomer[]
  selectedInterestedCustomerID: number | undefined
  placesAreas: PlaceArea
  isLoading: boolean
  isLoadingPlace: boolean
  paginatedAdminPlaces: {
    places: IAdminPlace[]
    total: number
  }
}

const initialPlaceAreas = {
  designedUsablePropertyAreas: 0,
  designedInnerPropertyAreas: 0,
  asBuildUsablePropertyAreas: 0,
  asBuildInnerPropertyAreas: 0,
  designedUsableUtilRoomAreas: 0,
  designedInnerUtilRoomAreas: 0,
  asBuildUsableUtilRoomAreas: 0,
  asBuildInnerUtilRoomAreas: 0,
}

const initialState: IPlacesState = {
  adminPlaces: [],
  isLoadingContractOnPlace: false,
  isPlaceGroundPlanUploading: false,
  isPlaceLoading: false,
  interestedCustomers: [],
  selectedPlace: undefined,
  selectedInterestedCustomerID: undefined,
  isLoading: false,
  isLoadingPlace: false,
  placesAreas: initialPlaceAreas,
  paginatedAdminPlaces: {
    places: [],
    total: 0,
  },
}

export const getPlacesByInvestmentsNamesForAdminPanel = createAsyncThunk(
  'places/getPlacesByInvestmentsNamesForAdminPanel',
  async (investmentNames: string[]) => {
    return PlacesServices.getPlacesByInvestmentsNamesForAdminPanel(
      investmentNames
    )
  }
)

export const getPlacesByInvestmentsNamesForSelectList = createAsyncThunk(
  'places/getPlacesByInvestmentsNamesForSelectList',
  async (investmentNames: string[]) => {
    return PlacesServices.getPlacesByInvestmentsNamesForSelectList(
      investmentNames
    )
  }
)

export const getPaginatedPlacesByInvestmentsNamesForAdminPanel = createAsyncThunk(
  'places/getPaginatedPlacesByInvestmentsNamesForAdminPanel',
  async (variables: PlacesByInvestmentsNamesForAdminPanel) => {
    return PlacesServices.getPaginatedPlacesByInvestmentsNamesForAdminPanel(
      variables
    )
  }
)

export const getPlacesByInvestmentStageId = createAsyncThunk(
  'places/getPlacesByInvestmentStageId',
  async (stageID: number) => {
    return PlacesServices.getPlacesByInvestmentStageId(stageID)
  }
)

export const addNewPlace = createAsyncThunk(
  'places/addPlace',
  async (place: IPlaceInput) => {
    return PlacesServices.addPlace(place)
  }
)

export const updatePlace = createAsyncThunk(
  'places/updatePlace',
  async (variables: { place: IPlaceInput; placeID: number }) => {
    return PlacesServices.updatePlace(variables)
  }
)

export const updateIsYslabAvailableInPlace = createAsyncThunk(
  'places/updateIsYslabAvailableInPlace',
  async (variables: { isYslabAvailable: boolean; placeID: number }) => {
    return PlacesServices.updateIsYslabAvailableInPlace(variables)
  }
)

export const deletePlace = createAsyncThunk(
  'places/deletePlaces',
  async (placeID: number) => {
    return PlacesServices.deletePlace(placeID)
  }
)

export const addGroundPlan = createAsyncThunk(
  'places/addGroundPlan',
  async (file: IGroundPlanPlaceInput) => {
    return PlacesServices.saveGroundPlanIntoPlace(file)
  }
)

export const updateGroundPlan = createAsyncThunk(
  'places/updateGroundPlan',
  async (parameters: IUpdateGroundPlan) => {
    return PlacesServices.editGroundPlane(parameters)
  }
)

export const deleteGroundPlan = createAsyncThunk(
  'places/deleteGroundPlan',
  async (fileID: number) => {
    return PlacesServices.deleteGroundPlan(fileID)
  }
)

export const fetchPlaceByPlaceID = createAsyncThunk(
  'places/fetchPlaceByPlaceID',
  async (placeID: number) => {
    return PlacesServices.fetchPlaceByPlaceID(placeID)
  }
)

export const fetchPlaceByPlaceIDForArragements = createAsyncThunk(
  'places/fetchPlaceByPlaceIDForArragements',
  async (placeID: number) => {
    return PlacesServices.fetchPlaceByPlaceIDForArragements(placeID)
  }
)

export const fetchCancelledContractPlaceByPlaceID = createAsyncThunk(
  'places/fetchCancelledContractPlaceByPlaceID',
  async (placeID: number) => {
    return PlacesServices.fetchCancelledContractPlaceByPlaceID(placeID)
  }
)

export const updatePlaceRelatedToContract = createAsyncThunk(
  'places/updatePlaceRelatedToContract',
  async (variables: any) => {
    return PlacesServices.updatePlaceRelatedToContract(variables)
  }
)

export const getSumsOfAreasInInvestments = createAsyncThunk(
  'places/fetchSumsOfAreasInInvestments',
  async (variables: any) => {
    return PlacesServices.fetchSumsOfAreasInInvestments(variables)
  }
)

export const updateContractCustomerWithImportedInterestedCustomer = createAsyncThunk(
  'places/updateContract',
  async (customer: IContractCustomerInput, thunkAPI) => {
    const activeContracts = (thunkAPI.getState() as RootState).places
      .selectedPlace?.activeContracts
    const selectedInterestedCustomer = (thunkAPI.getState() as RootState)
      .interestedCustomers.selectedInterestedCustomer

    if (selectedInterestedCustomer && activeContracts) {
      const contractCustomersList = [...activeContracts[0].contractCustomers]

      if (selectedInterestedCustomer) {
        const idExists = contractCustomersList
          ?.filter((c) => c.id > 0)
          .find(
            (c) =>
              c.email === selectedInterestedCustomer.email &&
              c.firstName === selectedInterestedCustomer.firstName &&
              c.lastName === selectedInterestedCustomer.lastName
          )
        const c = selectedInterestedCustomer

        if (idExists) {
          alert('Wybrany klient zainteresowany został już dodany do umowy')
          return
        }

        const newContractCustomer = {
          uuid: customer.uuid,
          parentUuid: customer.parentUuid,
          customerID: c.id ?? undefined,
          customerParentID: customer?.customerParentID ?? undefined,
          customerCode: customer.customerCode ?? '',
          customerType: c.customerType ?? '',
          firstName: c.firstName ?? '',
          lastName: c.lastName ?? '',
          secondName: c.secondName ?? '',
          maidenName: c.maidenName ?? '',
          parentsNames: c.parentsNames ?? '',
          pesel: c.pesel ?? '',
          taxNumber: c.taxNumber ?? '',
          sex: c.sex ?? '',
          phone1: c.phone1 ?? '',
          phone2: c.phone2 ?? '',
          email: c.email ?? '',
          email2: c.email2 ?? '',
          passportNumberAndSeries: c.passportNumberAndSeries ?? '',
          IDNumberAndSeries: c.IDNumberAndSeries ?? '',
          citizenship: c.citizenship ?? '',
          isBusiness: Boolean(c.isBusiness) || false,
          regon: c.regon ?? '',
          businessAddressName: c.businessAddressName ?? '',
          businessAddressSurname: c.businessAddressSurname ?? '',
          businessAddressBusinessName: c.businessAddressBusinessName ?? '',
          businessAddressCountry: c.businessAddressCountry ?? '',
          businessAddressCity: c.businessAddressCity ?? '',
          businessAddressStreet: c.businessAddressStreet ?? '',
          businessAddressNumber: c.businessAddressNumber ?? '',
          businessAddressPostalCode: c.businessAddressPostalCode ?? '',
          businessAddressVoivodeship: c.businessAddressVoivodeship ?? '',
          businessAddressIsForeign: c.businessAddressIsForeign ?? false,
          residenceName: c.residenceName ?? '',
          residenceSurname: c.residenceSurname ?? '',
          residenceBusinessName: c.residenceBusinessName ?? '',
          residenceCountry: c.residenceCountry ?? '',
          residenceCity: c.residenceCity ?? '',
          residenceStreet: c.residenceStreet ?? '',
          residenceNumber: c.residenceNumber ?? '',
          residencePostalCode: c.residencePostalCode ?? '',
          residenceVoivodeship: c.residenceVoivodeship ?? '',
          residenceIsForeign: c.residenceIsForeign ?? false,
          correspondenceName: c.correspondenceName ?? '',
          correspondenceSurname: c.correspondenceSurname ?? '',
          correspondenceBusinessName: c.correspondenceBusinessName ?? '',
          correspondenceCountry: c.correspondenceCountry ?? '',
          correspondenceCity: c.correspondenceCity ?? '',
          correspondenceStreet: c.correspondenceStreet ?? '',
          correspondenceNumber: c.correspondenceNumber ?? '',
          correspondencePostalCode: c.correspondencePostalCode ?? '',
          correspondenceVoivodeship: c.correspondenceVoivodeship ?? '',
          correspondenceIsForeign: c.correspondenceIsForeign ?? false,
          maritalStatus: customer.maritalStatus ?? '',
          contractID: customer.contractID ?? undefined,
          customerInvestmentCode: customer.customerInvestmentCode ?? '',
          isToughCustomer: c.isToughCustomer ?? false,
          isBlueList: c.isBlueList ?? false,
          isSpouseOfMainCustomer: customer.isSpouseOfMainCustomer ?? false,
          isAgentOfMainCustomer: customer.isAgentOfMainCustomer ?? false,
          isAgentOfCustomerSpouse: customer.isAgentOfCustomerSpouse ?? false,
          purchasePurpose: customer.purchasePurpose ?? '',
          businessType: c.businessType ?? '',
          businessName: c.businessName ?? '',
          sourceInfo: c.sourceInfo ?? '',
          isBroker: c.isBroker ?? false,
          brokerName: c.brokerName ?? '',
        }

        const interestedCustomerMarketingConsents = {
          ...c.marketingConsents[c.marketingConsents.length - 1],
        } as Partial<IMarketingConsentInput>
        delete interestedCustomerMarketingConsents.id

        return ContractCustomerService.updateContractCustomer({
          contractCustomer: newContractCustomer,
          contractCustomerID: customer.id,
          marketingConsent: interestedCustomerMarketingConsents
            ? interestedCustomerMarketingConsents
            : defaultMarketingConsents,
        })
      }
    }
  }
)

const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    setSelectedInterestedCustomerID(
      state,
      action: PayloadAction<number>
    ): void {
      state.selectedInterestedCustomerID = action.payload
    },
    clearSelectedPlaceState(state): void {
      state.selectedPlace = undefined
    },
    clearAdminPlaces(state): void {
      state.adminPlaces = []
    },
  },
  extraReducers: {
    [addNewPlace.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:addPlace')))
    },
    [getSumsOfAreasInInvestments.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.placesAreas = action.payload.sumsOfAreasInInvestments
    },
    [getSumsOfAreasInInvestments.rejected.toString()]: (state): void => {
      state.placesAreas = initialPlaceAreas
    },
    [getPaginatedPlacesByInvestmentsNamesForAdminPanel.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.paginatedAdminPlaces.places =
        action.payload.paginatedPlacesByInvestmentsNamesAndKeyWords.places
      state.paginatedAdminPlaces.total =
        action.payload.paginatedPlacesByInvestmentsNamesAndKeyWords.total
    },
    [getPlacesByInvestmentsNamesForSelectList.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.adminPlaces = action.payload.placesByInvestmentsNames
    },
    [getPlacesByInvestmentsNamesForAdminPanel.pending.toString()]: (
      state
    ): void => {
      state.isLoading = true
    },
    [getPlacesByInvestmentsNamesForAdminPanel.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.adminPlaces = action.payload.placesByInvestmentsNames
      state.isLoading = false
    },
    [getPlacesByInvestmentsNamesForAdminPanel.rejected.toString()]: (
      state
    ): void => {
      state.adminPlaces = []
      state.isLoading = true
    },
    [getPlacesByInvestmentStageId.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.adminPlaces = action.payload.placesByInvestmentsNames
    },
    [getPlacesByInvestmentStageId.rejected.toString()]: (state): void => {
      state.adminPlaces = []
    },
    [fetchPlaceByPlaceID.fulfilled.toString()]: (state, action): void => {
      state.selectedPlace = action.payload.place
      state.isPlaceLoading = false
    },
    [fetchPlaceByPlaceID.pending.toString()]: (state): void => {
      state.isPlaceLoading = true
    },
    [fetchPlaceByPlaceID.rejected.toString()]: (state): void => {
      state.selectedPlace = undefined
      state.isPlaceLoading = false
    },
    [fetchPlaceByPlaceIDForArragements.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.selectedPlace = action.payload.place
    },
    [fetchCancelledContractPlaceByPlaceID.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.selectedPlace = action.payload.place
      state.isPlaceLoading = false
    },
    [fetchCancelledContractPlaceByPlaceID.pending.toString()]: (
      state
    ): void => {
      state.isPlaceLoading = true
    },
    [fetchCancelledContractPlaceByPlaceID.rejected.toString()]: (
      state
    ): void => {
      state.selectedPlace = undefined
      state.isPlaceLoading = false
    },
    [deleteGroundPlan.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:deleteGroundPlan')))
    },
    [deleteGroundPlan.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:deleteGroundPlanError')))
    },
    [updateGroundPlan.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editGroundPlan')))
    },
    [updateGroundPlan.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editGroundPlanError')))
    },
    [addNewPlace.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addPlace')))
      state.isLoadingPlace = false
    },
    [addNewPlace.pending.toString()]: (state): void => {
      state.isLoadingPlace = true
    },
    [addNewPlace.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addPlaceError')))
      state.isLoadingPlace = false
    },
    [deletePlace.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:deletePlace')))
      state.isLoadingPlace = false
    },
    [deletePlace.pending.toString()]: (state): void => {
      state.isLoadingPlace = true
    },
    [deletePlace.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:deletePlaceError')))
      state.isLoadingPlace = false
    },
    [updatePlace.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:editPlace')))
      state.isLoadingPlace = false
    },
    [updatePlace.pending.toString()]: (state): void => {
      state.isLoadingPlace = true
    },
    [updatePlace.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:editPlaceError')))
      state.isLoadingPlace = false
    },
    [addGroundPlan.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addGroundPlan')))
      state.isPlaceGroundPlanUploading = false
    },
    [addGroundPlan.pending.toString()]: (state): void => {
      state.isPlaceGroundPlanUploading = true
    },
    [addGroundPlan.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addGroundPlanError')))
      state.isPlaceGroundPlanUploading = false
    },
    [deleteGroundPlan.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:deleteGroundPlan')))
    },
    [deleteGroundPlan.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:deleteGroundPlanError')))
    },
    [updatePlaceRelatedToContract.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:editData')))
      state.isLoadingContractOnPlace = false
    },
    [updatePlaceRelatedToContract.rejected.toString()]: (state): void => {
      state.isLoadingContractOnPlace = true
    },
    [updatePlaceRelatedToContract.rejected.toString()]: (state): void => {
      state.isLoadingContractOnPlace = false
      errorToastNotify(String(i18n.t('toast:editDataError')))
    },
  },
})

export const {
  setSelectedInterestedCustomerID,
  clearSelectedPlaceState,
  clearAdminPlaces,
} = placesSlice.actions

export default placesSlice.reducer
