import moment from 'moment'
import 'moment/locale/pl'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmationModal from '../Modals/ConfirmationModal'
import SimpleInputText from '../Inputs/SimpleInputs/SimpleInputText'
import SimpleInputCheckbox from '../Inputs/SimpleInputs/SimpleInputCheckbox'
import Table from '../Table2/Table'
import './Table.scss'
import { DATE_FORMAT_YYYYMMDD } from '../../../constants/dates'
import { IInterestedCustomer } from '../../../graphql/customers'
import { INote } from '../../../graphql/notes/notes'

import { AppDispatch, RootState } from '../../../ducks'
import {
  addUserNote,
  clearNotes,
  deleteUserNote,
  updateUserNote,
} from '../../../ducks/reminder'
import SimpleInputDate3 from '../Inputs/SimpleInputs/SimpleInputDate3'
import { StorageKeys } from '../../../hooks/useBrowserStorage'

import { getProperDateForTable } from '../../../utils/getProperDate'
import ButtonWithTooltip from '../Inputs/ButtonTooltip'
import { useUserAbility } from '../../../hooks/ability'

interface INotesTableProps<Notes> {
  canEdit: boolean
  customer?: IInterestedCustomer
  tasksComponent?: boolean
  title?: string
  notes: Notes[]
  fetchAction(): void
  contractNotes?: boolean
}

export function NotesTableComponent<Notes>({
  canEdit,
  customer,
  tasksComponent,
  title,
  notes,
  fetchAction,
  contractNotes = false,
}: INotesTableProps<Notes>): JSX.Element {
  const ability = useUserAbility()
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const { tokenParsed } = useSelector((state: RootState) => state.globalUser)
  const { chosenPlacesContract } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const today = moment().format(DATE_FORMAT_YYYYMMDD)

  const currentUserID = tokenParsed?.sub ?? ''
  const [content, setContent] = useState('')
  const [reminderDate, setReminderDate] = useState<string | null>(null)
  const [showAddModal, setShowAddModal] = useState(false)
  const [note, setNote] = useState<INote | null>(null)
  const [checkedMyTasks, setChecked] = useState(
    Boolean(note?.myTasks || tasksComponent)
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const isNoteForOtherUser = Boolean(note && note?.userID !== currentUserID)

  const columnsData = useMemo(
    () => [
      {
        Header: 'Opublikowano',
        accessor: (a: { publicationDate: string }): string => {
          const publicationDate = a?.publicationDate ?? ''
          return getProperDateForTable(publicationDate)
        },
        width: '100',
      },
      {
        Header: 'Treść',
        accessor: 'content',
        width: tasksComponent ? '1500' : '250',
      },
      {
        Header: 'Przypomnienie',
        accessor: (a: { reminderDate: string }): string => {
          const reminderDate = a?.reminderDate ?? ''
          return getProperDateForTable(reminderDate)
        },
        width: '100',
      },
      {
        id: 'myTasks',
        Header: 'Moje Zadania',
        width: '80',
        accessor: (a: { myTasks: boolean }): string => (a.myTasks ? '☑' : '☐'),
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: '30',
      },
    ],
    [tasksComponent]
  )

  const IDofContract = chosenPlacesContract?.activeContracts?.[0]?.id
  const customerID = customer?.id ?? null
  const contractID = contractNotes && IDofContract ? IDofContract : null

  useEffect(() => {
    setChecked(Boolean(note?.myTasks || tasksComponent))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note?.myTasks, tasksComponent])

  const selectedRowCallback = (passedData: INote): void => {
    const sameAsPrevious = passedData.id === note?.id
    if (sameAsPrevious) {
      setNote(null)
      setContent('')
      setReminderDate(null)
    } else {
      setNote(passedData)
      setContent(passedData?.content ?? '')
      setReminderDate(passedData?.reminderDate)
    }
  }

  const handleReminderDateChange = (e: any): void => {
    setReminderDate(
      e.day === null ? ' ' : moment(e.day).format(DATE_FORMAT_YYYYMMDD)
    )
  }

  const generateNoteContent = useCallback((): string => {
    if (customerID) {
      const filledContent = `${content} (${customer?.firstName ?? ''} ${
        customer?.lastName ?? ''
      }${customer?.phone1 ? `, ${customer?.phone1}` : ''}${
        customer?.phone2 ? `, ${customer?.phone2}` : ''
      }${customer?.email ? `, ${customer?.email}` : ''})`
      const editedContent =
        customer &&
        (content.includes(customer?.firstName) ||
          content.includes(customer?.lastName))
          ? content
          : filledContent
      return editedContent
    } else {
      return content
    }
  }, [content, customer, customerID])

  const handleClose = (): void => {
    setShowAddModal(false)
    setShowDeleteModal(false)
    setShowUpdateModal(false)
  }

  const cleanupAction = React.useCallback(() => {
    fetchAction()
    setShowAddModal(false)
    setReminderDate(null)
    setContent('')
    setChecked(!!(note?.myTasks || tasksComponent))
    handleClose()
    setNote(null)
  }, [fetchAction])

  const handleAdd = (e: any): void => {
    e.preventDefault()
    const note = {
      content: generateNoteContent(),
      publicationDate: today,
      reminderDate: reminderDate ?? null,
      myTasks: checkedMyTasks,
      userID: currentUserID,
      customerID,
      contractID,
      isAfterMigration: false,
      traderInitials: null,
    }
    canEdit && dispatch(addUserNote(note)).then(() => cleanupAction())
  }
  const handleDelete = (e: any): void => {
    e.preventDefault()
    if (note?.id) {
      dispatch(deleteUserNote(note.id)).then(() => cleanupAction())
    }
    setShowDeleteModal(false)
  }
  const handleUpdate = (e: any): void => {
    e.preventDefault()
    const variables = {
      note: {
        content: generateNoteContent(),
        publicationDate: note?.publicationDate ?? today,
        reminderDate: reminderDate ?? null,
        myTasks: checkedMyTasks ?? note?.myTasks ?? true,
        userID: note?.userID ?? currentUserID ?? null,
        customerID: note?.customerID ?? null,
        contractID: note?.contractID ?? contractID,
        isAfterMigration: note?.isAfterMigration,
        traderInitials: note?.traderInitials,
      },
      noteID: note?.id || 0,
    }
    if (note?.id && canEdit) {
      dispatch(updateUserNote(variables)).then(() => cleanupAction())
    }
  }

  const handleShowAddNote = (): void => setShowAddModal(true)
  const handleShowDeleteNote = (): void => setShowDeleteModal(true)
  const handleShowUpdateNote = (): void => setShowUpdateModal(true)

  useEffect(() => {
    fetchAction()
    return (): void => {
      dispatch(clearNotes())
    }
  }, [dispatch, customerID, fetchAction])

  const addNoteModalBody = useMemo(
    () => (
      <>
        {!tasksComponent && (
          <SimpleInputCheckbox
            additionalOnChange={(): void => setChecked((prev) => !prev)}
            label={String(t('contract:labels.in-my-tasks'))}
            defaultChecked={false}
          />
        )}
        <SimpleInputDate3
          name='reminderDate'
          label={String(t('contract:actions.reminder-date'))}
          additionalOnChange={handleReminderDateChange}
          defaultValue={reminderDate}
        />
        <SimpleInputText
          onChange={(e: any): void => setContent(e.target.value)}
          label={String(t('contract:actions.content'))}
        />
      </>
    ),
    [reminderDate, t, tasksComponent]
  )

  const updateNoteModalBody = useMemo(
    () => (
      <>
        {!tasksComponent && (
          <SimpleInputCheckbox
            additionalOnChange={(): void => setChecked((prev) => !prev)}
            label={String(t('contract:labels.in-my-tasks'))}
            defaultChecked={note?.myTasks}
            currentChecked={note?.myTasks}
          />
        )}
        <SimpleInputDate3
          name='reminderDate'
          label={String(t('contract:actions.reminder-date'))}
          additionalOnChange={handleReminderDateChange}
          defaultValue={reminderDate ? reminderDate : note?.reminderDate}
        />
        <SimpleInputText
          defaultValue={note?.content}
          disabled={note?.content.indexOf('Przypomnienie o aranżacji') !== -1}
          label={String(t('contract:actions.content'))}
          onChange={(e: any): void => setContent(e.target.value)}
        />
      </>
    ),
    [tasksComponent, t, note, reminderDate]
  )

  return (
    <Col className='px-2'>
      <Table columns={columnsData} data={notes}>
        <Table.Header align='between'>
          <Table.Title>{t('place:labels.notes')}</Table.Title>
          <Row>
            {canEdit && (
              <>
                <Col
                  className='d-flex justify-content-end mx-1 px-0'
                  lg='auto'
                  md='auto'
                  sm='auto'
                >
                  <Button className='primary' onClick={handleShowAddNote}>
                    {t('commons:actions.add')}
                  </Button>
                </Col>
                {canEdit ? (
                  <>
                    <Col
                      className='d-flex justify-content-end mx-1 px-0'
                      lg='auto'
                      md='auto'
                      sm='auto'
                    >
                      <ButtonWithTooltip
                        isTooltipAlwaysOnTop
                        message={t(
                          'commons:labels.cant-edit-dar-reminder-different-userID'
                        )}
                        shouldTooltipBeVisible={isNoteForOtherUser}
                      >
                        <Button
                          className='primary'
                          disabled={!note || isNoteForOtherUser}
                          onClick={handleShowUpdateNote}
                        >
                          {t('commons:actions.update')}
                        </Button>
                      </ButtonWithTooltip>
                    </Col>
                    {ability.can('delete', 'Note') && (
                      <Col
                        className='d-flex justify-content-end mx-1 px-0'
                        lg='auto'
                        md='auto'
                        sm='auto'
                      >
                        <Button
                          className='primary'
                          disabled={!note}
                          onClick={handleShowDeleteNote}
                        >
                          {t('commons:actions.delete')}
                        </Button>
                      </Col>
                    )}
                  </>
                ) : null}
              </>
            )}
          </Row>
        </Table.Header>
        <Table.TableBody onRowClick={selectedRowCallback} />
        <Table.Pagination tableKey={StorageKeys.NotesTable} />
      </Table>
      <ConfirmationModal
        body={addNoteModalBody}
        cancelAction={handleClose}
        submitAction={handleAdd}
        onHide={handleClose}
        show={showAddModal}
        submitText={t('commons:actions:add')}
        title={t('commons:labels:add-new-note-in-progress')}
      />
      <ConfirmationModal
        body={updateNoteModalBody}
        cancelAction={handleClose}
        submitAction={handleUpdate}
        onHide={handleClose}
        show={showUpdateModal}
        submitText={t('commons:actions:update')}
        title={t('commons:labels:update-note-in-progress')}
      />
      <ConfirmationModal
        body={t('commons:labels:are-you-sure-delete-item')}
        cancelAction={handleClose}
        submitAction={handleDelete}
        onHide={handleClose}
        show={showDeleteModal}
        submitText={t('commons:actions:delete')}
        title={t('commons:labels:delete-confirmation')}
      />
    </Col>
  )
}
