import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface PaginationState {
  interestedCustomersSearchQuery: string
  messagesOfChosenUnassignedCustomerSearchQuery: string
  messagesOfUnassignedCustomersSearchQuery: string
  unassignedCustomersSearchQuery: string
  isMaintainRowClickStopped: boolean
  placesByInvestmentNamesQuery: string
  addBlueListCustomerQuery: string
  refetch: boolean
  mainTableCurrentPage: number
  isStraightAfterFilter: boolean
}

const initialState = {
  interestedCustomersSearchQuery: '',
  messagesOfChosenUnassignedCustomerSearchQuery: '',
  messagesOfUnassignedCustomersSearchQuery: '',
  unassignedCustomersSearchQuery: '',
  isMaintainRowClickStopped: false,
  addBlueListCustomerQuery: '',
  refetch: false,
  mainTableCurrentPage: 1,
  isStraightAfterFilter: false,
} as PaginationState

const paginationSlice = createSlice({
  name: 'pagination',
  initialState: initialState,
  reducers: {
    setInterestedCustomersSearchQuery(
      state,
      action: PayloadAction<string>
    ): void {
      state.interestedCustomersSearchQuery = action.payload
    },
    setMessagesOfChosenUnassignedCustomerSearchQuery(
      state,
      action: PayloadAction<string>
    ): void {
      state.messagesOfChosenUnassignedCustomerSearchQuery = action.payload
    },
    setMessagesOfUnassignedCustomersSearchQuery(
      state,
      action: PayloadAction<string>
    ): void {
      state.messagesOfUnassignedCustomersSearchQuery = action.payload
    },
    setUnassignedCustomersSearchQuery(
      state,
      action: PayloadAction<string>
    ): void {
      state.unassignedCustomersSearchQuery = action.payload
    },
    setMainTableCurrentPage(state, action: PayloadAction<number>): void {
      state.mainTableCurrentPage = action.payload
    },
    setStopMaintainingRowClick(state, action: PayloadAction<boolean>): void {
      state.isMaintainRowClickStopped = action.payload
    },
    setPlacesByInvestmentNamesQueryForMainTable(
      state,
      action: PayloadAction<string>
    ): void {
      state.isMaintainRowClickStopped = true
      state.placesByInvestmentNamesQuery = action.payload
    },
    setPlacesByInvestmentNamesQuery(
      state,
      action: PayloadAction<string>
    ): void {
      state.placesByInvestmentNamesQuery = action.payload
    },
    setAddBlueListCustomerSearchQuery(state, action): void {
      state.addBlueListCustomerQuery = action.payload
    },
    setStraightAfterFilter(state, action): void {
      state.isStraightAfterFilter = action.payload
    },
    refetchPagination(state, action: PayloadAction<boolean>): void {
      state.refetch = action.payload
    },
  },
})

export const {
  setMainTableCurrentPage,
  setMessagesOfChosenUnassignedCustomerSearchQuery,
  setMessagesOfUnassignedCustomersSearchQuery,
  refetchPagination,
  setInterestedCustomersSearchQuery,
  setStopMaintainingRowClick,
  setPlacesByInvestmentNamesQuery,
  setPlacesByInvestmentNamesQueryForMainTable,
  setAddBlueListCustomerSearchQuery,
  setStraightAfterFilter,
  setUnassignedCustomersSearchQuery,
} = paginationSlice.actions
export default paginationSlice.reducer
