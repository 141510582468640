import 'moment/locale/pl'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Col } from 'react-bootstrap'
import { reset } from 'redux-form'
import AddContact from './AddContact'
import DeleteContact from './DeleteContact'
import EditContact from './EditContact'
import Table from '../../commons/Table2/Table'
import InterestedCustomersEventsColumns from '../../../mockups/InterestedCustomersEventsColumns'
import { IContact } from '../../../graphql/contacts'
import { IInterestedCustomer } from '../../../graphql/customers'
import { StorageKeys } from '../../../hooks/useBrowserStorage'
import TableHeaderWrapper from '../../../components/UnassignedCustomers/TableHeaderWrapper'
import { useUserAbility } from '../../../hooks/ability'
import ACCESS_PAGES from '../../../constants/accessPages'

interface IInterestedEventsTableProps {
  contractID?: number
  customer?: IInterestedCustomer
  isFullWidth?: boolean
  isUnassignedCustomerView?: boolean
}

export const InterestedEventsTable: FunctionComponent<IInterestedEventsTableProps> =
  ({ contractID, customer, isFullWidth, isUnassignedCustomerView }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const ability = useUserAbility()

    const columnsData = useMemo(
      () =>
        InterestedCustomersEventsColumns(isFullWidth, isUnassignedCustomerView),
      [isFullWidth]
    )

    const contacts = customer?.contacts
    const canEditAllFields = ability.can(
      'read',
      'Pages',
      ACCESS_PAGES.INTERESTED_CUSTOMERS
    )

    const customerID = customer?.id
    const hasCustomerContracts = Boolean(customer?.contracts?.length)

    const [isAddModalVisible, setIsAddModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)

    const [selectedContact, setSelectedContact] = useState<null | IContact>(
      null
    )
    const clearSelectedContact = (): void => setSelectedContact(null)
    const handleChooseSelectedContact = useCallback(
      (passedData: { id: number }): void => {
        const selectedContact =
          contacts?.find((contact) => contact.id === passedData.id) || null
        setSelectedContact(selectedContact)
        setIsUpdateModalVisible(false)
      },
      [setSelectedContact, contacts]
    )

    const handleAdd = useCallback(() => setIsAddModalVisible(true), [])
    const handleUpdate = useCallback(() => setIsUpdateModalVisible(true), [])
    const handleDelete = useCallback(() => setIsDeleteModalVisible(true), [])

    const handleClose = (): void => {
      setIsAddModalVisible(false)
      setIsDeleteModalVisible(false)
      dispatch(reset('saveContact'))
      dispatch(reset('updateContact'))
    }

    const getTableHeader = () => (
      <Table.Header>
        {canEditAllFields && (
          <Col
            className='d-flex justify-content-end mx-1 px-0'
            lg='auto'
            md='auto'
            sm='auto'
          >
            <Button className='primary' onClick={handleAdd}>
              {t('commons:actions.add')}
            </Button>
          </Col>
        )}
        {selectedContact && canEditAllFields ? (
          <>
            <Col
              className='d-flex justify-content-end mx-1 px-0'
              lg='auto'
              md='auto'
              sm='auto'
            >
              <Button className='primary' onClick={handleUpdate}>
                {t('commons:actions.update')}
              </Button>
            </Col>
            {ability.can('delete', 'Contact') && (
              <Col
                className='d-flex justify-content-end mx-1 px-0'
                lg='auto'
                md='auto'
                sm='auto'
              >
                <Button className='primary' onClick={handleDelete}>
                  {t('commons:actions.delete')}
                </Button>
              </Col>
            )}
          </>
        ) : null}
      </Table.Header>
    )
    return (
      <>
        <Table
          columns={columnsData}
          data={contacts ?? []}
          reSelectAllOnChange
          withoutPaddingX={isUnassignedCustomerView}
        >
          {isUnassignedCustomerView ? (
            <TableHeaderWrapper top={'200px'} zIndex={9}>
              {getTableHeader()}
            </TableHeaderWrapper>
          ) : (
            getTableHeader()
          )}
          <Table.TableBody onRowClick={handleChooseSelectedContact} />
          <Table.Pagination tableKey={StorageKeys.InterestedEventsTable} />
        </Table>
        {isAddModalVisible && canEditAllFields && customerID && (
          <AddContact
            contractID={contractID}
            customerID={customerID}
            hasCustomerContracts={hasCustomerContracts}
            handleClose={handleClose}
            isBroker={customer?.isBroker}
            show={isAddModalVisible}
            key='add-contact'
          />
        )}
        {selectedContact?.id && isUpdateModalVisible && (
          <EditContact
            contact={selectedContact}
            contractID={contractID}
            hasCustomerContracts={Boolean(hasCustomerContracts)}
            hideEditSection={clearSelectedContact}
            show={isUpdateModalVisible}
            key='edit-contact'
          />
        )}
        {selectedContact?.id && isDeleteModalVisible && (
          <DeleteContact
            contactID={selectedContact.id}
            contractID={contractID}
            customerID={customerID}
            hideDeleteModal={handleClose}
            showModal={isDeleteModalVisible}
          />
        )}
      </>
    )
  }

export default InterestedEventsTable
