import React, { FC } from 'react'

interface IMainRow {
  isRowContentCentered?: boolean
}

const MainRow: FC<IMainRow> = ({
  children,
  isRowContentCentered,
}): JSX.Element => (
  <div
    className='d-flex row mt-5 mx-5'
    style={{
      justifyContent: isRowContentCentered ? 'center' : 'space-between',
    }}
  >
    {children}
  </div>
)

export default MainRow
