import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'

import ArrangementService from '../services/arrangementsService'
import { IArrangementFileInput } from '../services/arrangementsService'
import i18n from '../i18n'

interface IArrangementFilesState {
  isUploading: boolean
}

const initialState: IArrangementFilesState = {
  isUploading: false,
}

export const fetchArrangementFilesByArrangementID = createAsyncThunk(
  'arrangements/fetchArrangementFiles',
  async (arrangementID: number) => {
    return ArrangementService.getArrangementFilesByArrangementID(arrangementID)
  }
)

export const saveArrangementFile = createAsyncThunk(
  'arrangements/saveArrangementFile',
  async (variables: { arrangementFile: IArrangementFileInput }) => {
    const response = await ArrangementService.saveArrangementFile(variables)
    return response
  }
)

export const deleteArrangementFile = createAsyncThunk(
  'arrangements/deleteArrangementFile',
  async (arrangementFileID: number) => {
    return ArrangementService.deleteArrangementFileFromArrangement(
      arrangementFileID
    )
  }
)

const arrangementFilesSlice = createSlice({
  name: 'arrangementFiles',
  initialState,
  reducers: {},
  extraReducers: {
    [saveArrangementFile.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addFile')))
      state.isUploading = false
    },
    [saveArrangementFile.pending.toString()]: (state): void => {
      state.isUploading = true
    },
    [saveArrangementFile.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addFileError')))
      state.isUploading = false
    },
  },
})

export default arrangementFilesSlice.reducer
