const ACCESS_PAGES = {
  DMA_MARKETING_REPORT: 'DMAMarketingReport',
  DAR_REPORTS: 'DARReports',
  SKA_REPORTS: 'SKAReports',
  DKO_REPORTS: 'DKOReports',
  DPP_REPORTS: 'DPPReports',
  DKS_REPORTS: 'DKSReports',
  DNI_REPORTS: 'DNIReports',
  DNK_REPORTS: 'DNKReports',
  DSPADM_REPORTS: 'DSPADMReports',
  DSP_AND_DSPADM_REPORTS: 'DSPandDSPADMReports',
  ADMIN_IT_PANEL: 'AdminITPanel',
  CONTRACT_DETAILS: 'ContractDetails',
  CUSTOMER_DATA: 'CustomerData',

  DKO_AREAS_REPORT: 'DKOAreasReport',
  DKO_EXECUTION_STATUS_REPORT: 'DKOExecutionStatusReport',
  DKO_SERIAL_CORRESPONDENCE_REPORT: 'DKOSerialCorrespondenceReport',
  DNI_ACTS_REPORT: 'DNIActsReport',
  DAR_ARRANGEMENT_REPORT: 'DARArrangementReport',
  DNI_PICKUP_SCHEDULE_REPORT: 'DNIPickupScheduleReport',
  DNI_SHARE_TABLE_REPORT: 'DNIShareTableReport',
  DSP_CANCELLED_CONTRACTS_REPORT: 'DSPCancelledContractsReport',
  CUSTOMER_BY_TRADERS_REPORT: 'CustomerByTradersReport',
  DSP_CUSTOMERS_BY_BROKERS_REPORT: 'DSPCustomersByBrokersReport',
  CUSTOMER_SOURCE_CONTRACT_REPORT: 'CustomerSourceContractReport',
  DSP_CUSTOMER_RESIGNATION_REPORT: 'DSPCustomerResignationReport',
  DSP_PLACES_LIST_REPORT: 'DSPPlacesListReport',
  DSP_SUMMARY_REPORT: 'DSPSummaryReport',
  BUILDING_ADMINISTRATORS_REPORT: 'BuildingAdministratorsReport',
  TABLE_UNASSIGNED_CUSTOMERS: 'TableUnassignedCustomers',
  DSP_INTERESTED_CUSTOMERS_DAILY_REPORT: 'DSPInterestedCustomersDailyReport',
  DSP_CUSTOMER_CONCLUDED_CONTRACTS_REPORT:
    'DSPCustomerConcludedContractsReport',
  DSP_DAILY_INTERESTED_PARTIES_REPORT: 'DSPDailyInterestedPartiesReport',
  DKS_BUSINESS_PLACES_REPORT: 'DKSBusinessPlacesReport',
  DAR_DATE_ANNEXES_REPORT: 'DARDateAnnexesReport',
  DAR_ARRANGEMENT_LIST_REPORT: 'DARArrangementListReport',
  DPP_PLACES_TALLY_REPORT: 'DPPPlacesTallyReport',
  INTERESTED_CUSTOMERS_REPORT: 'InterestedCustomersReport',
  BLUE_LIST_SALES_REPORT: 'BlueListSalesReport',
  LEAD_AND_INTERESTED_REPORT: 'LeadAndInterestedReport',
  LOGIN: 'login',
  LOGOUT: 'logout',
  MAIN_TABLE: 'mainTable',
  CANCELLED_CONTRACT_PLACE: 'CancelledContractPlace',
  CANCELLED_CONTRACT_CUSTOMER_DATA: 'CancelledContractCustomerData',
  LAND_STRIP_ADMIN_PANEL: 'LandStripAdminPanel',
  BLUE_LIST_ADMIN_PANEL: 'BlueListAdminPanel',
  PARKING_PLACES_ADMIN_PANEL: 'ParkingPlacesAdminPanel',
  UTILITY_ROOM_ADMIN_PANEL: 'UtilityRoomAdminPanel',
  PLACE_ADMIN_PANEL: 'PlaceAdminPanel',
  GARAGE_HALLS_ADMIN_PANEL: 'GarageHallsAdminPanel',
  BUILDINGS_ADMIN_PANEL: 'BuildingsAdminPanel',
  SALES_REGISTRATION: 'salesRegistration',
  INTERESTED_CUSTOMERS: 'InterestedCustomers',
  ARRANGEMENTS: 'Arrangements',
  TASKS: 'Tasks',
  PLACE: 'Place',
  FILE_HISTORY: 'FileHistory',

  CONTRACT: 'Contract',
  CONTRACT_CORRESPONDENCE_HISTORY: 'ContractCorrespondenceHistory',
  CANCELLED_ARCHIVAL_CONTRACTS: 'CancelledArchivalContracts',
  CANCELLED_CONTRACTS: 'CancelledContracts',

  INVESTMENT_ADMIN_PANEL: 'InvestmentAdminPanel',
  DNI_PLACE_SHARES: 'DNIPlaceShares',
  BUILDING_ADMINISTRATORS_ADMIN_PANEL: 'BuildingAdministratorsAdminPanel',
  NOTARIAL_OFFICES_ADMIN_PANEL: 'NotarialOfficesAdminPanel',
  LICENSES_PAGE: 'LicensesPage',
  SETTINGS_PAGE: 'SettingsPage',
}
export default ACCESS_PAGES
