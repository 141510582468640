import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import ButtonWithTooltip from '../commons/Inputs/ButtonTooltip'

interface DropDownItemProps {
  id?: number
  buttonID?: string
  href?: string
  label?: string
  onLinkClick(arg: string): void
}

const selectInvestmentTooltipOptions = [
  'DODAJ INWESTYCJĘ',
  'Lokale na działalność',
  'OSOBY FIZYCZNE',
  'PODMIOTY GOSPODARCZE',
  'LISTA MIESZKAŃ',
  'RAPORT DZIENNY',
  'ZAWARTE UMOWY',
  'ZAINTERESOWANI ŹRÓDŁA INFORMACJI',
  'ZAINTERESOWANI - ŹRÓDŁA UMOWY',
  'ZAINTERESOWANI - REZYGNACJE',
  'ZAINTERESOWANI WG. HANDLOWCOW',
  'ZAINTERESOWANI OD POŚREDNIKÓW',
  'ZAINTERESOWANI UMOWY OD POŚREDNIKÓW',
  'ZAINTERESOWANI DSP',
  'UMOWY ANULOWANE',
  'RAPORT ZBIORCZY',
  'BŁĘKITNA LISTA',
  'ARANŻACJE',
  'LISTA',
  'USTAWIENIA',
  'RAPORT LEADÓW I ZAINTERESOWANYCH',
]
const selectPlaceOptions = ['ANEKSY DAT']
const shouldDisableOption = (label: string): string => {
  const includesLabel = !selectInvestmentTooltipOptions.includes(label)
  const place = selectPlaceOptions.includes(label)
  return includesLabel && place ? 'place' : includesLabel ? 'investment' : ''
}

export const DropDownItem: React.FC<DropDownItemProps> = React.memo((props) => {
  const { buttonID, id, label, onLinkClick } = props
  const { t } = useTranslation()
  const { chosenInvestment } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const isChosenInvestment =
    chosenInvestment?.name !== 'Wszystkie' &&
    chosenInvestment?.name !== 'Umowy anulowane'
  const disableOptionType = shouldDisableOption(label || '')
  const isOptionDisabled = !!(
    !isChosenInvestment && disableOptionType === 'investment'
  )

  return (
    <ButtonWithTooltip
      isTooltipAlwaysOnTop
      message={t(`commons:labels.choose-${disableOptionType}`)}
      shouldTooltipBeVisible={isOptionDisabled}
    >
      <Dropdown.Item
        className='d-flex justify-content-start w-100 ik2-main-button-color'
        disabled={isOptionDisabled}
        onClick={(): void => onLinkClick(buttonID ?? '/')}
        key={`headerMenu-HeaderMenuSpecificOption-dropdown-${label}-${id}-dropdown`}
      >
        {label}
      </Dropdown.Item>
    </ButtonWithTooltip>
  )
})
