import React, { FunctionComponent, useCallback, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import AddProposedPlace from './AddProposedPlace'
import DeleteProposedPlace from './DeleteProposedPlace'
import ProposedPlace from './ProposedPlace'

import { IInterestedCustomer } from '../../../graphql/customers'
import { IProposedInvestment } from '../../../services/proposedInvestmentsService'

interface IInterestedCustomerProposedPlacesProps {
  customer: IInterestedCustomer
}

export const InterestedCustomerProposedPlaces: FunctionComponent<IInterestedCustomerProposedPlacesProps> = (
  props: IInterestedCustomerProposedPlacesProps
) => {
  const { t } = useTranslation()

  const { customer } = props

  const [
    isVisibleAddProposedPlaceModal,
    setIsVisibleAddProposedPlaceModal,
  ] = useState(false)
  const [
    isVisibleDeleteProposedPlaceModal,
    setIsVisibleDeleteProposedPlaceModal,
  ] = useState(false)
  const [
    selectedProposedItem,
    setSelectedProposedItem,
  ] = useState<IProposedInvestment | null>(null)

  const showAddProposedPlaceModal = useCallback(() => {
    setIsVisibleAddProposedPlaceModal(true)
  }, [setIsVisibleAddProposedPlaceModal])
  const showDeleteProposedPlaceModal = useCallback(() => {
    setIsVisibleDeleteProposedPlaceModal(true)
  }, [setIsVisibleDeleteProposedPlaceModal])
  const handleClose = useCallback(() => {
    setIsVisibleAddProposedPlaceModal(false)
    setIsVisibleDeleteProposedPlaceModal(false)
  }, [setIsVisibleAddProposedPlaceModal, setIsVisibleDeleteProposedPlaceModal])

  const handleProposedItemClick = (
    proposedPlace: IProposedInvestment
  ): void => {
    setSelectedProposedItem(proposedPlace)
  }

  const selectedCustomerProposedInvestments = customer?.proposedInvestments

  return (
    <Form className='w-100'>
      <Row className='d-flex justify-content-end w-100'>
        <Col lg={12} md={12} sm={12}>
          <Row className='d-flex justify-content-center w-100'>
            <Button onClick={showAddProposedPlaceModal}>
              {t('customers:actions.add-place-offer')}
            </Button>
          </Row>
          <Row className='px-5 mx-2 w-100'>
            {selectedCustomerProposedInvestments?.map(
              (proposedItem: IProposedInvestment, index: number) => (
                <Col
                  className='py-3'
                  lg={3}
                  md={12}
                  sm={12}
                  key={proposedItem.id + '.' + index}
                  onClick={(): void => handleProposedItemClick(proposedItem)}
                  style={{
                    backgroundColor:
                      proposedItem.id &&
                      selectedProposedItem?.id === proposedItem.id
                        ? '#f2f2f2'
                        : '#fff',
                  }}
                >
                  <ProposedPlace
                    key={proposedItem.id}
                    proposedPlace={proposedItem}
                  />
                  {
                    <Row className='d-flex justify-content-center'>
                      <Button
                        disabled={selectedProposedItem?.id !== proposedItem.id}
                        onClick={showDeleteProposedPlaceModal}
                      >
                        {t('commons:actions.delete')}
                      </Button>
                    </Row>
                  }
                </Col>
              )
            )}
          </Row>
        </Col>
      </Row>
      {isVisibleAddProposedPlaceModal && (
        <AddProposedPlace
          customerID={customer.id}
          key='addProposedPlace'
          handleClose={handleClose}
          show={isVisibleAddProposedPlaceModal}
          proposedPlaces={selectedCustomerProposedInvestments}
        />
      )}
      {isVisibleDeleteProposedPlaceModal && selectedProposedItem && (
        <DeleteProposedPlace
          key={selectedProposedItem?.id}
          handleClose={handleClose}
          proposedPlace={selectedProposedItem}
          show={isVisibleDeleteProposedPlaceModal}
          customerID={customer.id}
        />
      )}
    </Form>
  )
}

export default InterestedCustomerProposedPlaces
