import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'

type CategoryOfAnnexFile = 'annex' | 'protocols' | 'disclaimer' | 'contractFile'

export interface IAnnexFileInput {
  contractID: number
  name: string
  length: number
  category: CategoryOfAnnexFile
  created: string
  description: string
  content: string
  id?: number
  description2?: string
}

export const getFilesDetailsWithoutContentByContractID = (
  contractID: number
): Promise<any> => {
  const FETCH_FILES_BY_CONTRACT_ID = gql`
    query($contractID: Float!) {
      getContract(contractID: $contractID) {
        files {
          id
          name
          length
          category
          created
          description
          description2
          isAfterMigration
          migrationComment
        }
      }
    }
  `

  return GraphqlService.send(FETCH_FILES_BY_CONTRACT_ID, { contractID })
}

export const saveAnnexFileForContract = (
  file: IAnnexFileInput
): Promise<any> => {
  const SAVE_FILE_FOR_CONTRACT = gql`
    mutation saveFile($file: FileInput!) {
      saveFile(file: $file) {
        id
        name
        length
        category
        content
        created
        description
        description2
      }
    }
  `

  return GraphqlService.send(SAVE_FILE_FOR_CONTRACT, { file })
}

const updateAnnexFileFromContract = (
  file: Partial<IAnnexFileInput>,
  fileID: number
): Promise<any> => {
  const UPDATE_FILE_FROM_CONTRACT = gql`
    mutation uf($file: FileInput!, $fileID: Float!) {
      updateFile(file: $file, fileID: $fileID) {
        id
        name
        length
        category
        content
        created
        description
        description2
      }
    }
  `
  return GraphqlService.send(UPDATE_FILE_FROM_CONTRACT, { file, fileID })
}

export const deleteAnnexFileForContract = (fileID: number): Promise<any> => {
  const DELETE_FILE_FROM_CONTRACT = gql`
    mutation deleteFile($fileID: Float!) {
      deleteFile(fileID: $fileID)
    }
  `

  return GraphqlService.send(DELETE_FILE_FROM_CONTRACT, { fileID })
}

export const fetchAnnexFileContentById = (fileID: number): Promise<any> => {
  const FETCH_ANNEX_FILE_CONTENT_BY_ID = gql`
    query fafcbi($fileID: Float!) {
      file(fileID: $fileID) {
        content
      }
    }
  `

  return GraphqlService.send(FETCH_ANNEX_FILE_CONTENT_BY_ID, { fileID })
}

export default {
  updateAnnexFileFromContract,
  getFilesDetailsWithoutContentByContractID,
  saveAnnexFileForContract,
  deleteAnnexFileForContract,
  fetchAnnexFileContentById,
}
