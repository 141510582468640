import { IContract } from '../graphql/contractRegister'
import { IParkingPlace } from '../graphql/parkingPlaces'
import { sortByID } from '../utils/sortData'
import { formatAreas } from '../utils/formatAreas'

import { getProperDateForTable } from '../utils/getProperDate'

import { IContractCustomerCompany } from '../graphql/contractCustomerCompany'
import { IContractCustomer } from '../graphql/contractCustomers'

export const searchCompaniesTable = (): any[] => {
  return [
    {
      id: 'investment.name',
      Header: 'Kod inwestycji',
      accessor: 'contract.place.investment.name',
      disableSortBy: true,
      width: '100',
      sticky: 'left',
    },
    {
      id: 'placeCode',
      Header: 'Nr bud. lokalu',
      accessor: 'contract.place.placeCode',
      disableSortBy: true,
      width: '150',
      sticky: 'left',
    },
    {
      id: 'roomCount',
      Header: 'Liczba pokoi',
      disableSortBy: true,
      accessor: 'contract.place.roomCount',
      width: '75',
    },
    {
      id: 'stage.street',
      Header: 'Ulica',
      accessor: 'contract.place.stage.street',
      disableSortBy: true,
      width: '150',
    },
    {
      Header: 'Nr admin.',
      disableSortBy: true,
      accessor: (a: {
        contract: {
          place: {
            administrativeCode1: string
            administrativeCode2: string
          }
        }
      }): string =>
        `${a.contract.place.administrativeCode1} / ${a.contract.place.administrativeCode2}`,
      width: '150',
    },
    {
      id: 'designedUsablePropertyArea',
      Header: 'PUL proj.',
      disableSortBy: true,
      accessor: (a: {
        contract: { place: { designedUsablePropertyArea: number } }
      }): string => {
        const valueWithComma = String(
          a.contract.place.designedUsablePropertyArea
        ).replace('.', ',')
        return formatAreas(valueWithComma, true)
      },
      fullName: 'Pow. użyt. projekt.',
      width: '75',
    },
    {
      id: 'designedInnerPropertyArea',
      Header: 'PWL proj.',
      disableSortBy: true,
      accessor: (a: {
        contract: { place: { designedInnerPropertyArea: number } }
      }): string => {
        const valueWithComma = String(
          a.contract.place.designedInnerPropertyArea
        ).replace('.', ',')
        return formatAreas(valueWithComma, true)
      },
      fullName: 'Pow. wew. projekt.',
      width: '75',
    },
    {
      id: 'asBuildUsablePropertyArea',
      Header: 'PUL powyk.',
      disableSortBy: true,
      accessor: (a: {
        contract: { place: { asBuildUsablePropertyArea: number } }
      }): string => {
        const valueWithComma = String(
          a.contract.place.asBuildUsablePropertyArea
        ).replace('.', ',')
        return formatAreas(valueWithComma)
      },
      fullName: 'Pow. użyt. powyk.',
      width: '75',
    },
    {
      id: 'asBuildInnerPropertyArea',
      Header: 'PWL powyk.',
      disableSortBy: true,
      accessor: (a: {
        contract: { place: { asBuildInnerPropertyArea: number } }
      }): string => {
        const valueWithComma = String(
          a.contract.place.asBuildInnerPropertyArea
        ).replace('.', ',')
        return formatAreas(valueWithComma, true)
      },
      fullName: 'Pow. wew. powyk.',
      width: '75',
    },
    {
      Header: 'Liczba nabywców',
      disableSortBy: true,
      accessor: (a: { contract: IContract }): string => {
        const contract = a?.contract
        const contractCustomerCompanies = contract?.contractCustomerCompanies
        const contractCustomers = contract?.contractCustomers

        const companyOwners =
          contractCustomerCompanies?.filter(
            (customer: IContractCustomerCompany) => {
              return (
                !customer.isAgentOfMainCustomer &&
                !customer.isRepresentativePerson
              )
            }
          ) || []
        const owners =
          contractCustomers?.filter((customer: IContractCustomer) => {
            return (
              !customer.isAgentOfMainCustomer &&
              !customer.isAgentOfCustomerSpouse &&
              !customer.isSpouseOfMainCustomer
            )
          }) || []

        if (companyOwners.length) {
          return String(companyOwners?.length)
        } else if (owners.length) {
          return String(owners?.length)
        } else {
          return ''
        }
      },
      width: '100',
    },
    {
      id: 'firstName1',
      disableSortBy: true,
      Header: 'Imię / nazwa spółki (Klient 1.)',
      accessor: (a: { contract: IContract }): string => {
        const contract = a.contract
        const sortedContractCustomers =
          contract?.contractCustomers.slice().sort(sortByID) ?? []
        const owner = sortedContractCustomers.filter(
          (c) =>
            !c.isSpouseOfMainCustomer &&
            !c.isAgentOfCustomerSpouse &&
            !c.isAgentOfMainCustomer
        )
        const companyOwner = contract?.contractCustomerCompanies
        const spouse = sortedContractCustomers.filter(
          (c) => c.isSpouseOfMainCustomer
        )
        const ownerSpouse = spouse?.filter(
          (s) =>
            s.customerParentID === owner?.[0]?.id || s.uuid === owner?.[0]?.uuid
        )
        const ownerFirstName =
          owner?.[0]?.firstName ?? companyOwner?.[0]?.businessName ?? ''
        const spouseFirstName = owner?.[0]?.maritalStatus?.startsWith(
          '2 wspólnota majątkowa'
        )
          ? ownerSpouse?.[0]?.firstName
          : ''
        return (
          contract?.contractCustomerCompanies?.[0]?.businessName ||
          `${ownerFirstName}${spouseFirstName ? ', ' + spouseFirstName : ''}` ||
          ''
        )
      },
      width: '150',
    },
    {
      id: 'lastName1',
      disableSortBy: true,
      Header: 'Nazwisko (Klient 1.)',
      accessor: (a: { contract: IContract }): string => {
        const contract = a.contract
        const sortedContractCustomers =
          contract?.contractCustomers.slice().sort(sortByID) ?? []
        const owner = sortedContractCustomers.filter(
          (c) =>
            !c.isSpouseOfMainCustomer &&
            !c.isAgentOfCustomerSpouse &&
            !c.isAgentOfMainCustomer
        )
        const spouse = sortedContractCustomers.filter(
          (c) => c.isSpouseOfMainCustomer
        )
        const ownerSpouse = spouse?.filter(
          (s) =>
            s.customerParentID === owner?.[0]?.id || s.uuid === owner?.[0]?.uuid
        )
        const ownerLastName = owner?.[0]?.lastName ?? ''
        const spouseLastName = owner?.[0]?.maritalStatus?.startsWith(
          '2 wspólnota majątkowa'
        )
          ? ownerSpouse?.[0]?.lastName
          : ''

        return (
          `${ownerLastName}${spouseLastName ? ', ' + spouseLastName : ''}` || ''
        )
      },
      width: '180',
    },
    {
      id: 'firstName2',
      disableSortBy: true,
      Header: 'Imię / nazwa spółki (Klient 2.)',
      accessor: (a: { contract: IContract }): string => {
        const contract = a.contract
        const sortedContractCustomers =
          contract?.contractCustomers.slice().sort(sortByID) ?? []
        const owner = sortedContractCustomers.filter(
          (c) =>
            !c.isSpouseOfMainCustomer &&
            !c.isAgentOfCustomerSpouse &&
            !c.isAgentOfMainCustomer
        )
        const companyOwner = contract?.contractCustomerCompanies
        const spouse =
          sortedContractCustomers.filter((c) => c.isSpouseOfMainCustomer) ?? []
        const ownerSpouse = spouse?.filter(
          (s) =>
            s.customerParentID === owner?.[1]?.id || s.uuid === owner?.[1]?.uuid
        )
        const ownerFirstName =
          owner?.[1]?.firstName ?? companyOwner?.[1]?.businessName ?? ''
        const spouseFirstName = owner?.[1]?.maritalStatus?.startsWith(
          '2 wspólnota majątkowa'
        )
          ? ownerSpouse?.[0]?.firstName
          : ''

        return (
          contract?.contractCustomerCompanies?.[1]?.businessName ||
          `${ownerFirstName}${spouseFirstName ? ', ' + spouseFirstName : ''}` ||
          ''
        )
      },
      width: '150',
    },
    {
      id: 'lastName2',
      disableSortBy: true,
      Header: 'Nazwisko (Klient 2.)',
      accessor: (a: { contract: IContract }): string => {
        const contract = a.contract
        const sortedContractCustomers =
          contract?.contractCustomers.slice().sort(sortByID) ?? []
        const owner = sortedContractCustomers.filter(
          (c) =>
            !c.isSpouseOfMainCustomer &&
            !c.isAgentOfCustomerSpouse &&
            !c.isAgentOfMainCustomer
        )
        const spouse =
          sortedContractCustomers.filter((c) => c.isSpouseOfMainCustomer) ?? []
        const ownerSpouse = spouse?.filter(
          (s) =>
            s.customerParentID === owner?.[1]?.id || s.uuid === owner?.[1]?.uuid
        )
        const ownerLastName = owner?.[1]?.lastName ?? ''
        const spouseLastName = owner?.[1]?.maritalStatus?.startsWith(
          '2 wspólnota majątkowa'
        )
          ? ownerSpouse?.[0]?.lastName
          : ''

        return (
          `${ownerLastName}${spouseLastName ? ', ' + spouseLastName : ''}` || ''
        )
      },
      width: '180',
    },
    {
      Header: 'Nr miejsca postoj.',
      disableSortBy: true,
      accessor: (a: any): string => {
        let parkingPlacesFromContract = [] as string[]
        let parkingPlacesFromPlace = [] as string[]
        if (a?.contract?.parkingPlaces?.length > 0) {
          parkingPlacesFromContract = a.contract.parkingPlaces.map(
            (parkingPlace: IParkingPlace) => parkingPlace.name
          )
        }
        if (a?.contract?.place?.parkingPlaces?.length > 0) {
          parkingPlacesFromPlace = a.contract.place.parkingPlaces.map(
            (parkingPlace: IParkingPlace) => parkingPlace.name
          )
        }
        const fromPlace = parkingPlacesFromPlace
          .filter((p) => p != null)
          .reduce((prev, next) => {
            if (prev) {
              return prev + ',' + next
            } else {
              return next
            }
          }, '')
        const fromContract = parkingPlacesFromContract
          .filter((p) => p != null)
          .reduce((prev, next) => {
            if (prev) {
              return prev + ',' + next
            } else {
              return next
            }
          }, '')
        return fromPlace + fromContract
      },
      width: '100',
    },
    {
      Header: 'Nr pom. gosp.',
      disableSortBy: true,
      accessor: (a: any): string => {
        const utilityRoomsByPlace = a?.contract?.place?.utilityRooms ?? []
        const utilityRoomsByContract = a?.contract?.utilityRooms ?? []

        const fromPlace =
          utilityRoomsByPlace?.length &&
          utilityRoomsByPlace?.map((ur: any, i: number) => {
            if (i > 0) {
              return String(' ' + ur?.number)
            } else {
              return String(ur?.number ?? '')
            }
          }, '')

        const fromContract =
          utilityRoomsByContract?.length &&
          utilityRoomsByContract?.map((ur: any, i: number) => {
            if (i > 0) {
              return String(' ' + ur?.number)
            } else {
              return String(ur?.number ?? '')
            }
          }, '')

        return `${fromPlace !== 0 ? fromPlace : ''}${
          fromContract !== 0 ? fromContract : ''
        }`
      },
      width: '100',
    },
    {
      Header: 'PWPG proj.',
      disableSortBy: true,
      accessor: (a: any): string => {
        const utilityRoomsByPlace = a?.contract?.place?.utilityRooms ?? []
        const utilityRoomsByContract = a?.contract?.utilityRooms ?? []

        if (utilityRoomsByPlace?.length > 0) {
          const valueWithComma = String(
            utilityRoomsByPlace[0]?.designedInnerUtilRoomArea
          ).replace('.', ',')
          return formatAreas(valueWithComma, true)
        } else if (utilityRoomsByContract.length > 0) {
          const valueWithComma = String(
            utilityRoomsByContract[0].designedInnerUtilRoomArea ?? ''
          ).replace('.', ',')
          return formatAreas(valueWithComma, true)
        } else {
          return '0,0'
        }
      },
      fullName: 'Pow. wew. projekt. pom. gosp.',
      width: '75',
    },
    {
      id: 'designedUsableUtilRoomArea',
      Header: 'PUPG proj.',
      disableSortBy: true,
      accessor: (a: any): string => {
        const utilityRoomsByPlace = a?.contract?.place?.utilityRooms ?? []
        const utilityRoomsByContract = a?.contract?.utilityRooms ?? []

        if (utilityRoomsByPlace?.length > 0) {
          const valueWithComma = String(
            utilityRoomsByPlace[0]?.designedUsableUtilRoomArea
          ).replace('.', ',')
          return utilityRoomsByPlace[0]?.designedUsableUtilRoomArea
            ? formatAreas(valueWithComma, true)
            : '0,0'
        } else if (utilityRoomsByContract.length > 0) {
          const valueWithComma = String(
            utilityRoomsByContract[0].designedUsableUtilRoomArea ?? ''
          ).replace('.', ',')
          return utilityRoomsByContract[0].designedUsableUtilRoomArea
            ? formatAreas(valueWithComma, true)
            : '0,0'
        } else {
          return '0,0'
        }
      },
      fullName: 'Pow. użyt. projekt. pom. gosp.',
      width: '75',
    },
    {
      Header: 'PWPG powyk.',
      disableSortBy: true,
      accessor: (a: any): string => {
        const utilityRoomsByPlace = a?.contract?.place?.utilityRooms ?? []
        const utilityRoomsByContract = a?.contract?.utilityRooms ?? []

        if (utilityRoomsByPlace?.length > 0) {
          const valueWithComma = String(
            utilityRoomsByPlace[0]?.asBuildInnerUtilRoomArea
          ).replace('.', ',')
          return formatAreas(valueWithComma, true)
        } else if (utilityRoomsByContract.length > 0) {
          const valueWithComma = String(
            utilityRoomsByContract[0]?.asBuildInnerUtilRoomArea ?? ''
          ).replace('.', ',')
          return formatAreas(valueWithComma, true)
        } else {
          return '0,0'
        }
      },
      fullName: 'Pow. wew. powyk. pom. gosp.',
      width: '75',
    },
    {
      Header: 'PUPG powyk.',
      disableSortBy: true,
      accessor: (a: any): string => {
        const utilityRoomsByPlace = a?.contract?.place?.utilityRooms ?? []
        const utilityRoomsByContract = a?.contract?.utilityRooms ?? []

        if (utilityRoomsByPlace?.length > 0) {
          const valueWithComma = String(
            utilityRoomsByPlace[0]?.asBuildUsableUtilRoomArea
          ).replace('.', ',')
          return formatAreas(valueWithComma)
        } else if (utilityRoomsByContract.length > 0) {
          const valueWithComma = String(
            utilityRoomsByContract[0]?.asBuildUsableUtilRoomArea ?? ''
          ).replace('.', ',')
          return formatAreas(valueWithComma)
        } else {
          return '0,00'
        }
      },
      fullName: 'Pow. użyt. powyk. pom. gosp.',
      width: '75',
    },
    {
      Header: 'Data zaw. umowy',
      disableSortBy: true,
      accessor: (a: { contract: { contractDealDate: string } }): string => {
        const contractDealDate = a?.contract?.contractDealDate ?? ''
        return getProperDateForTable(contractDealDate)
      },
      width: '120',
    },
    {
      Header: 'Um. termin odb.',
      disableSortBy: true,
      accessor: (a: {
        contract: { contractualAcceptanceDate: string }
      }): string => {
        const contractualAcceptanceDate =
          a?.contract?.contractualAcceptanceDate ?? ''
        return getProperDateForTable(contractualAcceptanceDate)
      },
      width: '120',
    },
    {
      Header: 'Um. termin aktu',
      disableSortBy: true,
      accessor: (a: { contract: { contractualActDate: string } }): string => {
        const contractualActDate = a?.contract?.contractualActDate ?? ''
        return getProperDateForTable(contractualActDate)
      },
      width: '120',
    },
    {
      Header: 'Data odbioru',
      disableSortBy: true,
      accessor: (a: { contract: { acceptanceDate: string } }): string => {
        const acceptanceDate = a?.contract?.acceptanceDate ?? ''
        return getProperDateForTable(acceptanceDate)
      },
      width: '120',
    },
    {
      Header: 'Data aktu not.',
      disableSortBy: true,
      accessor: (a: { contract: { actDate: string } }): string => {
        const actDate = a?.contract?.actDate ?? ''
        return getProperDateForTable(actDate)
      },
      width: '120',
    },
    {
      Header: 'Handl.',
      disableSortBy: true,
      accessor: 'traderInitials',
      width: '80',
    },
    {
      Header: 'Zakup na dział gosp.',
      disableSortBy: true,
      width: '100',
      accessor: (a: { isBusinesss: boolean }): string => {
        if (!a.isBusinesss) {
          return '☐'
        } else {
          return '☑'
        }
      },
    },
    {
      Header: 'Spółka',
      disableSortBy: true,
      width: '100',
      accessor: (a: { contract: IContract }): string => {
        return a?.contract?.contractCustomerCompanies?.length ? '☑' : '☐'
      },
    },
    {
      Header: 'Walor.',
      disableSortBy: true,
      width: '50',
      accessor: (a: { contract: { valorization: boolean } }): string =>
        a.contract.valorization ? '☑' : '☐',
    },
    {
      Header: 'Kredyt',
      disableSortBy: true,
      width: '50',
      accessor: (a: { contract: { isCredit: boolean } }): string =>
        a.contract.isCredit ? '☑' : '☐',
    },
    {
      Header: 'Wysyłka FV na @',
      disableSortBy: true,
      width: '80',
      accessor: (a: { contract: { isEmailInvoice: boolean } }): string =>
        a.contract.isEmailInvoice ? '☑' : '☐',
    },
    {
      Header: 'ID',
      accessor: 'id',
      width: '10',
    },
  ]
}
