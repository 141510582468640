import moment from 'moment'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_YYYYMMDD } from '../../../constants/dates'
import {
  ActionTypes,
  Actions,
  IRaportWithDatesContext,
  RaportWithDatesState,
  clearState,
} from './actions'

interface ContextProviderProps {
  isLoading: boolean
  generateReport(state: RaportWithDatesState): Promise<void>
}

const initialState: RaportWithDatesState = {
  fromDate: moment().format(DATE_FORMAT_YYYYMMDD),
  toDate: moment().format(DATE_FORMAT_YYYYMMDD),
  chosenBrokerNames: [],
  chosenSourceInfo: [],
  chosenInvestments: [],
  chosenResignationReasons: [],
  chosenStages: [],
  chosenTraderInitials: [],
  isEmailInvoice: false,
  isValorization: false,
  customerType: '',
}

export const RaportWithDatesContext = React.createContext(
  {} as IRaportWithDatesContext
)

const raportWithDatesReducer = (
  state: RaportWithDatesState,
  action: Actions
): RaportWithDatesState => {
  switch (action.type) {
    case ActionTypes.EmailInvoice: {
      return {
        ...state,
        isEmailInvoice: action.payload,
      }
    }
    case ActionTypes.Valorization: {
      return {
        ...state,
        isValorization: action.payload,
      }
    }
    case ActionTypes.CustomerType: {
      return {
        ...state,
        customerType: action.payload,
      }
    }
    case ActionTypes.FromDate: {
      return {
        ...state,
        fromDate: action.payload,
      }
    }
    case ActionTypes.ToDate: {
      return {
        ...state,
        toDate: action.payload,
      }
    }
    case ActionTypes.Stages: {
      return {
        ...state,
        chosenStages: action.payload,
      }
    }
    case ActionTypes.Investments: {
      return {
        ...state,
        chosenInvestments: action.payload,
      }
    }
    case ActionTypes.BrokerNames: {
      return {
        ...state,
        chosenBrokerNames: action.payload,
      }
    }
    case ActionTypes.TraderInitials: {
      return {
        ...state,
        chosenTraderInitials: action.payload,
      }
    }
    case ActionTypes.SourceInfos: {
      return {
        ...state,
        chosenSourceInfo: action.payload,
      }
    }
    case ActionTypes.ResignationReasons: {
      return {
        ...state,
        chosenResignationReasons: action.payload,
      }
    }
    case ActionTypes.ClearState: {
      return {
        ...initialState,
      }
    }
    default:
      throw new Error(`Unused action type ${action}`)
  }
}

export const useRaportsWithDatesContext = (): IRaportWithDatesContext => {
  const ctx = React.useContext(RaportWithDatesContext)

  if (!ctx) {
    throw new Error(
      'Cant use raportsWithDatesContext outside RaportWithDates.tsx'
    )
  }
  return ctx
}

export const RaportWithDatesContextProvider: React.FC<ContextProviderProps> = ({
  children,
  isLoading,
  generateReport,
}) => {
  const [state, dispatch] = React.useReducer(
    raportWithDatesReducer,
    initialState
  )
  const { t } = useTranslation()

  const handleGenerateReport = React.useCallback(async () => {
    await generateReport(state)
    dispatch(clearState())
  }, [generateReport, state])

  const value = React.useMemo(() => {
    return {
      state,
      dispatch,
    }
  }, [state])

  return (
    <RaportWithDatesContext.Provider value={value}>
      {children}
      <div className='d-flex flex-column justify-content-center align-items-center my-0 py-0'>
        <Button disabled={isLoading} onClick={handleGenerateReport}>
          {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
        </Button>
      </div>
    </RaportWithDatesContext.Provider>
  )
}
