import { PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import * as React from 'react'
import { DATE_FORMAT_YYYYMMDD } from '../constants/dates'

export enum FomActionTypes {
  LoadData = 'LOAD_DATA',
  UpdateData = 'UPDATE_DATA',
  ClearData = 'CLEAR_DATA',
  UpdateFiled = 'UPDATE_FIELD',
}

type UseFormReturnType = {
  state: any
  reducerDispatch: React.Dispatch<any>
  updateFromInput: (e: any) => void
  updateField: (payload: { name: string; value: any }) => void
  loadData: (args?: any) => void
}

const loadInitialData = (data: any): PayloadAction<any> => {
  return {
    type: FomActionTypes.LoadData,
    payload: data,
  }
}

const updateData = (data: any): PayloadAction<any> => {
  return {
    type: FomActionTypes.UpdateData,
    payload: data,
  }
}

export const clearForm = (): PayloadAction<any> => {
  return {
    type: FomActionTypes.ClearData,
    payload: '',
  }
}

const updateFormField = (payload: {
  name: string
  value: any
}): PayloadAction<any> => {
  return {
    type: FomActionTypes.UpdateFiled,
    payload,
  }
}

export const useForm = (initialValues: any): UseFormReturnType => {
  const formReducer = (state: any, action: any): any => {
    switch (action.type) {
      case FomActionTypes.LoadData: {
        return { ...state, ...action.payload }
      }
      case FomActionTypes.UpdateData: {
        return {
          ...state,
          ...action.payload,
        }
      }
      case FomActionTypes.UpdateFiled: {
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        }
      }
      case FomActionTypes.ClearData: {
        return initialValues
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
  }
  const [state, reducerDispatch] = React.useReducer(formReducer, {})

  const updateFromInput = React.useCallback(
    (e: any) => {
      if (e?.target?.name) {
        const isCheckbox = e?.target?.type === 'checkbox'
        const newValues = {
          ...state,
          [e.target.name]: isCheckbox
            ? e.target.checked
            : e?.target?.value || '',
        }
        reducerDispatch(updateData(newValues))
      } else if (e?.name && (e?.day || e?.day === null)) {
        const newValues = {
          ...state,
          [e.name]: e.day ? moment(e.day).format(DATE_FORMAT_YYYYMMDD) : null,
        }
        reducerDispatch(updateData(newValues))
      } else if (e?.length) {
        const newValues = {
          ...state,
          [e.name]: e.value,
        }
        reducerDispatch(updateData(newValues))
      }
    },
    [state]
  )

  const updateField = React.useCallback(
    (payload: { name: string; value: any }): void => {
      reducerDispatch(updateFormField(payload))
    },
    []
  )

  const loadData = React.useCallback(
    (args?: any) => reducerDispatch(loadInitialData(args || initialValues)),
    [initialValues]
  )

  React.useEffect(() => {
    if (initialValues) {
      loadData()
    }
  }, [initialValues, loadData])

  return { state, reducerDispatch, updateFromInput, updateField, loadData }
}
