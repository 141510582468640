import { applyMiddleware, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { StateType } from 'typesafe-actions'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import { connectRouter } from 'connected-react-router'

import arrangementFiles from './arrangementFiles'
import globalInvestment from './globalInvestment'
import globalUser from './globalUser'
import annexFiles from './annexFiles'
import contractCustomers from './contractCustomers'
import contractCustomerCompanies from './contractCustomerCompanies'
import infoCards from './infoCards'
import interestedCustomers from './interestedCustomers'
import utilityRooms from './utilityRooms'
import parkingPlaces from './parkingPlaces'
import reminder from './reminder'
import garages from './garages'
import buildings from './buildings'
import places from './places'
import registerSales from './registerSales'
import correspondences from './correspondence'
import events from './events'
import reports from './reports'
import adminIT from './adminIT'
import proposedInvestments from './proposedInvestments'
import files from './files'
import persistCopyOfForm from './persistCopyOfForm'
import paginationSlice from '../components/commons/Table2/paginationSlice'
import cancelledContracts from './cancelledContracts'
import DNIPlaceShares from './DNIPlaceShares'
import search from './searchSlice'
import persistSettings from './persistSettings'
import unassignedCustomers from './unassignedCustomers'
import { combinedReportReducers } from './reports'
import settings from './settings'
import brokers from './brokers'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moduleHistory = require('history')
export const history = moduleHistory.createBrowserHistory()

const formPersistConfig = { key: 'form', storage }
const globalInvestmentPersistConfig = { key: 'globalInvestment', storage }
const globalUserPersistConfig = { key: 'globalUser', storage }
const registerSalesPersistConfig = { key: 'registerSales', storage }
const copyOfPersistFormConfig = { key: 'persistCopyOfForm', storage }
const persistSettingsConfig = { key: 'persistSettings', storage }

export const rootReducer = combineReducers({
  arrangementFiles: arrangementFiles,
  router: connectRouter(history),
  form: persistReducer(formPersistConfig, formReducer),
  globalInvestment: persistReducer(
    globalInvestmentPersistConfig,
    globalInvestment
  ),
  globalUser: persistReducer(globalUserPersistConfig, globalUser),
  annexFiles: annexFiles,
  contractCustomers: contractCustomers,
  contractCustomerCompanies: contractCustomerCompanies,
  infoCards: infoCards,
  interestedCustomers: interestedCustomers,
  utilityRooms: utilityRooms,
  parkingPlaces: parkingPlaces,
  reminder: reminder,
  garages: garages,
  buildings: buildings,
  places: places,
  registerSales: persistReducer(registerSalesPersistConfig, registerSales),
  correspondences: correspondences,
  events: events,
  reports: reports,
  adminIT: adminIT,
  proposedInvestments: proposedInvestments,
  files: files,
  persistCopyOfForm: persistReducer(copyOfPersistFormConfig, persistCopyOfForm),
  pagination: paginationSlice,
  cancelledContracts: cancelledContracts,
  combinedReports: combinedReportReducers,
  DNIPlaceShares: DNIPlaceShares,
  search: search,
  persistSettings: persistReducer(persistSettingsConfig, persistSettings),
  unassignedCustomers: unassignedCustomers,
  settings: settings,
  brokers: brokers,
})

const isProduction = process.env.NODE_ENV === 'production'
const composeEnhancers = composeWithDevTools({})
const middleware = isProduction
  ? applyMiddleware(thunk, routerMiddleware(history))
  : composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))

const store: any = createStore(rootReducer, undefined, middleware)

const configureStore = (): any => {
  const persistor = persistStore(store)
  return { persistor, store }
}

export type RootState = StateType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export default configureStore
