import React, { FunctionComponent } from 'react'
import './Reports.scss'
import { useSelector } from 'react-redux'
import { fetchDNIAdministrationReport } from '../../ducks/reports'
import { RootState } from '../../ducks'
import { ReportWithList } from './ReportWithList'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const BuildingAdministratorsReport: FunctionComponent = () => {
  useAuthRedirect(ACCESS_PAGES.DNI_REPORTS)
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DNIAdministrationReport
  )

  return (
    <ReportWithList
      reportAPIName='DNIForAdministrationReport'
      reportName='Raport-Administratora.xlsx'
      reduxAction={fetchDNIAdministrationReport}
      isLoading={isLoading}
    />
  )
}

export default BuildingAdministratorsReport
