import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../ducks'
import { SourceInfoViewRoles } from '../ducks/globalUser'
import { hasRoles } from '../services/idp'

interface Customer {
  isAfterMigration: boolean
  sourceInfo?: string
}

export const useCustomerSourceInfo = (
  customer: Customer,
  sourceInfoOptions: string[]
): any => {
  //tuples not supported
  const { roles } = useSelector((state: RootState) => state.globalUser)

  const shouldDisplayAdditionalSourceInfoInput = React.useMemo(() => {
    const isCustomerFromMigrationAndHasDiffrentSourceInfo =
      !customer.isAfterMigration &&
      !sourceInfoOptions.includes(String(customer?.sourceInfo))
    return (
      isCustomerFromMigrationAndHasDiffrentSourceInfo &&
      hasRoles(SourceInfoViewRoles, roles)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  const [sourceInfo, setSourceInfo] = React.useState(() =>
    shouldDisplayAdditionalSourceInfoInput ? 'Inne' : customer.sourceInfo || ''
  )

  return [sourceInfo, setSourceInfo, shouldDisplayAdditionalSourceInfoInput]
}
