import GraphqlService from './graphqlService'
import { gql } from 'graphql-request'

const fetchCancelledContracts = (): Promise<any> => {
  const FETCH_CANCELLED_CONTRACTS = gql`
    query {
      cancelledContracts {
        id
        isAfterMigration
        place {
          placeCode
          roomCount
          administrativeCode1
          administrativeCode2
          designedUsablePropertyArea
          designedInnerPropertyArea
          asBuildUsablePropertyArea
          asBuildInnerPropertyArea
          investment {
            name
          }
          parkingPlaces {
            name
          }
          activeContracts {
            id
            isAfterMigration
            isArchived
            contractNo
            cancelledContract
            contractDealDate
            cancelledContract
            contractualAcceptanceDate
            contractualActDate
            acceptanceDate
            actDate
            valorization
            isBusinessPurchase
            isCredit
            isEmailInvoice
            parkingPlaces {
              name
              garage {
                id
                name
                groundPlans {
                  id
                  name
                  migrationComment
                }
              }
            }
          }
        }
        placeID
        comments
        street
        backyardTerrace
        roofTerrace
        garden
        repNumber
        otherContract
        lawContract
        deluxeContract
        valorization
        cancelledContract
        cancelledContractDate
        isEmailInvoice
        isCredit
        creditType
        creditSecurity
        lendingBank
        amountOfCredit1
        amountOfCredit2
        creditDealNo
        creditDealDate
        contractDealDate
        contractualAcceptanceDate
        contractualActDate
        acceptanceDate
        actDate
        notarialOffice
        isPledge
        pledge
        isWarranty
        warranty
        numberOfOwners
        placeID
        contractNo
        parkingPlaces {
          name
        }
        contractCustomerCompany {
          businessName
          isAgentOfMainCustomer
          isRepresentativePerson
          traderInitials
        }
        contractCustomers {
          lastName
          firstName
          isAgentOfMainCustomer
          isSpouseOfMainCustomer
          traderInitials
          isAgentOfCustomerSpouse
        }
      }
    }
  `
  return GraphqlService.send(FETCH_CANCELLED_CONTRACTS, {})
}

export interface ICancelContractProps {
  contractID: number
  cancelledContractDate?: string
}

export interface IDeleteContractProps {
  contractID: number
}

const cancelContract = (props: ICancelContractProps): Promise<any> => {
  const CANCEL_CONTRACT_QUERY = gql`
    mutation($contractID: Float!, $cancelledContractDate: String) {
      cancelContract(
        contractID: $contractID
        cancelledContractDate: $cancelledContractDate
      ) {
        id
      }
    }
  `

  return GraphqlService.send(CANCEL_CONTRACT_QUERY, props)
}

const deleteContract = (props: IDeleteContractProps): Promise<any> => {
  const DELETE_CONTRACT_QUERY = gql`
    mutation sc($contractID: Float!) {
      deleteContract(contractID: $contractID)
    }
  `

  return GraphqlService.send(DELETE_CONTRACT_QUERY, props)
}

const mutations = { fetchCancelledContracts, cancelContract, deleteContract }
export default mutations
