import { emailPattern } from '../constants/patterns'

export const isValidEmail = (email: string) => email && emailPattern.test(email)

export const isValidPhone1 = (phone?: string) =>
  Boolean(phone && phone?.indexOf('_') === -1)

export const isValidPhone2 = (phone?: string) => Boolean(phone)

export const getFormBlocks = (state: any) => {
  const showEmailTooltip =
    (state?.email || (!state?.phone1 && !state?.phone2)) &&
    !isValidEmail(state?.email)
  const showEmail2Tooltip = state?.email2 && !isValidEmail(state?.email2)
  const showPhone1Tooltip =
    (state?.phone1 || (!state?.email && !state?.phone2)) &&
    !isValidPhone1(state.phone1)
  const showPhone2Tooltip =
    (state?.phone2 || (!state?.email && !state?.phone1)) &&
    !isValidPhone2(state?.phone2?.length)

  const tooltipBlock =
    showPhone1Tooltip ||
    showPhone2Tooltip ||
    showEmailTooltip ||
    showEmail2Tooltip

  return {
    showPhone1Tooltip,
    showPhone2Tooltip,
    showEmailTooltip,
    showEmail2Tooltip,
    tooltipBlock,
  }
}
