import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from './'
import DNIPlaceSharesService, {
  IPlaceShares,
  ImportDNIShares,
} from '../services/dniPlaceSharesService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface IDNIPlacesSharesState {
  data: IPlaceShares[]
  isLoading: boolean
  saved: boolean
  error: boolean
  wrongData: IPlaceShares[]
}

interface IUpdateData {
  index: number
  data: Partial<IPlaceShares>
}

const initialState: IDNIPlacesSharesState = {
  data: [],
  isLoading: false,
  saved: false,
  error: false,
  wrongData: [],
}

export const importShares = createAsyncThunk(
  'DNIPlaceShares/importShares',
  async (variables: ImportDNIShares) => {
    return DNIPlaceSharesService.importPlaceShares(variables)
  }
)

export const generateDNIImportSharesReport = createAsyncThunk(
  'DNIPlacesShares/generateDNIImportSharesReport',
  async (data, thunkAPI) => {
    const investmentID: number = (thunkAPI.getState() as RootState)
      .globalInvestment.chosenInvestment?.id
    const edit: IPlaceShares[] = (thunkAPI.getState() as RootState)
      .DNIPlaceShares.data
    return DNIPlaceSharesService.generateDNIReport({ investmentID, edit })
  }
)

export const saveDNIPlaceShares = createAsyncThunk(
  'DNIPlacesShares/saveDNIPlaceShares',
  async (data, thunkAPI) => {
    const investmentID: number = (thunkAPI.getState() as RootState)
      .globalInvestment.chosenInvestment?.id
    const toUpdateImportedDataList: IPlaceShares[] = (thunkAPI.getState() as RootState)
      .DNIPlaceShares.data
    return DNIPlaceSharesService.saveDNIPlaceShares({
      investmentID,
      toUpdateImportedDataList,
    })
  }
)

const DNIPlacesSharesData = (state: RootState): IPlaceShares[] =>
  state.DNIPlaceShares.data
export const getDataByIndex = (index: number) =>
  createSelector(DNIPlacesSharesData, (data) => data[index])

const DNIPlaceSharesSlice = createSlice({
  name: ' DNIPlaceShares',
  initialState,
  reducers: {
    updateData(state, action: PayloadAction<IUpdateData>): void {
      const dataToUpdate = state.data[action.payload.index]
      state.saved = false
      state.data[action.payload.index] = {
        ...dataToUpdate,
        ...action.payload.data,
      }
    },
    cancelError(state): void {
      state.error = false
      state.wrongData = []
    },
  },
  extraReducers: {
    [importShares.pending.toString()]: (state): void => {
      state.isLoading = true
    },
    [importShares.fulfilled.toString()]: (state, action): void => {
      const wrongImportData = action.payload.importDNIData.filter(
        (item: { placeIsTypeService: string }) =>
          !item.placeIsTypeService.length
      )
      const correctImportData = action.payload.importDNIData.filter(
        (item: { placeIsTypeService: string }) =>
          !!item.placeIsTypeService.length
      )
      state.data = correctImportData
      state.isLoading = false
      if (wrongImportData.length) {
        state.error = true
        state.wrongData = wrongImportData
      }
    },
    [importShares.rejected.toString()]: (state): void => {
      state.isLoading = false
      state.error = true
    },
    [generateDNIImportSharesReport.fulfilled.toString()]: (state): void => {
      state.isLoading = false
    },
    [generateDNIImportSharesReport.pending.toString()]: (state): void => {
      state.isLoading = true
    },
    [generateDNIImportSharesReport.rejected.toString()]: (state): void => {
      errorToastNotify(
        String(i18n.t('toast:generateDNIImportSharesReportError'))
      )
      state.isLoading = false
    },
    [saveDNIPlaceShares.pending.toString()]: (state): void => {
      state.isLoading = true
    },
    [saveDNIPlaceShares.fulfilled.toString()]: (state, action): void => {
      state.data = action.payload.updateImportedDNIData
      successToastNotify(String(i18n.t('toast:savedPlaceShares')))
      state.saved = true
      state.isLoading = false
    },
    [saveDNIPlaceShares.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:savedPlaceSharesError')))
      state.isLoading = false
    },
  },
})

export const { updateData, cancelError } = DNIPlaceSharesSlice.actions

export default DNIPlaceSharesSlice.reducer
