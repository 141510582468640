import React, { FC, useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { tooltipOnTopZIndex, tooltipZIndex } from '../../../constants/zIndexes'

type ToolTipPlacement = 'top' | 'right' | 'bottom' | 'left'

interface Props {
  isTooltipAlwaysOnTop?: boolean
  isVisibleAsDefault?: boolean
  placement?: ToolTipPlacement
  message: string
  shouldTooltipBeVisible?: boolean
}

const ButtonWithTooltip: FC<Props> = ({
  isTooltipAlwaysOnTop,
  isVisibleAsDefault = false,
  placement = 'top',
  message,
  children,
  shouldTooltipBeVisible = true,
}) => {
  const tooltip = useMemo(
    () => (
      <Tooltip
        id={message}
        style={{
          zIndex: isTooltipAlwaysOnTop ? tooltipOnTopZIndex : tooltipZIndex,
        }}
      >
        {message}
      </Tooltip>
    ),
    [isTooltipAlwaysOnTop, message]
  )

  return (
    <>
      {shouldTooltipBeVisible ? (
        <OverlayTrigger
          defaultShow={isVisibleAsDefault}
          placement={placement}
          overlay={tooltip}
        >
          <span>{children}</span>
        </OverlayTrigger>
      ) : (
        children
      )}
    </>
  )
}

export default ButtonWithTooltip
