import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'

import i18n from '../i18n'

import UnassignedCustomersService, {
  MessagesOfUnassignedCustomerPaginationProps,
  UnassignedCustomersPaginationProps,
  assignUnassignedCustomerToExistingCustomerProps,
  createCustomerFromUnassignedCustomerProps,
} from '../services/unassignedCustomersService'
import { IMarketingConsent } from '../graphql/marketingConsents'

export type ProposedInvestment = {
  investmentID: number
  stageID: number
  placeID?: number
  isAfterMigration?: boolean
}
export interface IAttachment {
  id: number
  content?: string
  name?: string
}

export interface IShowAttachmentProps {
  messageID?: number
  unassignedCustomerID?: number
}

export type IShowAttachment = ({
  messageID,
  unassignedCustomerID,
}: IShowAttachmentProps) => void

export interface IMessage {
  date?: string
  id: number
  content?: string
  htmlContent?: string
  attachments?: { id: number; content: string }[]
  subject?: string
  isLinkedWithContact?: boolean
  isSpam?: boolean
  isArchive?: boolean
  fromCustomer?: boolean
  eml?: string
  unassignedCustomerID: string
}

export interface IUnassignedCustomer {
  id: number
  lastName: string
  firstName: string
  phone?: string
  email: string
  email2?: string
  customerID?: number | null
  messages?: {
    attachments?: {
      id: number
    }[]
  }[]
  traderInitials?: string
  status?: string
  source?: string
  marketingConsents?: IMarketingConsent[]
  brokerName?: string
  isBroker?: boolean
  proposedInvestments?: {
    investmentID: number
    stageID: number
    placeID: number
    isAfterMigration: boolean
  }[]
}

interface IUnassignedCustomersState {
  isLoadingCreateContact: boolean
  isLoadingSelectedUnassignedCustomer: boolean
  isLoadingUnassignedCustomers: boolean
  isLoadingMessagesByUnassignedCustomerID: boolean
  numberOfMessages: number
  numberOfMessagesChange: boolean
  numberOfNotTakenUnassignedCustomers: number
  selectedUnassignedCustomer: any
  unassignedCustomers: any
  message?: IMessage
  messagesByUnassignedCustomerID?: IMessage[]
  unassignedCustomerIDForMessages?: string
  totalMessagesOfUnassignedCustomer?: number
  totalUnassignedCustomers?: number
}

const initialState: IUnassignedCustomersState = {
  isLoadingCreateContact: false,
  isLoadingSelectedUnassignedCustomer: false,
  isLoadingUnassignedCustomers: false,
  isLoadingMessagesByUnassignedCustomerID: false,
  numberOfMessages: 0,
  numberOfMessagesChange: false,
  numberOfNotTakenUnassignedCustomers: 0,
  selectedUnassignedCustomer: undefined,
  unassignedCustomers: [],
  message: undefined,
  messagesByUnassignedCustomerID: [],
  unassignedCustomerIDForMessages: undefined,
  totalMessagesOfUnassignedCustomer: 0,
  totalUnassignedCustomers: 0,
}

export const getIDsOfUnassignedCustomersWithActiveMessagesOrCustomerID =
  createAsyncThunk(
    'unassignedCustomers/getIDsOfUnassignedCustomersWithActiveMessagesOrCustomerID',
    async () => {
      return {
        unassignedCustomersIDs:
          await UnassignedCustomersService.getNotTakenUnassignedCustomers(),
      }
    }
  )

export const getMessageByMessageID = createAsyncThunk(
  'unassignedCustomers/getMessageByMessageID',
  async (variables: { messageID: number }) => {
    return {
      messageID: await UnassignedCustomersService.getMessageByMessageID(
        variables
      ),
    }
  }
)

export const getMessageFileByMessageFileID = createAsyncThunk(
  'unassignedCustomers/getMessageFileByMessageFileID',
  async (variables: { messageFileID: number }) => {
    return {
      messageFile:
        await UnassignedCustomersService.getMessageFileByMessageFileID(
          variables
        ),
    }
  }
)

export const getMessagesByUnassignedCustomerID = createAsyncThunk(
  'unassignedCustomers/getMessagesByUnassignedCustomerID',
  async (variables: MessagesOfUnassignedCustomerPaginationProps) => {
    return {
      messagesByUnassignedCustomerID:
        await UnassignedCustomersService.getMessagesByUnassignedCustomerID(
          variables
        ),
      unassignedCustomerID: variables.unassignedCustomerID,
    }
  }
)

export const getNumberOfMessagesFromUnassignedCustomers = createAsyncThunk(
  'unassignedCustomers/getNumberOfMessagesFromUnassignedCustomers',
  async () => {
    return {
      numberOfMessages:
        await UnassignedCustomersService.getNumberOfMessagesFromUnassignedCustomers(),
    }
  }
)

export const getUnassignedCustomerByID = createAsyncThunk(
  'unassignedCustomers/getUnassignedCustomerByID',
  async (variables: { unassignedCustomerID: number }) => {
    return {
      selectedUnassignedCustomer:
        await UnassignedCustomersService.getUnassignedCustomerByID(variables),
    }
  }
)

export const getUnassignedCustomers = createAsyncThunk(
  'unassignedCustomers/getUnassignedCustomers',
  async (arg?: UnassignedCustomersPaginationProps) => {
    return {
      unassignedCustomers:
        await UnassignedCustomersService.getUnassignedCustomers(arg),
    }
  }
)

export const markAsSpamMessage = createAsyncThunk(
  'unassignedCustomers/markAsSpamMessage',
  async (variables: { messageID: number }) => {
    return {
      messageID: await UnassignedCustomersService.markAsSpamMessage(variables),
    }
  }
)

export const saveContactFromMailMessage = createAsyncThunk(
  'unassignedCustomers/saveContactFromMailMessage',
  async (variables: {
    messageID: number
    unassignedCustomerEmail: string
    contact: any
    proposedInvestments?: ProposedInvestment[]
  }) => {
    return {
      messageID: await UnassignedCustomersService.saveContactFromMailMessage(
        variables
      ),
    }
  }
)

export const updateUnassignedCustomer = createAsyncThunk(
  'unassignedCustomers/updateUnassignedCustomer',
  async (variables: {
    unassignedCustomer: Partial<IUnassignedCustomer>
    unassignedCustomerID: number
  }) => {
    return UnassignedCustomersService.updateUnassignedCustomer(variables)
  }
)

export const takeUnassignedCustomer = createAsyncThunk(
  'unassignedCustomers/takeUnassignedCustomer',
  async (variables: { unassignedCustomerID: number }) => {
    return UnassignedCustomersService.takeUnassignedCustomer(variables)
  }
)

export const forgotUnassignedCustomer = createAsyncThunk(
  'unassignedCustomers/forgotUnassignedCustomer',
  async (variables: { unassignedCustomerID: number }) => {
    return UnassignedCustomersService.forgotUnassignedCustomer(variables)
  }
)
export const forwardUnassignedCustomer = createAsyncThunk(
  'unassignedCustomers/forwardUnassignedCustomer',
  async (variables: {
    traderInitials: string
    unassignedCustomerID: number
  }) => {
    return UnassignedCustomersService.forwardUnassignedCustomer(variables)
  }
)
export const createCustomerFromUnassignedCustomer = createAsyncThunk(
  'unassignedCustomers/createCustomerFromUnassignedCustomer',
  async (variables: createCustomerFromUnassignedCustomerProps) => {
    return UnassignedCustomersService.createCustomerFromUnassignedCustomer(
      variables
    )
  }
)
export const assignUnassignedCustomerToExistingCustomer = createAsyncThunk(
  'unassignedCustomers/assignUnassignedCustomerToExistingCustomer',
  async (variables: assignUnassignedCustomerToExistingCustomerProps) => {
    return UnassignedCustomersService.assignUnassignedCustomerToExistingCustomer(
      variables
    )
  }
)

export const markUnassignedCustomerFromMessage = createAsyncThunk(
  'unassignedCustomers/markUnassignedCustomerFromMessage',
  async (variables: { messageID: number }) => {
    return UnassignedCustomersService.markUnassignedCustomerFromMessage(
      variables
    )
  }
)

const unassignedCustomersSlice = createSlice({
  name: 'unassignedCustomers',
  initialState,
  reducers: {
    clearUnassignedCustomer(state): void {
      state.selectedUnassignedCustomer = undefined
    },
    setNumberOfMessagesChange(state, action): void {
      state.numberOfMessagesChange = action.payload
    },
  },
  extraReducers: {
    [getIDsOfUnassignedCustomersWithActiveMessagesOrCustomerID.fulfilled.toString()]:
      (state, action): void => {
        state.numberOfNotTakenUnassignedCustomers =
          action.payload?.unassignedCustomersIDs?.unassignedCustomers?.total
      },
    [getMessageByMessageID.fulfilled.toString()]: (state, action): void => {
      state.message = action.payload.messageID.message
    },
    [getMessagesByUnassignedCustomerID.pending.toString()]: (
      state,
    ): void => {
      state.isLoadingMessagesByUnassignedCustomerID = true
    },
    [getMessagesByUnassignedCustomerID.rejected.toString()]: (
      state,
    ): void => {
      state.isLoadingMessagesByUnassignedCustomerID = false
    },
    [getMessagesByUnassignedCustomerID.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.isLoadingMessagesByUnassignedCustomerID = false
      state.unassignedCustomerIDForMessages =
        action.payload.unassignedCustomerID
      state.messagesByUnassignedCustomerID =
        action.payload.messagesByUnassignedCustomerID.getMessagesByUnassignedCustomerID.messages
      state.totalMessagesOfUnassignedCustomer =
        action.payload.messagesByUnassignedCustomerID.getMessagesByUnassignedCustomerID.total
    },
    [getNumberOfMessagesFromUnassignedCustomers.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.numberOfMessages =
        action.payload.numberOfMessages.getNumberOfMessagesFromUnassignedCustomers
    },
    [getUnassignedCustomerByID.fulfilled.toString()]: (state, action): void => {
      state.selectedUnassignedCustomer =
        action.payload.selectedUnassignedCustomer.unassignedCustomer
      state.isLoadingSelectedUnassignedCustomer = false
    },
    [getUnassignedCustomerByID.pending.toString()]: (state): void => {
      state.isLoadingSelectedUnassignedCustomer = true
    },
    [getUnassignedCustomerByID.rejected.toString()]: (state): void => {
      state.isLoadingSelectedUnassignedCustomer = false
    },
    [getUnassignedCustomers.fulfilled.toString()]: (state, action): void => {
      state.unassignedCustomers =
        action.payload.unassignedCustomers.unassignedCustomers.unassignedCustomers
      state.totalUnassignedCustomers =
        action.payload.unassignedCustomers.unassignedCustomers.total
      state.isLoadingUnassignedCustomers = false
    },
    [getUnassignedCustomers.pending.toString()]: (state): void => {
      state.isLoadingUnassignedCustomers = true
    },
    [getUnassignedCustomers.rejected.toString()]: (state): void => {
      state.isLoadingUnassignedCustomers = false
    },
    [markAsSpamMessage.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:markAsSpamMessage')))
    },
    [markAsSpamMessage.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:markAsSpamMessageFailed')))
    },
    [saveContactFromMailMessage.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:saveContactFromMailMessage')))
      state.isLoadingCreateContact = false
    },
    [saveContactFromMailMessage.pending.toString()]: (state): void => {
      state.isLoadingCreateContact = true
    },
    [saveContactFromMailMessage.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:saveContactFromMailMessageFailed')))
      state.isLoadingCreateContact = false
    },
    [updateUnassignedCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:updateUnassignedCustomer')))
    },
    [updateUnassignedCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:updateUnassignedCustomerFailed')))
    },
    [takeUnassignedCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:takeUnassignedCustomer')))
    },
    [takeUnassignedCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:takeUnassignedCustomerFailed')))
    },
    [forgotUnassignedCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:forgotUnassignedCustomer')))
    },
    [forgotUnassignedCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:forgotUnassignedCustomerFailed')))
    },
    [forwardUnassignedCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:forwardUnassignedCustomer')))
    },
    [forwardUnassignedCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:forwardUnassignedCustomerFailed')))
    },
    [assignUnassignedCustomerToExistingCustomer.fulfilled.toString()]:
      (): void => {
        successToastNotify(String(i18n.t('toast:assignUnassignedCustomer')))
      },
    [assignUnassignedCustomerToExistingCustomer.rejected.toString()]:
      (): void => {
        errorToastNotify(String(i18n.t('toast:assignUnassignedCustomerFailed')))
      },
    [createCustomerFromUnassignedCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:createFromUnassignedCustomer')))
    },
    [createCustomerFromUnassignedCustomer.rejected.toString()]: (): void => {
      errorToastNotify(
        String(i18n.t('toast:createFromUnassignedCustomerFailed'))
      )
    },
    [markUnassignedCustomerFromMessage.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:successMarkAsCustomer')))
    },
    [markUnassignedCustomerFromMessage.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:errorMarkAsCustomer')))
    },
  },
})

export const { clearUnassignedCustomer, setNumberOfMessagesChange } =
  unassignedCustomersSlice.actions
export default unassignedCustomersSlice.reducer
