import { useState } from 'react'

export const useFormValidationNotEmpty = (e: any): boolean => {
  const [validated, setValidated] = useState(false)
  const form = e.currentTarget
  e.preventDefault()
  e.stopPropagation()
  if (form.checkValidity()) {
    setValidated(true)
  }
  return validated
}
