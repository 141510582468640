import React, { FunctionComponent, useCallback } from 'react'
import { Button, Dropdown, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route as Router, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import ButtonWithTooltip from '../commons/Inputs/ButtonTooltip'
import {
  DARreportsMeta,
  DKOreportsMeta,
  DKSReportsMeta,
  DNIAdministrationMeta,
  DNIreportsMeta,
  DNKAdministrationMeta,
  DNKreportsMeta,
  DPPReportsMeta,
  DSPAdministrationMeta,
  DSPandDSPADMandDNKMeta,
  DSPandDSPADMreportsMeta,
  SKAReportsMeta,
} from '../../mockups/tempHeaderMeta'
import { DropDownItem } from './DropDownItem'
import { useUserAbility, useUserPagesAbility } from '../../hooks/ability'
import ACCESS_PAGES from '../../constants/accessPages'

export interface ILabelbuttonID {
  id?: number
  buttonID?: string
  href?: string
  label?: string
}

interface IHeaderMenuSpecificOptionProps {
  buttonID?: string
  disabled?: boolean
  meta: string | ILabelbuttonID[]
  nameOfHeaderOption: string
  placeActiveContract?: boolean
  selectedPlaceID?: number
}

export const HeaderMenuSpecificOption: FunctionComponent<IHeaderMenuSpecificOptionProps> =
  (props: IHeaderMenuSpecificOptionProps) => {
    const history = useHistory()
    const { t } = useTranslation()

    const { chosenInvestment } = useSelector(
      (state: RootState) => state.globalInvestment
    )

    const {
      buttonID,
      disabled = false,
      meta,
      nameOfHeaderOption,
      placeActiveContract,
      selectedPlaceID,
    } = props

    const isChosenInvestment =
      chosenInvestment?.name !== 'Wszystkie' &&
      chosenInvestment?.name !== 'Umowy anulowane'
    const isButtonDisabledNotSelectedPlace =
      nameOfHeaderOption === 'Aranżacje' && !selectedPlaceID
    const isButtonDisabledNotSelectedInvestment =
      nameOfHeaderOption === 'Korespondencja Seryjna' && !isChosenInvestment
    const isButtonDisabled =
      isButtonDisabledNotSelectedPlace || isButtonDisabledNotSelectedInvestment
    const tooltipMessage = isButtonDisabledNotSelectedPlace
      ? t('commons:labels.choose-place')
      : t('commons:labels.choose-investment')
    const onLinkClick = useCallback(
      (href: string) => {
        history.push(href)
      },
      [history]
    )

    if (meta === 'button') {
      return (
        <ButtonWithTooltip
          message={tooltipMessage}
          shouldTooltipBeVisible={isButtonDisabled}
        >
          <Button
            disabled={isButtonDisabled}
            id={`${nameOfHeaderOption}-${meta}`}
            className='ik2-main-button-color'
            onClick={(): void =>
              onLinkClick(!isButtonDisabled && buttonID ? buttonID : '/')
            }
          >
            {nameOfHeaderOption}
          </Button>
        </ButtonWithTooltip>
      )
    } else {
      return (
        <>
          <Dropdown>
            {(placeActiveContract || !selectedPlaceID) &&
            nameOfHeaderOption.indexOf('Rejestracja') !== -1 ? (
              <ButtonWithTooltip
                isTooltipAlwaysOnTop
                message={t('commons:labels.choose-free-place-to-register')}
                shouldTooltipBeVisible={!selectedPlaceID || placeActiveContract}
              >
                <Dropdown.Toggle
                  aria-expanded='false'
                  id='dropdown-basic'
                  style={{
                    background: '#06bff3',
                    color: '#e6e6e6',
                    opacity: '0.65',
                  }}
                >
                  {nameOfHeaderOption}
                </Dropdown.Toggle>
              </ButtonWithTooltip>
            ) : (
              <Dropdown.Toggle id='dropdown-basic' disabled={disabled}>
                {nameOfHeaderOption}
              </Dropdown.Toggle>
            )}
            {!disabled && (
              <Dropdown.Menu className='dw-100'>
                {Array.isArray(meta) &&
                  meta.map((item: ILabelbuttonID) => (
                    <DropDownItem
                      {...item}
                      key={item.buttonID}
                      onLinkClick={onLinkClick}
                    />
                  ))}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </>
      )
    }
  }

interface HeaderMenuSpecificOptionsProps {
  placeActiveContract?: boolean
  selectedPlaceID?: number
}

export const HeaderMenuSpecificOptions: FunctionComponent<HeaderMenuSpecificOptionsProps> =
  (props: HeaderMenuSpecificOptionsProps) => {
    const history = useHistory()
    const { canReadPage } = useUserPagesAbility()
    const { t } = useTranslation('headerMenu')
    const { placeActiveContract, selectedPlaceID } = props
    const { chosenPlacesContract } = useSelector(
      (state: RootState) => state.globalInvestment
    )
    const { mainTableCurrentPage } = useSelector(
      (state: RootState) => state.pagination
    )
    const { mainTableSelectedRowPage } = useSelector(
      (state: RootState) => state.persistSettings
    )
    const { numberOfNotTakenUnassignedCustomers } = useSelector(
      (state: RootState) => state.unassignedCustomers
    )
    const disableRegistrationButton =
      chosenPlacesContract?.investment?.isArchive ||
      mainTableCurrentPage !== mainTableSelectedRowPage

    const onLinkClick = useCallback(
      (href: string) => {
        history.push(href)
      },
      [history]
    )

    const administrationMeta = canReadPage(ACCESS_PAGES.DNI_REPORTS)
      ? DNIAdministrationMeta
      : canReadPage(ACCESS_PAGES.DNK_REPORTS)
      ? DNKAdministrationMeta
      : canReadPage(ACCESS_PAGES.DSPADM_REPORTS)
      ? DSPAdministrationMeta
      : undefined

    const reportsMeta = canReadPage(ACCESS_PAGES.DAR_REPORTS)
      ? DARreportsMeta
      : canReadPage(ACCESS_PAGES.SKA_REPORTS)
      ? SKAReportsMeta
      : canReadPage(ACCESS_PAGES.DKO_REPORTS)
      ? DKOreportsMeta
      : canReadPage(ACCESS_PAGES.DPP_REPORTS)
      ? DPPReportsMeta
      : canReadPage(ACCESS_PAGES.DKS_REPORTS)
      ? DKSReportsMeta
      : canReadPage(ACCESS_PAGES.DNI_REPORTS)
      ? DNIreportsMeta
      : canReadPage(ACCESS_PAGES.DSP_AND_DSPADM_REPORTS)
      ? DSPandDSPADMreportsMeta
      : undefined

    return (
      <>
        {canReadPage(ACCESS_PAGES.ADMIN_IT_PANEL) && (
          <Button
            className='ik2-main-button-color'
            onClick={(): void => onLinkClick('/')}
          >
            {t('commons:menu.admin-it-panel')}
          </Button>
        )}
        {canReadPage(ACCESS_PAGES.INTERESTED_CUSTOMERS) && (
          <Button
            className='ik2-main-button-color'
            onClick={(): void =>
              onLinkClick('/interested-customers-sales-department')
            }
          >
            {t('commons:menu.interested-customers')}
          </Button>
        )}
        {canReadPage(ACCESS_PAGES.TABLE_UNASSIGNED_CUSTOMERS) && (
          <Button
            className='ik2-main-button-color'
            onClick={(): void => onLinkClick('/unassigned-customers')}
          >
            {`${t(
              'commons:menu.unassigned-customers'
            )} (${numberOfNotTakenUnassignedCustomers})`}
          </Button>
        )}
        {canReadPage(ACCESS_PAGES.SALES_REGISTRATION) && (
          <HeaderMenuSpecificOption
            nameOfHeaderOption={t('commons:menu.sales-registration')}
            placeActiveContract={placeActiveContract}
            meta={
              placeActiveContract || !selectedPlaceID
                ? []
                : DSPandDSPADMandDNKMeta
            }
            selectedPlaceID={selectedPlaceID}
            disabled={disableRegistrationButton}
          />
        )}
        {/* TODO : poza zakresem podstawowym
    {hasRoles(rentalRegistrationViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.rental-registration')} meta={rentalRegistrationMeta(id)}
    ctrlID={id} selectedPlaceID={selectedPlaceID} />)} */}
        {canReadPage(ACCESS_PAGES.ARRANGEMENTS) && (
          <HeaderMenuSpecificOption
            nameOfHeaderOption={t('commons:menu.arrangements')}
            meta={'button'}
            buttonID={'arrangements'}
            selectedPlaceID={selectedPlaceID}
          />
        )}
        {canReadPage(ACCESS_PAGES.DKO_SERIAL_CORRESPONDENCE_REPORT) && (
          <HeaderMenuSpecificOption
            nameOfHeaderOption={t('commons:menu.mail-merge-report')}
            meta={'button'}
            buttonID={'mail-merge-report'}
            selectedPlaceID={selectedPlaceID}
          />
        )}
        {canReadPage(ACCESS_PAGES.DMA_MARKETING_REPORT) && (
          <HeaderMenuSpecificOption
            nameOfHeaderOption={t('commons:menu.reports')}
            meta={'button'}
            buttonID={'/'}
            selectedPlaceID={selectedPlaceID}
          />
        )}
        {reportsMeta && (
          <HeaderMenuSpecificOption
            nameOfHeaderOption={t('commons:menu.reports')}
            meta={reportsMeta}
            selectedPlaceID={selectedPlaceID}
          />
        )}
        {administrationMeta && (
          <HeaderMenuSpecificOption
            nameOfHeaderOption={t('commons:menu.administration')}
            meta={administrationMeta}
            selectedPlaceID={selectedPlaceID}
          />
        )}
      </>
    )
  }

const HeaderMenu = (): JSX.Element | null => {
  const history = useHistory()
  const location = useLocation()
  const ability = useUserAbility()
  const { t } = useTranslation('headerMenu')

  const { chosenPlacesContract } = useSelector(
    (state: RootState) => state.globalInvestment
  )
  const user = useSelector((state: RootState) => state.globalUser)

  const hasPlaceActiveContract = chosenPlacesContract?.activeContracts?.length

  const onLinkClick = useCallback(
    (href: string) => {
      history.push(href)
    },
    [history]
  )

  const generateHeader = (): JSX.Element | null => {
    const administationPathnames: string[] = [
      '/add-investment-admin',
      '/buildings-admin',
      '/garages-admin',
      '/places-admin',
      '/utility-rooms-admin',
      '/parking-places-admin',
      '/tough-customers-admin',
      '/settings',
    ]
    const isUserDSPADMinAdministration =
      user.roles.includes('DSP-ADM') &&
      administationPathnames?.includes(location.pathname)

    const canSeeMainTableView = ability.can('see', 'Path', 'main-table-view')
    const mainViewForDMAOrADM =
      location.pathname === '/main-table-view' && canSeeMainTableView
    const mainViewForOtherRoles =
      (location.pathname === '/' ||
        location.pathname === '/cancelled-contracts') &&
      !canSeeMainTableView

    if (
      mainViewForDMAOrADM ||
      mainViewForOtherRoles ||
      isUserDSPADMinAdministration
    ) {
      return (
        <Row className='d-flex justify-content-center'>
          <Button
            className='ik2-main-button-color'
            onClick={(): void => {
              onLinkClick(
                `${mainViewForDMAOrADM ? '/main-table-view' : ''}/tasks`
              )
            }}
          >
            {t('commons:menu.tasks')}
          </Button>
          <HeaderMenuSpecificOptions
            placeActiveContract={Boolean(hasPlaceActiveContract)}
            selectedPlaceID={chosenPlacesContract?.id}
          />
        </Row>
      )
    } else {
      return null
    }
  }

  return <Router>{generateHeader()}</Router>
}

export default HeaderMenu
