import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'
import { GenericPaginationProps } from './interestedCustomerService'

export interface ICurrentInterestedCustomer {
  id: number
  contracts?: {
    id: number
  }[]
}

export interface ICurrentContractCustomer {
  id: number
  contractID: number
  contract: any
  isAgentOfMainCustomer?: boolean
  isSpouseOfMainCustomer?: boolean
  isAgentOfCustomerSpouse?: boolean
  customerCode: string
}

export interface IEmployeeLogs {
  date: string
  opID: string
  author: string
}
export interface IEmployee {
  firstName: string
  lastName: string
  email: string
  phone: string
  id: string
  roles: Array<string[]>
  username: string
  initials: string
  password: string
}

export interface IEmployeeInput {
  firstName: string
  lastName: string
  email: string
  phone: string
  roles: Array<string[]>
  username: string
  initials: string
  password?: string
}

export interface FetchClientsInInvestmentsProps extends GenericPaginationProps {
  isBlueList?: boolean
  isToughCustomer?: boolean
  keyword?: string
}

export interface IEmployeeLogsFetch extends GenericPaginationProps {
  startDate: string
  endDate: string
}

const getClientsInInvestments = (
  variables: FetchClientsInInvestmentsProps
): Promise<void> => {
  const FETCH_CLIENTS_IN_INVESTMENTS = gql`
    query(
      $perPage: Float
      $page: Float
      $isBlueList: Boolean
      $isToughCustomer: Boolean
      $keyword: String
      $sortField: String
      $sortOrder: Boolean
      $isDNKCustomer: Boolean
    ) {
      paginatedContractCustomersByKeyWordsForAdmin(
        perPage: $perPage
        page: $page
        isBlueList: $isBlueList
        isToughCustomer: $isToughCustomer
        keyWords: $keyword
        sortField: $sortField
        sortOrder: $sortOrder
        isDNKCustomer: $isDNKCustomer
      ) {
        total
        contractCustomers {
          id
          isAgentOfMainCustomer
          isSpouseOfMainCustomer
          isAgentOfCustomerSpouse
          lastName
          secondName
          firstName
          sex
          email
          phone1
          phone2
          businessName
          contractID
          isToughCustomer
          isBlueList
          customerCode
          pesel
          regon
          brokerName
          placeShares
          businessName
          customerInvestmentCode
          maritalStatus
          traderInitials
          contract {
            contractDealDate
            numberOfOwners
            contractNo
            contractCustomers {
              id
              uuid
              customerParentID
              isAgentOfMainCustomer
              isSpouseOfMainCustomer
              isAgentOfCustomerSpouse
            }
            contractCustomerCompany {
              id
              uuid
              isAgentOfMainCustomer
              isRepresentativePerson
              companyParentID
            }
            place {
              investment {
                name
              }
              placeCode
            }
          }
          marketingConsents {
            isInfoDutyFulfilled
            infoDutyForm
            infoDutyFormDate
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            isMSWiAAgreement
          }
        }
      }
    }
  `

  return GraphqlService.send(FETCH_CLIENTS_IN_INVESTMENTS, {
    ...variables,
    sortField: variables.sortBy?.id,
    sortOrder: variables.sortBy?.desc,
  })
}

const getClientsInInvestmentsForSearch = (
  variables: FetchClientsInInvestmentsProps
): Promise<void> => {
  const FETCH_CLIENTS_IN_INVESTMENTS = gql`
    query(
      $perPage: Float
      $page: Float
      $isBlueList: Boolean
      $isToughCustomer: Boolean
      $keyword: String
      $sortField: String
      $sortOrder: Boolean
      $isDNKCustomer: Boolean
    ) {
      paginatedContractCustomersByKeyWordsForAdmin(
        perPage: $perPage
        page: $page
        isBlueList: $isBlueList
        isToughCustomer: $isToughCustomer
        keyWords: $keyword
        sortField: $sortField
        sortOrder: $sortOrder
        isDNKCustomer: $isDNKCustomer
      ) {
        total
        contractCustomers {
          id
          businessName
          customerInvestmentCode
          traderInitials
          isBusiness
          contract {
            contractCustomerCompanies {
              id
              businessName
            }
            contractCustomers {
              id
              maritalStatus
              isSpouseOfMainCustomer
              isAgentOfMainCustomer
              isAgentOfCustomerSpouse
              firstName
              lastName
              uuid
              customerParentID
            }
            valorization
            isCredit
            isEmailInvoice
            actDate
            acceptanceDate
            contractualAcceptanceDate
            contractualActDate
            contractDealDate
            numberOfOwners
            contractNo
            numberOfOwners
            parkingPlaces {
              name
            }
            utilityRooms {
              number
              designedInnerUtilRoomArea
              asBuildUsableUtilRoomArea
              asBuildInnerUtilRoomArea
              parkingPlace {
                id
                name
              }
            }
            place {
              parkingPlaces {
                name
              }
              utilityRooms {
                number
                designedInnerUtilRoomArea
                asBuildUsableUtilRoomArea
                asBuildInnerUtilRoomArea
                parkingPlace {
                  id
                  name
                }
              }
              designedInnerPropertyArea
              designedUsablePropertyArea
              asBuildInnerPropertyArea
              asBuildUsablePropertyArea
              administrativeCode1
              administrativeCode2
              stage {
                street
              }
              investment {
                name
              }
              placeCode
              roomCount
            }
          }
        }
      }
    }
  `

  return GraphqlService.send(FETCH_CLIENTS_IN_INVESTMENTS, {
    ...variables,
    sortField: variables.sortBy?.id,
    sortOrder: variables.sortBy?.desc,
  })
}

const getCompaniesInInvestmentsForSearch = (
  variables: FetchClientsInInvestmentsProps
): Promise<any> => {
  const FETCH_COMPANIES_IN_INVESTMENTS = gql`
    query(
      $perPage: Float
      $page: Float
      $isBlueList: Boolean
      $keyword: String
      $sortField: String
      $sortOrder: Boolean
      $isDNKCustomer: Boolean
    ) {
      paginatedContractCustomerCompaniesByKeyWordsForAdmin(
        perPage: $perPage
        page: $page
        isBlueList: $isBlueList
        keyWords: $keyword
        sortField: $sortField
        sortOrder: $sortOrder
        isDNKCustomer: $isDNKCustomer
      ) {
        total
        contractCustomerCompanies {
          id
          businessName
          customerInvestmentCode
          traderInitials
          contract {
            contractCustomerCompanies {
              id
              businessName
              isAgentOfMainCustomer
              isRepresentativePerson
            }
            contractCustomers {
              id
              maritalStatus
              isSpouseOfMainCustomer
              isAgentOfMainCustomer
              isAgentOfCustomerSpouse
              firstName
              lastName
              uuid
              customerParentID
            }
            valorization
            isCredit
            isEmailInvoice
            actDate
            acceptanceDate
            contractualAcceptanceDate
            contractualActDate
            contractDealDate
            numberOfOwners
            contractNo
            numberOfOwners
            parkingPlaces {
              name
            }
            utilityRooms {
              number
              designedInnerUtilRoomArea
              asBuildUsableUtilRoomArea
              asBuildInnerUtilRoomArea
              parkingPlace {
                id
                name
              }
            }
            place {
              parkingPlaces {
                name
              }
              utilityRooms {
                number
                designedInnerUtilRoomArea
                asBuildUsableUtilRoomArea
                asBuildInnerUtilRoomArea
                parkingPlace {
                  id
                  name
                }
              }
              designedInnerPropertyArea
              designedUsablePropertyArea
              asBuildInnerPropertyArea
              asBuildUsablePropertyArea
              administrativeCode1
              administrativeCode2
              stage {
                street
              }
              investment {
                name
              }
              placeCode
              roomCount
            }
          }
        }
      }
    }
  `
  return GraphqlService.send(FETCH_COMPANIES_IN_INVESTMENTS, {
    isDNKCustomer: variables.isDNKCustomer,
    keyword: variables.keyword,
    page: variables.page,
    perPage: variables.perPage,
    sortField: variables.sortBy?.id ?? undefined,
    sortOrder: variables.sortBy?.desc ?? undefined,
  })
}

const fetchClientDataReport = (): Promise<any> => {
  const FETCH_CLIENT_DATA_REPORT = gql`
    query {
      contractCustomers {
        id
        uuid
      }
    }
  `
  return GraphqlService.send(FETCH_CLIENT_DATA_REPORT, {})
}

const fetchEmployeeLogs = (variables: IEmployeeLogsFetch): Promise<void> => {
  // TODO Missing sort fields on backend
  const FETCH_USER_LOGS = gql`
    query($startDate: String, $endDate: String, $perPage: Float, $page: Float) {
      paginatedChanges(
        endDate: $endDate
        startDate: $startDate
        perPage: $perPage
        page: $page
      ) {
        total
        changes {
          author
          date
          opID
          content
        }
      }
    }
  `
  return GraphqlService.send(FETCH_USER_LOGS, variables)
}

const getAllUsers = (): Promise<void> => {
  const FETCH_ALL_USERS = gql`
    query {
      users {
        id
        email
        lastName
        firstName
        phone
        roles
        username
        initials
      }
    }
  `
  return GraphqlService.send(FETCH_ALL_USERS, {})
}

const addUser = (variables: IEmployeeInput): Promise<void> => {
  const SAVE_USER = gql`
    mutation saveUser($user: UserInput!) {
      saveUser(user: $user) {
        id
      }
    }
  `
  return GraphqlService.send(SAVE_USER, { user: variables })
}

const editUser = (user: IEmployeeInput, userID: string): Promise<void> => {
  const EDIT_USER = gql`
    mutation editUser($user: UserInput!, $userID: String!) {
      updateUser(user: $user, userID: $userID) {
        lastName
        firstName
        email
        phone
        roles
        username
        initials
        id
      }
    }
  `
  return GraphqlService.send(EDIT_USER, { user, userID })
}

const deleteUser = (userID: string): Promise<void> => {
  const DELETE_USER = gql`
    mutation du($userID: String!) {
      deleteUser(userID: $userID)
    }
  `
  return GraphqlService.send(DELETE_USER, { userID })
}

const fetchTraderInitials = (roles?: string[]): Promise<void> => {
  const FETCH_TRADER_INITIALS = gql`
    query($roles: [String!]) {
      users(roles: $roles) {
        initials
      }
    }
  `
  return GraphqlService.send(FETCH_TRADER_INITIALS, { roles })
}

const mutations = {
  getClientsInInvestmentsForSearch,
  getClientsInInvestments,
  getCompaniesInInvestmentsForSearch,
  fetchClientDataReport,
  fetchEmployeeLogs,
  getAllUsers,
  addUser,
  editUser,
  deleteUser,
  fetchTraderInitials,
}
export default mutations
