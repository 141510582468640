import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../ducks'
import useGetRole from '../hooks/useGetRole'

export const useMainTableHiddenColumns = (
  isAlreadyHidden?: boolean
): string[] => {
  const {
    isDARCustomer,
    isDPICustomer,
    isSEKCustomer,
    isDKOCustomer,
    isDNICustomer,
    isDPPCustomer,
    isSKACustomer,
    isDSPCustomer,
  } = useGetRole()
  const { roles } = useSelector((state: RootState) => state.globalUser)

  const hiddenColumnsByRoles = useMemo(() => {
    const sensitiveToHide = isAlreadyHidden
      ? ['firstName1', 'firstName2', 'lastName1', 'lastName2']
      : []
    if (isDSPCustomer) {
      return ['designedUsableUtilRoomArea', ...sensitiveToHide]
    } else if (isDARCustomer) {
      return ['Walor.', 'Wysyłka FV na @', 'Kredyt', ...sensitiveToHide]
    } else if (isDPICustomer || isDPPCustomer) {
      return [
        'Walor.',
        'Wysyłka FV na @',
        'Kredyt',
        'firstName1',
        'firstName2',
        'lastName1',
        'lastName2',
      ]
    } else if (isSEKCustomer) {
      return ['Walor.', 'Kredyt', ...sensitiveToHide]
    } else if (isDKOCustomer) {
      return ['Wysyłka FV na @', 'Kredyt', ...sensitiveToHide]
    } else if (isDNICustomer || isSKACustomer) {
      return ['Wysyłka FV na @', ...sensitiveToHide]
    }
    return [''] as string[]
  }, [isAlreadyHidden, roles])

  return hiddenColumnsByRoles
}
