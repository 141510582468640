import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import SettingsService, { GenericProps } from '../services/settingsService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

interface SettingsState {
  timeToServeTaken: number | null
  timeToServeNotTaken: number | null
  timeToSecondContactWithCustomer: number | null
  bannedEmails: any
  totalBannedEmails: number
  loadingBannedEmails: boolean
}

const initialState = {
  timeToServeTaken: null,
  timeToServeNotTaken: null,
  timeToSecondContactWithCustomer: null,
  bannedEmails: [],
  loadingBannedEmails: false,
  totalBannedEmails: 0,
} as SettingsState

export const fetchTimeToServeTaken = createAsyncThunk(
  'settings/fetchTimeToServeTaken',
  async () => {
    return SettingsService.fetchTimeToServeTaken()
  }
)
export const fetchTimeToServeNotTaken = createAsyncThunk(
  'settings/fetchTimeToServeNotTaken',
  async () => {
    return SettingsService.fetchTimeToServeNotTaken()
  }
)
export const fetchTimeToSecondContactWithCustomer = createAsyncThunk(
  'settings/fetchTimeToSecondContactWithCustomer',
  async () => {
    return SettingsService.fetchTimeToSecondContactWithCustomer()
  }
)
export const setTimeToServeTaken = createAsyncThunk(
  'settings/setTimeToServeTaken',
  async (value: number) => {
    return SettingsService.setTimeToServeTaken(value)
  }
)

export const setTimeToServeNotTaken = createAsyncThunk(
  'settings/setTimeToServeNotTaken',
  async (value: number) => {
    return SettingsService.setTimeToServeNotTaken(value)
  }
)
export const setTimeToSecondContactWithCustomer = createAsyncThunk(
  'settings/setTimeToSecondContactWithCustomer',
  async (value: number) => {
    return SettingsService.setTimeToSecondContactWithCustomer(value)
  }
)

export const fetchBannedEmails = createAsyncThunk(
  'settings/fetchBannedEmails',
  async (v: GenericProps) => {
    return SettingsService.fetchBannedEmails(v)
  }
)

export const banEmail = createAsyncThunk(
  'settings/banEmail',
  async (email: string) => {
    return SettingsService.banEmail(email)
  }
)
export const removeBan = createAsyncThunk(
  'settings/removeBan',
  async (id: number) => {
    return SettingsService.removeBan(id)
  }
)
const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTimeToServeTaken.fulfilled.toString()]: (state, action): void => {
      state.timeToServeTaken = action.payload.timeToServeTaken
    },
    [fetchTimeToServeTaken.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:errorFetchTimeToServeTaken')))
    },
    [fetchTimeToServeNotTaken.fulfilled.toString()]: (state, action): void => {
      state.timeToServeNotTaken = action.payload.timeToServeNotTaken
    },
    [fetchTimeToServeNotTaken.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:errorFetchTimeToServeNotTaken')))
    },
    [fetchTimeToSecondContactWithCustomer.fulfilled.toString()]: (
      state,
      action
    ): void => {
      state.timeToSecondContactWithCustomer =
        action.payload.timeToSecondContactWithCustomer
    },
    [fetchTimeToSecondContactWithCustomer.rejected.toString()]: (): void => {
      errorToastNotify(
        String(i18n.t('toast:errorFetchTimeToSecondContactWithCustomer'))
      )
    },
    [setTimeToServeTaken.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:successSetTimeToServeTaken')))
    },
    [setTimeToServeTaken.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:errorSetTimeToServeTaken')))
    },
    [setTimeToServeNotTaken.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:successSetTimeToServeNotTaken')))
    },
    [setTimeToServeNotTaken.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:errorSetTimeToServeNotTaken')))
    },
    [setTimeToSecondContactWithCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(
        String(i18n.t('toast:successSetTimeToSecondContactWithCustomer'))
      )
    },
    [setTimeToSecondContactWithCustomer.rejected.toString()]: (): void => {
      errorToastNotify(
        String(i18n.t('toast:errorSetTimeToSecondContactWithCustomer'))
      )
    },
    [fetchBannedEmails.fulfilled.toString()]: (state, action): void => {
      state.bannedEmails = action.payload.bannedMails.bannedMails
      state.totalBannedEmails = action.payload.bannedMails.total
      state.loadingBannedEmails = false
    },
    [fetchBannedEmails.pending.toString()]: (state): void => {
      state.loadingBannedEmails = true
    },
    [fetchBannedEmails.rejected.toString()]: (state): void => {
      state.loadingBannedEmails = false
    },
    [banEmail.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:successBanEmail')))
    },
    [banEmail.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:errorBanEmail')))
    },
    [removeBan.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:successRemoveBan')))
    },
    [removeBan.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:errorRemoveBan')))
    },
  },
})

export default settingsSlice.reducer
