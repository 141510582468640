import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IBreadcrumbContainerProps {
  index: number
  isLastSlash?: boolean
  pathLength: number
}

const BreadcrumbContainer: FunctionComponent<IBreadcrumbContainerProps> = ({
  children,
  index,
  isLastSlash,
  pathLength,
}) => {
  return (
    <div className='d-flex align-items-start'>
      {children}
      {index < pathLength - 1 && !isLastSlash ? (
        <span className='px-2'>❯</span>
      ) : null}
    </div>
  )
}

interface IBreadcrumbItemProps {
  href: string
  index: number
  text: string
  pathname: string
}

const BreadcrumbItem: FunctionComponent<IBreadcrumbItemProps> = ({
  href,
  index,
  pathname,
  text,
}) => {
  const history = useHistory()
  const hrefNotSlash = href && href !== '/' ? href.split('/').join('') : ''
  const pathNameArray = pathname.split(`/${hrefNotSlash}`)

  const pathNameWithoutDoubleOccurences = `${
    pathNameArray[0] !== '/' ? pathNameArray[0] + '/' : '/'
  }${hrefNotSlash}`

  const handleBreadcrumbClick = useCallback((): void => {
    history.push(pathNameWithoutDoubleOccurences)
  }, [history, pathNameWithoutDoubleOccurences])

  return (
    <ol
      className='breadcrumb'
      onClick={handleBreadcrumbClick}
      key={`${index}-ol`}
      style={{ color: '#f2f2f2' }}
    >
      <li aria-current='page' key={`${index}-li`}>
        {text}
      </li>
    </ol>
  )
}

const HeaderBreadcrumb: FunctionComponent = () => {
  const pathname = useLocation().pathname
  const { t } = useTranslation()

  const [path, setPath] = useState(
    pathname.length !== 1 ? pathname.split('/').slice(0) : [`${pathname}`]
  )

  useEffect(() => {
    setPath(
      pathname.length !== 1
        ? pathname.split('/').slice(0)
        : [`${pathname ?? ''}`]
    )
  }, [pathname])

  return (
    <nav aria-label='breadcrumb py-0 my-0'>
      {path.map((pathPart: string, index: number) =>
        index === 0 ? (
          <BreadcrumbContainer
            index={index}
            pathLength={path.length}
            key={`breadcrum-${index}`}
          >
            <BreadcrumbItem
              href='/'
              index={index}
              key={`breadcrum-${index}`}
              pathname={pathname}
              text={t('commons:path.homepage')}
            />
          </BreadcrumbContainer>
        ) : (
          pathPart.indexOf('valid-contract') === -1 &&
          pathPart.indexOf('cancelled-contract') === -1 && (
            <BreadcrumbContainer
              index={index}
              isLastSlash={!pathPart}
              pathLength={path.length}
              key={`breadcrum-${index}`}
            >
              <BreadcrumbItem
                key={`breadcrum-${index}`}
                href={`${pathPart}/`}
                index={index}
                pathname={pathname}
                text={pathPart ? t(`commons:path.${pathPart}`) : ''}
              />
            </BreadcrumbContainer>
          )
        )
      )}
    </nav>
  )
}

export default HeaderBreadcrumb
