import {
  IContractCustomer,
  IContractCustomerInput,
  ISpouseFile,
} from './../graphql/contractCustomers'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ContractCustomerService, {
  UpdateContractCustomerCodeProps,
} from '../services/contractCustomerService'
import { IMarketingConsentInput } from '../graphql/marketingConsents'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'
import { RootState } from '.'
import { defaultContractCustomer } from './registerSales'

interface IContractCustomersState {
  attorneyFiles: ISpouseFile[] | null
  contractCustomerAttorneyFileIsUploading: boolean
  contractCustomers: IContractCustomer[]
}

const initialState: IContractCustomersState = {
  attorneyFiles: null,
  contractCustomerAttorneyFileIsUploading: false,
  contractCustomers: [],
}

export const updateContractCustomerCode = createAsyncThunk(
  'contractCustomers/updateContractCustomerCode',
  async (props: UpdateContractCustomerCodeProps) => {
    return ContractCustomerService.updateContractCustomerCode(props)
  }
)

export const anonymizeContractCustomer = createAsyncThunk(
  'contractCustomers/anonymizeContractCustomer',
  async (contractCustomerID?: number) => {
    if (contractCustomerID) {
      return ContractCustomerService.anonymizeContractCustomer(
        contractCustomerID
      )
    }
  }
)

export const fetchAllContractCustomers = createAsyncThunk(
  'contractCustomers/fetchAllContractCustomers',
  async () => {
    return ContractCustomerService.fetchAllContractCustomers()
  }
)

export const addNewContractCustomer = createAsyncThunk(
  'contractCustomers/addNewContractCustomer',
  async (variables: { contractCustomer: IContractCustomer }) => {
    return ContractCustomerService.addNewContractCustomer(variables)
  }
)

export const updateContractCustomerWithMarketingConsents = createAsyncThunk(
  'contractCustomers/updateContractCustomerWithMarketingConsents',
  async ({
    contractCustomer,
    contractCustomerID,
    marketingConsent,
    marketingConsentID,
  }: {
    contractCustomer: Partial<IContractCustomerInput>
    contractCustomerID: number
    marketingConsent: Partial<IMarketingConsentInput>
    marketingConsentID: number
  }) => {
    return ContractCustomerService.updateContractCustomerWithMarketingConsents({
      contractCustomer,
      contractCustomerID,
      marketingConsent,
      marketingConsentID,
    })
  }
)

export const updateContractCustomerWithMarketingConsentsWithoutID = createAsyncThunk(
  'contractCustomers/updateContractCustomerWithMarketingConsents',
  async ({
    contractCustomer,
    contractCustomerID,
    marketingConsent,
  }: {
    contractCustomer: Partial<IContractCustomerInput>
    contractCustomerID: number
    marketingConsent: Partial<IMarketingConsentInput>
  }) => {
    return ContractCustomerService.updateContractCustomerWithMarketingConsentsWithoutID(
      {
        contractCustomer,
        contractCustomerID,
        marketingConsent,
      }
    )
  }
)

export const updateContractCustomer = createAsyncThunk(
  'contractCustomers/updateContractCustomer',
  async ({
    contractCustomer,
    contractCustomerID,
    marketingConsent,
  }: {
    contractCustomer: Partial<IContractCustomerInput>
    contractCustomerID: number
    marketingConsent: Partial<IMarketingConsentInput> | undefined
  }) => {
    return ContractCustomerService.updateContractCustomer({
      contractCustomer,
      contractCustomerID,
      marketingConsent,
    })
  }
)

export const saveContractCustomer = createAsyncThunk(
  'contractCustomers/saveContractCustomer',
  async (contractCustomer: Partial<IContractCustomerInput>) => {
    return ContractCustomerService.saveContractCustomer(contractCustomer)
  }
)

export const saveAttorneyFile = createAsyncThunk(
  'contractCustomers/addAtorney',
  async (attorneyFile: ISpouseFile) => {
    return ContractCustomerService.saveAttorneyFile(attorneyFile)
  }
)

export const deleteAttorneyFile = createAsyncThunk(
  'contractCustomers/deleteAtorney',
  async (attorneyFileID: number) => {
    return ContractCustomerService.deleteAttorneyFile(attorneyFileID)
  }
)

export const deleteContractCustomer = createAsyncThunk(
  'contractCustomers/deleteContractCustomer',
  async (contractCustomerID?: number) => {
    if (contractCustomerID) {
      return ContractCustomerService.deleteContractCustomer(contractCustomerID)
    }
  }
)

export const deleteContractCustomerWithRelative = createAsyncThunk(
  'contractCustomers/deleteContractCustomerWithRelative',
  async (customer: IContractCustomerInput, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { chosenPlacesContract } = state.globalInvestment
    const contractCustomers =
      chosenPlacesContract?.activeContracts?.[0]?.contractCustomers

    if (contractCustomers) {
      // Find and remove all relative customers
      contractCustomers.forEach((c: IContractCustomerInput) => {
        const isOwner =
          !c.isSpouseOfMainCustomer &&
          !c.isAgentOfCustomerSpouse &&
          !c.isAgentOfMainCustomer

        if (isOwner && c.id === customer.id) {
          const relatives = contractCustomers
            .filter(
              (r: IContractCustomerInput) =>
                r.isSpouseOfMainCustomer ||
                r.isAgentOfMainCustomer ||
                r.isAgentOfCustomerSpouse
            )
            .filter((r: IContractCustomerInput) => r.uuid === customer.uuid)

          relatives.forEach((r: IContractCustomerInput) =>
            ContractCustomerService.deleteContractCustomer(r.id)
          )
        }
      })
      // Remove owner
      return ContractCustomerService.deleteContractCustomer(customer.id)
    }
  }
)

export const deleteContractCustomerWithRelativeFromAdminPanel = createAsyncThunk(
  'contractCustomers/deleteContractCustomerWithRelativeFromAdminPanel',
  async ({ owner, allCustomers }: any) => {
    const contractCustomers = allCustomers?.filter(
      (c: any) =>
        c.id !== owner.id &&
        (c.uuid === owner.uuid ||
          c?.parentCustomerID === owner.id ||
          c?.companyParentID === owner.id)
    )

    if (contractCustomers) {
      // Find and remove all relative customers
      contractCustomers.forEach(async (c: IContractCustomerInput) => {
        await ContractCustomerService.deleteContractCustomer(c.id)
      })
      // Remove owner
      return await ContractCustomerService.deleteContractCustomer(owner.id)
    }
  }
)

export const getFileByID = createAsyncThunk(
  'file/getFile',
  async (fileID: number) => {
    return ContractCustomerService.getFileByID(fileID)
  }
)

export const saveNewRelativeContractCustomer = createAsyncThunk(
  'contractCustomer/saveNewRelativeContractCustomer',
  async (
    variables: {
      isAgentOfCustomerSpouse: boolean
      isAgentOfMainCustomer: boolean
      isSpouseOfMainCustomer: boolean
      contractID?: number
      uuid?: string
      maritalStatus?: string
      customerCode?: string
      customerParentID?: number
      placeShares?: string
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState

    const {
      uuid,
      isAgentOfCustomerSpouse,
      isAgentOfMainCustomer,
      placeShares,
      isSpouseOfMainCustomer,
      contractID,
      maritalStatus = '',
      customerCode,
      customerParentID,
    } = variables

    const relativeCustomers = state.registerSales.newRelativeCustomers
    const {
      id,
      parentUuid,
      ...defaultContractCustomerWithoutID
    } = defaultContractCustomer

    const oldSpouse = relativeCustomers?.find(
      (c) => uuid === c?.uuid && c?.isSpouseOfMainCustomer
    )
    const oldAgent = relativeCustomers?.find(
      (c) => uuid === c?.uuid && c?.isAgentOfMainCustomer
    )
    const oldSpouseAgent = relativeCustomers?.find(
      (c) => uuid === c?.uuid && c?.isAgentOfCustomerSpouse
    )
    if (
      (oldSpouse && isSpouseOfMainCustomer) ||
      (oldAgent && isAgentOfMainCustomer) ||
      (oldSpouseAgent && isAgentOfCustomerSpouse)
    ) {
      return
    }

    const customer: any = {
      ...defaultContractCustomerWithoutID,
      uuid,
      isSpouseOfMainCustomer,
      isAgentOfCustomerSpouse,
      isAgentOfMainCustomer,
      maritalStatus,
      contractID,
      customerCode,
      placeShares,
    }
    if (customerParentID !== undefined) {
      customer['customerParentID'] = customerParentID
    }
    return ContractCustomerService.saveContractCustomer(customer)
  }
)

export const saveNewContractCustomer = createAsyncThunk(
  'contractCustomer/saveNewContractCustomer',
  async (
    variables: { customer: IContractCustomerInput; contractID: number },
    thunkAPI
  ) => {
    const { id, parentUuid, ...contractCustomersToSave } = variables.customer
    const customer = {
      ...contractCustomersToSave,
      contractID: variables.contractID,
    }
    return ContractCustomerService.saveContractCustomer(customer)
  }
)

const contractCustomersSlice = createSlice({
  name: 'contractCustomers',
  initialState,
  reducers: {
    setAttorneyFiles(state, action): void {
      const attorneyFiles = action.payload || []
      state.attorneyFiles = attorneyFiles
    },
  },
  extraReducers: {
    [fetchAllContractCustomers.fulfilled.toString()]: (state, action): void => {
      state.contractCustomers = action.payload.contractCustomers
    },
    [fetchAllContractCustomers.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:fetchAllContractCustomersError')))
      state.contractCustomers = []
    },
    [saveAttorneyFile.fulfilled.toString()]: (state, action): void => {
      successToastNotify(String(i18n.t('toast:addAttorney')))
      if (state.attorneyFiles) {
        state.attorneyFiles = [...state?.attorneyFiles, action.payload.saveFile]
      } else {
        state.attorneyFiles = [action.payload.saveFile]
      }
      state.contractCustomerAttorneyFileIsUploading = false
    },
    [saveAttorneyFile.pending.toString()]: (state): void => {
      state.contractCustomerAttorneyFileIsUploading = true
    },
    [saveAttorneyFile.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:addAttorneyError')))
      state.contractCustomerAttorneyFileIsUploading = false
    },
    [deleteAttorneyFile.fulfilled.toString()]: (state, action): void => {
      successToastNotify(String(i18n.t('toast:deleteAttorney')))
      const deletedAttorneyFileID = action.meta.arg
      const filteredAttorneyFiles =
        state?.attorneyFiles?.filter(
          (attorney) => attorney.id !== deletedAttorneyFileID
        ) ?? []
      state.attorneyFiles = filteredAttorneyFiles
      state.contractCustomerAttorneyFileIsUploading = false
    },
    [deleteAttorneyFile.pending.toString()]: (state): void => {
      state.contractCustomerAttorneyFileIsUploading = true
    },
    [deleteAttorneyFile.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:deleteAttorneyError')))
      state.contractCustomerAttorneyFileIsUploading = false
    },
    [anonymizeContractCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:anonymizeContractCustomer')))
    },
    [anonymizeContractCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:anonymizeContractCustomerError')))
    },
    [deleteContractCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:removeContractCustomer')))
    },
    [deleteContractCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:removeContractCustomerError')))
    },
    [updateContractCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editPerson')))
    },
    [updateContractCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editPersonError')))
    },
    [saveContractCustomer.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:editPerson')))
    },
    [saveContractCustomer.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:editPersonError')))
    },
  },
})

export const { setAttorneyFiles } = contractCustomersSlice.actions

export default contractCustomersSlice.reducer
