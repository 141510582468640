import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AppDispatch, RootState } from '../../ducks'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'
import { Stages } from './RaportWithDates/Stages'
import { Investments } from './RaportWithDates/Investments'
import { RaportWithDatesState } from './RaportWithDates/actions'
import {
  downloadXlsxReport,
  fetchDSPPlacesListReport,
} from '../../ducks/reports'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'
import useGetRole from '../../hooks/useGetRole'

const DSPPlacesListReport: FC = () => {
  useAuthRedirect([
    ACCESS_PAGES.DKO_REPORTS,
    ACCESS_PAGES.DPP_REPORTS,
    ACCESS_PAGES.DSP_AND_DSPADM_REPORTS,
  ])
  const { isDNKCustomer, isDSPCustomer } = useGetRole()
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(
    (state: RootState) => state.combinedReports.DSPPlacesListReport.isLoading
  )
  const isDSPDNKCustomer = isDNKCustomer || isDSPCustomer

  const isTypeServiceForPlacesList = React.useMemo(() => {
    if (isDSPDNKCustomer) {
      return isDNKCustomer
    }
    return undefined
  }, [isDNKCustomer, isDSPDNKCustomer])

  const generateReport = React.useCallback(
    async (state: RaportWithDatesState) => {
      const investmentsIDs = state.chosenInvestments.map(
        (investment) => investment?.id
      )
      const stagesIDs = state.chosenStages.map((stage) => stage.id)

      const response = await dispatch(
        fetchDSPPlacesListReport({
          investmentsIDs,
          stagesIDs,
          isTypeService: isTypeServiceForPlacesList,
        })
      )
      const content = response.payload.DSPPlacesListReport
      dispatch(
        downloadXlsxReport({
          content,
          filename: 'Raport - lista mieszkań.xlsx',
        })
      ).then(() => {
        history.push('/')
      })
    },
    [dispatch, history, isTypeServiceForPlacesList]
  )

  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <Investments />
      <Stages />
    </RaportWithDates>
  )
}

export default DSPPlacesListReport
