import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../ducks'
import { setCheckedValueOnSingleElement } from '../../../ducks/persistSettings'

import { useTableContext } from './TableContext'

export type SensitiveInfoButtonProps = {
  initialHiddenData?: string[]
  sensitiveColumns: string[]
}

const SensitiveInfoButton: FC<SensitiveInfoButtonProps> = ({
  initialHiddenData,
  sensitiveColumns,
}) => {
  const dispatch = useDispatch()
  const { mainTableHiddenColumns } = useSelector(
    (state: RootState) => state.persistSettings
  )
  const { t } = useTranslation()
  const [isSensitiveHidden, setIsSensitiveHidden] = useState(
    mainTableHiddenColumns?.firstName1 === false &&
      mainTableHiddenColumns?.firstName2 === false &&
      mainTableHiddenColumns?.lastName1 === false &&
      mainTableHiddenColumns?.lastName2 === false
  )

  const { setHiddenColumns } = useTableContext()
  const colsToHide = initialHiddenData ?? ['']

  const newHiddenColumnsState = useCallback(
    (state: boolean) => {
      return {
        ...mainTableHiddenColumns,
        firstName1: state,
        lastName1: state,
        firstName2: state,
        lastName2: state,
      }
    },
    [mainTableHiddenColumns]
  )

  const handleHideSensitiveClick = useCallback((): void => {
    if (isSensitiveHidden) {
      setHiddenColumns(colsToHide)
      dispatch(setCheckedValueOnSingleElement(newHiddenColumnsState(true)))
      setIsSensitiveHidden(false)
    } else {
      setHiddenColumns(colsToHide.concat(sensitiveColumns))
      dispatch(setCheckedValueOnSingleElement(newHiddenColumnsState(false)))
      setIsSensitiveHidden(true)
    }
  }, [
    isSensitiveHidden,
    setHiddenColumns,
    colsToHide,
    dispatch,
    newHiddenColumnsState,
    sensitiveColumns,
  ])

  useEffect(() => {
    setIsSensitiveHidden(
      mainTableHiddenColumns?.firstName1 === false &&
        mainTableHiddenColumns?.firstName2 === false &&
        mainTableHiddenColumns?.lastName1 === false &&
        mainTableHiddenColumns?.lastName2 === false
    )
  }, [mainTableHiddenColumns])

  return (
    <Col
      className='d-flex justify-content-end mx-1 px-0'
      lg='auto'
      md='auto'
      sm='auto'
    >
      <Button className='primary' onClick={handleHideSensitiveClick}>
        {isSensitiveHidden
          ? t('commons:actions.show-sensitive-data')
          : t('commons:actions.hide-sensitive-data')}
      </Button>
    </Col>
  )
}

export default SensitiveInfoButton
