import React, { FunctionComponent, useCallback } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AppDispatch } from '../../../ducks'
import { useDispatch } from 'react-redux'

import ReduxModal from '../../commons/Modals/ReduxModal'
import { IProposedInvestment } from '../../../services/proposedInvestmentsService'
import { getInterestedCustomerByID } from '../../../ducks/interestedCustomers'
import { removeProposedInvestment } from '../../../ducks/proposedInvestments'

interface IDeleteProposedPlaceProps {
  handleClose: () => void
  proposedPlace: IProposedInvestment
  show: boolean
  customerID: number
}

const DeleteProposedPlace: FunctionComponent<IDeleteProposedPlaceProps> = (
  props: IDeleteProposedPlaceProps
) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const { handleClose, proposedPlace, show, customerID } = props

  const onSubmitForm = useCallback(
    async (e: any) => {
      e.preventDefault()
      await dispatch(removeProposedInvestment(proposedPlace.id))
      await dispatch(getInterestedCustomerByID(customerID))
      handleClose()
    },
    [customerID, dispatch, handleClose, proposedPlace.id]
  )

  const deleteProposedPlaceModalData = (
    <Form onSubmit={onSubmitForm}>
      <Col lg={12} md={12} sm={12}>
        {t('commons:labels.are-you-sure-delete-item')}
      </Col>
      <Col lg={12} md={12} sm={12}>
        {t('commons:labels.proposed-place')}: {proposedPlace?.place?.placeCode}
      </Col>
      <Col className='d-flex justify-content-end' lg={12} md={12} sm={12}>
        <Button type='submit'>{t('commons:actions.delete')}</Button>
      </Col>
    </Form>
  )

  return (
    <ReduxModal
      body={deleteProposedPlaceModalData}
      cancelAction={handleClose}
      onHide={handleClose}
      show={show}
      title={t('customers:labels.add-proposed-place')}
    />
  )
}

export default DeleteProposedPlace
