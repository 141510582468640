import React, { FC } from 'react'
import './Reports.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDNIForNotarialOfficeReport } from '../../ducks/reports'
import { ReportWithList } from './ReportWithList'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const NotarialOfficeReport: FC = () => {
  useAuthRedirect(ACCESS_PAGES.DNI_REPORTS)
  const { isLoading } = useSelector(
    (state: RootState) => state.combinedReports.DNIForNotarialOfficeReport
  )

  return (
    <ReportWithList
      isLoading={isLoading}
      reportAPIName='DNIForNotarialOfficeReport'
      reportName='Raport-dla-kancelarii.xlsx'
      reduxAction={fetchDNIForNotarialOfficeReport}
    />
  )
}

export default NotarialOfficeReport
