const cutomersValidationColumns = [
  {
    Header: 'Imię klienta',
    accessor: 'firstName',
    width: '150',
  },
  {
    Header: 'Nazwisko Klienta',
    accessor: 'lastName',
    width: '200',
  },
  {
    Header: 'Telefon 1.',
    accessor: 'phone1',
    width: '120',
  },
  {
    Header: 'Telefon 2.',
    accessor: 'phone2',
    width: '120',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '250',
  },
  {
    Header: 'BL',
    id: 'isBlueList',
    width: '50',
    accessor: (a: { isBlueList: boolean }): string =>
      a.isBlueList ? '☑' : '☐',
  },
  {
    Header: 'Klient IK',
    id: 'isIKCustomer',
    width: '90',
    accessor: (a: { isIKCustomer: boolean }): string =>
      a.isIKCustomer ? '☑' : '☐',
  },
  {
    Header: 'Pośrednik',
    id: 'isBroker',
    width: '100',
    accessor: (a: { isBroker: boolean }): string => (a.isBroker ? '☑' : '☐'),
  },
  {
    Header: 'Nazwa pośrednika',
    accessor: 'brokerName',
    width: '180',
  },
]

export default cutomersValidationColumns
