import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../ducks'

interface IUseTableRowSelect {
  isMainTable: boolean
  selectedRowIds: {
    [key: string]: boolean
  }
  toggleRowSelected(id: string, status: boolean): void
  dataLength: number
}

export const useTableRowSelect = ({
  isMainTable,
  selectedRowIds,
  toggleRowSelected,
  dataLength,
}: IUseTableRowSelect): void => {
  const { isMaintainRowClickStopped, mainTableCurrentPage } = useSelector(
    (state: RootState) => state.pagination
  )
  const { mainTableSelectedRowIndex, mainTableSelectedRowPage } = useSelector(
    (state: RootState) => state.persistSettings
  )

  const setActiveTableRow = React.useCallback(() => {
    const rowIds = Object.keys(selectedRowIds)
    const pageIsCorrect = mainTableSelectedRowPage === mainTableCurrentPage
    if (
      mainTableSelectedRowIndex !== null &&
      dataLength > mainTableSelectedRowIndex &&
      pageIsCorrect &&
      !rowIds.includes(`${mainTableSelectedRowIndex}`)
    ) {
      return toggleRowSelected(`${mainTableSelectedRowIndex}`, true)
    }
  }, [
    dataLength,
    mainTableCurrentPage,
    mainTableSelectedRowIndex,
    mainTableSelectedRowPage,
    selectedRowIds,
    toggleRowSelected,
  ])

  React.useEffect(() => {
    if (isMainTable && !isMaintainRowClickStopped) {
      setActiveTableRow()
    }
  }, [isMainTable, isMaintainRowClickStopped, setActiveTableRow])
}
