import moment from 'moment'
import 'moment/locale/pl'
import React, { FunctionComponent } from 'react'
import { InputGroup } from 'react-bootstrap'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'

import IKInputGroup from './IKInputGroup'

import { DATE_FORMAT_YYYYMMDD } from '../../../constants/dates'

import { IInputProps } from './InputTypes'

import 'react-day-picker/lib/style.css'

interface IInputDateProps extends IInputProps {
  contact2Date?: boolean
  customFieldWidth?: number
  customWidth?: number
  empty?: boolean
  onDayChange?: (day: Date) => void
  required?: boolean
  isHidden?: boolean
}

const InputDate: FunctionComponent<IInputDateProps> = (
  props: IInputDateProps
) => {
  const {
    customWidth,
    disabled,
    empty,
    id,
    onDayChange,
    contact2Date = false,
    required,
    isHidden,
    text,
  } = props
  const today = moment().format(DATE_FORMAT_YYYYMMDD)
  const sevenDaysLater = moment(today, 'YYYY-MM-DD').add(7, 'd')
  const day = sevenDaysLater.format('DD')
  const month = sevenDaysLater.format('MM')
  const year = sevenDaysLater.format('YYYY')

  const sevenDaysLaterDate = moment(year + '-' + month + '-' + day).format(
    DATE_FORMAT_YYYYMMDD
  )

  return (
    <>
      {!isHidden && (
        <IKInputGroup id={id}>
          <InputGroup.Text
            className='d-flex align-items-center'
            style={{ width: customWidth ? `${customWidth}px` : '150px' }}
          >
            <label
              className='d-flex justify-content-start'
              htmlFor={id}
              style={{ width: '130px', paddingTop: '5px' }}
            >
              {text}
            </label>
            {disabled ? (
              empty ? (
                ' '
              ) : contact2Date ? (
                sevenDaysLaterDate
              ) : (
                today
              )
            ) : (
              <DayPickerInput
                inputProps={{
                  readOnly: false,
                  style: {
                    marginLeft: '10px',
                    marginTop: '0px',
                    width: '100px',
                  },
                  required: required,
                }}
                formatDate={MomentLocaleUtils.formatDate}
                format={DATE_FORMAT_YYYYMMDD}
                onDayChange={onDayChange}
                parseDate={MomentLocaleUtils.parseDate}
                value={`${contact2Date ? sevenDaysLaterDate : today}`}
                dayPickerProps={{
                  locale: 'pl',
                  localeUtils: MomentLocaleUtils,
                }}
              />
            )}
          </InputGroup.Text>
        </IKInputGroup>
      )}
    </>
  )
}

export default InputDate
