import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { updateResignationReasons } from './actions'
import {
  resignationReasonsIDName as mockResignationReasonsIDName,
} from '../../../mockups/resignationReasonsIDName'
import { Button } from 'react-bootstrap'

import MainColForReportCheckbox from '../../commons/Containers/MainColForReportCheckbox'
import MainRow from '../../commons/Containers/MainRow'
import SimpleInputCheckbox3 from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'

import { useRaportsWithDatesContext } from './raportWithDatesContext'
import { useCheckIfSmallResolution } from '../../../hooks/useCheckIfSmallResolution'

export const ResignationReasons = (): JSX.Element => {
  const { t } = useTranslation()
  const { state, dispatch } = useRaportsWithDatesContext()
  const resignationReasonsIDName = React.useMemo(
    () =>
      mockResignationReasonsIDName.filter(
        (sourceInfo) => sourceInfo.name?.length >= 3
      ),
    []
  )
  const resignationReasons = resignationReasonsIDName
    .map((rr) => rr.name)
    .sort()

  const selectAllResignationReasons = React.useCallback(() => {
    if (state.chosenResignationReasons.length) {
      return dispatch(updateResignationReasons([]))
    }
    dispatch(updateResignationReasons(resignationReasons))
  }, [dispatch, resignationReasons, state.chosenResignationReasons.length])

  const handleResignationReasonClick = React.useCallback(
    (clickedResignationReason: string) => {
      const isAlreadySelected = state.chosenResignationReasons.includes(
        clickedResignationReason
      )
      if (!isAlreadySelected) {
        return dispatch(
          updateResignationReasons([
            ...state.chosenResignationReasons,
            clickedResignationReason,
          ])
        )
      }
      dispatch(
        updateResignationReasons(
          state.chosenResignationReasons.filter(
            (reason) => reason !== clickedResignationReason
          )
        )
      )
    },
    [dispatch, state.chosenResignationReasons]
  )

  const isOneColInRow = useCheckIfSmallResolution()

  return (
    <>
      <MainRow isRowContentCentered>
        <p className='icr-header mt-4'>
          {t('commons:labels.choose-resignation-reasons')}
        </p>
      </MainRow>
      <MainRow isRowContentCentered>
        <Button onClick={selectAllResignationReasons}>
          {t('commons:actions.select-deselect-all')}
        </Button>
      </MainRow>
      <MainRow isRowContentCentered>
        {resignationReasonsIDName?.map(
          (resignationReason: { name: string; id: number }, index: number) => {
            const numberOfCols = resignationReasons.length
            return (
              <MainColForReportCheckbox
                forLargeCheckbox
                numberOfCols={numberOfCols}
                isOneColInRow={isOneColInRow}
                key={resignationReason?.id + '.' + index}
              >
                <SimpleInputCheckbox3
                  additionalOnChange={(): void =>
                    handleResignationReasonClick(resignationReason?.name)
                  }
                  customLabelWidth={18}
                  key={`${resignationReason?.name}-${resignationReason?.id}-checkbox`}
                  name={resignationReason?.name}
                  label={resignationReason?.name}
                  value={
                    !!state.chosenResignationReasons.includes(
                      resignationReason?.name
                    )
                  }
                  wordWrap
                />
              </MainColForReportCheckbox>
            )
          }
        )}
      </MainRow>
    </>
  )
}
