import { gql } from 'graphql-request'
import { IContract } from '../graphql/contracts/contracts'
import GraphqlService from './graphqlService'

export interface IEventFile {
  id: number
  name: string
  content: string
  eventID: number
  migrationComment?: string
}

export interface IEventTableRow {
  id: number
  selectedRow: IEvent
}

export interface IEvent {
  id: number
  changeDate: string
  changeType: string
  eventDate: string
  comments: string
  tradersList: string
  eventFile?: IEventFile
  contractID: number
  contract?: IContract
}

export interface IEventQuery {
  getContract: {
    events: IEvent[]
  }
}

export interface IEventInput {
  changeDate: string
  changeType: string
  eventDate: string
  comments: string
  tradersList: string
  contractID: number
}

export interface IEventFileInput {
  name: string
  content: string
  eventID: number
}

const getEventByContractID = (contractID: number): Promise<any> => {
  const FETCH_EVENTS_BY_CONTRACT_ID = gql`
    query($contractID: Float!) {
      getContract(contractID: $contractID) {
        events {
          id
          eventFile {
            name
            id
            migrationComment
          }
          changeType
          changeDate
          eventDate
          comments
          tradersList
        }
      }
    }
  `
  return GraphqlService.send(FETCH_EVENTS_BY_CONTRACT_ID, { contractID })
}

const saveEvent = (event: IEventInput): Promise<any> => {
  const SAVE_EVENT = gql`
    mutation ac($event: EventInput!) {
      saveEvent(event: $event) {
        id
        eventFile {
          name
          id
          migrationComment
        }
        changeType
        changeDate
        eventDate
        comments
        tradersList
      }
    }
  `
  return GraphqlService.send(SAVE_EVENT, { event })
}

const saveEventFile = (eventFile: IEventFileInput): Promise<any> => {
  const SAVE_EVENT_FILE = gql`
    mutation acf($eventFile: EventFileInput!) {
      saveEventFile(eventFile: $eventFile) {
        id
        name
        eventID
      }
    }
  `
  return GraphqlService.send(SAVE_EVENT_FILE, { eventFile })
}

const deleteEvent = (eventID: number): Promise<any> => {
  const DELETE_EVENT = gql`
    mutation ac($eventID: Float!) {
      deleteEvent(eventID: $eventID)
    }
  `
  return GraphqlService.send(DELETE_EVENT, { eventID })
}

const deleteEventFile = (eventFileID: number): Promise<any> => {
  const DELETE_EVENT_FILE = gql`
    mutation acf($eventFileID: Float!) {
      deleteEventFile(eventFileID: $eventFileID)
    }
  `
  return GraphqlService.send(DELETE_EVENT_FILE, { eventFileID })
}

const fetchEventsChanges = (contractID: number): Promise<any> => {
  const FETCH_EVENTS_CHANGES = gql`
    query($contractID: Float!) {
      getEventsChangesByContract(contractID: $contractID) {
        id
        opID
        author
        date
      }
    }
  `
  return GraphqlService.send(FETCH_EVENTS_CHANGES, { contractID })
}

const mutations = {
  getEventByContractID,
  saveEvent,
  saveEventFile,
  deleteEvent,
  deleteEventFile,
  fetchEventsChanges,
}

export default mutations
