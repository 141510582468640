import React, { FC } from 'react'

interface IMainColForReportCheckbox {
  forLargeCheckbox?: boolean
  isDNKStage?: boolean
  isOneColInRow?: boolean
  numberOfCols: number
}

const MainColForReportCheckbox: FC<IMainColForReportCheckbox> = ({
  children,
  forLargeCheckbox,
  isDNKStage,
  isOneColInRow,
  numberOfCols,
}): JSX.Element => (
  <div
    className={`d-flex col-lg-${
      forLargeCheckbox || isDNKStage ? 2 : 1
    } col-md-${forLargeCheckbox ? 3 : isDNKStage ? 3 : 2} col-12`}
    style={{
      width: isOneColInRow ? undefined : '33.33rem',
      justifyContent:
        isOneColInRow ||
        numberOfCols < 4 ||
        (forLargeCheckbox && numberOfCols > 1)
          ? 'center'
          : 'end',
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }}
  >
    {children}
  </div>
)

export default MainColForReportCheckbox
