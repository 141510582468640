import { getProperDateForTable } from '../utils/getProperDate'

const InterestedCustomersEventsColumns = (
  isFullWidth?: boolean,
  isUnassignedCustomerView?: boolean
): any[] => [
  {
    Header: 'Zdarzenie',
    accessor: 'name',
    width: '210',
  },

  {
    Header: 'Komentarz',
    accessor: 'comment',
    width: isFullWidth ? '710' : '150',
  },

  {
    Header: 'Data',
    disableSortBy: true,
    accessor: (a: { date: string }): string => {
      const date = a?.date ?? ''
      return getProperDateForTable(date)
    },
    width: '150',
  },

  {
    Header: 'Rodzaj',
    accessor: 'type',
    width: isFullWidth ? '200' : '100',
  },

  {
    Header: isUnassignedCustomerView ? 'Opiekun' : 'Handl.',
    accessor: 'traderInitials',
    width: '140',
  },

  {
    Header: 'Treść nast. kontaktu',
    accessor: 'nextContactName',
    width: '200',
  },

  {
    Header: 'Data nast. kontaktu',
    disableSortBy: true,
    accessor: (a: { nextContactDate: string }): string => {
      const nextContactDate = a?.nextContactDate ?? ''
      return getProperDateForTable(nextContactDate)
    },
    width: isFullWidth ? '200' : '100',
  },

  {
    Header: 'Rezyg.',
    accessor: (a: { isResignation: boolean }): string =>
      a.isResignation ? '☑' : '☐',
    width: '80',
  },

  {
    Header: 'Podpis.',
    accessor: (a: { isSigned: boolean }): string => (a.isSigned ? '☑' : '☐'),
    width: '80',
  },
]

export default InterestedCustomersEventsColumns
