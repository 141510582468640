import { useEffect, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import createActivityDetector from 'activity-detector'

export interface IConfigurationOptionsForUseIdle {
  timeToIdle: number
}

export const useIdle = (options: IConfigurationOptionsForUseIdle): boolean => {
  const [isIdle, setIsIdle] = useState(false)

  useEffect(() => {
    const activityDetector = createActivityDetector(options)
    activityDetector.on('idle', () => setIsIdle(true))
    activityDetector.on('active', () => setIsIdle(false))
    return (): void => activityDetector.stop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isIdle
}
