import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'

export const fetchBrokers = (): Promise<any> => {
  const FETCH_BROKERS = gql`
    query (
      $page: Float
      $perPage: Float
      $sortField: String
      $sortOrder: Boolean
    ) {
      paginatedBrokers(
        page: $page
        perPage: $perPage
        sortField: $sortField
        sortOrder: $sortOrder
      ) {
        total
        brokers {
          id
          name
        }
      }
    }
  `
  return GraphqlService.send(FETCH_BROKERS, {
    page: 1,
    perPage: 1000,
    sortField: 'name',
    sortOrder: true,
  })
}

export const saveBroker = (broker: { name: string }): Promise<any> => {
  const SAVE_BROKERS = gql`
    mutation SaveBroker($broker: BrokerInput!) {
      saveBroker(broker: $broker) {
        name
      }
    }
  `
  return GraphqlService.send(SAVE_BROKERS, { broker })
}

export const editBroker = ({
  name,
  id,
}: {
  name: string
  id: number
}): Promise<any> => {
  const EDIT_BROKERS = gql`
    mutation UpdateBroker($brokerID: Float!, $broker: BrokerInput!) {
      updateBroker(brokerID: $brokerID, broker: $broker) {
        name
      }
    }
  `
  return GraphqlService.send(EDIT_BROKERS, { brokerID: id, broker: { name } })
}

export const deleteBroker = (brokerID: number): Promise<any> => {
  const SAVE_BROKERS = gql`
    mutation DeleteBroker($brokerID: Float!) {
      deleteBroker(brokerID: $brokerID)
    }
  `
  return GraphqlService.send(SAVE_BROKERS, { brokerID })
}

export default { fetchBrokers, saveBroker, editBroker, deleteBroker }
