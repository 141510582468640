import React, { FunctionComponent, useCallback, useMemo } from 'react'

import './SimpleInputs.scss'

export interface IOptionElement {
  id: string | number
  name: string | number
}

interface ISimpleInputListProps {
  additionalOnChange?: (e: any) => void
  customLabelWidth?: number
  customLabelSpanWidth?: number
  customSelectWidth?: number
  disabled?: boolean
  highlighted?: boolean
  input?: {
    value: string
  }
  label?: string | number
  options: IOptionElement[] | string[] | number[]
  defaultValue?: IOptionElement | string | number
  onChangeValue?: (selectedValue: any) => void
  required?: boolean
  name?: string
}

export const SimpleInputList2: FunctionComponent<ISimpleInputListProps> = ({
  additionalOnChange,
  customLabelWidth,
  customSelectWidth,
  disabled,
  highlighted,
  input: inputFromReduxFormField,
  label,
  options,
  name,
  defaultValue = options[0] || { id: '', name: '' },
  onChangeValue,
  required,
  customLabelSpanWidth,
}) => {
  const getOptionsForSelect: IOptionElement[] = useMemo(
    () =>
      typeof options[0] === 'string' || typeof options[0] === 'number'
        ? [...options].map(
            (option: any): IOptionElement => {
              return { id: option, name: option }
            }
          )
        : (options as IOptionElement[]),
    [options]
  )

  const getDefaultValue: IOptionElement = useMemo(
    () =>
      typeof defaultValue === 'string' || typeof defaultValue === 'number'
        ? {
            id: defaultValue as string | number,
            name: defaultValue as string | number,
          }
        : (defaultValue as IOptionElement),
    [defaultValue]
  )

  const onChangeSelectedValue = useCallback(
    (event) => {
      const selectedValue: IOptionElement = [...getOptionsForSelect].find(
        (option: IOptionElement) =>
          option.id.toString() === event.target.value.toString()
      ) || { id: '', name: '' }
      onChangeValue && onChangeValue(selectedValue)
      if (additionalOnChange) {
        return additionalOnChange(event)
      }
    },
    [additionalOnChange, getOptionsForSelect, onChangeValue]
  )

  return (
    <div className='d-flex align-items-center'>
      <label
        className='simple-input-label'
        style={{ width: customLabelWidth ? `${customLabelWidth}px` : '150px' }}
      >
        <span
          className='input-group-text simple-input-label-span'
          style={{
            width: customLabelSpanWidth ? `${customLabelSpanWidth}em` : '10em',
          }}
        >
          {label}
        </span>
      </label>
      <div>
        <select
          id={inputFromReduxFormField?.value}
          name={name || inputFromReduxFormField?.value}
          onChange={onChangeSelectedValue}
          style={{
            padding: '0.5rem',
            width: customSelectWidth ? `${customSelectWidth}em` : '18em',
            marginLeft: '0em',
            backgroundColor: highlighted
              ? '#f2a291'
              : disabled
              ? '#ebebeb'
              : undefined,
          }}
          {...inputFromReduxFormField}
          className='form-control'
          disabled={disabled}
          required={required}
          value={getDefaultValue?.name}
        >
          <option
            key={`option-${getDefaultValue?.id}-${getDefaultValue?.name}`}
            value={getDefaultValue?.id}
          >
            {getDefaultValue?.name}
          </option>
          {getOptionsForSelect
            .filter(({ id }) => id !== getDefaultValue?.id)
            .map(({ id, name }, index) => (
              <option key={`option-${id}-${name}.${index}`} value={id}>
                {name}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

export default SimpleInputList2
