import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { fetchDARDateAnnexesReport } from '../../ducks/reports'
import { ReportWithList } from './ReportWithList'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DARDateAnnexesReport: React.FC = () => {
  useAuthRedirect(ACCESS_PAGES.DAR_REPORTS)
  const { isLoadingDARDateAnnexesReport } = useSelector(
    (state: RootState) => state.reports
  )

  return (
    <ReportWithList
      isLoading={isLoadingDARDateAnnexesReport}
      reportAPIName='DARDateAnnexesReport'
      reportName='Raport-aneksy.xlsx'
      reduxAction={fetchDARDateAnnexesReport}
      selectByActiveContractsIDs
    />
  )
}
export default DARDateAnnexesReport
