import { IUtilityRoomInput } from '../graphql/utilityRooms'
import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'

import { IInvestmentNames } from '../hooks/useGetInvestmentNames'

export const getAllUtilityRooms = (): Promise<any> => {
  const FETCH_ALL_UTILITY_ROOMS_QUERY = gql`
    query {
      utilityRooms {
        id
        isTypeService
        number
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        comment
        contractID
        parkingPlaceID
        parkingPlace {
          id
          name
          isExternal
          type
          garage {
            name
          }
        }
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          name
          buildingID
          isTypeService
        }
        placeID
        place {
          placeCode
          isTypeService
        }
      }
    }
  `

  return GraphqlService.send(FETCH_ALL_UTILITY_ROOMS_QUERY, {})
}

export const getUtilityRoomsByContract = ({
  contractID,
}: {
  contractID: number
}): Promise<any> => {
  const FETCH_UTILITY_ROOMS_BY_CONTRACT_ID_QUERY = gql`
    query($contractID: Float!) {
      utilityRoomsByContract(contractID: $contractID) {
        id
        number
        isTypeService
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        designedUsableUtilRoomArea
        comment
        isAfterMigration
        parkingPlaceID
        parkingPlace {
          id
          name
          isExternal
          type
          garage {
            name
          }
        }
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          name
          buildingID
          isTypeService
        }
        placeID
        place {
          placeCode
          id
          isTypeService
          building {
            name
            id
          }
          activeContracts {
            id
          }
          utilityRooms {
            id
            number
            parkingPlace {
              id
              name
              isExternal
              type
              garage {
                name
              }
            }
          }
        }
        contractID
      }
    }
  `
  return GraphqlService.send(FETCH_UTILITY_ROOMS_BY_CONTRACT_ID_QUERY, {
    contractID,
  })
}

export const getRoomsByInvestmentNames = ({
  investmentNames,
  stagesIDs,
}: IInvestmentNames): Promise<any> => {
  const FETCH_UTILITY_ROOMS_BY_INVESTMENTS_NAMES_QUERY = gql`
    query($investmentsNames: [String!]!, $stagesIDs: [Float!]) {
      utilityRoomsByInvestmentsNamesThroughGarage(
        investmentsNames: $investmentsNames
        stagesIDs: $stagesIDs
      ) {
        id
        number
        isTypeService
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        designedUsableUtilRoomArea
        comment
        isAfterMigration
        parkingPlaceID
        parkingPlace {
          id
          name
          isExternal
          type
          garage {
            name
          }
        }
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          name
          buildingID
          isTypeService
        }
        placeID
        place {
          placeCode
          id
          isTypeService
          building {
            name
            id
          }
          activeContracts {
            id
          }
          utilityRooms {
            id
            number
            parkingPlace {
              id
              name
              isExternal
              type
              garage {
                name
              }
            }
          }
        }
        contractID
      }
    }
  `
  return GraphqlService.send(FETCH_UTILITY_ROOMS_BY_INVESTMENTS_NAMES_QUERY, {
    investmentsNames: investmentNames,
    stagesIDs,
  })
}

export const getRoomsByInvestmentStageId = (stageID: number): Promise<any> => {
  const FETCH_UTILITY_ROOMS_BY_INVESTMENTS_STAGE_ID_QUERY = gql`
    query($stagesIDs: [Float!]!) {
      utilityRoomsByStages(stagesIDs: $stagesIDs) {
        id
        number
        isTypeService
        designedUsableUtilRoomArea
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        comment
        isAfterMigration
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          isTypeService
          name
          buildingID
        }
        parkingPlace {
          id
          name
          isExternal
          type
          garage {
            name
          }
        }
        placeID
        place {
          placeCode
          isTypeService
          id
          activeContracts {
            id
          }
          building {
            name
            id
          }
        }
        contractID
      }
    }
  `
  return GraphqlService.send(
    FETCH_UTILITY_ROOMS_BY_INVESTMENTS_STAGE_ID_QUERY,
    { stagesIDs: [stageID] }
  )
}

export const getUtilityRoomsByPlace = (placeID: number): any => {
  const FETCH_UTILITY_ROOMS_BY_PLACE_ID_QUERY = gql`
    query($placeID: Float!) {
      utilityRoomsByPlace(placeID: $placeID) {
        id
        number
        isTypeService
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        comment
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          isTypeService
          name
          buildingID
        }
        placeID
        parkingPlace {
          id
          name
          isExternal
          garage {
            name
          }
        }
        contractID
      }
    }
  `
  return GraphqlService.send(FETCH_UTILITY_ROOMS_BY_PLACE_ID_QUERY, { placeID })
}

export const addNewRoom = ({
  utilityRoom,
}: {
  utilityRoom: IUtilityRoomInput
}): Promise<any> => {
  const ADD_UTILITY_ROOM_QUERY = gql`
    mutation aur($utilityRoom: UtilityRoomInput!) {
      saveUtilityRoom(utilityRoom: $utilityRoom) {
        id
      }
    }
  `
  return GraphqlService.send(ADD_UTILITY_ROOM_QUERY, { utilityRoom })
}

export const updateRoom = ({
  utilityRoom,
  utilityRoomID,
}: {
  utilityRoom: IUtilityRoomInput
  utilityRoomID: number
}): Promise<any> => {
  const UPDATE_UTILITY_ROOM_QUERY = gql`
    mutation sp($utilityRoom: UtilityRoomInput!, $utilityRoomID: Float!) {
      updateUtilityRoom(
        utilityRoom: $utilityRoom
        utilityRoomID: $utilityRoomID
      ) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_UTILITY_ROOM_QUERY, {
    utilityRoom,
    utilityRoomID,
  })
}

export const removeRoom = (utilityRoomID: number): Promise<any> => {
  const DELETE_UTILITY_ROOM_QUERY = gql`
    mutation deleteUtilityRoom($utilityRoomID: Float!) {
      deleteUtilityRoom(utilityRoomID: $utilityRoomID)
    }
  `
  return GraphqlService.send(DELETE_UTILITY_ROOM_QUERY, { utilityRoomID })
}

export const getContractConnectedToUtilityRoom = (
  contractID: number
): Promise<any> => {
  const GET_CONTRACT_CONNECTED_TO_UTILITY_ROOM = gql`
    query($contractID: Float!) {
      getContract(contractID: $contractID) {
        id
        isArchived
        cancelledContract
      }
    }
  `

  return GraphqlService.send(GET_CONTRACT_CONNECTED_TO_UTILITY_ROOM, {
    contractID,
  })
}

const mutations = {
  getAllUtilityRooms,
  getContractConnectedToUtilityRoom,
  getRoomsByInvestmentNames,
  getRoomsByInvestmentStageId,
  getUtilityRoomsByContract,
  getUtilityRoomsByPlace,
  addNewRoom,
  removeRoom,
  updateRoom,
}

export default mutations
