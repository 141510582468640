import { useTranslation } from 'react-i18next'
import React, { FC, useCallback, useMemo } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../ducks'
import { columnsData } from '../../mockups/InterestedCustomers_Columns'
import { fetchInterestedCustomersPagination } from '../../ducks/interestedCustomers'
import Table from '../commons/Table2/Table'
import { SearchFilters } from './SearchFilters'
import { generateData } from '../../ducks/searchSlice'
import {
  fetchClientsInInvestmentsForSearch,
  fetchCompaniesInInvestmentsForSearch,
} from '../../ducks/adminIT'
import { searchCompaniesTable } from '../../mockups/SearchCompaniesTable'
import { searchCutomerTable } from '../../mockups/SearchCustomerTable'
import { StorageKeys } from '../../hooks/useBrowserStorage'
import Loader from '../commons/Loader/Loader'
import useGetRole from '../../hooks/useGetRole'

const Search: FC = () => {
  const { isDSPCustomer } = useGetRole()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { query } = useSelector((state: RootState) => state.search)
  const { interestedCustomers, totalInterestedCustomers, isLoadingCustomers } =
    useSelector((state: RootState) => state.interestedCustomers)
  const {
    clientsInInvestments,
    clientsInInvestmentsTotal,
    companiesInInvestments,
    companiesInInvestmentsTotal,
    isLoadingClientsInInvestments,
    isLoadingCompaniesInInvestments,
  } = useSelector((state: RootState) => state.adminIT)

  const { interestedChecked, regularChecked } = useSelector(
    (state: RootState) => state.search
  )
  const isLoading = useSelector(
    (state: RootState) =>
      state.combinedReports.DSPCustomerSearchReport.isLoading
  )

  const disableGenerating = useMemo(
    () =>
      (interestedChecked && interestedCustomers?.length === 0) ||
      (regularChecked && clientsInInvestments?.length === 0) ||
      (!regularChecked && !interestedChecked) ||
      isLoading,
    [
      interestedChecked,
      interestedCustomers,
      regularChecked,
      clientsInInvestments,
      isLoading,
    ]
  )

  const onGenerateData = useCallback(() => {
    dispatch(generateData({ regularChecked, interestedChecked }))
  }, [dispatch, interestedChecked, regularChecked])

  const columns = useMemo(() => searchCutomerTable(), [])
  const companyColumns = useMemo(() => searchCompaniesTable(), [])
  const hidenCols = React.useMemo(
    () => (isDSPCustomer ? ['designedUsableUtilRoomArea'] : ['']),
    [isDSPCustomer]
  )
  return (
    <Container fluid>
      <SearchFilters />
      <div className='d-flex justify-content-center'>
        <Button
          disabled={disableGenerating}
          onClick={onGenerateData}
          className='primary'
        >
          {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
        </Button>
      </div>
      {regularChecked && (
        <>
          <h6 className='font-weight-bold px-3'>
            {t('table:labels.regular-customers')} (Osoby Fizyczne)
          </h6>
          <Table
            columns={columns}
            totalRows={clientsInInvestmentsTotal ?? 0}
            manualSortBy={true}
            data={clientsInInvestments ?? []}
            defaultNumberOfRows={5}
          >
            {isLoadingClientsInInvestments ? (
              <Loader />
            ) : (
              <>
                <Table.TableBody />
                <Table.Header align='start'>
                  <Table.Filters />
                </Table.Header>
              </>
            )}
            <Table.PaginationWithFetch
              tableKey={StorageKeys.MainTable}
              storageType='localStorage'
              genericFetch={fetchClientsInInvestmentsForSearch}
              additionalParameters={{ keyword: query }}
            />
          </Table>
          <h6 className='font-weight-bold px-3'>
            {t('table:labels.regular-customers')} (Podmioty Gosp.)
          </h6>
          <Table
            columns={companyColumns}
            totalRows={companiesInInvestmentsTotal ?? 0}
            manualSortBy={true}
            data={companiesInInvestments ?? []}
            defaultNumberOfRows={5}
          >
            {isLoadingCompaniesInInvestments ? (
              <Loader />
            ) : (
              <>
                <Table.TableBody />
                <Table.Header align='start'>
                  <Table.Filters />
                </Table.Header>
              </>
            )}
            <Table.PaginationWithFetch
              tableKey={StorageKeys.MainTable}
              storageType='localStorage'
              genericFetch={fetchCompaniesInInvestmentsForSearch}
              additionalParameters={{ keyword: query }}
            />
          </Table>
        </>
      )}
      {interestedChecked && (
        <>
          <h6 className='font-weight-bold px-3'>
            {t('table:labels.interested-customers')}
          </h6>
          <Table
            columns={columnsData}
            manualSortBy={true}
            data={interestedCustomers ?? []}
            totalRows={totalInterestedCustomers}
            defaultNumberOfRows={5}
            hidenCols={hidenCols}
          >
            {isLoadingCustomers ? <Loader /> : <Table.TableBody />}
            <Table.PaginationWithFetch
              storageType='localStorage'
              tableKey={StorageKeys.InterestedCustomersTable}
              genericFetch={fetchInterestedCustomersPagination}
              additionalParameters={{ keyWords: query }}
            />
          </Table>
        </>
      )}
    </Container>
  )
}

export default Search
