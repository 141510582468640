import moment from 'moment'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MainButton from '../commons/Inputs/MainButton'
import ReduxModal from '../commons/Modals/ReduxModal'
import ValidationTooltip from '../commons/Inputs/ValidationTooltip'
import infoSourcesIDName from '../../mockups/infoSourcesIDName'
import './InterestedCustomers.scss'
import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates'
import { AppDispatch, RootState } from '../../ducks'
import { useForm } from '../../hooks/useForm'
import SimpleInput3 from '../commons/Inputs/SimpleInputs/SimpleInput3'
import { MaskedInputText2 } from '../commons/Inputs/SimpleInputs/MaskedInputText2'
import SimpleInputList from '../commons/Inputs/SimpleInputs/SimpleInputList'
import SimpleInputList2 from '../commons/Inputs/SimpleInputs/SimpleInputList2'
import SimpleInputCheckbox3 from '../commons/Inputs/SimpleInputs/SimpleInputCheckbox3'
import SimpleInputDate3 from '../commons/Inputs/SimpleInputs/SimpleInputDate3'
import { useMarketingConsentsReducer } from '../SalesRegister/CustomerMarketingConsentsContext'
import InfoDutyForms from '../../mockups/InfoDutyForms'
import ContactContent from '../../mockups/ContactContent'
import TypeOfContact from '../../mockups/TypeOfContact'
import { resignationReasonsIDName } from '../../mockups/resignationReasonsIDName'
import { useUserInitialsListForRoles } from '../../hooks/useUserInitialsListForRoles'
import { refetchPagination } from '../commons/Table2/paginationSlice'
import { useIdle } from '../../hooks/useIdle'
import { defaultIdleTime } from '../../constants/idleTime'
import {
  clearPersistRegisterSalesCopy,
  pasteInterestedCustomerIntoPersistStore,
} from '../../ducks/persistCopyOfForm'
import { IMarketingConsent } from '../../graphql/marketingConsents'
import { addCustomerWithMarketingConsentsProposedInvestmentsAndContacts } from '../../ducks/interestedCustomers'
import {
  IFoundedCustomer,
  INewCustomerVerificationParameters,
  useNewCustomerVerification,
} from '../../hooks/useNewCustomerVerification'
import CustomersVerificationsModal from '../commons/Modals/CustomersVerificationsModal'
import { useProposedInvestment } from './useProposedInvestment'
import { useDataForProposedInputs } from './useDataForProposedInputs'

import {
  IUnassignedCustomer,
  assignUnassignedCustomerToExistingCustomer,
  createCustomerFromUnassignedCustomer,
} from '../../ducks/unassignedCustomers'
import useGetRole from '../../hooks/useGetRole'
import { isEqual } from 'lodash-es'
import { getBrokers } from '../../ducks/brokers'
import { getFormBlocks } from '../../utils'

interface ExternalDefaultDataType extends IUnassignedCustomer {
  phone2?: string
}
interface INewInterestedProps {
  externalDefaultData?: ExternalDefaultDataType
  handleClose: () => void
  isContractRegistrationProcess?: boolean
  isShowWithActiveMessagesOnly?: boolean
  isUnassignedCustomersView?: boolean
  isEditCustomersView?: boolean
  show: boolean
  unassignedCustomerID?: number
  unassignedCustomerEmail?: string
  isTakingUnassigned?: boolean
}

const NewInterested: FunctionComponent<INewInterestedProps> = ({
  externalDefaultData,
  handleClose,
  isContractRegistrationProcess,
  isShowWithActiveMessagesOnly,
  isUnassignedCustomersView,
  isEditCustomersView,
  show,
  unassignedCustomerID = null,
  unassignedCustomerEmail,
  isTakingUnassigned,
}) => {
  const { isDNKCustomer } = useGetRole()
  const { adminPlaces } = useSelector((state: RootState) => state.places)
  const [initialProposedInvestmentsAdded, setInitialProposedInvestmentsAdded] =
    React.useState(false)
  const userInitialsList = useUserInitialsListForRoles()
  const todayDate = moment().format(DATE_FORMAT_YYYYMMDD)
  const sevenDaysAfterTodayDate = moment()
    .add(7, 'days')
    .format(DATE_FORMAT_YYYYMMDD)
  const { isSaveInterestedPending } = useSelector(
    (state: RootState) => state.interestedCustomers
  )

  const dispatch = useDispatch<AppDispatch>()
  const { state: proposedState, setters } = useProposedInvestment()
  const {
    stagesByInvestment,
    placesByChosenStage,
    investmentsWithFirstPositionEmpty,
    getProposedInvestmentObjectsFromInitial,
  } = useDataForProposedInputs({
    proposedState,
    initialProposedInvestments: externalDefaultData?.proposedInvestments,
  })

  const isAddProposedDisabled =
    proposedState.investment?.id === -1 || proposedState.stage?.id === -1

  const infoSources = infoSourcesIDName.map((is) => is.name)
  const infoByPage = infoSourcesIDName.find((is) => is.id === 17)?.name
  const resignationReasons = resignationReasonsIDName
    .map((rr) => rr.name)
    .sort()

  const { verifyNewCustomer } = useNewCustomerVerification()
  const { brokers } = useSelector((state: RootState) => state.brokers)
  const brokersName = brokers?.map((broker) => broker.name)

  const isIdle = useIdle(
    isUnassignedCustomersView
      ? {
          timeToIdle: 1,
        }
      : defaultIdleTime
  )
  const { copyOfInterestedCustomer } = useSelector(
    (state: RootState) => state.persistCopyOfForm
  )

  useEffect(() => {
    if (isIdle) {
      const collectedDataAboutInterestedCustomer = {
        customer: state,
        contact: contactState,
        marketingConsents: marketingConsentsState,
      }
      dispatch(
        pasteInterestedCustomerIntoPersistStore(
          collectedDataAboutInterestedCustomer
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle])

  React.useEffect(() => {
    dispatch(getBrokers())
  }, [dispatch])

  useEffect(() => {
    return (): void => {
      dispatch(clearPersistRegisterSalesCopy())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialContactsValues = useMemo(() => {
    const { contact } = copyOfInterestedCustomer
    const date = contact?.date
      ? moment(contact?.date).format(DATE_FORMAT_YYYYMMDD)
      : moment().format(DATE_FORMAT_YYYYMMDD)

    const setDefaultValue = isEditCustomersView || isUnassignedCustomersView
    const defaultNextContactName = setDefaultValue
      ? 'Przypomnienie'
      : contact?.nextContactName ?? ''
    const defaultType = setDefaultValue ? 'Korespondencja' : contact?.type ?? ''
    const defaultName = setDefaultValue
      ? 'Prezentacja oferty'
      : contact?.name ?? ''

    const getDefaultContactDate = () => {
      if (contact?.nextContactDate) {
        return moment(contact?.nextContactDate).format(DATE_FORMAT_YYYYMMDD)
      } else if (setDefaultValue) {
        return sevenDaysAfterTodayDate
      } else {
        return null
      }
    }

    const defaultNextContactDate = getDefaultContactDate()
    return {
      date,
      name: contact?.name ?? defaultName,
      type: contact?.type ?? defaultType,
      comment: contact?.comment ?? '',
      traderInitials: contact?.traderInitials ?? userInitialsList[0],
      nextContactName: contact?.nextContactName ?? defaultNextContactName,
      nextContactComment: contact?.nextContactComment ?? '',
      nextContactDate: defaultNextContactDate,
      isSigned: contact?.isSigned ?? false,
      isResignation: contact?.isResignation ?? false,
      resignationReason: contact?.resignationReason ?? '',
      isAfterMigration: true,
    }
  }, [copyOfInterestedCustomer, userInitialsList])

  const defaultInterestedCustomer = useMemo(() => {
    const { customer } = copyOfInterestedCustomer
    const defaultInfoSource =
      (isEditCustomersView || isUnassignedCustomersView) &&
      externalDefaultData?.source === 'form'
        ? infoByPage
        : externalDefaultData?.source ?? ''

    const email = externalDefaultData?.email ?? customer?.email ?? ''
    const email2 = externalDefaultData?.email2 ?? customer?.email2 ?? ''

    return {
      lastName: externalDefaultData?.lastName ?? customer?.lastName ?? '',
      firstName: externalDefaultData?.firstName ?? customer?.firstName ?? '',
      email: email,
      email2: email2 !== email ? email2 : '',
      phone1: externalDefaultData?.phone ?? customer?.phone1 ?? '',
      phone2: externalDefaultData?.phone2 ?? customer?.phone2 ?? '',
      traderInitials: customer?.traderInitials ?? userInitialsList[0],
      isToughCustomer: customer?.isToughCustomer ?? false,
      isVIP: customer?.isVIP ?? false,
      isBlueList: customer?.isBlueList ?? false,
      isIKCustomer: false,
      isBroker: externalDefaultData?.isBroker ?? customer?.isBroker ?? false,
      brokerName: externalDefaultData?.brokerName ?? customer?.brokerName ?? '',
      isCommissionDivision: customer?.isCommissionDivision ?? false,
      commissionDivision: customer?.commissionDivision ?? '',
      sourceInfo: defaultInfoSource,
      customerType: customer?.customerType ?? '',
      maidenName: customer?.maidenName ?? '',
      secondName: customer?.secondName ?? '',
      parentsNames: customer?.parentsNames ?? '',
      pesel: customer?.pesel ?? '',
      sex: customer?.sex ?? '',
      IDNumberAndSeries: customer?.IDNumberAndSeries ?? '',
      citizenship: customer?.citizenship ?? '',
      purchasePurpose: customer?.purchasePurpose ?? '',
      residenceName: customer?.residenceName ?? '',
      residenceSurname: customer?.residenceSurname ?? '',
      residenceCountry: customer?.residenceCountry ?? '',
      residenceCity: customer?.residenceCity ?? '',
      residenceStreet: customer?.residenceStreet ?? '',
      residenceNumber: customer?.residenceNumber ?? '',
      residencePostalCode: customer?.residencePostalCode ?? '',
      residenceVoivodeship: customer?.residenceVoivodeship ?? '',
      residenceIsForeign: customer?.residenceIsForeign ?? false,
      correspondenceName: customer?.correspondenceName ?? '',
      correspondenceSurname: customer?.correspondenceSurname ?? '',
      correspondenceCountry: customer?.correspondenceCountry ?? '',
      correspondenceCity: customer?.correspondenceCity ?? '',
      correspondenceStreet: customer?.correspondenceStreet ?? '',
      correspondenceNumber: customer?.correspondenceNumber ?? '',
      correspondencePostalCode: customer?.correspondencePostalCode ?? '',
      correspondenceVoivodeship: customer?.correspondenceVoivodeship ?? '',
      correspondenceIsForeign: customer?.correspondenceIsForeign ?? false,
      isBusiness: customer?.isBusiness ?? false,
      maritalStatus: customer?.maritalStatus ?? '',
      isDNKCustomer,
      isAfterMigration: true,
    }
  }, [externalDefaultData, isDNKCustomer, userInitialsList])

  const initialValuesFormMarketingConsents = useMemo(() => {
    const defaultConsents = {
      agreementEmailCancellationDate: null,
      agreementEmailDate: null,
      agreementPhoneCancellationDate: null,
      agreementPhoneDate: null,
      infoDutyForm: '',
      infoDutyFormDate: null,
      isAfterMigration: true,
      isAgreementEmail: false,
      isAgreementEmailCancelled: false,
      isAgreementPhone: false,
      isAgreementPhoneCancelled: false,
      isInfoDutyFulfilled: false,
      isMSWiAAgreement: false,
    }
    const { marketingConsents } = copyOfInterestedCustomer

    const defaultData =
      (isEditCustomersView || isUnassignedCustomersView) &&
      isEqual(marketingConsents, defaultConsents)
        ? externalDefaultData?.marketingConsents?.[0]
        : marketingConsents

    return {
      isInfoDutyFulfilled: defaultData?.isInfoDutyFulfilled ?? false,

      infoDutyFormDate: defaultData?.infoDutyFormDate ?? null,
      infoDutyForm: defaultData?.infoDutyForm ?? '',
      isAgreementPhone: defaultData?.isAgreementPhone ?? false,
      agreementPhoneDate: defaultData?.agreementPhoneDate ?? null,
      isAgreementPhoneCancelled:
        defaultData?.isAgreementPhoneCancelled ?? false,
      agreementPhoneCancellationDate:
        defaultData?.agreementPhoneCancellationDate ?? null,
      isAgreementEmail: defaultData?.isAgreementEmail ?? false,
      agreementEmailDate: defaultData?.agreementEmailDate ?? null,
      isAgreementEmailCancelled:
        defaultData?.isAgreementEmailCancelled ?? false,
      agreementEmailCancellationDate:
        defaultData?.agreementEmailCancellationDate ?? null,
      isMSWiAAgreement: defaultData?.isMSWiAAgreement ?? false,
      isAfterMigration: true,
    } as IMarketingConsent
  }, [copyOfInterestedCustomer, externalDefaultData])

  const {
    state: { data: marketingConsentsState },
    updateContextFromInput: updateMarketingFromInput,
  } = useMarketingConsentsReducer(initialValuesFormMarketingConsents)
  const { state, updateFromInput, updateField } = useForm(
    defaultInterestedCustomer
  )
  const {
    state: contactState,
    updateFromInput: updateContactFromInput,
    updateField: updateContactField,
  } = useForm(initialContactsValues)

  const { t } = useTranslation()
  const emailTarget = useRef(null)
  const emailTarget2 = useRef(null)
  const phoneTarget = useRef(null)
  const phoneTarget2 = useRef(null)
  const [isRequiredRed, setRequiredAsRed] = useState(false)

  const handleIsBrokerClick = useCallback(() => {
    if (!state.isBroker) {
      updateContactField({ name: 'type', value: 'Od pośrednika' })
      updateField({ value: 'Pośrednicy', name: 'sourceInfo' })
      updateContactField({ name: 'nextContactDate', value: null })
    } else {
      if (contactState.nextContactName?.length > 1) {
        updateContactField({
          name: 'nextContactDate',
          value: sevenDaysAfterTodayDate,
        })
      }
      updateContactField({ name: 'type', value: '' })
      updateField({ value: '', name: 'brokerName' })
      updateField({ value: '', name: 'sourceInfo' })
    }
  }, [
    sevenDaysAfterTodayDate,
    state.isBroker,
    updateContactField,
    updateField,
    contactState.nextContactName,
  ])

  const isNextContactNameNotHighlighted = isContractRegistrationProcess
    ? true
    : Boolean(
        !contactState.isResignation && !state.isBroker
          ? contactState?.nextContactName !== undefined &&
              contactState?.nextContactName !== ' ' &&
              contactState?.nextContactName !== ''
          : true
      )

  const isInfoDutyFulfilledDateFilled =
    marketingConsentsState.isInfoDutyFulfilled
      ? !!marketingConsentsState?.infoDutyFormDate
      : true
  const isBrokerNameEmpty = state.isBroker ? !!state?.brokerName : true

  const {
    showPhone1Tooltip,
    showPhone2Tooltip,
    showEmailTooltip,
    showEmail2Tooltip,
    tooltipBlock,
  } = getFormBlocks(state)

  const isContactNameRequired = state.isBroker ? true : contactState?.name
  const requiredFieldsNotEmpty = Boolean(
    state?.traderInitials &&
      isContactNameRequired &&
      contactState?.type &&
      state?.sourceInfo &&
      isNextContactNameNotHighlighted &&
      isInfoDutyFulfilledDateFilled &&
      isBrokerNameEmpty
  )

  const onSubmitForm = useCallback(
    (e: any): void => {
      e.preventDefault()
      const proposedInvestments = proposedState.proposedInvestments.map(
        (item: any) => {
          return {
            investmentID: item.investmentID,
            stageID: item.stageID,
            placeID: item.placeID,
          }
        }
      )
      const { id: marketingID, ...restMarketing } = marketingConsentsState
      if (requiredFieldsNotEmpty && !tooltipBlock) {
        if (isUnassignedCustomersView && unassignedCustomerID) {
          dispatch(
            createCustomerFromUnassignedCustomer({
              contacts: [contactState],
              proposedInvestments,
              marketingConsents: [restMarketing],
              customer: {
                ...state,
                phone1: state.phone1.replace(/\s/g, '') || '',
                phone2: state.phone2.replace(/\s/g, '') || '',
              },
              unassignedCustomerID,
            })
          ).then((res: any) => {
            if (!res.error) {
              dispatch(refetchPagination(true))
              handleClose()
            }
          })
        } else if (isEditCustomersView && unassignedCustomerID) {
          externalDefaultData &&
            dispatch(
              assignUnassignedCustomerToExistingCustomer({
                contacts: [contactState],
                proposedInvestments,
                marketingConsents: [restMarketing],
                customerUpdate: {
                  ...state,
                  phone1: state.phone1.replace(/\s/g, '') || '',
                  phone2: state.phone2.replace(/\s/g, '') || '',
                },
                customerID: externalDefaultData.id,
                takingUnassigned: isTakingUnassigned,
                unassignedCustomerID,
              })
            ).then((res: any) => {
              if (!res.error) {
                dispatch(refetchPagination(true))
                handleClose()
              }
            })
        } else {
          dispatch(
            addCustomerWithMarketingConsentsProposedInvestmentsAndContacts({
              contacts: [contactState],
              proposedInvestments,
              marketingConsents: [restMarketing],
              customer: {
                ...state,
                phone1: state.phone1.replace(/\s/g, '') || '',
                phone2: state.phone2.replace(/\s/g, '') || '',
              },
            })
          ).then((res: any) => {
            if (!res.error) {
              dispatch(refetchPagination(true))
              handleClose()
            }
          })
        }
      } else {
        alert('Uzupełnij pola obowiązkowe!')
        setRequiredAsRed(true)
      }
    },
    [
      proposedState.proposedInvestments,
      marketingConsentsState,
      requiredFieldsNotEmpty,
      dispatch,
      contactState,
      state,
      unassignedCustomerID,
      handleClose,
      isUnassignedCustomersView,
      isShowWithActiveMessagesOnly,
    ]
  )

  const [
    isVerifacationCustomerModalVisible,
    setIsVerifacationCustomerModalVisible,
  ] = useState(false)
  const hideFoundedCustomersModal = (): void =>
    setIsVerifacationCustomerModalVisible(false)

  const [foundedCustomers, setFoundedCustomers] = useState<IFoundedCustomer[]>(
    []
  )
  const performVerification = useCallback(async (): Promise<void> => {
    const parameters: INewCustomerVerificationParameters = {
      email: state?.email,
      phone1: state?.phone1,
      phone2: state?.phone2,
      firstName: state?.firstName,
      lastName: state?.lastName,
    }
    const customers = await verifyNewCustomer(parameters)

    if (customers === undefined) {
      return
    } else if (customers?.length === 0) {
      alert(t('customers:labels.verification-no-customers'))
    } else if (customers?.length > 0) {
      setFoundedCustomers(customers)
      setIsVerifacationCustomerModalVisible(true)
    }
  }, [state, t, verifyNewCustomer])

  const foundedCustomersModalBody = useMemo(
    () => (
      <CustomersVerificationsModal
        foundedCustomers={foundedCustomers}
        hideModal={hideFoundedCustomersModal}
      />
    ),
    [foundedCustomers]
  )

  useEffect(() => {
    if (contactState.nextContactName?.length > 1) {
      updateContactField({
        name: 'nextContactDate',
        value: sevenDaysAfterTodayDate,
      })
    } else {
      updateContactField({ name: 'nextContactDate', value: null })
    }
  }, [
    sevenDaysAfterTodayDate,
    contactState.nextContactName,
    updateContactField,
  ])

  useEffect(() => {
    if (adminPlaces.length > 0 && !initialProposedInvestmentsAdded) {
      setters.addInitialProposedInvestments(
        getProposedInvestmentObjectsFromInitial()
      )
      setInitialProposedInvestmentsAdded(true)
    }
  }, [adminPlaces, initialProposedInvestmentsAdded])

  const newInterestedData = (
    <Form onSubmit={onSubmitForm}>
      <div className='row d-flex justify-content-center pl-2'>
        <Col lg={6} md={12} sm={12}>
          <Row>
            <SimpleInput3
              name='lastName'
              label={String(t('commons:labels.surname'))}
              value={state?.lastName}
              onChange={updateFromInput}
              type='text'
            />
          </Row>
          <Row>
            <SimpleInput3
              name='firstName'
              label={String(t('commons:labels.name'))}
              value={state?.firstName}
              type='text'
              onChange={updateFromInput}
            />
          </Row>
          <Row>
            <SimpleInput3
              name='email'
              label={String(t('commons:labels.email'))}
              value={state?.email}
              onChange={updateFromInput}
              reference={emailTarget}
              highlighted={isRequiredRed && showEmailTooltip}
            />
            <ValidationTooltip
              isEmail
              show={isRequiredRed && showEmailTooltip}
              target={emailTarget.current}
            />
          </Row>
          <Row>
            <SimpleInput3
              name='email2'
              label={String(t('commons:labels.email') + ' 2')}
              value={state?.email2}
              onChange={updateFromInput}
              reference={emailTarget2}
              highlighted={isRequiredRed && showEmail2Tooltip}
            />
            <ValidationTooltip
              isEmail
              show={isRequiredRed && showEmail2Tooltip}
              target={emailTarget2.current}
            />
          </Row>
          {unassignedCustomerEmail &&
            state?.email !== unassignedCustomerEmail &&
            state?.email2 !== unassignedCustomerEmail && (
              <div style={{ padding: '10px 0' }}>
                <span
                  style={{ fontWeight: 'bold' }}
                >{`Email leada ${unassignedCustomerEmail} zostanie odrzucony`}</span>
              </div>
            )}
          <Row>
            <MaskedInputText2
              name='phone1'
              label={`${t('commons:labels.phone')} 1.`}
              value={state?.phone1}
              mask='111 111 111'
              onChange={updateFromInput}
              reference={phoneTarget}
              highlighted={isRequiredRed && showPhone1Tooltip}
            />
            <ValidationTooltip
              isPhoneNumber
              show={isRequiredRed && showPhone1Tooltip}
              target={phoneTarget.current}
            />
          </Row>
          <Row>
            <SimpleInput3
              name='phone2'
              label={`${t('commons:labels.phone')} 2.`}
              value={state?.phone2}
              onChange={updateFromInput}
              maxLength={18}
              isNumberInput
              reference={phoneTarget2}
              highlighted={isRequiredRed && showPhone2Tooltip}
            />
            <ValidationTooltip
              isPhoneNumber2
              show={isRequiredRed && showPhone2Tooltip}
              target={phoneTarget2.current}
            />
          </Row>
          {!isEditCustomersView && (
            <Row>
              <Button onClick={performVerification}>
                {t('customers:labels.verification-button')}
              </Button>
            </Row>
          )}
          <Row>
            <SimpleInputList2
              name='brokerName'
              label={String(t('place:labels.broker-name'))}
              options={brokersName}
              disabled={!state.isBroker}
              defaultValue={state?.brokerName}
              highlighted={
                !state?.brokerName && state.isBroker && isRequiredRed
              }
              additionalOnChange={updateFromInput}
            />
          </Row>
          <Row>
            <SimpleInput3
              name='commissionDivision'
              label={String(t('place:labels.commision-division'))}
              type='text'
              disabled={!state.isCommissionDivision}
              value={state?.commissionDivision}
              onChange={updateFromInput}
            />
          </Row>
          <Row>
            <SimpleInputList2
              name='sourceInfo'
              required
              defaultValue={state?.sourceInfo}
              additionalOnChange={updateFromInput}
              highlighted={!state?.sourceInfo && isRequiredRed}
              label={String(t('place:labels.information-source'))}
              options={infoSources}
            />
          </Row>
          <Row>
            <SimpleInputList2
              name='traderInitials'
              defaultValue={state?.traderInitials}
              options={userInitialsList}
              additionalOnChange={updateFromInput}
              label={String(t('commons:labels.trader-initials'))}
              required
              highlighted={!state?.traderInitials && isRequiredRed}
            />
          </Row>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Row className='row d-flex justify-content-start'>
            <SimpleInputCheckbox3
              name='isBroker'
              label={String(t('place:labels.broker'))}
              value={Boolean(state?.isBroker)}
              onChange={updateFromInput}
              additionalOnChange={handleIsBrokerClick}
            />
            <SimpleInputCheckbox3
              name='isCommissionDivision'
              label={String(t('place:labels.commision-division'))}
              onChange={updateFromInput}
              value={Boolean(state?.isCommissionDivision)}
            />
            <SimpleInputCheckbox3
              name='isVIP'
              value={Boolean(state?.isVIP)}
              onChange={updateFromInput}
              label={String(t('customers:labels.vip'))}
            />
            <SimpleInputCheckbox3
              name='isBlueList'
              value={Boolean(state?.isBlueList)}
              onChange={updateFromInput}
              label={String(t('customers:labels.blue-list'))}
            />
            <SimpleInputCheckbox3
              name='isToughCustomer'
              value={Boolean(state?.isToughCustomer)}
              onChange={updateFromInput}
              label={String(t('customers:labels.tough-customer'))}
            />
          </Row>
          <h6 className='px-1 mt-4'>Marketing</h6>
          <Row className='row d-flex justify-content-start'>
            <SimpleInputCheckbox3
              name='isInfoDutyFulfilled'
              value={Boolean(marketingConsentsState?.isInfoDutyFulfilled)}
              onChange={updateMarketingFromInput}
              label={String(t('place:labels.information-duty'))}
            />
            {marketingConsentsState?.isInfoDutyFulfilled && (
              <>
                <SimpleInputDate3
                  name='infoDutyFormDate'
                  defaultValue={
                    marketingConsentsState.isInfoDutyFulfilled
                      ? marketingConsentsState?.infoDutyFormDate
                      : null
                  }
                  label={String(t('place:labels.information-duty-date'))}
                  additionalOnChange={updateMarketingFromInput}
                  highlighted={
                    !marketingConsentsState?.infoDutyFormDate && isRequiredRed
                  }
                />
                <span style={{ width: '0.3em' }}> </span>
                <SimpleInputList2
                  name='infoDutyForm'
                  label={String(t('place:labels.information-duty-form'))}
                  defaultValue={marketingConsentsState?.infoDutyForm}
                  options={InfoDutyForms}
                  additionalOnChange={updateMarketingFromInput}
                />
              </>
            )}
          </Row>
          <Row className='row d-flex justify-content-start'>
            <SimpleInputCheckbox3
              name='isAgreementPhone'
              value={Boolean(marketingConsentsState?.isAgreementPhone)}
              label={String(t('place:labels.agreement-phone'))}
              onChange={updateMarketingFromInput}
            />
            {marketingConsentsState?.isAgreementPhone && (
              <>
                <SimpleInputDate3
                  name='agreementPhoneDate'
                  disabled={!marketingConsentsState.isAgreementPhone}
                  defaultValue={
                    marketingConsentsState.isAgreementPhone
                      ? marketingConsentsState?.agreementPhoneDate
                      : null
                  }
                  label={String(t('customers:labels.agreement-date-phone'))}
                  additionalOnChange={updateMarketingFromInput}
                />
                <SimpleInputCheckbox3
                  name='isAgreementPhoneCancelled'
                  disabled={!marketingConsentsState.isAgreementPhone}
                  value={Boolean(
                    marketingConsentsState?.isAgreementPhoneCancelled
                  )}
                  label={String(t('place:labels.cancelled-agreement-phone'))}
                  onChange={updateMarketingFromInput}
                />
                {marketingConsentsState?.isAgreementPhoneCancelled ? (
                  <SimpleInputDate3
                    name='agreementPhoneCancellationDate'
                    disabled={!marketingConsentsState.isAgreementPhoneCancelled}
                    defaultValue={
                      marketingConsentsState.isAgreementPhoneCancelled
                        ? marketingConsentsState.agreementPhoneCancellationDate
                        : null
                    }
                    label={String(t('customers:labels.withdrawal-date'))}
                    additionalOnChange={updateMarketingFromInput}
                  />
                ) : null}
              </>
            )}
          </Row>
          <Row className='row d-flex justify-content-start'>
            <SimpleInputCheckbox3
              name='isAgreementEmail'
              value={Boolean(marketingConsentsState?.isAgreementEmail)}
              label={String(t('place:labels.agreement-email'))}
              onChange={updateMarketingFromInput}
            />
            {marketingConsentsState?.isAgreementEmail && (
              <>
                <SimpleInputDate3
                  name='agreementEmailDate'
                  defaultValue={
                    marketingConsentsState.isAgreementEmail
                      ? marketingConsentsState?.agreementEmailDate
                      : null
                  }
                  label={String(t('customers:labels.agreement-date-email'))}
                  additionalOnChange={updateMarketingFromInput}
                />
                <SimpleInputCheckbox3
                  name='isAgreementEmailCancelled'
                  value={Boolean(
                    marketingConsentsState?.isAgreementEmailCancelled
                  )}
                  onChange={updateMarketingFromInput}
                  label={String(t('place:labels.cancelled-agreement-email'))}
                />
                {marketingConsentsState?.isAgreementEmailCancelled ? (
                  <SimpleInputDate3
                    name='agreementEmailCancellationDate'
                    defaultValue={
                      marketingConsentsState.isAgreementEmailCancelled
                        ? marketingConsentsState.agreementEmailCancellationDate
                        : null
                    }
                    additionalOnChange={updateMarketingFromInput}
                    label={String(t('customers:labels.withdrawal-date'))}
                    disabled={!marketingConsentsState.isAgreementEmailCancelled}
                  />
                ) : null}
              </>
            )}
          </Row>
          <Row className='row d-flex justify-content-start'>
            <SimpleInputCheckbox3
              name='isMSWiAAgreement'
              value={Boolean(marketingConsentsState?.isMSWiAAgreement)}
              label='Zgoda MSWiA'
              onChange={updateMarketingFromInput}
            />
          </Row>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <h6 className='mt-4'>Kontakt z Zainteresowanym</h6>
          <Row>
            <SimpleInputList2
              name='type'
              label={String(t('commons:labels.type'))}
              highlighted={!contactState?.type && isRequiredRed}
              options={TypeOfContact}
              defaultValue={contactState?.type}
              additionalOnChange={updateContactFromInput}
            />
          </Row>
          <Row>
            <SimpleInputList2
              name='name'
              label={String(t('commons:labels.contact-content'))}
              highlighted={
                !state.isBroker && !contactState?.name && isRequiredRed
              }
              options={ContactContent}
              defaultValue={contactState?.name}
              additionalOnChange={updateContactFromInput}
            />
          </Row>
          <Row>
            <SimpleInput3
              name='comment'
              type='text'
              label={String(t('commons:labels.comment'))}
              value={contactState?.comment}
              onChange={updateContactFromInput}
            />
          </Row>
          <Row>
            <SimpleInputDate3
              name='date'
              label={String(t('commons:labels.date'))}
              defaultValue={contactState?.date || todayDate}
              additionalOnChange={updateContactFromInput}
            />
          </Row>
          <Row>
            <SimpleInputList2
              name='traderInitials'
              label={String(t('commons:labels.trader-initials'))}
              options={userInitialsList}
              highlighted={!contactState?.traderInitials && isRequiredRed}
              defaultValue={contactState?.traderInitials}
              additionalOnChange={updateContactFromInput}
            />
          </Row>
          {!contactState.isResignation && !isContractRegistrationProcess && (
            <>
              <Row className='pt-4'>
                <SimpleInputList2
                  name='nextContactName'
                  label={String(t('customers:labels.next-contact-content'))}
                  options={ContactContent}
                  highlighted={
                    !isNextContactNameNotHighlighted && isRequiredRed
                  }
                  defaultValue={contactState?.nextContactName}
                  additionalOnChange={updateContactFromInput}
                />
              </Row>
              <Row>
                <SimpleInput3
                  name='nextContactComment'
                  type='text'
                  label={String(t('commons:labels.comment'))}
                  value={contactState?.nextContactComment}
                  onChange={updateContactFromInput}
                />
              </Row>
              <Row>
                <SimpleInputDate3
                  name='nextContactDate'
                  label={String(t('customers:labels.next-contact-date'))}
                  defaultValue={contactState?.nextContactDate}
                  additionalOnChange={updateContactFromInput}
                />
              </Row>
            </>
          )}
          <Row className='row'>
            <SimpleInputCheckbox3
              name='isSigned'
              disabled
              label={String(t('customers:labels.signed'))}
              onChange={updateContactFromInput}
              value={false}
            />
            <SimpleInputCheckbox3
              name='isResignation'
              label={String(t('customers:labels.resignation'))}
              value={contactState.isResignation}
              onChange={updateContactFromInput}
            />
            {contactState?.isResignation && (
              <SimpleInputList2
                defaultValue={contactState.resignationReason}
                name='resignationReason'
                label={String(t('commons:labels.resignation-reason'))}
                options={resignationReasons}
                additionalOnChange={updateContactFromInput}
              />
            )}
          </Row>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <h6 className='px-1 mt-4'>Propozycje inwestycji</h6>
          <Row className='row d-flex justify-content-center'>
            <div>
              <SimpleInputList2
                defaultValue={proposedState.investment}
                onChangeValue={setters.onChangeInvestmentValue}
                label={t('commons:labels.investment').toString()}
                options={investmentsWithFirstPositionEmpty}
              />
              <SimpleInputList2
                defaultValue={proposedState.stage}
                onChangeValue={setters.onChangeStageValue}
                label={String(t('commons:labels.stage'))}
                options={stagesByInvestment}
              />
              {placesByChosenStage.length ? (
                <SimpleInputList2
                  defaultValue={proposedState.place}
                  disabled={placesByChosenStage.length <= 1}
                  onChangeValue={setters.onChangePlacesValue}
                  label={String(t('place:labels.place-no'))}
                  options={placesByChosenStage}
                />
              ) : null}

              <div className='proposed-places-container'>
                {proposedState.proposedInvestments.length > 0 && (
                  <div className='proposed-places-title'>Dodane propozycje</div>
                )}
                {proposedState.proposedInvestments.map(
                  (item: { name: string }, index: number) => (
                    <li className='proposed-place' key={item.name}>
                      {item.name}
                      <span
                        className='proposed-places-remove-button'
                        onClick={(): void => setters.removeProposedItem(index)}
                      >
                        x
                      </span>
                    </li>
                  )
                )}
              </div>
              <Button
                className='proposed-places-button'
                onClick={setters.addProposedInvestment}
                disabled={isAddProposedDisabled}
              >
                Dodaj propozycje
              </Button>
            </div>
          </Row>
        </Col>
      </div>
      <Row className='d-flex justify-content-end mx-3 px-1'>
        <MainButton
          isUploading={isSaveInterestedPending}
          disabled={isRequiredRed && tooltipBlock}
        >
          {isEditCustomersView
            ? t('commons:actions.saveAndAssign')
            : t('commons:actions.save')}
        </MainButton>
      </Row>
    </Form>
  )

  return (
    <>
      <ReduxModal
        body={newInterestedData}
        cancelAction={handleClose}
        customWidth={50}
        onHide={handleClose}
        show={show}
        title={
          isEditCustomersView
            ? t('customers:labels.edit-interested')
            : t('customers:labels.add-new-interested')
        }
      />
      <ReduxModal
        body={foundedCustomersModalBody}
        cancelAction={hideFoundedCustomersModal}
        customWidth={50}
        onHide={hideFoundedCustomersModal}
        show={isVerifacationCustomerModalVisible}
        title={t('customers:labels.verification-customers')}
      />
    </>
  )
}

export default NewInterested
