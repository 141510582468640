import React, { FunctionComponent } from 'react'

import './SimpleInputs.scss'

interface ISimpleInputCheckboxProps {
  customLabelWidth?: number | string
  disabled?: boolean
  label: string | number
  wordWrap?: boolean
  onChange?: (e: any) => void
  additionalOnChange?: any
  name: string
  value: any
}

export const SimpleInputCheckbox3: FunctionComponent<ISimpleInputCheckboxProps> = ({
  customLabelWidth,
  disabled,
  label,
  wordWrap = false,
  name,
  value,
  onChange = (): void => {},
  additionalOnChange = (): void => {},
}) => {
  const stylesForWordWrapping = {
    width: customLabelWidth ? `${customLabelWidth}em` : '10em',
    height: '70px',
    whiteSpace: 'normal',
    textAlign: 'left',
  }

  const defaultStyles = {
    width: customLabelWidth ? `${customLabelWidth}em` : '10em',
  }
  const handleChange = React.useCallback(
    (e: any) => {
      onChange(e)
      additionalOnChange()
    },
    [additionalOnChange, onChange]
  )

  return (
    <div className='d-flex align-items-center'>
      <label className='simple-input-label'>
        <span
          className='input-group-text simple-input-label-span'
          style={wordWrap ? stylesForWordWrapping : defaultStyles}
        >
          <input
            name={name}
            checked={Boolean(value)}
            onChange={handleChange}
            className='simple-input-checkbox'
            type='checkbox'
            disabled={disabled}
          />
          {label}
        </span>
      </label>
    </div>
  )
}

export default SimpleInputCheckbox3
