import * as React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import Loader from '../commons/Loader/Loader'

export const SuspendedRoute: React.FC<RouteProps> = ({
  children,
  ...props
}) => (
  <Route {...props}>
    <React.Suspense fallback={<Loader timeout={9999999} />}>
      {children}
    </React.Suspense>
  </Route>
)
