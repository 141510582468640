import { Dispatch } from 'react'
import { IInvestment, IStage } from '../../../graphql/investments'

export enum ActionTypes {
  FromDate = 'FromDate',
  ToDate = 'ToDate',
  BrokerNames = 'BrokerNames',
  SourceInfos = 'SourceInfos',
  Investments = 'Investments',
  ResignationReasons = 'ResignationReasons',
  Stages = 'Stages',
  TraderInitials = 'TraderInitials',
  ClearState = 'ClearState',
  Valorization = 'Valorization',
  EmailInvoice = 'EmailInvoice',
  CustomerType = 'CustomerType',
}

export interface RaportWithDatesState {
  fromDate: string
  toDate: string
  chosenBrokerNames: string[]
  chosenSourceInfo: string[]
  chosenInvestments: IInvestment[]
  chosenResignationReasons: string[]
  chosenStages: IStage[]
  chosenTraderInitials: string[]
  customerType: string
  isEmailInvoice: boolean
  isValorization: boolean
}

export interface IRaportWithDatesContext {
  state: RaportWithDatesState
  dispatch: Dispatch<Actions>
}

interface ChangeFromDate {
  type: ActionTypes.FromDate
  payload: string
}

interface ChangeToDate {
  type: ActionTypes.ToDate
  payload: string
}

interface ChangeBrokerNames {
  type: ActionTypes.BrokerNames
  payload: string[]
}

interface ChangeSourceInfos {
  type: ActionTypes.SourceInfos
  payload: string[]
}

interface ChangeInvestments {
  type: ActionTypes.Investments
  payload: IInvestment[]
}

interface ChangeResignationReasons {
  type: ActionTypes.ResignationReasons
  payload: string[]
}

interface ChangeStages {
  type: ActionTypes.Stages
  payload: IStage[]
}

interface ChangeTraderInitials {
  type: ActionTypes.TraderInitials
  payload: string[]
}

interface ChangeClearState {
  type: ActionTypes.ClearState
}

interface ChangeEmailInvoice {
  type: ActionTypes.EmailInvoice
  payload: boolean
}

interface ChangeValorization {
  type: ActionTypes.Valorization
  payload: boolean
}

interface ChangeCustomerType {
  type: ActionTypes.CustomerType
  payload: string
}

export const updateCustomerType = (
  customerType: string
): ChangeCustomerType => {
  return {
    type: ActionTypes.CustomerType,
    payload: customerType,
  }
}

export const updateEmailInvoice = (
  isEmailInvoice: boolean
): ChangeEmailInvoice => {
  return {
    type: ActionTypes.EmailInvoice,
    payload: isEmailInvoice,
  }
}

export const updateValorization = (
  valorization: boolean
): ChangeValorization => {
  return {
    type: ActionTypes.Valorization,
    payload: valorization,
  }
}

export const updateFromDate = (date: string): ChangeFromDate => {
  return {
    type: ActionTypes.FromDate,
    payload: date,
  }
}

export const updateToDate = (date: string): ChangeToDate => {
  return {
    type: ActionTypes.ToDate,
    payload: date,
  }
}

export const updateStages = (stages: IStage[]): ChangeStages => {
  return {
    type: ActionTypes.Stages,
    payload: stages,
  }
}

export const updateInvestments = (
  investments: IInvestment[]
): ChangeInvestments => {
  return {
    type: ActionTypes.Investments,
    payload: investments,
  }
}

export const updateBrokers = (brokers: string[]): ChangeBrokerNames => {
  return {
    type: ActionTypes.BrokerNames,
    payload: brokers,
  }
}

export const updateTraders = (traders: string[]): ChangeTraderInitials => {
  return {
    type: ActionTypes.TraderInitials,
    payload: traders,
  }
}

export const updateSourceInfo = (sourceInfo: string[]): ChangeSourceInfos => {
  return {
    type: ActionTypes.SourceInfos,
    payload: sourceInfo,
  }
}

export const updateResignationReasons = (
  reasons: string[]
): ChangeResignationReasons => {
  return {
    type: ActionTypes.ResignationReasons,
    payload: reasons,
  }
}

export const clearState = (): ChangeClearState => {
  return {
    type: ActionTypes.ClearState,
  }
}

export type Actions =
  | ChangeTraderInitials
  | ChangeClearState
  | ChangeFromDate
  | ChangeToDate
  | ChangeBrokerNames
  | ChangeSourceInfos
  | ChangeInvestments
  | ChangeStages
  | ChangeResignationReasons
  | ChangeCustomerType
  | ChangeEmailInvoice
  | ChangeValorization
