import {
  IContractCustomer,
  IContractCustomerInput,
  ISpouseFile,
} from '../graphql/contractCustomers'
import { gql } from 'graphql-request'
import GraphqlService from './graphqlService'
import { IMarketingConsentInput } from '../graphql/marketingConsents'

export interface UpdateContractCustomerCodeProps {
  contractID: number
  customerCode: string
}

const updateContractCustomerCode = (
  props: UpdateContractCustomerCodeProps
): Promise<any> => {
  const UPDATE_CUSTOMER_CODE = gql`
    mutation($contractID: Float!, $customerCode: String!) {
      updateCustomerCode(customerCode: $customerCode, contractID: $contractID) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_CUSTOMER_CODE, props)
}

export const fetchAllContractCustomers = (): Promise<any> => {
  const FETCH_ALL_CONTRACT_CUSTOMERS_QUERY = gql`
    query {
      contractCustomers {
        id
        parentUuid
        customerCode
        customerParentID
        customerType
        lastName
        firstName
        secondName
        maidenName
        parentsNames
        pesel
        taxNumber
        sex
        phone1
        phone2
        email
        passportNumberAndSeries
        IDNumberAndSeries
        citizenship
        isBusiness
        businessName
        regon
        businessAddressName
        businessAddressSurname
        businessAddressBusinessName
        businessAddressCountry
        businessAddressCity
        businessAddressStreet
        businessAddressNumber
        businessAddressPostalCode
        businessAddressVoivodeship
        businessAddressIsForeign
        residenceName
        residenceSurname
        residenceBusinessName
        residenceCountry
        residenceCity
        residenceStreet
        residenceNumber
        residencePostalCode
        residenceVoivodeship
        residenceIsForeign
        correspondenceName
        correspondenceSurname
        correspondenceBusinessName
        correspondenceCountry
        correspondenceCity
        correspondenceStreet
        correspondenceNumber
        correspondencePostalCode
        correspondenceVoivodeship
        correspondenceIsForeign
        maritalStatus
        contractID
        customerInvestmentCode
        isBlueList
        placeShares
        sourceInfo
        isBroker
        brokerName
        traderInitials
        agentFiles {
          created
          id
          name
        }
        contract {
          id
        }
      }
    }
  `
  return GraphqlService.send(FETCH_ALL_CONTRACT_CUSTOMERS_QUERY, {})
}

export const addNewContractCustomer = ({
  contractCustomer,
}: {
  contractCustomer: IContractCustomer
}): any => {
  const ADD_CONTRACT_CUSTOMER_QUERY = gql`
    mutation aur($contractCustomer: ContractCustomerInput!) {
      saveContractCustomer(contractCustomer: $contractCustomer) {
        id
      }
    }
  `
  return GraphqlService.send(ADD_CONTRACT_CUSTOMER_QUERY, { contractCustomer })
}

export const anonymizeContractCustomer = (contractCustomerID: number): any => {
  const ANONYMIZE_CONTRACT_CUSTOMER_QUERY = gql`
    mutation anonymizeContractCustomer($contractCustomerID: Float!) {
      anonymizeContractCustomer(contractCustomerID: $contractCustomerID)
    }
  `
  return GraphqlService.send(ANONYMIZE_CONTRACT_CUSTOMER_QUERY, {
    contractCustomerID,
  })
}

export const updateContractCustomerWithMarketingConsents = ({
  contractCustomer,
  contractCustomerID,
  marketingConsent,
  marketingConsentID,
}: {
  contractCustomer: Partial<IContractCustomerInput>
  contractCustomerID: number
  marketingConsent: Partial<IMarketingConsentInput>
  marketingConsentID: number
}): any => {
  const UPDATE_CONTRACT_CUSTOMER_QUERY = gql`
    mutation sp(
      $contractCustomer: ContractCustomerInput!
      $contractCustomerID: Float!
      $marketingConsent: MarketingConsentInput!
      $marketingConsentID: Float!
    ) {
      updateContractCustomerWithMarketingConsent(
        contractCustomer: $contractCustomer
        contractCustomerID: $contractCustomerID
        marketingConsent: $marketingConsent
        marketingConsentID: $marketingConsentID
      ) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_CONTRACT_CUSTOMER_QUERY, {
    contractCustomer,
    contractCustomerID,
    marketingConsent,
    marketingConsentID,
  })
}

export const updateContractCustomerWithMarketingConsentsWithoutID = ({
  contractCustomer,
  contractCustomerID,
  marketingConsent,
}: {
  contractCustomer: Partial<IContractCustomerInput>
  contractCustomerID: number
  marketingConsent: Partial<IMarketingConsentInput>
}): any => {
  const UPDATE_CONTRACT_CUSTOMER_QUERY = gql`
    mutation sp(
      $contractCustomer: ContractCustomerInput!
      $contractCustomerID: Float!
      $marketingConsent: MarketingConsentInput!
    ) {
      updateContractCustomerWithMarketingConsent(
        contractCustomer: $contractCustomer
        contractCustomerID: $contractCustomerID
        marketingConsent: $marketingConsent
      ) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_CONTRACT_CUSTOMER_QUERY, {
    contractCustomer,
    contractCustomerID,
    marketingConsent,
  })
}

export const updateContractCustomer = ({
  contractCustomer,
  contractCustomerID,
  marketingConsent,
}: {
  contractCustomer: Partial<IContractCustomerInput>
  contractCustomerID: number
  marketingConsent: Partial<IMarketingConsentInput> | undefined
}): any => {
  const UPDATE_CONTRACT_CUSTOMER_QUERY = gql`
    mutation sp(
      $marketingConsent: MarketingConsentInput
      $contractCustomer: ContractCustomerInput!
      $contractCustomerID: Float!
    ) {
      updateContractCustomerWithMarketingConsent(
        contractCustomer: $contractCustomer
        contractCustomerID: $contractCustomerID
        marketingConsent: $marketingConsent
      ) {
        id
      }
    }
  `
  return GraphqlService.send(UPDATE_CONTRACT_CUSTOMER_QUERY, {
    contractCustomer,
    contractCustomerID,
    marketingConsent,
  })
}

export const saveContractCustomer = (
  contractCustomer: Partial<IContractCustomerInput>
): any => {
  const SAVE_CONTRACT_CUSTOMER_QUERY = gql`
    mutation($contractCustomer: ContractCustomerInput!) {
      saveContractCustomer(contractCustomer: $contractCustomer) {
        id
      }
    }
  `
  return GraphqlService.send(SAVE_CONTRACT_CUSTOMER_QUERY, { contractCustomer })
}

export const deleteContractCustomer = (
  contractCustomerID: number
): Promise<any> => {
  const DELETE_CONTRACT_CUSTOMER_QUERY = gql`
    mutation deleteContractCustomer($contractCustomerID: Float!) {
      deleteContractCustomer(contractCustomerID: $contractCustomerID)
    }
  `
  return GraphqlService.send(DELETE_CONTRACT_CUSTOMER_QUERY, {
    contractCustomerID,
  })
}

export const saveAttorneyFile = (attorneyFile: ISpouseFile): Promise<any> => {
  const SAVE_ATTORNEY_FILE = gql`
    mutation saveAttorneyFile($file: FileInput!) {
      saveFile(file: $file) {
        name
        created
        id
        contractCustomerID
        contractCompanyID
      }
    }
  `
  return GraphqlService.send(SAVE_ATTORNEY_FILE, { file: attorneyFile })
}

export const deleteAttorneyFile = (FileID: number): Promise<any> => {
  const DELETE_ATTORNEY_FILE = gql`
    mutation deleteFile($FileID: Float!) {
      deleteFile(fileID: $FileID)
    }
  `
  return GraphqlService.send(DELETE_ATTORNEY_FILE, { FileID })
}

export const getFileByID = (fileID: number): Promise<any> => {
  const FETCH_FILE_BY_ID = gql`
    query($fileID: Float!) {
      file(fileID: $fileID) {
        content
        name
      }
    }
  `
  return GraphqlService.send(FETCH_FILE_BY_ID, { fileID })
}

const mutations = {
  updateContractCustomerCode,
  addNewContractCustomer,
  anonymizeContractCustomer,
  deleteContractCustomer,
  fetchAllContractCustomers,
  updateContractCustomer,
  updateContractCustomerWithMarketingConsents,
  updateContractCustomerWithMarketingConsentsWithoutID,
  saveAttorneyFile,
  deleteAttorneyFile,
  getFileByID,
  saveContractCustomer,
}

export default mutations
