import React, { FunctionComponent } from 'react'

import MaskedInput from 'react-maskedinput'

interface IMaskedInputTextProps {
  customWidth?: number
  customSelectWidth?: number
  defaultValue?: string | number
  disabled?: boolean
  highlighted?: boolean
  label: string | number
  mask: string
  onChange?: (e: any) => void
  name?: string
  value: any
  reference?: any
}

export const MaskedInputText2: FunctionComponent<IMaskedInputTextProps> = ({
  customWidth,
  customSelectWidth,
  name,
  disabled,
  highlighted,
  label,
  mask,
  onChange,
  value,
  reference,
}) => {
  const stylesForInput = {
    padding: '0.5rem',
    width: customSelectWidth ? `${customSelectWidth}em` : '18em',
    backgroundColor: highlighted ? '#f2a291' : disabled ? '#ebebeb' : undefined,
  }

  return (
    <div className='d-flex align-items-center'>
      <label
        className='simple-input-label'
        style={{ width: customWidth ? `${customWidth}px` : '150px' }}
      >
        <span
          className='input-group-text simple-input-label-span'
          style={{ width: customWidth ? `${customWidth}em` : '10em' }}
        >
          {label}
        </span>
      </label>
      <div ref={reference}>
        <MaskedInput
          className='simple-input-masked-text'
          type='text'
          name={name}
          mask={mask}
          data-toggle='validator'
          disabled={disabled}
          onChange={onChange}
          value={value}
          role='form'
          style={stylesForInput}
        />
      </div>
    </div>
  )
}
