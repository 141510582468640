import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IAnnexFileInput } from '../services/annexFilesService'
import AnnexFilesService from '../services/annexFilesService'
import FileService from '../services/fileService'
import {
  errorToastNotify,
  successToastNotify,
} from '../components/commons/Toast/Toast'
import i18n from '../i18n'

export interface IAnnexFile extends IAnnexFileInput {
  id: number
  migrationComment?: string
}

interface IAnnexFilesState {
  arePlaceContractFilesLoading: boolean
  protocolsFiles: IAnnexFile[]
  annexFiles: IAnnexFile[]
  disclaimerFiles: IAnnexFile[]
  contractFile: IAnnexFile[]
  error: string | null
  isUploading: boolean
}

const initialState: IAnnexFilesState = {
  arePlaceContractFilesLoading: false,
  protocolsFiles: [],
  annexFiles: [],
  disclaimerFiles: [],
  contractFile: [],
  error: null,
  isUploading: false,
}

export const fetchAllFilesByContractID = createAsyncThunk(
  'annexFiles/fetchFiles',
  async (contractID: number) => {
    return AnnexFilesService.getFilesDetailsWithoutContentByContractID(
      contractID
    )
  }
)

export const addFileToContract = createAsyncThunk(
  'annexFiles/addFile',
  async (file: IAnnexFileInput) => {
    return AnnexFilesService.saveAnnexFileForContract(file)
  }
)

export const updateFileFromContract = createAsyncThunk(
  'annexFiles/updateFile',
  async ({
    file,
    fileID,
  }: {
    file: Partial<IAnnexFileInput>
    fileID: number
  }) => {
    return AnnexFilesService.updateAnnexFileFromContract(file, fileID)
  }
)

export const deleteAnnexFileFromContract = createAsyncThunk(
  'annexFiles/deleteAnnexFile',
  async (fileID: number) => {
    return AnnexFilesService.deleteAnnexFileForContract(fileID)
  }
)

export const deleteProtocolFileFromContract = createAsyncThunk(
  'annexFiles/deleteProtocolFile',
  async (fileID: number) => {
    return AnnexFilesService.deleteAnnexFileForContract(fileID)
  }
)

export const deleteDisclaimerFileFromContract = createAsyncThunk(
  'annexFiles/deleteDisclaimerFile',
  async (fileID: number) => {
    return AnnexFilesService.deleteAnnexFileForContract(fileID)
  }
)

export const getPlaceContractFileContentById = createAsyncThunk(
  'annexFiles/getPlaceContractFileContentById',
  async (variables: { fileID: number; fileName: string }) => {
    const result = await AnnexFilesService.fetchAnnexFileContentById(
      variables.fileID
    )

    if (result && result.file.content) {
      FileService.downloadFileSavedByUser({
        filename: variables.fileName,
        content: result.file.content,
      })
    }
  }
)

const annexFilesSlice = createSlice({
  name: 'annexFiles',
  initialState,
  reducers: {
    clearDownloadedFiles(state): void {
      state.disclaimerFiles = []
      state.annexFiles = []
      state.protocolsFiles = []
    },
  },
  extraReducers: {
    [fetchAllFilesByContractID.fulfilled.toString()]: (state, action): void => {
      const files = action.payload.getContract.files
      const newProtocolsFiles = files.filter(
        (file: IAnnexFile) => file.category === 'protocols'
      )
      const newAnnexFiles = files.filter(
        (file: IAnnexFile) => file.category === 'annex'
      )
      const newDisclaimerFiles = files.filter(
        (file: IAnnexFile) => file.category === 'disclaimer'
      )
      const newContractFile = files.filter(
        (file: IAnnexFile) => file.category === 'contractFile'
      )
      state.protocolsFiles = newProtocolsFiles
      state.annexFiles = newAnnexFiles
      state.disclaimerFiles = newDisclaimerFiles
      state.contractFile = newContractFile
      state.arePlaceContractFilesLoading = false
    },
    [fetchAllFilesByContractID.pending.toString()]: (state): void => {
      state.arePlaceContractFilesLoading = true
    },
    [fetchAllFilesByContractID.rejected.toString()]: (state): void => {
      state.arePlaceContractFilesLoading = false
    },
    [addFileToContract.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:addFile')))
      state.isUploading = false
    },
    [addFileToContract.pending.toString()]: (state): void => {
      state.isUploading = true
    },
    [addFileToContract.rejected.toString()]: (state): void => {
      state.isUploading = false
      errorToastNotify(String(i18n.t('toast:addFileError')))
    },
    [updateFileFromContract.fulfilled.toString()]: (state): void => {
      successToastNotify(String(i18n.t('toast:updateFile')))
      state.isUploading = false
    },
    [updateFileFromContract.pending.toString()]: (state): void => {
      state.isUploading = true
    },
    [updateFileFromContract.rejected.toString()]: (state): void => {
      errorToastNotify(String(i18n.t('toast:updateFileError')))
      state.isUploading = false
    },
    [deleteAnnexFileFromContract.fulfilled.toString()]: (): void => {
      successToastNotify(String(i18n.t('toast:removeFile')))
    },
    [deleteAnnexFileFromContract.rejected.toString()]: (): void => {
      errorToastNotify(String(i18n.t('toast:removeFileError')))
    },
  },
})

export const { clearDownloadedFiles } = annexFilesSlice.actions

export default annexFilesSlice.reducer
