import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../ducks'
import {
  fetchAndPrintReminderPDFFile,
  fetchReminderPDFFile,
} from '../ducks/reminder'

const useReminderPdf = () => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.globalUser)
  const generateReminderPDF = useCallback(() => {
    if (token) {
      dispatch(fetchReminderPDFFile(token))
    }
  }, [dispatch, token])

  const printReminderPDF = useCallback(() => {
    if (token) {
      dispatch(fetchAndPrintReminderPDFFile(token))
    }
  }, [dispatch, token])

  return {
    generateReminderPDF,
    printReminderPDF,
  }
}

export default useReminderPdf
