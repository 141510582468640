import React, { FunctionComponent } from 'react'

import { useCheckIfSmallResolution } from '../../../../hooks/useCheckIfSmallResolution'

import './SimpleInputs.scss'

export interface ISimpleInputTextProps {
  children?: JSX.Element
  customWidth?: number
  customInputWidth?: number
  disabled?: boolean
  highlighted?: boolean
  value: string | number | undefined
  isDoubleInput?: boolean
  isModal?: boolean
  label?: string | number
  max?: number
  min?: number
  placeholder?: string
  reference?: any
  required?: boolean
  step?: string
  type?: 'number' | 'text' | 'email'
  onChange?: (e: any) => void
  name?: string
  maxLength?: number
  isNumberInput?: boolean
}

export const SimpleInputText3: FunctionComponent<ISimpleInputTextProps> = React.memo(
  ({
    customWidth,
    customInputWidth,
    disabled,
    highlighted,
    value,
    onChange,
    isDoubleInput,
    isModal,
    label,
    max,
    min,
    placeholder,
    reference,
    required,
    step,
    type = 'text',
    name,
    maxLength,
    isNumberInput,
  }) => {
    const isSmallView = useCheckIfSmallResolution()
    return (
      <div className='d-flex align-items-center'>
        {label && (
          <label
            className='simple-input-label'
            style={{ width: customWidth ? `${customWidth}px` : '150px' }}
          >
            <span
              className='input-group-text simple-input-label-span'
              style={{ width: customWidth ? `${customWidth}em` : '10em' }}
            >
              {label}
            </span>
          </label>
        )}
        <div>
          <input
            value={value}
            className='simple-input-text'
            disabled={disabled}
            onChange={(n) => {
              if (
                !isNumberInput ||
                (!isNaN(Number(n.target.value)) &&
                  !n.target.value.includes('.'))
              ) {
                onChange && onChange(n)
              }
            }}
            style={{
              padding: '0.5rem',
              width:
                isSmallView && isModal
                  ? isDoubleInput
                    ? '4.25rem'
                    : '10rem'
                  : customInputWidth
                  ? `${customInputWidth}em`
                  : '18em',
              height: 'calc(1.5em + 0.75rem + 2px)',
              backgroundColor: highlighted
                ? '#f2a291'
                : disabled
                ? '#ebebeb'
                : undefined,
            }}
            max={max}
            min={min}
            maxLength={maxLength}
            placeholder={placeholder}
            ref={reference}
            required={required}
            step={step}
            type={type}
            name={name}
          />
        </div>
      </div>
    )
  }
)

export default SimpleInputText3
