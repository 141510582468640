import React, { FunctionComponent, useCallback, useState } from 'react'

import { useCheckIfSmallResolution } from '../../../../hooks/useCheckIfSmallResolution'

import './SimpleInputs.scss'

export interface ISimpleInputTextProps {
  children?: JSX.Element
  customWidth?: number
  customLabelSpanWidth?: number
  customInputWidth?: number
  defaultValue?: string | number
  disabled?: boolean
  highlighted?: boolean
  input?: {
    value: string
  }
  isDoubleInput?: boolean
  isModal?: boolean
  label?: string | number
  max?: number
  min?: number
  name?: string
  onChange?: (e: any) => void
  placeholder?: string
  readonly?: boolean
  reference?: any
  required?: boolean
  step?: string
  type?: 'number' | 'text'
  valueDependingOnExternalValues?: string | number
}

export const SimpleInputText: FunctionComponent<ISimpleInputTextProps> = React.memo(
  ({
    customWidth,
    customInputWidth,
    defaultValue,
    disabled,
    highlighted,
    input: inputFromReduxFormField,
    label,
    max,
    min,
    name,
    onChange,
    placeholder,
    readonly,
    reference,
    required,
    step,
    isDoubleInput,
    isModal,
    type = 'text',
    valueDependingOnExternalValues,
    customLabelSpanWidth,
  }) => {
    const valueNotZero =
      defaultValue === '0' || defaultValue === 0 ? '' : defaultValue

    const [value, setValue] = useState(valueNotZero)
    const onValueChange = useCallback(
      (e: any): void => {
        setValue(e.target.value)
        if (onChange) {
          return onChange(e)
        }
      },
      [onChange, setValue]
    )

    const isSmallView = useCheckIfSmallResolution()

    return (
      <div className='d-flex align-items-center'>
        {label && (
          <label
            className='simple-input-label'
            style={{ width: customWidth ? `${customWidth}px` : '150px' }}
          >
            <span
              className='input-group-text simple-input-label-span'
              style={{
                width: customLabelSpanWidth
                  ? `${customLabelSpanWidth}em`
                  : '10em',
              }}
            >
              {label}
            </span>
          </label>
        )}
        <div>
          <input
            {...inputFromReduxFormField}
            className='simple-input-text'
            disabled={disabled}
            value={valueDependingOnExternalValues ?? value ?? undefined}
            onChange={onValueChange}
            style={{
              padding: '0.5rem',
              width:
                isSmallView && isModal
                  ? isDoubleInput
                    ? '4.25rem'
                    : '10rem'
                  : customInputWidth
                  ? `${customInputWidth}em`
                  : '18em',
              height: 'calc(1.5em + 0.75rem + 2px)',
              backgroundColor: highlighted
                ? '#f2a291'
                : disabled
                ? '#ebebeb'
                : undefined,
            }}
            maxLength={max}
            minLength={min}
            name={name || ''}
            placeholder={placeholder}
            readOnly={readonly}
            ref={reference}
            required={required}
            step={step}
            type={type}
          />
        </div>
      </div>
    )
  }
)

export default SimpleInputText
