import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IRegisterPlaceForm } from '../components/SalesRegister/RegisterPlaceDataFormType'
import { IContact } from '../graphql/contacts'
import { ISpouseFileInput } from '../graphql/contractCustomers'
import { IInterestedCustomer } from '../graphql/customers'
import { IMarketingConsentInput } from '../graphql/marketingConsents'
import { IGarage, IGarageGroundPlanInput } from '../services/garagesService'

export interface ICopyOfAttorneyFile extends Partial<ISpouseFileInput> {
  contractID: number | undefined
  contractCustomerID: number | undefined
  contractCompanyID: number | undefined
  draftID: number
  isAgentOfMainCustomer?: boolean
  isAgentOfCustomerSpouse?: boolean
  isRepresentativePerson?: boolean
  uuid: string
}

interface IInterestedCustomerInPersistStore {
  customer: Partial<IInterestedCustomer> | null
  contact: Partial<IContact> | null
  marketingConsents: Partial<IMarketingConsentInput> | null
}

interface IPersistCopyOfFormStore {
  copyOfRegisterPlaceData: Partial<IRegisterPlaceForm> | null
  copyOfCustomersDataInNaturalPersonAgreement: any
  copyOfCustomersDataInLegalPersonAgreement: any
  copyOfInterestedCustomer: IInterestedCustomerInPersistStore
  copyOfGarage: {
    garage: Partial<IGarage> | null
    groundPlan: IGarageGroundPlanInput | null
  }
  copyOfParkingPlace: any
  copyOfUtilityRoom: any
  copyOfPlaceContract: {
    placeContract: any
    pledgePeriod: string | null
    warrantyPeriod: string | null
  }
  copyOfAttorneyFiles: ICopyOfAttorneyFile[]
  isAddAtorneyFileIntoPersistStoreLoading?: boolean
}

const initialState: IPersistCopyOfFormStore = {
  copyOfRegisterPlaceData: null,
  copyOfCustomersDataInNaturalPersonAgreement: null,
  copyOfCustomersDataInLegalPersonAgreement: null,
  copyOfInterestedCustomer: {
    contact: null,
    customer: null,
    marketingConsents: null,
  },
  copyOfGarage: {
    garage: null,
    groundPlan: null,
  },
  copyOfParkingPlace: null,
  copyOfUtilityRoom: null,
  copyOfPlaceContract: {
    placeContract: null,
    pledgePeriod: null,
    warrantyPeriod: null,
  },
  copyOfAttorneyFiles: [],
  isAddAtorneyFileIntoPersistStoreLoading: false,
}

export const deleteNaturalCustomerDataAfterFetchingInterestedCustomer = createAsyncThunk(
  'persistCopyOfForm/deleteNaturalCustomerDataAfterFetchingInterestedCustomer',
  async (uuid: string, thunkAPI) => {
    return thunkAPI.dispatch(
      deleteNaturalCustomerDataAfterFetchingInterestedCustomerAction(uuid)
    )
  }
)

export const deleteLegalCustomerDataAfterFetchingInterestedCustomer = createAsyncThunk(
  'persistCopyOfForm/deleteLegalCustomerDataAfterFetchingInterestedCustomer',
  async (uuid: string, thunkAPI) => {
    return thunkAPI.dispatch(
      deleteLegalCustomerDataAfterFetchingInterestedCustomerAction(uuid)
    )
  }
)

const persistCopyOfFormSlice = createSlice({
  name: 'persistCopyOfForm',
  initialState,
  reducers: {
    pasteRegisterPlaceDataIntoPersistStore(
      state,
      action: PayloadAction<any>
    ): void {
      state.copyOfRegisterPlaceData = action.payload
    },
    pasteCustomersDataInNaturalPersonAgreementIntoPersistStore(
      state,
      action: PayloadAction<any>
    ): void {
      state.copyOfCustomersDataInNaturalPersonAgreement = action.payload
    },
    pasteCustomersDataInLegalPersonAgreementIntoPersistStore(
      state,
      action: PayloadAction<any>
    ): void {
      state.copyOfCustomersDataInLegalPersonAgreement = action.payload
    },
    pasteInterestedCustomerIntoPersistStore(
      state,
      action: PayloadAction<any>
    ): void {
      state.copyOfInterestedCustomer = action.payload
    },
    pasteGarageIntoPersistStore(state, action: PayloadAction<any>): void {
      state.copyOfGarage = action.payload
    },
    pasteParkingPlaceIntoPersistStore(state, action: PayloadAction<any>): void {
      state.copyOfParkingPlace = action.payload
    },
    pasteUtilityRoomIntoPersistStore(state, action: PayloadAction<any>): void {
      state.copyOfUtilityRoom = action.payload
    },
    pastePlaceContractIntoPersistStore(
      state,
      action: PayloadAction<any>
    ): void {
      state.copyOfPlaceContract = action.payload
    },
    addAtorneyFileIntoPersistStore(
      state,
      action: PayloadAction<ICopyOfAttorneyFile>
    ): void {
      state.copyOfAttorneyFiles = [...state.copyOfAttorneyFiles, action.payload]
    },
    deleteAttorneyFileFromPersistStore(
      state,
      action: PayloadAction<number>
    ): void {
      state.copyOfAttorneyFiles = [...state.copyOfAttorneyFiles].filter(
        (file) => file.draftID !== action.payload
      )
    },
    deleteNaturalCustomerDataAfterFetchingInterestedCustomerAction(
      state,
      action: PayloadAction<string>
    ): void {
      const newCopy = state.copyOfCustomersDataInNaturalPersonAgreement
      delete newCopy[action.payload]
      state.copyOfCustomersDataInNaturalPersonAgreement =
        Object.keys(newCopy).length > 0 ? newCopy : null
    },
    deleteLegalCustomerDataAfterFetchingInterestedCustomerAction(
      state,
      action: PayloadAction<string>
    ): void {
      const newCopy = state.copyOfCustomersDataInLegalPersonAgreement
      delete newCopy[action.payload]
      state.copyOfCustomersDataInLegalPersonAgreement =
        Object.keys(newCopy).length > 0 ? newCopy : null
    },
    clearPersistRegisterSalesCopy(state): void {
      state.copyOfRegisterPlaceData = null
      state.copyOfCustomersDataInNaturalPersonAgreement = null
      state.copyOfCustomersDataInLegalPersonAgreement = null
      state.copyOfInterestedCustomer = {
        contact: null,
        customer: null,
        marketingConsents: null,
      }
      state.copyOfGarage = {
        garage: null,
        groundPlan: null,
      }
      state.copyOfParkingPlace = null
      state.copyOfUtilityRoom = null
      state.copyOfPlaceContract = {
        placeContract: null,
        pledgePeriod: null,
        warrantyPeriod: null,
      }
      state.copyOfAttorneyFiles = []
    },
    setIsAddAtorneyFileIntoPersistStoreLoading(
      state,
      action: PayloadAction<boolean>
    ): void {
      state.isAddAtorneyFileIntoPersistStoreLoading = action.payload
    },
  },
  extraReducers: {},
})

export const {
  pasteRegisterPlaceDataIntoPersistStore,
  pasteCustomersDataInNaturalPersonAgreementIntoPersistStore,
  pasteCustomersDataInLegalPersonAgreementIntoPersistStore,
  pasteInterestedCustomerIntoPersistStore,
  pasteGarageIntoPersistStore,
  pasteParkingPlaceIntoPersistStore,
  pasteUtilityRoomIntoPersistStore,
  pastePlaceContractIntoPersistStore,
  addAtorneyFileIntoPersistStore,
  deleteAttorneyFileFromPersistStore,
  deleteNaturalCustomerDataAfterFetchingInterestedCustomerAction,
  deleteLegalCustomerDataAfterFetchingInterestedCustomerAction,
  clearPersistRegisterSalesCopy,
  setIsAddAtorneyFileIntoPersistStoreLoading,
} = persistCopyOfFormSlice.actions

export default persistCopyOfFormSlice.reducer
