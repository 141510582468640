import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { useCheckIfSmallResolution } from '../../../../hooks/useCheckIfSmallResolution'

import './SimpleInputs.scss'

export interface IOptionElement {
  id: string | number
  name: string | number
}

interface ISimpleInputListProps {
  additionalOnChange?: (e: any) => void
  customLabelWidth?: number
  customLabelSpanWidth?: number
  customSelectWidth?: number
  disabled?: boolean
  highlighted?: boolean
  input?: {
    value: string
    name: string
  }
  isDoubleInput?: boolean
  isModal?: boolean
  label?: string | number
  options: IOptionElement[] | string[] | number[]
  defaultValue?: IOptionElement | string | number
  onChangeValue?: (selectedValue: any) => void
  required?: boolean
  name?: string
}

export const SimpleInputList: FunctionComponent<ISimpleInputListProps> = ({
  additionalOnChange,
  name,
  customLabelWidth,
  customSelectWidth,
  disabled,
  highlighted,
  input: inputFromReduxFormField,
  label,
  options,
  defaultValue = options[0] || { id: '', name: '' },
  onChangeValue,
  required,
  customLabelSpanWidth,
  isDoubleInput,
  isModal,
}) => {
  const getOptionsForSelect: IOptionElement[] = useMemo(
    () =>
      typeof options[0] === 'string' || typeof options[0] === 'number'
        ? [...options].map(
            (option: any): IOptionElement => {
              return { id: option, name: option }
            }
          )
        : (options as IOptionElement[]),
    [options]
  )

  const getDefaultValue: string | number = useMemo(
    () =>
      typeof defaultValue === 'string' || typeof defaultValue === 'number'
        ? (defaultValue as string | number)
        : (defaultValue?.id as string | number),
    [defaultValue]
  )

  const onChangeSelectedValue = useCallback(
    (event) => {
      const selectedValue: IOptionElement = [...getOptionsForSelect].find(
        (option: IOptionElement) =>
          option.id.toString() === event.target.value.toString()
      ) || { id: '', name: '' }
      onChangeValue && onChangeValue(selectedValue)
      if (additionalOnChange) {
        return additionalOnChange(event)
      }
    },
    [additionalOnChange, getOptionsForSelect, onChangeValue]
  )

  const isSmallView = useCheckIfSmallResolution()

  return (
    <div className='d-flex align-items-center'>
      <label
        className='simple-input-label'
        style={{
          width: customLabelSpanWidth ? `${customLabelSpanWidth}px` : '150px',
        }}
      >
        <span
          className='input-group-text simple-input-label-span'
          style={{ width: customLabelWidth ? `${customLabelWidth}em` : '10em' }}
        >
          {label}
        </span>
      </label>
      <div>
        <select
          id={`${label}-${inputFromReduxFormField?.name}`}
          name={name || inputFromReduxFormField?.value}
          onChange={onChangeSelectedValue}
          style={{
            padding: '0.5rem',
            width:
              isSmallView && isModal
                ? isDoubleInput
                  ? '4.25rem'
                  : '10rem'
                : customSelectWidth
                ? `${customSelectWidth}em`
                : '18em',
            marginLeft: '0em',
            backgroundColor: highlighted
              ? '#f2a291'
              : disabled
              ? '#ebebeb'
              : undefined,
          }}
          {...inputFromReduxFormField}
          className='form-control'
          value={getDefaultValue}
          disabled={disabled}
          required={required}
        >
          {getOptionsForSelect.map(({ id, name }) => (
            <option key={`option-${id}-${name}`} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default SimpleInputList
