import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch, RootState } from '../../ducks'
import {
  downloadXlsxReport,
  fetchDSPCustomerConcludedContractsReport,
} from '../../ducks/reports'
import { RaportWithDatesState } from './RaportWithDates/actions'
import { Dates } from './RaportWithDates/Dates'
import { Investments } from './RaportWithDates/Investments'
import { RaportWithDates } from './RaportWithDates/RaportWithDates'
import { Stages } from './RaportWithDates/Stages'
import { Traders } from './RaportWithDates/Traders'
import useAuthRedirect from '../../hooks/useAuthRedirect'
import ACCESS_PAGES from '../../constants/accessPages'

const DSPCustomerConcludedContractsReport: FC = () => {
  useAuthRedirect([ACCESS_PAGES.DSP_AND_DSPADM_REPORTS])
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(
    (state: RootState) =>
      state.combinedReports.DSPCustomerConcludedContractsReport.isLoading
  )

  const generateReport = React.useCallback(
    async (state: RaportWithDatesState) => {
      const investmentIDs = state.chosenInvestments.map(
        (investment) => investment?.id
      )
      const stagesIDs = state.chosenStages.map((stage) => stage.id)
      const response = await dispatch(
        fetchDSPCustomerConcludedContractsReport({
          fromDate: state.fromDate,
          toDate: state.toDate,
          investmentIDs,
          tradersInitials: state.chosenTraderInitials?.length
            ? state.chosenTraderInitials
            : undefined,
          stagesIDs,
        })
      )
      const content = response.payload.DSPCustomerConcludedContractsReport
      dispatch(
        downloadXlsxReport({
          content,
          filename: 'Zainteresowani - zawarte umowy.xlsx',
        })
      ).then(() => {
        history.push('/')
      })
    },
    [dispatch, history]
  )
  return (
    <RaportWithDates isLoading={isLoading} generateReport={generateReport}>
      <Dates />
      <Investments />
      <Stages />
      <Traders />
    </RaportWithDates>
  )
}

export default DSPCustomerConcludedContractsReport
