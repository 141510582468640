import { PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates'
import { RootState } from '../../ducks'
import { getInterestedCustomerMarketingConsentsFromContractCustomer } from '../../ducks/registerSales'
import { IContractCustomerCompanyInput } from '../../graphql/contractCustomerCompany'
import { IContractCustomerInput } from '../../graphql/contractCustomers'
import {
  IMarketingConsent,
  IMarketingConsentInput,
} from '../../graphql/marketingConsents'

interface ICustomerMarketingConsents {
  id: number
  isInfoDutyFulfilled: boolean
  infoDutyForm: string
  infoDutyFormDate: string | null
  agreementEmailCancellationDate: string | null
  agreementEmailDate: string | null
  agreementPhoneCancellationDate: string | null
  agreementPhoneDate: string | null
  isAgreementEmail: boolean
  isAgreementEmailCancelled: boolean
  isAgreementPhone: boolean
  isAgreementPhoneCancelled: boolean
  isMSWiAAgreement: boolean
  dispatch?: React.Dispatch<any>
  canEdit?: boolean
}

type CustomerMarketingConsentsContextProviderProps = {
  customer: IContractCustomerInput | IContractCustomerCompanyInput
}

interface ICustomerMarketingConsentsContext {
  data: ICustomerMarketingConsents
}

type useReducerReturnType = {
  state: ICustomerMarketingConsentsContext
  updateContextFromInput: (e: any) => void
  dispatch: React.Dispatch<any>
}

export const initialCustomerMarketingConsentsContext: ICustomerMarketingConsentsContext = {
  data: {
    id: -1,
    isInfoDutyFulfilled: false,
    infoDutyForm: '',
    infoDutyFormDate: null,
    agreementEmailCancellationDate: null,
    agreementEmailDate: null,
    agreementPhoneCancellationDate: null,
    agreementPhoneDate: null,
    isAgreementEmail: false,
    isAgreementEmailCancelled: false,
    isAgreementPhone: false,
    isAgreementPhoneCancelled: false,
    isMSWiAAgreement: false,
  },
}

export enum CustomerMarketingConsents {
  LoadConsents = 'LOAD_CONSENTS',
  UpdateMarketingConsents = 'UPDATE_CONSENTS',
  ClearConsents = 'CLEAR_CONSENTS',
}

export const loadUserConsents = (data: any): PayloadAction<any> => {
  return {
    type: CustomerMarketingConsents.LoadConsents,
    payload: data,
  }
}

export const updateConsents = (data: any): PayloadAction<any> => {
  return {
    type: CustomerMarketingConsents.UpdateMarketingConsents,
    payload: data,
  }
}

export const clarUserConsents = (): PayloadAction<any> => {
  return {
    type: CustomerMarketingConsents.LoadConsents,
    payload: '',
  }
}

const CustomerMarketingConsentsContext = React.createContext(
  initialCustomerMarketingConsentsContext
)

export const customerMarketingConsentsReducer = (
  state: ICustomerMarketingConsentsContext,
  action: any
): any => {
  switch (action.type) {
    case CustomerMarketingConsents.LoadConsents: {
      return { ...state, data: action.payload }
    }
    case CustomerMarketingConsents.UpdateMarketingConsents: {
      return {
        ...state,
        data: action.payload,
      }
    }
    case CustomerMarketingConsents.ClearConsents: {
      return initialCustomerMarketingConsentsContext
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const useCustomerMarketingConsentsContext = (): any => {
  const context = React.useContext(CustomerMarketingConsentsContext).data
  if (!context) {
    throw new Error(
      'Cant use useCustomerMarketingConsentsContext outside of CustomerMarketingConsentsContextProvider '
    )
  }
  return context
}

export const useMarketingConsentsReducer = (
  data?: IMarketingConsent
): useReducerReturnType => {
  const [state, reducerDispatch] = React.useReducer(
    customerMarketingConsentsReducer,
    initialCustomerMarketingConsentsContext
  )
  const updateContextFromInput = React.useCallback(
    (e: any) => {
      if (e?.target?.name && e?.target?.value) {
        const isCheckbox = e.target.type === 'checkbox'
        const newValues = {
          ...state.data,
          [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }
        reducerDispatch(updateConsents(newValues))
      } else if (e?.name && e?.day !== undefined) {
        const newValues = {
          ...state.data,
          [e.name]: e.day ? moment(e.day).format(DATE_FORMAT_YYYYMMDD) : null,
        }
        reducerDispatch(updateConsents(newValues))
      }
    },
    [state.data]
  )

  React.useEffect(() => {
    if (data) {
      reducerDispatch(loadUserConsents(data))
    }
  }, [data])
  return { state, updateContextFromInput, dispatch: reducerDispatch }
}

export const CustomerMarketingConsentsContextProvider: React.FC<CustomerMarketingConsentsContextProviderProps> = ({
  children,
  customer,
}): JSX.Element => {
  const [state, dispatch] = React.useReducer(
    customerMarketingConsentsReducer,
    initialCustomerMarketingConsentsContext
  )
  const { interestedCustomers } = useSelector(
    (state: RootState) => state.interestedCustomers
  )
  const interestedCustomerMarketingConsents = useSelector(
    getInterestedCustomerMarketingConsentsFromContractCustomer(
      customer.customerID
    )
  ) as IMarketingConsentInput[]
  const marketingConsents = customer?.marketingConsents
  const usedInterestedCustomer = React.useMemo(
    () => interestedCustomers.filter((ic) => ic.id === customer?.customerID),
    [customer, interestedCustomers]
  )
  const canEdit = usedInterestedCustomer?.length
  const customersFirstMarketingConsent = React.useMemo(
    () =>
      usedInterestedCustomer[0]?.marketingConsents?.length &&
      usedInterestedCustomer[0]?.marketingConsents[0]
        ? usedInterestedCustomer[0]?.marketingConsents[0]
        : undefined,
    [usedInterestedCustomer]
  )

  const newConsents = React.useMemo(
    () =>
      interestedCustomerMarketingConsents
        ? [customersFirstMarketingConsent]
        : marketingConsents,
    [
      customersFirstMarketingConsent,
      interestedCustomerMarketingConsents,
      marketingConsents,
    ]
  )

  React.useEffect(() => {
    if (newConsents?.[0]) {
      dispatch(loadUserConsents(newConsents[newConsents.length - 1]))
    }
  }, [newConsents])

  const value = React.useMemo(() => {
    return {
      ...state,
      dispatch,
      canEdit,
    }
  }, [state, canEdit])

  return (
    <CustomerMarketingConsentsContext.Provider value={{ data: value }}>
      {children}
    </CustomerMarketingConsentsContext.Provider>
  )
}
